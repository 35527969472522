import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { v4 as uuidv4 } from 'uuid'
import { RootState } from '../redux/store'
import { IExtendedCompany, ILCHttpResponse } from '@logicalcommander/types'
import { configManager } from '../../config/configManager'

export const companyApi = createApi({
  reducerPath: 'companyApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${configManager.api.GATEWAY_URL}/company`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('x-request-id', uuidv4())
      const { token, companyToken } = (getState() as RootState).authReducer
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      if (companyToken) {
        headers.set('x-company-token', companyToken)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    getExtendedCompany: builder.query<IExtendedCompany, void>({
      query: () => ({
        url: `/extended`,
      }),
      transformResponse: (rawResult: ILCHttpResponse<IExtendedCompany>, meta) => {
        return rawResult.data as IExtendedCompany
      },
    }),
  }),
})

export const { useLazyGetExtendedCompanyQuery } = companyApi
