import './emo-risk-dashboard.scss'
import React, { CSSProperties, FC, HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import { IDashboardDataAttendanceBar, BarColor, CustomLabels } from '../../../../../interfaces/dashboard.interface'
import { LCBarAttendanceNew } from '../../../../ui/components/charts/lc-bar-attendance'
import { BaseDashboardComponent } from '../../../../ui/components/base-dashboard/BaseDashboardComponent'
import { DashboardComponentTitle } from '../../../../ui/components/base-dashboard/DashboardComponentTitle'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

const EmoRiskMenAndWomanEmotion: FC<Props> = ({ customStyle }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.emoRisk.riskMenAndWomanEmotion' })

  const isLoading = false
  const error = false

  const data: IDashboardDataAttendanceBar[] = [
    {
      title: 'Energy',
      value1: 50,
      value2: 20,
    },
    {
      title: 'Happiness',
      value1: 30,
      value2: 50,
    },
    {
      title: 'Anger',
      value1: 10,
      value2: 30,
    },
    {
      title: 'Stress',
      value1: 50,
      value2: 30,
    },
    {
      title: 'Excitement',
      value1: 50,
      value2: 30,
    },
    {
      title: 'Sadness',
      value1: 10,
      value2: 60,
    },
    {
      title: 'Mental Load',
      value1: 20,
      value2: 30,
    },
    {
      title: 'Discomfort',
      value1: 20,
      value2: 60,
    },
    {
      title: 'Extreme Emotion',
      value1: 50,
      value2: 20,
    },
    {
      title: 'Emotional Stress',
      value1: 30,
      value2: 10,
    },
    {
      title: 'Dissatisfaction',
      value1: 50,
      value2: 20,
    },
    {
      title: 'Burnout',
      value1: 30,
      value2: 20,
    },
    {
      title: 'Depression',
      value1: 10,
      value2: 10,
    },
  ]

  return (
    <BaseDashboardComponent customStyle={customStyle} sectionTitle={<DashboardComponentTitle title={t('title')} />} isLoading={isLoading} isSuccess={!!data} isError={!!error}>
      <LCBarAttendanceNew
        data={data}
        options={{
          emotionRisk: 'default',
          color: BarColor.COLOR_WOMEN_MEN,
          label: CustomLabels.EMO_RISK_ATTENDANCE_BAR_WOMAN_MEN,
        }}
      />
    </BaseDashboardComponent>
  )
}

export default EmoRiskMenAndWomanEmotion
