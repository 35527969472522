import React, { CSSProperties, HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik';
import { LCButton } from '../../../../ui/components/lc-button/LCButton'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

export const SettingsEmoRisk = ({ customStyle }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'settings.emoRisk' })

  const initialValues: any = {}
  const validationSchema = {}

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: (values: any) => {
      console.log('onSubmit', values)
    },
  })

  console.log(formik.values)

  return (
    <div style={customStyle}>
      <form className="form" onSubmit={formik.handleSubmit}>
        <h3 className='first-h3'>{t('title')}</h3>

        <LCButton
          label={t('updateButton')}
          customStyle={{ marginTop: 30 }}
          type='submit'
          disabled={false} //!isValidForm
        />
      </form>
    </div>
  )
}
