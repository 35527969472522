import { SVGProps } from 'react'

const SvgPlay = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="10" cy="10" r="10" fill="#46C4D7" />
    <path
      d="M7.47619 14C7.55556 14 7.63095 13.9827 7.70238 13.948C7.77381 13.9165 7.84921 13.8724 7.92857 13.8157L12.5556 10.6308C12.7222 10.5143 12.8373 10.4119 12.9008 10.3237C12.9669 10.2323 13 10.1236 13 9.99764C13 9.87163 12.9669 9.76452 12.9008 9.67631C12.8373 9.58496 12.7222 9.48258 12.5556 9.36917L7.92857 6.17956C7.84921 6.12601 7.77381 6.08348 7.70238 6.05198C7.63095 6.01733 7.55556 6 7.47619 6C7.33069 6 7.21429 6.06143 7.12698 6.18429C7.04233 6.30715 7 6.47096 7 6.67572V13.3196C7 13.5243 7.04233 13.6881 7.12698 13.811C7.21429 13.937 7.33069 14 7.47619 14Z"
      fill="#F4F7FE"
    />
  </svg>
)

export default SvgPlay
