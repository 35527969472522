// import './settings-riskhr.scss'
import React, { CSSProperties, HTMLAttributes, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { LCButton } from '../../../../../ui/components/lc-button/LCButton'
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks'
import { IDropdownOption, LCDropdown } from '../../../../../ui/components/lc-dropdown/LCDropdown'
import { LCFormInputText } from '../../../../../ui/components/forms/lc-form-inputText/lc-form-inputText'
import { LCFormCheckbox } from '../../../../../ui/components/forms/lc-form-checkBox/lc-form-checkBox'
import * as Yup from 'yup'
import { selectSettings } from '../../../../../redux/reducers/settingsSlice'
import {
  useCreateCustomAssessmentMutation,
  useLazyGetAssessmentsInfoQuery,
  useLazyGetAssessmentsTopicsQuery,
} from '../../../../../services/assessments.service'
import { isEmpty } from '../../../../../utils/object'
import { setAssessmentsInfo } from '../../../../../redux/reducers/assessmentsSlice'
import { LCRadioButton, eLCRadioButtonLayout } from '../../../../../ui/components/lc-radio-button/LCRadioButton'
import { eAssessmentSizeId, eAssessmentTypeId } from '@logicalcommander/types'
import { toast } from 'react-toastify'
import { LCToast } from '../../../../../ui/components/lc-toast/Toast'

interface IInitialValues {
  nameId: string
  [topicId: string]: boolean | string
}

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

export const SettingsRiskHrCreate = ({ customStyle }: Props) => {
  const [getAssessmentsTopics, { data }] = useLazyGetAssessmentsTopicsQuery()
  const [updateCustomAssessment, { data: dataUpdate, error: errorUpdate }] = useCreateCustomAssessmentMutation()
  const [getAssessmentsInfo, { data: dataTypes, error: errorTypes }] = useLazyGetAssessmentsInfoQuery()
  const { riskHR } = useAppSelector(selectSettings)
  const dispatch = useAppDispatch()

  const { t: s } = useTranslation('translation', { keyPrefix: 'settings.assessments' })
  const { t: v } = useTranslation('translation', { keyPrefix: 'validation' })
  const { t: l } = useTranslation('translation', { keyPrefix: 'languages' })
  const { t } = useTranslation('translation', { keyPrefix: 'settings.riskHr' })

  const [selectedSizeId, setSelectedSizeId] = useState<eAssessmentSizeId>(eAssessmentSizeId.DEMO)
  const [selectedTypeId, setSelectedTypeId] = useState<eAssessmentTypeId>(eAssessmentTypeId.PERIODIC)

  // Prepare the dropdowns data
  const assessmentsTypesDropdown: IDropdownOption[] = ['200', '300'].map((i) => ({ label: s(`types.${i}`), value: i }))
  const supportedLanguages: IDropdownOption[] = riskHR.assessments.language.map((i) => ({ label: l(i), value: i }))

  const radioOptions = [
    { label: s('sizes.3'), value: '3' },
    { label: s('sizes.1'), value: '1' },
    { label: s('sizes.2'), value: '2' },
  ]

  // const [selectedAssessmentTypeName, setSelectedAssessmentTypeName] = useState<string>()
  const [totalTopicsSelected, setTotalTopicsSelected] = useState<number>(0)
  const [selectedLanguage, setSelectedLanguage] = useState<string>('en')

  const totalTopicsAllowed = selectedSizeId === eAssessmentSizeId.COMPREHENSIVE ? { min: 4, max: 22 } : { min: 1, max: 3 }

  const initialValues: IInitialValues = {
    nameId: '',
  }

  const validationSchema = Yup.object().shape({
    nameId: Yup.string()
      .min(5, v('short', { field: t('assessmentName'), short: 5, long: 50 }))
      .max(50, v('long', { field: t('assessmentName'), short: 5, long: 50 }))
      .required(v('required', { field: t('assessmentName') })),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: (values: any) => {
      console.log('onSubmit', values)
      const assessmentToCreate: any = {
        sizeId: +selectedSizeId,
        typeId: +selectedTypeId,
        customName: values.nameId,
        topics: Object.keys(values)
          .filter((i) => values[i] === true)
          .map((a) => +a),
      }
      console.log('assessmentToCreate', assessmentToCreate)
      updateCustomAssessment(assessmentToCreate)
    },
  })

  useEffect(() => {
    getAssessmentsTopics('en')
  }, [])

  useEffect(() => {
    if (!errorUpdate && dataUpdate) {
      toast.success(<LCToast title={t('successCreatingAssessment.title')} body={t('successCreatingAssessment.body')} />)
      getAssessmentsInfo()
    } else if (errorUpdate) {
      if ((errorUpdate as any).status === 409) {
        toast.error(
          <LCToast
            title={t('errorAssessmentNameAlreadyExists.title')}
            body={t('errorAssessmentNameAlreadyExists.body', { nameId: formik.values.nameId, typeId: selectedTypeId })}
          />
        )
      } else {
        toast.error(<LCToast title={t('errorCreatingAssessment.title')} body={t('errorCreatingAssessment.body')} />)
      }
    }
  }, [dataUpdate, errorUpdate])

  useEffect(() => {
    if (dataTypes) {
      console.log('SettingsRiskHrCreate:: Received assessments types:', dataTypes)
      dispatch(setAssessmentsInfo(dataTypes))
      formik.resetForm()
    } else if (errorTypes) {
      console.error('SettingsRiskHrCreate:: Error fetching assessments types:', errorTypes)
    }
  }, [dataTypes, errorTypes, dispatch])

  const onSelectedAssesstmentTypeChanged = (event: any) => {
    formik.resetForm()
    setSelectedTypeId(event?.value)
  }

  useEffect(() => {
    setTotalTopicsSelected(Object.values(formik.values).filter((i) => i === true).length)
  }, [formik.values])

  const isFormValid = (): boolean => {
    if (formik.values.nameId === '') return false
    if (!isEmpty(formik.errors)) return false
    return isTopicsSelectedCountValid()
  }

  const isTopicsSelectedCountValid = (): boolean => {
    const selectedTopicsCount = Object.values(formik.values).filter((i) => i === true).length
    if (totalTopicsAllowed && (selectedTopicsCount > totalTopicsAllowed?.max || selectedTopicsCount < totalTopicsAllowed?.min)) {
      return false
    }
    return true
  }

  // console.log('formik.values', formik.values)
  // console.log('formik.errors', formik.errors)
  // console.log('formik.touched', formik.touched)
  // console.log('======== data', data)

  console.log('totalTopicsAllowed', totalTopicsAllowed)

  const handleRadioChange = (selectedValue: string) => {
    setSelectedSizeId(+selectedValue)
  }

  const onSelectedAssesstmentLanguageChanged = (event: any) => {
    event?.value && setSelectedLanguage(event.value)
    getAssessmentsTopics(event.value)
  }

  return (
    <div className="settings-riskhr-container" style={customStyle}>
      <form className="settings-riskhr-form" onSubmit={formik.handleSubmit}>
        <h3>{t('title')}</h3>
        <div className="select-role">
          {t('selectAssessmentSize')}
          <LCRadioButton
            selected={selectedSizeId + ''}
            layout={eLCRadioButtonLayout.HORIZONTAL}
            options={radioOptions}
            name="assessmentSize"
            onChange={handleRadioChange}
          />
        </div>

        <div className="select-role">
          {t('selectAssessmentType')}
          {assessmentsTypesDropdown ? (
            <LCDropdown
              options={assessmentsTypesDropdown}
              defaultOption={selectedTypeId + ''}
              onChange={onSelectedAssesstmentTypeChanged}
            />
          ) : null}

          <LCDropdown options={supportedLanguages} defaultOption={selectedLanguage} onChange={onSelectedAssesstmentLanguageChanged} />

          <LCFormInputText
            inputId="nameId"
            inputType="text"
            placeholder={t('assessmentName')}
            onChange={formik.handleChange}
            error={formik.errors.nameId}
            value={formik.values.nameId}
          />
          <LCButton label={t('createRiskHrAssessments')} customStyle={{ marginTop: 5 }} type="submit" disabled={!isFormValid()} />
        </div>

        <div className="description">{t('description')}</div>
        <div className={`sub-description${isTopicsSelectedCountValid() ? '' : ' invalid'}`}>
          {t('subDescription', { min: totalTopicsAllowed?.min, max: totalTopicsAllowed?.max })}
        </div>

        <div className="assessment-details">
          <div className="create-assessment">
            <h3>{s(`types.${selectedTypeId}`) + ' ➙ ' + formik.values.nameId}</h3>
            <span>{t('selectedTopics', { selected: totalTopicsSelected, total: totalTopicsAllowed?.max })}</span>
          </div>

          {data
            ? Object.keys(data).map((topicId: string) => {
                return (
                  <div key={topicId}>
                    <div className="topic-text">
                      <LCFormCheckbox
                        inputId={topicId}
                        label={data[topicId]}
                        disabled={totalTopicsSelected >= totalTopicsAllowed?.max && (formik.values[topicId] as boolean) !== true}
                        checked={formik.values[topicId] as boolean}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                )
              })
            : null}
        </div>
      </form>
    </div>
  )
}
