import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { v4 as uuidv4 } from 'uuid'
import { RootState } from '../redux/store'
import { configManager } from '../../config/configManager'
import {
  ICompanyInfo,
  IEmailSettings,
  IEnumsPositionGroupSettingsItem,
  IEnumsSettings,
  ILCHttpResponse,
  ISavedFiltersSettings,
  ISearch,
  ISettings,
  ITmpURL,
  IUXSettings,
  eTableName,
} from '@logicalcommander/types'
import { ISettingUpdatePermissions } from '../pages/Settings/components/permissions/permissions-helper'
import { eSettingsEnumKind } from '../pages/Settings/components/enumerated/SettingsEnumItem'

export interface IDeleteSavedFilter {
  name: string
  tableName: eTableName
}
export interface IAddSavedFilter extends IDeleteSavedFilter {
  filters: ISearch[]
}
export interface IUpdateSeveritySettings {
  severityPositionsGroups?: {
    id: number;
    topicsTRF: { [key: string]: number };
  }[];
  severityPositions?: {
    id: number;
    topicsTRF: { [key: string]: number };
  }[];
}

export const settingsApi = createApi({
  reducerPath: 'settingsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${configManager.api.GATEWAY_URL}/settings`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('x-request-id', uuidv4())
      const { token, companyToken } = (getState() as RootState).authReducer
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      if (companyToken) {
        headers.set('x-company-token', companyToken)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    getSettings: builder.query<ISettings, void>({
      query: () => ({
        url: '',
      }),
      transformResponse: (rawResult: ILCHttpResponse<ISettings>, meta) => {
        return rawResult.data as ISettings
      },
    }),
    updateSeveritySettings: builder.mutation<IUpdateSeveritySettings, Partial<IUpdateSeveritySettings>>({
      query: (body) => ({
        url: '/severity',
        method: 'PUT',
        body,
      }),
      transformResponse: (rawResult: ILCHttpResponse<IUpdateSeveritySettings>, meta) => {
        return rawResult.data as IUpdateSeveritySettings;
      },
    }),
    updatePermissions: builder.mutation<ISettingUpdatePermissions, Partial<ISettingUpdatePermissions>>({
      query: (body) => ({
        url: '/permissions',
        method: 'PUT',
        body,
      }),
      transformResponse: (rawResult: ILCHttpResponse<ISettingUpdatePermissions>, meta) => {
        return rawResult.data as ISettingUpdatePermissions
      },
    }),
    updateCompanyInfoSettings: builder.mutation<ICompanyInfo, Partial<ICompanyInfo>>({
      query: (body) => ({
        url: '/company-info',
        method: 'PUT',
        body,
      }),
      transformResponse: (rawResult: ILCHttpResponse<ICompanyInfo>, meta) => {
        return rawResult.data as ICompanyInfo
      },
    }),
    updateUXSettings: builder.mutation<IUXSettings, Partial<IUXSettings>>({
      query: (body) => ({
        url: '/ux',
        method: 'PUT',
        body,
      }),
      transformResponse: (rawResult: ILCHttpResponse<IUXSettings>, meta) => {
        return rawResult.data as IUXSettings
      },
    }),
    addEnumSettingsItem: builder.mutation<IEnumsSettings, { type: eSettingsEnumKind; name: string }>({
      query: (body) => ({
        url: '/enum',
        method: 'POST',
        body,
      }),
      transformResponse: (rawResult: ILCHttpResponse<IEnumsSettings>, meta) => {
        return rawResult.data as IEnumsSettings
      },
    }),
    deleteEnumSettingsItem: builder.mutation<IEnumsSettings, { type: eSettingsEnumKind; id: number }>({
      query: (body) => ({
        url: '/enum',
        method: 'DELETE',
        body,
      }),
      transformResponse: (rawResult: ILCHttpResponse<IEnumsSettings>, meta) => {
        return rawResult.data as IEnumsSettings
      },
    }),
    updateEnumSettingsItem: builder.mutation<IEnumsSettings, { type: eSettingsEnumKind; id: number; name: string }>({
      query: (body) => ({
        url: '/enum',
        method: 'PUT',
        body,
      }),
      transformResponse: (rawResult: ILCHttpResponse<IEnumsSettings>, meta) => {
        return rawResult.data as IEnumsSettings
      },
    }),
    updateGroupPositions: builder.mutation<IEnumsPositionGroupSettingsItem[], IEnumsPositionGroupSettingsItem[]>({
      query: (body) => ({
        url: '/enum/groups/positions',
        method: 'PUT',
        body,
      }),
      transformResponse: (rawResult: ILCHttpResponse<IEnumsPositionGroupSettingsItem[]>, meta) => {
        return rawResult.data as IEnumsPositionGroupSettingsItem[]
      },
    }),
    updateEmailSettings: builder.mutation<IEmailSettings, Partial<IEmailSettings>>({
      query: (body) => ({
        url: '/emails',
        method: 'PUT',
        body,
      }),
      transformResponse: (rawResult: ILCHttpResponse<IEmailSettings>, meta) => {
        return rawResult.data as IEmailSettings
      },
    }),
    addFilter: builder.mutation<ISavedFiltersSettings[], IAddSavedFilter>({
      query: (body) => ({
        url: '/filter',
        method: 'POST',
        body,
      }),
      transformResponse: (rawResult: ILCHttpResponse<ISavedFiltersSettings[]>, meta) => {
        return rawResult.data as ISavedFiltersSettings[]
      },
    }),
    deleteFilter: builder.mutation<ISavedFiltersSettings[], IDeleteSavedFilter>({
      query: (body) => ({
        url: '/filter',
        method: 'DELETE',
        body,
      }),
      transformResponse: (rawResult: ILCHttpResponse<ISavedFiltersSettings[]>, meta) => {
        return rawResult.data as ISavedFiltersSettings[]
      },
    }),
    getCompanyLogoTmpUrl: builder.query<ITmpURL, void>({
      query: () => ({
        url: '/tmp-url/logo',
      }),
      transformResponse: (rawResult: ILCHttpResponse<ITmpURL>, meta) => {
        return rawResult.data as ITmpURL
      },
    }),
  }),
})

export const {
  useLazyGetSettingsQuery,
  useUpdateCompanyInfoSettingsMutation,
  useUpdateUXSettingsMutation,
  useUpdatePermissionsMutation,
  useUpdateSeveritySettingsMutation,
  useAddFilterMutation,
  useDeleteFilterMutation,
  useAddEnumSettingsItemMutation,
  useDeleteEnumSettingsItemMutation,
  useUpdateEnumSettingsItemMutation,
  useUpdateEmailSettingsMutation,
  useUpdateGroupPositionsMutation,
  useLazyGetCompanyLogoTmpUrlQuery,
} = settingsApi
