import './settings-permissions-by-role.scss'
import React, { CSSProperties, HTMLAttributes, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { LCButton } from '../../../../ui/components/lc-button/LCButton'
import { LCFormToggle } from '../../../../ui/components/forms/lc-form-toggle/lc-form-toggle'
import { IDropdownOption, LCDropdown } from '../../../../ui/components/lc-dropdown/LCDropdown'
import { IGroupedPermissions, IRolesAndPermissions, ePermission, eRoleType } from '@logicalcommander/types'
import { cachedStorage, eStorageKey } from '../../../../utils/cachedStorage'
import { showLoader } from '../../../../redux/reducers/generalSlice'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux-hooks'
import { ISettingPermission, ISettingUpdatePermissions } from './permissions-helper'
import { useUpdatePermissionsMutation } from '../../../../services/settings.service'
import { getAllRolesDropdown } from '../../../../utils/list-helper'
import { selectAllRoles } from '../../../../redux/reducers/settingsSlice'
import { toast } from 'react-toastify'
import { LCToast } from '../../../../ui/components/lc-toast/Toast'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

export const SettingsPermissionsByRole = ({ customStyle }: Props) => {
  const [updateCompanyPermissions, { data: dataUpdate, error: errorUpdate }] = useUpdatePermissionsMutation()
  const { t } = useTranslation('translation', { keyPrefix: 'settings.permissions' })
  const [rolesAndPermissions, setRolesAndPermissions] = useState<IRolesAndPermissions[]>()
  const [dropdownRoles, setDropdownRoles] = useState<IDropdownOption[]>()
  const [selectedRole, setSelectedRole] = useState<IRolesAndPermissions>()
  const groupedPermissions: IGroupedPermissions | undefined = cachedStorage.get<IGroupedPermissions>(eStorageKey.GROUPED_PERMISSIONS)
  const dispatch = useAppDispatch()
  const roles: IRolesAndPermissions[] = useAppSelector(selectAllRoles)

  const initialValues: ISettingPermission = {}

  useEffect(() => {
    const dropdownRoles: IDropdownOption[] = getAllRolesDropdown(roles)
    setDropdownRoles(dropdownRoles)
    const selectedDropdownRole = dropdownRoles[0]
    setRolesAndPermissions(roles)
    dispatch(showLoader(false))
    updateSelectedRole(selectedDropdownRole.value)
  }, [])

  useEffect(() => {
    if (!errorUpdate && dataUpdate) {
      toast.success(<LCToast title={t('successUpdatingPermissions.title')} body={t('successUpdatingPermissions.body')} />)
    } else if (errorUpdate) {
      toast.error(<LCToast title={t('errorUpdatingPermissions.title')} body={t('errorUpdatingPermissions.body')} />)
    }
  }, [dataUpdate, errorUpdate])

  const updateSelectedRole = (roleName: string) => {
    const selectedRole: IRolesAndPermissions | undefined = roles?.find((r) => r.role === roleName)
    if (selectedRole) {
      setSelectedRole(selectedRole)
    }
    console.log('useEffect:: init: setSelectedRole for the first time', selectedRole)

    if (selectedRole?.permissions) {
      const truePermissions = selectedRole.permissions
      const gPermissions: ISettingPermission = convertToFlatObject(groupedPermissions, truePermissions)
      console.log('gPermissions', gPermissions)
      formik.setValues(gPermissions)
    }
  }

  const convertToFlatObject = (gPermissions: any, truePermissions: string[]): ISettingPermission => {
    const result: { [key: string]: boolean } = {}
    for (const key in gPermissions) {
      if (Object.prototype.hasOwnProperty.call(gPermissions, key)) {
        const values = gPermissions[key]
        values.forEach((value: any) => {
          result[value] = truePermissions.includes(value as ePermission)
        })
      }
    }
    return result
  }

  const validationSchema = Yup.object().shape({})

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: (values: any) => {
      console.log('onSubmit', values)
      const update: ISettingUpdatePermissions = {
        [selectedRole?.role as string]: Object.keys(values).map((pName) => {
          return { [pName]: values[pName] }
        }),
      }
      updateCompanyPermissions(update)
    },
  })

  const onSelectedRoleChanged = (event: any) => {
    const selectedDropdownRole = dropdownRoles?.find((r) => r.value === event?.value)
    console.log('onSelectedRoleChanged:: Selected role: ', selectedDropdownRole)
    const selectedRole: IRolesAndPermissions | undefined = rolesAndPermissions?.find((r) => r.role === event?.value)
    if (selectedRole) {
      updateSelectedRole(selectedRole.role as string)
    }
  }

  console.log('formik.values', formik.values)
  console.log('selectedRole', selectedRole)

  return (
    <form className="settings-form" onSubmit={formik.handleSubmit}>
      <div className="settings-content" style={customStyle}>
        <h3>{t('title')}</h3>
        <div className="select-role">
          {t('selectRole')}
          {dropdownRoles ? <LCDropdown options={dropdownRoles} defaultOption={dropdownRoles[0]} onChange={onSelectedRoleChanged} /> : null}
        </div>

        <div className="description">{t('descriptionByRole')}</div>

        <div className="permissions">
          {!!formik.values && groupedPermissions && selectedRole ? (
            <div>
              <h3 className="group-name">{selectedRole?.role as string}</h3>
              <div className="permissions-group-wrapper">
                {Object.keys(groupedPermissions).map((gName) => (
                  <div className="permissions-group" key={gName}>
                    <h4>{gName}</h4>
                    {(groupedPermissions as any)[gName].map((pName: ePermission) => {
                      return (
                        <LCFormToggle
                          customStyle={{ width: '300px' }}
                          key={pName}
                          inputId={pName}
                          label={pName}
                          disabled={selectedRole.type === eRoleType.COMMON}
                          onChange={formik.handleChange}
                          checked={formik.values[pName] === true}
                        />
                      )
                    })}
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="update-button-wrapper">
        <LCButton label={t('updateButton')} type="submit" disabled={!selectedRole || selectedRole.type === eRoleType.COMMON} />
      </div>{' '}
    </form>
  )
}
