export enum ePublicRoutes {
  LOGIN = '/login',
  RESET_PASSWORD = '/reset-password',
  SET_NEW_PASSWORD = '/set-new-password',
  TRIAL_REGISTRATION_EMAIL = '/trial-registration-email',
  TRIAL_REGISTRATION_COMPANY = '/trial-registration-company/:email/:emailType/:language/:token',
}

export enum ePrivateRoutes {
  SAMPLES = '/samples',

  USER_CREATE = '/user/create',
  USER_EDIT = '/user/edit/:identity',
  USER_VIEW = '/user/view/:identity',
  USER = '/user/:identity',
  USERS = '/users',

  GENERAL_DASHBOARD = '/general-dashboard',

  RISKHR_DASHBOARD = '/riskhr/dashboard',
  RISKHR_ANALYTICS = '/riskhr/analytics',
  RISKHR_ASSESSMENTS = '/riskhr/assessments',
  RISKHR_CASES = '/riskhr/cases',
  RISKHR_CASE = '/riskhr/case/:caseId',
  RISKHR_REPORT = '/riskhr/report/:identity/:evaluationId',

  SETTINGS = '/settings/*',

  EMORISK_DASHBOARD = '/emo/dashboard',
  EMORISK_ANALYTICS = '/emo/analytics',
  EMORISK_RESULTS = '/emo/results',

  ACTIVITY_LOG = '/activity/log',

  PURCHASE = '/purchase/*',
}
