export const dataSeverityRulesMock = [
  {
    idPosition: 1,
    idTopic: 110,
    riskLevel: {
      personalQuestions: 0,
      semiPersonalQuestions: 0,
      conceptualQuestions: 0,
      semiPersonalQuestionsMax: 0,
      personalQuestionsMax: 0,
      conceptualQuestionsMax: 0,
    },
  },
  {
    idPosition: 1,
    idTopic: 120,
    riskLevel: {
      personalQuestions: 0,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 1,
    idTopic: 130,
    riskLevel: {
      personalQuestions: 2,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 1,
    idTopic: 140,
    riskLevel: {
      personalQuestions: 2,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 1,
    idTopic: 150,
    riskLevel: {
      personalQuestions: 2,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 1,
    idTopic: 160,
    riskLevel: {
      personalQuestions: 2,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 1,
    idTopic: 170,
    riskLevel: {
      personalQuestions: 2,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 1,
    idTopic: 180,
    riskLevel: {
      personalQuestions: 2,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 1,
    idTopic: 190,
    riskLevel: {
      personalQuestions: 2,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 1,
    idTopic: 200,
    riskLevel: {
      personalQuestions: 2,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 1,
    idTopic: 210,
    riskLevel: {
      personalQuestions: 2,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 1,
    idTopic: 220,
    riskLevel: {
      personalQuestions: 2,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 1,
    idTopic: 230,
    riskLevel: {
      personalQuestions: 0,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 1,
    idTopic: 240,
    riskLevel: {
      personalQuestions: 2,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 1,
    idTopic: 250,
    riskLevel: {
      personalQuestions: 2,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 1,
    idTopic: 260,
    riskLevel: {
      personalQuestions: 2,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 1,
    idTopic: 270,
    riskLevel: {
      personalQuestions: 2,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 1,
    idTopic: 280,
    riskLevel: {
      personalQuestions: 0,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 1,
    idTopic: 290,
    riskLevel: {
      personalQuestions: 2,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 1,
    idTopic: 300,
    riskLevel: {
      personalQuestions: 2,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 1,
    idTopic: 310,
    riskLevel: {
      personalQuestions: 2,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 1,
    idTopic: 320,
    riskLevel: {
      personalQuestions: 2,
      semiPersonalQuestions: 1,
      conceptualQuestions: 0,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 1,
    idTopic: 330,
    riskLevel: {
      personalQuestions: 2,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 1,
    idTopic: 340,
    riskLevel: {
      personalQuestions: 2,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 1,
    idTopic: 350,
    riskLevel: {
      personalQuestions: 2,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 1,
    idTopic: 360,
    riskLevel: {
      personalQuestions: 2,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 1,
    idTopic: 380,
    riskLevel: {
      personalQuestions: 2,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 1,
    idTopic: 390,
    riskLevel: {
      personalQuestions: 2,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 2,
    idTopic: 110,
    riskLevel: {
      personalQuestions: 2,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 2,
    idTopic: 120,
    riskLevel: {
      personalQuestions: 2,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 2,
    idTopic: 130,
    riskLevel: {
      personalQuestions: 2,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 2,
    idTopic: 140,
    riskLevel: {
      personalQuestions: 2,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 2,
    idTopic: 150,
    riskLevel: {
      personalQuestions: 2,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 2,
    idTopic: 160,
    riskLevel: {
      personalQuestions: 2,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 2,
    idTopic: 170,
    riskLevel: {
      personalQuestions: 2,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 2,
    idTopic: 180,
    riskLevel: {
      personalQuestions: 2,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
  {
    idPosition: 3,
    idTopic: 190,
    riskLevel: {
      personalQuestions: 2,
      semiPersonalQuestions: 1,
      conceptualQuestions: 2,
      semiPersonalQuestionsMax: 4,
      personalQuestionsMax: 3,
      conceptualQuestionsMax: 2,
    },
  },
]
export const dataTopicImpactRule = [
  {
    name: 'Child labor',
    impacts: [
      { keyImpact: 'impact_time', selectImpact: true },
      { keyImpact: 'impact_health_and_safety', selectImpact: false },
      { keyImpact: 'impact_sustainability', selectImpact: true },
      { keyImpact: 'impact_financial', selectImpact: true },
      { keyImpact: 'impact_reputational', selectImpact: false },
      { keyImpact: 'Regulatory', selectImpact: false },
      { keyImpact: 'Compliance', selectImpact: true },
    ],
  },
  {
    name: 'Extortion',
    impacts: [
      { keyImpact: 'impact_time', selectImpact: false },
      { keyImpact: 'impact_health_and_safety', selectImpact: true },
      { keyImpact: 'impact_sustainability', selectImpact: false },
      { keyImpact: 'impact_financial', selectImpact: true },
      { keyImpact: 'impact_reputational', selectImpact: true },
      { keyImpact: 'Regulatory', selectImpact: false },
      { keyImpact: 'Compliance', selectImpact: true },
    ],
  },
  {
    name: 'Money Laundering',
    impacts: [
      { keyImpact: 'impact_time', selectImpact: true },
      { keyImpact: 'impact_health_and_safety', selectImpact: false },
      { keyImpact: 'impact_sustainability', selectImpact: true },
      { keyImpact: 'impact_financial', selectImpact: false },
      { keyImpact: 'impact_reputational', selectImpact: true },
      { keyImpact: 'Regulatory', selectImpact: false },
      { keyImpact: 'Compliance', selectImpact: true },
    ],
  },
  {
    name: 'Relationship With Labor Union',
    impacts: [
      { keyImpact: 'impact_time', selectImpact: false },
      { keyImpact: 'impact_health_and_safety', selectImpact: true },
      { keyImpact: 'impact_sustainability', selectImpact: false },
      { keyImpact: 'impact_financial', selectImpact: true },
      { keyImpact: 'impact_reputational', selectImpact: true },
      { keyImpact: 'Regulatory', selectImpact: false },
      { keyImpact: 'Compliance', selectImpact: true },
    ],
  },
  {
    name: 'Satisfaction With The Company',
    impacts: [
      { keyImpact: 'impact_time', selectImpact: true },
      { keyImpact: 'impact_health_and_safety', selectImpact: false },
      { keyImpact: 'impact_sustainability', selectImpact: true },
      { keyImpact: 'impact_financial', selectImpact: false },
      { keyImpact: 'impact_reputational', selectImpact: true },
      { keyImpact: 'Regulatory', selectImpact: false },
      { keyImpact: 'Compliance', selectImpact: true },
    ],
  },
  {
    name: 'Curriculum Vitae',
    impacts: [
      { keyImpact: 'impact_time', selectImpact: false },
      { keyImpact: 'impact_health_and_safety', selectImpact: true },
      { keyImpact: 'impact_sustainability', selectImpact: true },
      { keyImpact: 'impact_financial', selectImpact: false },
      { keyImpact: 'impact_reputational', selectImpact: true },
      { keyImpact: 'Regulatory', selectImpact: false },
      { keyImpact: 'Compliance', selectImpact: true },
    ],
  },
  {
    name: 'Company Loyalty',
    impacts: [
      { keyImpact: 'impact_time', selectImpact: false },
      { keyImpact: 'impact_health_and_safety', selectImpact: true },
      { keyImpact: 'impact_sustainability', selectImpact: false },
      { keyImpact: 'impact_financial', selectImpact: true },
      { keyImpact: 'impact_reputational', selectImpact: true },
      { keyImpact: 'Regulatory', selectImpact: false },
      { keyImpact: 'Compliance', selectImpact: true },
    ],
  },
  {
    name: 'Maintaining Confidentiality',
    impacts: [
      { keyImpact: 'impact_time', selectImpact: true },
      { keyImpact: 'impact_health_and_safety', selectImpact: false },
      { keyImpact: 'impact_sustainability', selectImpact: true },
      { keyImpact: 'impact_financial', selectImpact: false },
      { keyImpact: 'impact_reputational', selectImpact: true },
      { keyImpact: 'Regulatory', selectImpact: false },
      { keyImpact: 'Compliance', selectImpact: true },
    ],
  },
  {
    name: 'Stealing From The Workplace',
    impacts: [
      { keyImpact: 'impact_time', selectImpact: false },
      { keyImpact: 'impact_health_and_safety', selectImpact: true },
      { keyImpact: 'impact_sustainability', selectImpact: false },
      { keyImpact: 'impact_financial', selectImpact: true },
      { keyImpact: 'impact_reputational', selectImpact: false },
      { keyImpact: 'Regulatory', selectImpact: false },
      { keyImpact: 'Compliance', selectImpact: true },
    ],
  },
  {
    name: 'Illegal Drugs Use',
    impacts: [
      { keyImpact: 'impact_time', selectImpact: true },
      { keyImpact: 'impact_health_and_safety', selectImpact: false },
      { keyImpact: 'impact_sustainability', selectImpact: true },
      { keyImpact: 'impact_financial', selectImpact: false },
      { keyImpact: 'impact_reputational', selectImpact: true },
      { keyImpact: 'Regulatory', selectImpact: false },
      { keyImpact: 'Compliance', selectImpact: true },
    ],
  },
  {
    name: 'History Of Honesty',
    impacts: [
      { keyImpact: 'impact_time', selectImpact: false },
      { keyImpact: 'impact_health_and_safety', selectImpact: true },
      { keyImpact: 'impact_sustainability', selectImpact: false },
      { keyImpact: 'impact_financial', selectImpact: true },
      { keyImpact: 'impact_reputational', selectImpact: true },
      { keyImpact: 'Regulatory', selectImpact: false },
      { keyImpact: 'Compliance', selectImpact: true },
    ],
  },
  {
    name: 'Reporting Procedures',
    impacts: [
      { keyImpact: 'impact_time', selectImpact: true },
      { keyImpact: 'impact_health_and_safety', selectImpact: false },
      { keyImpact: 'impact_sustainability', selectImpact: true },
      { keyImpact: 'impact_financial', selectImpact: false },
      { keyImpact: 'impact_reputational', selectImpact: true },
      { keyImpact: 'Regulatory', selectImpact: false },
      { keyImpact: 'Compliance', selectImpact: true },
    ],
  },
  {
    name: 'Team Work',
    impacts: [
      { keyImpact: 'impact_time', selectImpact: false },
      { keyImpact: 'impact_health_and_safety', selectImpact: true },
      { keyImpact: 'impact_sustainability', selectImpact: false },
      { keyImpact: 'impact_financial', selectImpact: true },
      { keyImpact: 'impact_reputational', selectImpact: false },
      { keyImpact: 'Regulatory', selectImpact: false },
      { keyImpact: 'Compliance', selectImpact: true },
    ],
  },
  {
    name: 'Bribery And Kickbacks',
    impacts: [
      { keyImpact: 'impact_time', selectImpact: true },
      { keyImpact: 'impact_health_and_safety', selectImpact: false },
      { keyImpact: 'impact_sustainability', selectImpact: true },
      { keyImpact: 'impact_financial', selectImpact: false },
      { keyImpact: 'impact_reputational', selectImpact: true },
      { keyImpact: 'Regulatory', selectImpact: false },
      { keyImpact: 'Compliance', selectImpact: true },
    ],
  },
  {
    name: 'Scam And Deception',
    impacts: [
      { keyImpact: 'impact_time', selectImpact: false },
      { keyImpact: 'impact_health_and_safety', selectImpact: true },
      { keyImpact: 'impact_sustainability', selectImpact: false },
      { keyImpact: 'impact_financial', selectImpact: true },
      { keyImpact: 'impact_reputational', selectImpact: false },
      { keyImpact: 'Regulatory', selectImpact: false },
      { keyImpact: 'Compliance', selectImpact: true },
    ],
  },
  {
    name: 'Gambling',
    impacts: [
      { keyImpact: 'impact_time', selectImpact: true },
      { keyImpact: 'impact_health_and_safety', selectImpact: false },
      { keyImpact: 'impact_sustainability', selectImpact: true },
      { keyImpact: 'impact_financial', selectImpact: false },
      { keyImpact: 'impact_reputational', selectImpact: true },
      { keyImpact: 'Regulatory', selectImpact: false },
      { keyImpact: 'Compliance', selectImpact: true },
    ],
  },
  {
    name: 'Alcohol Consumption/Intoxication',
    impacts: [
      { keyImpact: 'impact_time', selectImpact: false },
      { keyImpact: 'impact_health_and_safety', selectImpact: true },
      { keyImpact: 'impact_sustainability', selectImpact: false },
      { keyImpact: 'impact_financial', selectImpact: true },
      { keyImpact: 'impact_reputational', selectImpact: false },
      { keyImpact: 'Regulatory', selectImpact: false },
      { keyImpact: 'Compliance', selectImpact: true },
    ],
  },
  {
    name: 'Sexual Harassment',
    impacts: [
      { keyImpact: 'impact_time', selectImpact: true },
      { keyImpact: 'impact_health_and_safety', selectImpact: false },
      { keyImpact: 'impact_sustainability', selectImpact: true },
      { keyImpact: 'impact_financial', selectImpact: false },
      { keyImpact: 'impact_reputational', selectImpact: true },
      { keyImpact: 'Regulatory', selectImpact: false },
      { keyImpact: 'Compliance', selectImpact: true },
    ],
  },
  {
    name: 'Organized Crime',
    impacts: [
      { keyImpact: 'impact_time', selectImpact: false },
      { keyImpact: 'impact_health_and_safety', selectImpact: true },
      { keyImpact: 'impact_sustainability', selectImpact: false },
      { keyImpact: 'impact_financial', selectImpact: true },
      { keyImpact: 'impact_reputational', selectImpact: false },
      { keyImpact: 'Regulatory', selectImpact: false },
      { keyImpact: 'Compliance', selectImpact: true },
    ],
  },
  {
    name: 'Terrorist Groups',
    impacts: [
      { keyImpact: 'impact_time', selectImpact: false },
      { keyImpact: 'impact_health_and_safety', selectImpact: true },
      { keyImpact: 'impact_sustainability', selectImpact: false },
      { keyImpact: 'impact_financial', selectImpact: true },
      { keyImpact: 'impact_reputational', selectImpact: false },
      { keyImpact: 'Regulatory', selectImpact: false },
      { keyImpact: 'Compliance', selectImpact: true },
    ],
  },
]
