import { ISort, ISearch } from '@logicalcommander/types'
import { IUserInfo } from './user.interfaces'

enum EnterExitEnum {
  enter = 'enter',
  exit = 'exit',
}

export enum EmoEmotionsNames {
  energy = 'energy',
  happiness = 'happiness',
  anger = 'anger',
  stress = 'stress',
  excitement = 'excitement',
  sadness = 'sadness',
  mentalLoad = 'mentalLoad',
  discomfort = 'discomfort',
  confidence = 'confidence',
  extremeEmotion = 'extremeEmotion',
  emotionalStress = 'emotionalStress',
  dissatisfaction = 'dissatisfaction',
  burnOut = 'burnOut',
  depression = 'depression',
}

export interface EmoResults {
  energy: number
  happiness: number
  anger: number
  stress: number
  excitement: number
  sadness: number
  mentalLoad: number
  discomfort: number
  confidence: number
  extremeEmotion: number
  emotionalStress: number
  dissatisfaction: number
  burnOut: number
  depression: number
}

export interface IEmoResults {
  identity: string
  companyId: string
  firstName: string
  middleName: string
  lastName: string
  enterExit: EnterExitEnum
  date: Date
  results: EmoResults
}

export interface IEmoResultsSummaryEmotionsRiskLevel {
  critical: number
  high: number
  medium: number
  low: number
  score: number
}

export type IEmoResultsSummaryEmotions = {
  [key in EmoEmotionsNames]: IEmoResultsSummaryEmotionsRiskLevel
}

export interface IEmoResultsSummary {
  totalEnter: number
  totalExit: number
  identity: string
  userInfo: IUserInfo
  emotions: IEmoResultsSummaryEmotions
}

export interface IEmoResultsSummaryByMonths extends IEmoResultsSummary {
  year: number
  month: number
}

export interface IGetEmoResultsRequest {
  pageIndex: number
  pageSize: number
  sortBy?: ISort[]
  search?: ISearch[]
}

export interface IGetEmoAttendanceForUserRequest {
  identity: string
  search?: ISearch[]
}

export interface IGetEmoAttendanceForUserResponse {
  identity: string
  dates: {
    enterExit: string
    date: Date
  }[]
}

export interface IGetEmoAttendanceForUserDates {
  title: string
  start: string
  end: string
}
