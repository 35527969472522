import { IExtendedColumn } from '../../../../pages/riskhr/riskHrEvaluations/columns/riskHrEvaluations.columns'

export const searchColumnByKey = (columns: IExtendedColumn[], key: string): IExtendedColumn => {
  let result: any = {}
  columns.forEach((column: IExtendedColumn) => {
    if (column.columns) {
      column.columns.forEach((innerColumn: IExtendedColumn) => {
        if (innerColumn.accessor === key) {
          result = innerColumn
        }
      })
    } else {
      if (column.accessor === key) {
        result = column
      }
    }
  })
  return result
}
