import { SVGProps } from 'react'

const SvgExpandCollapseSideMenu = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 124"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    filter="drop-shadow( 3px 3px 2px rgba(0, 0, 0, .1))"
    {...props}>
    <path
      d="M14.1827 44.369C3.64698 28.4574 0.337686 8.15982 3.8147e-06 -1.2414e-06L-1.15051e-10 123.927C5.65042e-07 105.568 9.45513 86.6981 14.1827 79.5583C18.5726 74.4584 24.7184 60.2807 14.1827 44.369Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgExpandCollapseSideMenu
