import React, { CSSProperties, HTMLAttributes } from 'react'
import { LCTabs } from '../../../../../ui/components/lc-tabs/lc-tabs'
import { useTranslation } from 'react-i18next'
import { SettingsRiskHrView } from './SettingsRiskHrView'
import { SettingsRiskHrCreate } from './SettingsRiskHrCreate'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

export const SettingsRiskHrTabs = ({ customStyle }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'settings.riskHr' })

  return (
    <div className="tabs">
      <LCTabs
        tabs={[
          {
            id: '1',
            title: t('tabs.viewAssessments'),
            component: <SettingsRiskHrView />,
          },
          {
            id: '2',
            title: t('tabs.createAssessments'),
            component: <SettingsRiskHrCreate />,
          },
        ]}
      />
    </div>
  )
}
