import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { eTableName, ISearch } from '@logicalcommander/types'
import deepEqual from 'deep-equal'
import { removeObjectByKeyValuePair } from '../../utils/general'

export enum eFilterType {
  SEARCH = 'SEARCH',
  FILTER = 'FILTER',
}

export interface IFilter {
  tableName: eTableName
  search: ISearch
  type: eFilterType
}

interface IFiltersState {
  history: IFilter[]
  tableHeader: IFilter[]
}

const initialState: IFiltersState = {
  history: [],
  tableHeader: [],
}

export const filtersSlice = createSlice({
  name: 'filtersReducer',
  initialState,
  reducers: {
    addFilter: (state: IFiltersState, action: PayloadAction<IFilter>) => {
      const existInHistory = state.history.find((i) => deepEqual(i, action.payload))
      const existInTableHeader = state.tableHeader.find((i) => deepEqual(i, action.payload))
      !existInHistory && state.history.push(action.payload)
      !existInTableHeader && state.tableHeader.push(action.payload)
    },
    deleteFilter: (state: IFiltersState, action: PayloadAction<IFilter>) => {
      const tableHeader = state.tableHeader.filter((item) => !deepEqual(item, action.payload))
      return {
        ...state,
        tableHeader,
      }
    },
    setSearchFilter: (state: IFiltersState, action: PayloadAction<IFilter>) => {
      state.tableHeader = [action.payload]
    },
    addSearchFilter: (state: IFiltersState, action: PayloadAction<IFilter>) => {
      state.tableHeader.push(action.payload)
    },
    clearSearchFilter: (state: IFiltersState, action: PayloadAction<any>) => {
      state.tableHeader = removeObjectByKeyValuePair<IFilter>(state.tableHeader, 'type', eFilterType.SEARCH)
    },
  },
})

export const { addFilter, deleteFilter, addSearchFilter, clearSearchFilter, setSearchFilter } = filtersSlice.actions
export default filtersSlice.reducer
export const selectFilters = (state: RootState) => state.filtersReducer
