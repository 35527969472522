import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ILoginResponse } from '../../../interfaces/auth.interfaces'
import { RootState } from '../store'
import { cachedStorage, eStorageKey } from '../../utils/cachedStorage'

// Define a type for the slice state
export interface AuthState extends ILoginResponse {}

// Define the initial state using that type
const initialState: AuthState = {
  common: {
    acceptanceVersion: '1.0.0',
  },
  token: cachedStorage.get<RootState>(eStorageKey.STATE)?.authReducer.token || '',
  companyToken: cachedStorage.get<RootState>(eStorageKey.STATE)?.authReducer.companyToken || '',
  user: cachedStorage.get<RootState>(eStorageKey.STATE)?.authReducer.user || {
    companyId: '',
    identity: '',
    roles: [],
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
  },
}

export const authSlice = createSlice({
  name: 'authReducer',
  initialState,
  reducers: {
    setAuthenticatedUser: (state, action: PayloadAction<ILoginResponse>) => {
      state.companyToken = action.payload.companyToken
      state.user = action.payload.user
      state.token = action.payload.token
    },
    resetState: (state) => {
      state = { ...initialState, token: '', companyToken: '' }
      cachedStorage.clear(eStorageKey.STATE)
    },
  },
})

export const { setAuthenticatedUser, resetState } = authSlice.actions
export default authSlice.reducer
export const selectAuth = (state: RootState) => state.authReducer
