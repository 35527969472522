import React, { useEffect, useState } from 'react'
import { Routes, Route, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LCPage } from '../../ui/components/lc-page/LCPage'
import { useMatch } from 'react-router'
import { PurchaseModule } from './components/ModulePurchaseRiskHr'
import '../purchase/purchase-tabs.scss'
import { LCMenuExpand } from '../../ui/components/lc-menu-expand/LCMenuExpand'
import { eRoutesPurchase } from './e-routesPurchase'
import { namesPurchase } from './e-namesPurchase'
import { faCartShopping } from '@fortawesome/free-solid-svg-icons/faCartShopping'

const ExactNavLink = (props: any) => {
  let match = useMatch(`/app/purchase${props.to}`)
  return <Link className={!!match ? 'link active' : 'link'} onClick={props.onClick} {...props} />
}
export const PurchaseTabs = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'purchase' })
  const [currentTab, setCurrentTab] = useState<string>('risk-hr')

  useEffect(() => {
    const newPathname = window.location.pathname
    var pathSegments = newPathname.split('/')
    var lastPathSegment = pathSegments[pathSegments.length - 1]
    setCurrentTab(lastPathSegment)
  }, [])

  return (
    <div className="settings-tabs">
      <div className="tabs-wrapper">
        <div className="tabs-title-container">
          <div className="tabs">
            <nav>
              <ExactNavLink to={`/app/purchase${eRoutesPurchase.RISK_HR}`} onClick={() => setCurrentTab('risk-hr')}>
                {t('menu.risk-hr')}
              </ExactNavLink>
              <div className="seperator"></div>
              <LCMenuExpand
                label={t('menu.moduleCentrix')}
                menuItems={
                  <div className="sub-menus">
                    <ExactNavLink to={`/app/purchase${eRoutesPurchase.EMO_RISK}`} onClick={() => setCurrentTab('emo-risk')}>
                      {t('menu.emo-risk')}
                    </ExactNavLink>
                    <ExactNavLink
                      to={`/app/purchase${eRoutesPurchase.INTERNAL_COMPLAINTS}`}
                      onClick={() => setCurrentTab('internal-complaints')}>
                      {t('menu.internal-complaints')}
                    </ExactNavLink>
                    <ExactNavLink to={`/app/purchase${eRoutesPurchase.SOS}`} onClick={() => setCurrentTab('sos')}>
                      {t('menu.sos')}
                    </ExactNavLink>
                    <ExactNavLink to={`/app/purchase${eRoutesPurchase.DOCUMENTS}`} onClick={() => setCurrentTab('documents')}>
                      {t('menu.documents')}
                    </ExactNavLink>
                  </div>
                }
              />
              <div className="seperator"></div>
              <LCMenuExpand
                label={t('menu.packages')}
                menuItems={
                  <div className="sub-menus">
                    <ExactNavLink to={`/app/purchase${eRoutesPurchase.PACKAGES_SILVER}`} onClick={() => setCurrentTab('silver')}>
                      {t('menu.silver')}
                    </ExactNavLink>

                    <ExactNavLink to={`/app/purchase${eRoutesPurchase.PACKAGES_GOLD}`} onClick={() => setCurrentTab('silver')}>
                      {t('menu.gold')}
                    </ExactNavLink>
                    <ExactNavLink to={`/app/purchase${eRoutesPurchase.PACKAGES_PLATINIUM}`} onClick={() => setCurrentTab('silver')}>
                      {t('menu.platinium')}
                    </ExactNavLink>
                  </div>
                }
              />
            </nav>
          </div>
        </div>
        <div className="tabs-content">
          <LCPage icon={faCartShopping} title={t('menu.page_title')} breadcrumbs={t(`menu.${currentTab}`)}>
            <Routes>
              <Route path={eRoutesPurchase.RISK_HR} element={<PurchaseModule moduleName={namesPurchase.RISK_HR} />} />
              <Route path={eRoutesPurchase.EMO_RISK} element={<PurchaseModule moduleName={namesPurchase.EMO_RISK} />} />
              <Route
                path={eRoutesPurchase.INTERNAL_COMPLAINTS}
                element={<PurchaseModule moduleName={namesPurchase.INTERNAL_COMPLAINTS} />}
              />
              <Route path={eRoutesPurchase.SOS} element={<PurchaseModule moduleName={namesPurchase.SOS} />} />
              <Route path={eRoutesPurchase.DOCUMENTS} element={<PurchaseModule moduleName={namesPurchase.DOCUMENTS} />} />
              <Route path={eRoutesPurchase.PACKAGES_SILVER} element={<PurchaseModule moduleName={namesPurchase.PACKAGES_SILVER} />} />
              <Route path={eRoutesPurchase.PACKAGES_PLATINIUM} element={<PurchaseModule moduleName={namesPurchase.PACKAGES_PLATINIUM} />} />
              <Route path={eRoutesPurchase.PACKAGES_GOLD} element={<PurchaseModule moduleName={namesPurchase.PACKAGES_GOLD} />} />
            </Routes>
          </LCPage>
        </div>
      </div>
    </div>
  )
}
