export const toAprove_columns = (t: any) => [
  {
    Header: t('columns.applicant'),
    accessor: 'applicant',
  },
  {
    Header: t('columns.requested_product'),
    accessor: 'requested_product',
  },
  {
    Header: t('columns.application_date'),
    accessor: 'application_date',
  },
  {
    Header: t('columns.price_of_products'),
    accessor: 'price_of_products',
    Cell: (row: any) => {
      const index = row.row.index
      const currency = row.data[index].currency
      const price = Number(row.cell.value).toLocaleString('en-US')
      return (
        <span>
          ${price} {currency}
        </span>
      )
    },
  },
  {
    Header: t('columns.order_request_status'),
    accessor: 'order_request_status',
    Cell: (row: any) => {
      const loadingDescription = row.row.original.order_request_status.description !== ''
      const dataStatus = row.row.original.order_request_status.status
      const dataDescription = loadingDescription ? row.row.original.order_request_status.description : null
      return <div className="container-status">{`${dataStatus}${loadingDescription ? `; description: ${dataDescription}` : ''}`}</div>
    },
  },
  {
    Header: t('columns.approve_or_cancel'),
    Cell: () => {
      return (
        <div className="container-buttons">
          <button className="button-to-Approve">{t('buttons.approve')}</button>
          <button className="button-to-Modify">{t('buttons.modify')}</button>
          <button className="button-cancel">{t('buttons.reject')}</button>
        </div>
      )
    },
  },
]
