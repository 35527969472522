export const dataMyRequests: any[] = [
  {
    id: 1,
    update_date: '2024-03-14',
    customer_name: 'Ana García',
    product_and_quantity: [
      {
        product: 'Documents',
        quantity: 1,
      },
      {
        product: 'SOS',
        quantity: 1,
      },
    ],
    status_requests: 'Approved',
  },
  {
    id: 2,
    update_date: '2024-03-14',
    customer_name: 'Pedro López',
    product_and_quantity: [
      {
        product: 'Internal Complaints',
        quantity: 1,
      },
      {
        product: 'EmoRisk',
        quantity: 1,
      },
    ],
    status_requests: 'Pending',
  },
  {
    id: 3,
    update_date: '2024-03-14',
    customer_name: 'María Pérez',
    product_and_quantity: [
      {
        product: 'Investigate',
        quantity: 1,
      },
    ],
    status_requests: 'Approved-modified',
    modified: [
      {
        modify_name: 'Marcos Saragoza',
        product: 'Risk-Hr Short',
        quantity: 10,
      },
    ],
  },
  {
    id: 4,
    update_date: '2024-03-14',
    customer_name: 'Juan Martín',
    product_and_quantity: [
      {
        product: 'Risk-HR Short',
        quantity: 1,
      },
    ],
    status_requests: 'Approved-modified',
    modified: [
      {
        modify_name: 'Marcos Saragoza',
        product: 'Risk-Hr Short',
        quantity: 10,
      },
    ],
  },
  {
    id: 5,
    update_date: '2024-03-14',
    customer_name: 'Sofía Gómez',
    product_and_quantity: [
      {
        product: 'Risk-HR Long',
        quantity: 1,
      },
    ],
    status_requests: 'Rejected',
  },
  {
    id: 6,
    update_date: '2024-03-14',
    customer_name: 'David Martínez',
    product_and_quantity: [
      {
        product: 'Packages Platinium',
        quantity: 1,
      },
    ],
    status_requests: 'Approved',
  },
  {
    id: 7,
    update_date: '2024-03-14',
    customer_name: 'Laura Fernández',
    product_and_quantity: [
      {
        product: 'Packages Gold',
        quantity: 2,
      },
    ],
    status_requests: 'Pending ',
  },
  {
    id: 8,
    update_date: '2024-03-14',
    customer_name: 'Miguel Rodríguez',
    product_and_quantity: [
      {
        product: 'Packages Silver',
        quantity: 1,
      },
    ],
    status_requests: 'Pending',
  },
  {
    id: 9,
    update_date: '2024-03-14',
    customer_name: 'Elena Ruiz',
    product_and_quantity: [
      {
        product: 'Documents',
        quantity: 1,
      },
      {
        product: 'Internal Complaints',
        quantity: 1,
      },
    ],
    status_requests: 'Approved-modified',
    modified: [
      {
        modify_name: 'Marcos Saragoza',
        product: 'Risk-Hr Short',
        quantity: 10,
      },
    ],
  },
  {
    id: 10,
    update_date: '2024-03-14',
    customer_name: 'Alejandro Sánchez',
    product_and_quantity: [
      {
        product: 'SOS',
        quantity: 1,
      },
    ],
    status_requests: 'Approved',
  },
]
