import './lc-topics-with-risk.scss'
import { CSSProperties, FC, HTMLAttributes, useEffect } from 'react'
import { ILCActionPopupItemData, LCActionPopup } from '../lc-action-popup/LCActionPopup'
import { useLazyGetAssessmentsTopicsQuery } from '../../../services/assessments.service'

export interface ILLCTopicsWithRiskProps {
  topicsIds: number[]
  customStyle?: CSSProperties
}

export const LCTopicsWithRisk: FC<ILLCTopicsWithRiskProps & HTMLAttributes<HTMLDivElement>> = ({
  topicsIds,
  customStyle,
  ...props
}: ILLCTopicsWithRiskProps) => {
  const [getEvaluationTopics, { data }] = useLazyGetAssessmentsTopicsQuery()

  useEffect(() => {
    getEvaluationTopics('en')
  }, [])

  const actionPopupTopicsData: ILCActionPopupItemData[] = topicsIds?.map((id: any) => {
    return {
      reactElement: (
        <div>
          <div>{data ? data[id] : 'N/A'}</div>
        </div>
      ),
    }
  })

  return (
    <div style={customStyle} {...props}>
      {actionPopupTopicsData && actionPopupTopicsData.length > 0 ? (
        <LCActionPopup
          data={actionPopupTopicsData}
          position={['left', 'midbottom']}
          customStyle={{ width: '320px' }}
          anchor={<div className="circle">{topicsIds.length}</div>}
        />
      ) : null}
    </div>
  )
}
