import React, { ReactNode, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLazyGetSettingsQuery } from '../../services/settings.service'
import { useGetAllPermissionsGroupedMutation } from '../../services/permissions.service'
import {
  IAssessmentSizes,
  setAssessmentsSizesSettings,
  setAllSettings,
  setRolesSettings,
  setAdminsSettings,
} from '../../redux/reducers/settingsSlice'
import { cachedStorage, eStorageKey } from '../../utils/cachedStorage'
import { useLazyGetExtendedCompanyQuery } from '../../services/company.service'
import { setExtendedCompany } from '../../redux/reducers/extendedCompanySlice'
import { useLazyGetAssessmentsInfoQuery } from '../../services/assessments.service'
import { setAssessmentsInfo } from '../../redux/reducers/assessmentsSlice'
import {
  ISettings,
  IGroupedPermissions,
  IAssessmentSchemaInfo,
  IExtendedCompany,
  IRolesAndPermissions,
  IUser,
} from '@logicalcommander/types'
import { getAssessmentsSizesFromAssessmentsInfoArray } from './AuthenticatedInitializerHelper'
import { eInitializeState, setInitializeState } from '../../redux/reducers/generalSlice'
import { useAppSelector } from '../../redux/hooks'
import { selectAuth } from '../../redux/reducers/authSlice'
import { useLocation } from 'react-router'
import { useLazyGetRolesAndPermissionsQuery } from '../../services/roles.service'
import { useLazyGetAdminsQuery } from '../../services/users.service'

type ITriggers = ISettings | IGroupedPermissions[] | IAssessmentSchemaInfo[] | IExtendedCompany | IRolesAndPermissions[] | IUser[] | null
type ITriggersTuple = [
  ISettings | null,
  IGroupedPermissions[] | null,
  IAssessmentSchemaInfo[] | null,
  IExtendedCompany | null,
  IRolesAndPermissions[] | null,
  IUser[] | null,
]

interface AuthenticatedInitializerProps {
  children: ReactNode
}

const AuthenticatedInitializer: React.FC<AuthenticatedInitializerProps> = ({ children }: AuthenticatedInitializerProps) => {
  const { token, companyToken } = useAppSelector(selectAuth)
  const dispatch = useDispatch()
  const location = useLocation()

  const isPrivateRoute = location.pathname.indexOf('/app') === 0

  // console.log('privatePaths:', privatePaths)
  console.log('token, company token:', token, companyToken)

  // Initialize lazy query hooks
  const [triggerGetSettings] = useLazyGetSettingsQuery()
  const [triggerGetPermissions] = useGetAllPermissionsGroupedMutation()
  const [triggerGetAssessmentsInfo] = useLazyGetAssessmentsInfoQuery()
  const [triggerGetExtendedCompany] = useLazyGetExtendedCompanyQuery()
  const [triggerGetAllRoles] = useLazyGetRolesAndPermissionsQuery()
  const [triggerGetAllAdmins] = useLazyGetAdminsQuery()

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setInitializeState(eInitializeState.LOADING))
      try {
        // Execute the queries and wait for them to complete
        const promises: Promise<ITriggers>[] = []
        promises.push(triggerGetSettings().unwrap())
        promises.push(triggerGetPermissions().unwrap())
        promises.push(triggerGetAssessmentsInfo().unwrap())
        promises.push(triggerGetExtendedCompany().unwrap())
        promises.push(triggerGetAllRoles().unwrap())
        promises.push(triggerGetAllAdmins().unwrap())

        const [settingsResult, permissionsResult, assessmentsInfoResult, extendedCompanyResult, allRolesResult, allAdminsResult] =
          (await Promise.all(promises)) as ITriggersTuple

        // Dispatch action to update the state with the fetched data
        settingsResult && dispatch(setAllSettings(settingsResult))

        // Dispatch action to update the state with all roles including custom roles.
        allRolesResult && dispatch(setRolesSettings(allRolesResult))

        // Dispatch action to update the state with all roles including custom roles.
        allAdminsResult && dispatch(setAdminsSettings(allAdminsResult))

        // dispatch(setPermissions(permissionsResult));
        permissionsResult && cachedStorage.set(eStorageKey.GROUPED_PERMISSIONS, permissionsResult)

        extendedCompanyResult && dispatch(setExtendedCompany(extendedCompanyResult))

        if (assessmentsInfoResult) {
          dispatch(setAssessmentsInfo(assessmentsInfoResult))
          const assessmentSizes: IAssessmentSizes = getAssessmentsSizesFromAssessmentsInfoArray(assessmentsInfoResult)
          dispatch(setAssessmentsSizesSettings(assessmentSizes))
        }

        // Once all queries are successful, update the state to render the app
        dispatch(setInitializeState(eInitializeState.FINSIHED))
      } catch (error) {
        console.error('Failed to fetch initial configuration:', error)
      }
    }

    if (isPrivateRoute) {
      fetchData()
    }
  }, [triggerGetSettings, triggerGetPermissions, triggerGetAssessmentsInfo, triggerGetExtendedCompany, isPrivateRoute])

  return <>{children}</>
}

export default AuthenticatedInitializer
