import React, { ReactNode, createContext, useState } from 'react'
export type License = {
  title: string
  type: string
  description: string
  value: string
  package: string | null
  currency: string
  amount: number
}
interface DataLicence {
  [key: string]: License
}

interface contextProps {
  dataPurchase: DataLicence[] | []
  updatePurchaseMost: (newValorPurchase: DataLicence[] | []) => void
  resetPurchase: () => void
}
interface Props {
  children: ReactNode
}

export const MyContextPurchase = createContext<contextProps>({
  dataPurchase: [],
  updatePurchaseMost: function (newValorPurchase: DataLicence[] | []): void {
    throw console.error('Function not implemented.')
  },
  resetPurchase: function (): void {
    throw console.error('Function not implemented.')
  },
})

export const ProviderContextService = ({ children }: Props) => {
  const [dataPurchase, setDataPurchase] = useState<DataLicence[] | []>([])

  const resetPurchase = () => {
    setDataPurchase([])
  }
  const updatePurchaseMost = (newValorPurchase: DataLicence[]) => {
    setDataPurchase((prevState) => {
      const newDataPurchase = [...prevState]
      newValorPurchase.forEach((newData) => {
        const keys = Object.keys(newData)

        keys.forEach((key) => {
          const existingIndex = newDataPurchase.findIndex((item) => item.hasOwnProperty(key))

          if (existingIndex !== -1) {
            newDataPurchase[existingIndex][key] = newData[key]
          } else if (newValorPurchase[0][key].amount > 0) {
            newDataPurchase.push({ [key]: newData[key] })
          }
        })
      })

      return newDataPurchase
    })
  }
  return <MyContextPurchase.Provider value={{ dataPurchase, resetPurchase, updatePurchaseMost }}>{children}</MyContextPurchase.Provider>
}
