import './widgets-general.scss'
import React, { CSSProperties, FC, HTMLAttributes, lazy, useEffect, useState } from 'react'
import { sumBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { BarColor, CustomLabels, BarChartLayout } from '../../../../interfaces/dashboard.interface'
import { useLazyGetRisksByCitiesQuery } from '../../../services/dashboard.service'
import { ITitleValueNumber } from '@logicalcommander/types'
import { BaseDashboardComponent } from '../../../ui/components/base-dashboard/BaseDashboardComponent'
import { DashboardComponentTitle } from '../../../ui/components/base-dashboard/DashboardComponentTitle'

const LCBarChartNew = lazy(() => import('../../../ui/components/charts/lc-bar-chart'))

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

const GeneralDashboardCity: FC<Props> = ({ customStyle }: Props) => {
  const [getRisksByCities, { data, error, isLoading }] = useLazyGetRisksByCitiesQuery()
  const country = 'colombia'
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.general.location' })
  const [cityAlert, setCityAlert] = useState<ITitleValueNumber[]>([])
  const [sumCityAlert, setSumCityAlert] = useState<number>(0)

  useEffect(() => {
    getRisksByCities({ country })
  }, [country])

  useEffect(() => {
    if (!error && data) {
      setCityAlert(data)
      setSumCityAlert(sumBy(data, 'value'))
    }
  }, [data, error])

  return (
    <BaseDashboardComponent sectionTitle={<DashboardComponentTitle title={t('title', { city: `All` })} subTitle={`${t('total')} ${sumCityAlert}`} />} isLoading={isLoading} isSuccess={!!cityAlert} isError={!!error}>
      <LCBarChartNew
        data={cityAlert}
        options={{
          color: BarColor.COLOR_DEPARTMENT,
          label: CustomLabels.TOTAL_EMPLOYEES,
          layout: BarChartLayout.VERTICAL,
        }}
      />
    </BaseDashboardComponent>
  )
}

export default GeneralDashboardCity
