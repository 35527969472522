import moment from 'moment'
import { IDropdownOption } from '../ui/components/lc-dropdown/LCDropdown'
import { configManager } from '../../config/configManager'

export const datesHelper = {
  formatShort: (date: Date): string => {
    return moment(date).format(configManager.SHORT_DATE_FORMAT)
  },
  formatTime: (date: Date): string => {
    return moment(date).format(configManager.SHORT_TIME_FORMAT)
  },
  formatForCalendarShort: (date: Date): string => {
    return moment(date).format(configManager.SHORT_DATE_CALENDAR_FORMAT)
  },
  formatLong: (date: Date): string => {
    return moment(date).format(configManager.LONG_DATE_FORMAT)
  },
  getDatesRangeFromNow: (): IDropdownOption[] => {
    const day1 = moment().subtract(1, 'days')
    const day2 = moment().subtract(2, 'days')
    const week1 = moment().subtract(1, 'weeks')
    const week2 = moment().subtract(2, 'weeks')
    const month1 = moment().subtract(1, 'months')
    const month2 = moment().subtract(2, 'months')
    const month6 = moment().subtract(6, 'months')
    const year1 = moment().subtract(1, 'years')
    const year2 = moment().subtract(2, 'years')
    return [
      {
        label: day1.fromNow(),
        value: day1.toISOString(),
      },
      {
        label: day2.fromNow(),
        value: day2.toISOString(),
      },
      {
        label: week1.fromNow(),
        value: week1.toISOString(),
      },
      {
        label: week2.fromNow(),
        value: week2.toISOString(),
      },
      {
        label: month1.fromNow(),
        value: month1.toISOString(),
      },
      {
        label: month2.fromNow(),
        value: month2.toISOString(),
      },
      {
        label: month6.fromNow(),
        value: month6.toISOString(),
      },
      {
        label: year1.fromNow(),
        value: year1.toISOString(),
      },
      {
        label: year2.fromNow(),
        value: year2.toISOString(),
      },
    ]
  },
}
