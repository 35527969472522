import './lc-comment-view-editor.scss'
import React, { FC, HTMLAttributes, ReactElement } from 'react'
import { CSSProperties } from 'styled-components'
import { LCAdminName } from '../lc-admin-name/LCAdminName'
import { datesHelper } from '../../../utils/dates'
import LCAwareOthersImageProfileBlob from '../../awareComponents/lc-profile-image/LCAwareOthersImageProfileBlob'
import { SanitizeHTML } from '../lc-sanitize-html/SanitizeHTML'

interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
  comment: string
  date: Date
  identity: string
}

export const LCCommentViewEditor: FC<Props> = ({ customStyle, comment, date, identity }: Props): ReactElement | null => {
  return (
    <div className="lc-comment-view-editor" style={customStyle}>
      <div className="title-wrapper">
        <LCAwareOthersImageProfileBlob identity={identity} />
        <LCAdminName identity={identity} />
        <div className="date"> {datesHelper.formatLong(date)}</div>
      </div>
      <div className="comment-wrapper">
        <div className="comment">
          <SanitizeHTML html={comment} />
        </div>
      </div>
    </div>
  )
}
