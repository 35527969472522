import './emo-dashboard.scss'
import { CSSProperties, FC, HTMLAttributes } from 'react'
import EmoRiskMenAndWomanEmotion from './component/EmoRiskMenAndWomanEmotion'
import EmoRiskEmotionPerRisk from './component/EmoRiskEmotionPerRisk'
import EmotionRiskPosition from './component/EmotionRiskPosition'
import EmoRiskDepartment from './component/EmoRiskDepartment'
import EmoRiskEmotionPosition from './component/EmoRiskEmotionPosition'
import EmoRiskDashboardCase from './component/EmoRiskDashboardCase'
import EmoRiskDashboardExitVsEntry from './component/EmoRiskExitVsEntry'
import { LCPage } from '../../../ui/components/lc-page/LCPage'
import { EmoRiskEmotionAge } from './component/EmoRiskEmotionAge'
import { useTranslation } from 'react-i18next'
import { faGauge } from '@fortawesome/free-solid-svg-icons/faGauge'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

export const EmoDashboardPage: FC<Props> = ({ customStyle }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'menu' })

  // const optionCountry = [
  //   {
  //     name: 'Colombia',
  //     value: 'dataColombia',
  //   },
  //   {
  //     name: 'España',
  //     value: 'dataEspaña',
  //   },
  //   {
  //     name: 'Estados Unidos',
  //     value: 'dataEstadosUnidos',
  //   },
  //   {
  //     name: 'Inglaterra',
  //     value: 'dataInglaterra',
  //   },
  // ]

  // const optionYear = [
  //   {
  //     name: '3 months',
  //     value: 'dataColombia',
  //   },
  //   {
  //     name: '6 months',
  //     value: 'dataEspaña',
  //   },
  //   {
  //     name: '2022',
  //     value: 'dataEstadosUnidos',
  //   },
  //   {
  //     name: '2021',
  //     value: 'dataInglaterra',
  //   },
  //   {
  //     name: '2020',
  //     value: 'dataInglaterra',
  //   },
  //   {
  //     name: 'All Data',
  //     value: '',
  //   },
  // ]

  // const optionsCity = optionCountry.map((data, i) => {
  //   const option = {
  //     label: data.name,
  //     value: `${i}`,
  //   }
  //   return option
  // })

  // const optionsYears = optionYear.map((data, i) => {
  //   const option = {
  //     label: data.name,
  //     value: `${i}`,
  //   }
  //   return option
  // })

  return (
    <LCPage icon={faGauge} title={t('emo_risk')} breadcrumbs="Dashboard">
      <div className="emo-risk-dashboard" style={customStyle}>
        {/* <div className={classInformation}>
          <div className={containerClassDropdown}>
            <LCDropdown options={optionsCity} defaultOption="All Data" onChange={onInputChange} />
            <LCDropdown options={optionsYears} defaultOption="3 months" onChange={onInputChange} />
          </div>
        </div> */}

        <EmoRiskEmotionPosition />
        <EmoRiskDepartment />
        <EmotionRiskPosition />
        <EmoRiskEmotionPerRisk customStyle={{ width: '400px', maxWidth: '400px' }} />
        <EmoRiskDashboardCase customStyle={{ width: '400px', maxWidth: '400px' }} />
        <EmoRiskDashboardExitVsEntry customStyle={{ width: '400px', maxWidth: '400px' }} />
        <EmoRiskMenAndWomanEmotion />
        <EmoRiskEmotionAge />
      </div>
    </LCPage>
  )
}
