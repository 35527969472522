import { menuClasses } from 'react-pro-sidebar'

// const menuStyles = {}
export const menuStyles = {
  button: ({ level, active, disabled }: any) => {
    // only apply styles on first level elements of the tree
    // if (level === 0)
    return {
      color: active ? '#ffffff' : undefined,
      backgroundColor: active ? '#02A8FF' : undefined,
    }
  },
}

export const menuItemRootStyles = {
  ['>.' + menuClasses.button]: {
    '&:hover': {
      backgroundColor: '#f1f1f1 !important',
      color: '#444444',
    },
  },
  ['.' + menuClasses.button]: {
    // borderBottom: 'solid 1px #eeeeee',
    fontSize: '14px',
  },
}

// const subMenuRootStyles = {}
export const subMenuRootStyles = {
  ['& > .' + menuClasses.button]: {
    '&:hover': {
      backgroundColor: '#f1f1f1 !important',
      color: '#444444',
    },
  },
  ['.' + menuClasses.button]: {
    fontSize: '14px',
  },
  ['.' + menuClasses.subMenuContent]: {
    backgroundColor: '#fafafa',
    // borderBottom: 'solid 1px #eeeeee',
  },
}

export const subMenuItemStyles = {
  ['>.' + menuClasses.button]: {
    '&:hover': {
      backgroundColor: '#f1f1f1 !important',
      color: '#444444',
    },
  },
}
