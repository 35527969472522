import './admin-management-list.scss'

import React, { CSSProperties, HTMLAttributes, Suspense, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyGetAdminsQuery } from '../../../../services/users.service'
import LCAwareOthersImageProfile from '../../../../ui/awareComponents/lc-profile-image/LCAwareOthersImageProfile'
import { LcStyleNoData } from '../../../../ui/components/charts/LcStyleNoData'
import { LCFormInputText } from '../../../../ui/components/forms/lc-form-inputText/lc-form-inputText'
import { InvitePeopleDialog } from './components/invite-people/InvitePeopleDialog'
import { EditAccessDialog } from './components/edit-access/EditAccessDialog'
import { RevokeAccessDialog } from './components/revoke-access/revoke-access'
import { LCButton } from '../../../../ui/components/lc-button/LCButton'
import { GrantAccessDialog } from './components/grant-access/grantAccessDialog'
import { LCNameLetters } from '../../../../ui/components/lc-name-letters/LCNameLetters'
import { useAppSelector } from '../../../../hooks/redux-hooks'
import { selectAllRoles } from '../../../../redux/reducers/settingsSlice'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

export const AdminManagementList = ({ customStyle }: Props) => {
  const [getAdmins, { data: admins }] = useLazyGetAdminsQuery()
  const [search, setSearch] = useState<string>('')
  const roles = useAppSelector(selectAllRoles)

  const { t } = useTranslation('translation', { keyPrefix: 'settings.admins.list' })

  useEffect(() => {
    getAdmins()
  }, [])

  const filtered =
    admins?.filter(
      (admin) =>
        admin.fullName?.toLowerCase()?.indexOf(search.toLowerCase()) !== -1 ||
        (admin.roles[0] as string).toLowerCase().indexOf(search.toLowerCase()) !== -1
    ) || []

  return (
    <div className="admins-settings-form">
      <div className="settings-content" style={customStyle}>
        <div className="invite-title">
          <h3>{t('title')}</h3>
          <div className="title-buttons">
            <InvitePeopleDialog togglerText={t('inviteButton')} />
            <GrantAccessDialog togglerText={t('grantButton')} />
          </div>
        </div>

        <div className="admins-list">
          <div className="search-bar">
            <LCFormInputText
              value={search}
              placeholder={t('search')}
              inputId={'search'}
              inputType={'text'}
              disabled={false}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <div className="filter-bar">
            {roles?.map((role, index) => (
              <LCButton key={`filter-${index}`} label={role.role as string} size="small" onClick={() => setSearch(role.role as string)} />
            ))}
            <LCButton label="X" size="small" onClick={() => setSearch('')} />
          </div>

          <Suspense fallback={<LcStyleNoData />}>
            {filtered.map((admin, index) => (
              <div className="admin" key={index}>
                <div className="avatar">
                  {admin.profileImage ? (
                    <LCAwareOthersImageProfile identity={admin.identity} />
                  ) : (
                    <LCNameLetters firstName={admin.firstName} lastName={admin.lastName} />
                  )}
                </div>
                <div className="name-role">
                  <div className="name">
                    {admin.fullName} &nbsp;({admin.identity})
                  </div>
                  <div className="role">{admin.roles[0] as string}</div>
                </div>
                <div className="buttons">
                  <EditAccessDialog togglerText={t('edit')} admin={admin} onCloseDialog={() => getAdmins()} />
                  <RevokeAccessDialog admin={admin} onCloseDialog={() => getAdmins()} />
                </div>
              </div>
            ))}
          </Suspense>
        </div>
      </div>
    </div>
  )
}
