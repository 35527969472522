import './lc-comment-editor.scss'
import { FC, HTMLAttributes, ReactElement } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { CSSProperties } from 'styled-components'
import { EditorState } from 'draft-js'
import { LCButton } from '../lc-button/LCButton'

interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
  editorState: EditorState
  onEditorStateChange: (state: EditorState) => void
  onAddComment: () => void
}

export const LCCommentEditor: FC<Props> = ({ customStyle, onEditorStateChange, onAddComment, editorState }: Props): ReactElement | null => {
  return (
    <div className="lc-comment-editor" style={customStyle}>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbar-class"
        wrapperClassName="editor-wrapper-class"
        editorClassName="editor-class"
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'remove', 'history'],
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          // link: { inDropdown: false },
          // image: { inDropdown: false },
          history: { inDropdown: true },
        }}
      />
      {/* <textarea value={draftToHtml(convertToRaw(editorState.getCurrentContent()))} onChange={() => {}} /> */}
      <LCButton style={{ marginTop: '10px' }} label="Add comment" onClick={onAddComment} />
    </div>
  )
}
