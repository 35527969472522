const labelType1 = [7];
const labelType2 = [-1, 14];
const labelType3 = [-8, 7, 20]
const labelType4 = [-10, 0, 10, 20]

export const posticionText: any = {
    1: (i: number) => labelType1[i],
    2: (i: number) => labelType2[i],
    3: (i: number) => labelType3[i],
    4: (i: number) => labelType4[i],
}