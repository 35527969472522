export enum eRoutesPurchase {
  RISK_HR = '/risk-hr',
  EMO_RISK = '/emoRisk',
  INTERNAL_COMPLAINTS = '/internal-complaints',
  SOS = '/sos',
  DOCUMENTS = '/documents',
  PACKAGES_SILVER = '/packages/silver',
  PACKAGES_GOLD = '/packages/gold',
  PACKAGES_PLATINIUM = '/packages/platinium',
}
