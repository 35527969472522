import './lc-button.scss'
import { ButtonHTMLAttributes, CSSProperties, FC } from 'react'
import { ISvgProps, LCSvg } from '../lc-svg/lc-svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string
  children?: string
  icon?: ISvgProps['name']
  newIcon?: any
  disabled?: boolean
  size?: 'xsmall' | 'small' | 'medium' | 'large'
  fixWidth?: 'small' | 'medium' | 'large' | 'fill'
  customStyle?: CSSProperties
  primary?: boolean
  isLoading?: boolean
  onClick?: () => void
}

export const LCButton: FC<IButtonProps> = ({
  label,
  disabled,
  icon,
  newIcon,
  size = 'medium',
  fixWidth,
  customStyle,
  primary = true,
  isLoading,
  onClick,
  ...props
}: IButtonProps) => {
  const classes = [
    'lc-button',
    primary ? 'lc-button--primary' : 'lc-button--secondary',
    size ? `lc-button-size--${size}` : '',
    fixWidth ? `lc-button-fixWidth--${fixWidth}` : '',
    disabled || isLoading ? 'lc-button--disabled' : '',
  ].join(' ')

  return (
    <button
      disabled={isLoading ? true : disabled}
      className={classes}
      data-test-id={'button-' + label}
      style={customStyle}
      onClick={onClick}
      {...props}>
      {icon ? (
        <LCSvg customStyle={{ marginRight: 16, color: '#ffffff', lineHeight: '10px' }} widthAndHeight={[20, 14]} name={icon} />
      ) : null}
      {newIcon ? <FontAwesomeIcon icon={newIcon} size="lg" /> : null}
      {isLoading ? (
        <LCSvg customStyle={{ marginRight: 16, color: '#ffffff', lineHeight: '10px' }} widthAndHeight={[20, 14]} name="loader" />
      ) : null}
      {label}
    </button>
  )
}
