import LCPopup from '../lc-popup/LCPopup'
import { LCSvg } from '../lc-svg/lc-svg'
import './lc-tabs.scss'
import { CSSProperties, FC, HTMLAttributes, ReactElement, useEffect, useState } from 'react'

export interface TabData {
  id: string | number
  title: string
  hasError?: boolean
  errors?: any
  component: ReactElement
}

export interface Props extends HTMLAttributes<HTMLDivElement> {
  tabs: TabData[]
  activeTab?: string | number
  customStyle?: CSSProperties
}

export const LCTabs: FC<Props> = ({ tabs, activeTab: _activeTab, customStyle, ...props }: Props) => {
  const [activeTab, setActiveTab] = useState<string | number>(_activeTab || tabs[0].id)

  useEffect(() => {
    _activeTab && setActiveTab(_activeTab)
  }, [_activeTab])

  console.log('tab.errors', tabs)

  return (
    <div className="lc-tabs" style={customStyle} {...props}>
      <div className="tabs-header">
        {tabs.map((tab) => {
          return (
            <div onClick={() => setActiveTab(tab.id)} className={`title ${tab.id === activeTab ? 'active ' : ''}`} key={tab.id}>
              {tab.hasError ? (
                <div>
                  <LCPopup
                    position={['midleft', 'bottom']}
                    toggleOn={'hover'}
                    toggler={
                      <div>
                        <LCSvg name={'danger'} customStyle={{ color: 'red', marginRight: '10px' }} widthAndHeight={[16, 16]} />
                      </div>
                    }>
                    <div className="tab-errors">
                      {Object.keys(tab.errors).map((key, index) => (
                        <ul key={index}>
                          <li>
                            <span className="tab-error-key">{key}</span>: {JSON.stringify(tab.errors[key])}
                          </li>
                        </ul>
                      ))}
                    </div>
                  </LCPopup>
                </div>
              ) : null}
              <div>{tab.title}</div>
            </div>
          )
        })}
      </div>
      <div className="component">
        <div className="seperator"></div>
        <div className="tabs-content">{tabs.find((tab) => tab.id === activeTab)?.component}</div>
      </div>
    </div>
  )
}
