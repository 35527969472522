import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { IPaginationRequest, IPaginationResponse, eTableName } from '@logicalcommander/types'
import { LCTableServerPagination } from '../../../ui/components/lc-table/LCTableServerPagination'
import { activity_columns } from './ActivityListColumns'
import { useTranslation } from 'react-i18next'
import { IActivityLogEntry, useGetLogMutation } from '../../../services/activitylog.service'
import { useNavigate } from 'react-router'
import { useAppSelector } from '../../../hooks/redux-hooks'
import { selectAllRoles } from '../../../redux/reducers/settingsSlice'

export const ActivityLogPage = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'security.activityLog.columns' })
  const [logActivity, setLogActivity] = useState<any[]>([])
  const [pageCount, setPageCount] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(100)
  const [getLogPagination, { isLoading, data, error }] = useGetLogMutation()
  const navigate = useNavigate()
  const rolesAndPermissions = useAppSelector(selectAllRoles)

  useEffect(() => {
    if (!error && data) {
      setLogActivity(data.data)
    }
  }, [data, error])

  const fetchData = useCallback(({ pageSize, pageIndex, sortBy, search }: IPaginationRequest) => {
    getLogPagination({ pageIndex, pageSize, sortBy, search })
      .then((result: any) => {
        const resData: IPaginationResponse<IActivityLogEntry[]> = result.data
        setPageCount(Math.ceil(resData.totalSize / pageSize))
        setPageIndex(pageIndex)
        setPageSize(pageSize)
      })
      .catch((err) => {
        console.log('ERROR: ', err)
      })
  }, [])

  const columns = useMemo(() => activity_columns(navigate, t, rolesAndPermissions), [pageIndex])

  return (
    <>
      <LCTableServerPagination
        tableName={eTableName.ACTIVITY_LOG_TABLE}
        columns={columns}
        data={logActivity}
        fetchData={fetchData}
        loading={isLoading}
        pageCount={pageCount}
        initPageSize={pageSize}
        showTableActions={true}
      />
    </>
  )
}
