import './emails-settings.scss'

import React, { CSSProperties, HTMLAttributes, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux-hooks'
import { selectSettings, setEmailsSettings } from '../../../../redux/reducers/settingsSlice'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { LCButton } from '../../../../ui/components/lc-button/LCButton'
import { LCFormInputText } from '../../../../ui/components/forms/lc-form-inputText/lc-form-inputText'
import { isEmpty } from '../../../../utils/object'
import { findObjectDifferences } from '../settingsHelper'
import { LCFormCheckbox } from '../../../../ui/components/forms/lc-form-checkBox/lc-form-checkBox'
import { IEmailSettings } from '@logicalcommander/types'
import { useUpdateEmailSettingsMutation } from '../../../../services/settings.service'
import { toast } from 'react-toastify'
import { LCToast } from '../../../../ui/components/lc-toast/Toast'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

export const EmailSettings = ({ customStyle }: Props) => {
  const [updateEmailSettings, { data: dataUpdate, error: errorUpdate }] = useUpdateEmailSettingsMutation()
  const { t } = useTranslation('translation', { keyPrefix: 'settings.email' })
  const { t: v } = useTranslation('translation', { keyPrefix: 'validation' })
  const { emails } = useAppSelector(selectSettings)
  const dispatch = useAppDispatch()

  const initialValues: IEmailSettings = emails
  const validationSchema = Yup.object().shape({
    SMTPServer: Yup.string()
      .min(5, v('short', { field: t('fields.smtpServer'), short: 5, long: 50 }))
      .max(50, v('long', { field: t('fields.smtpServer'), short: 5, long: 50 }))
      .required(v('required', { field: t('fields.smtpServer') })),
    SMTPPort: Yup.number()
      .min(0, v('between', { field: t('fields.smtpPort'), min: 0, max: 65535 }))
      .max(65535, v('between', { field: t('fields.smtpPort'), min: 0, max: 65535 }))
      .required(v('required', { field: t('fields.smtpPort') })),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: (values: any) => {
      console.log('onSubmit', values)
      const email: Partial<IEmailSettings> = findObjectDifferences<IEmailSettings>(initialValues, values)
      console.log('email', email)
      if (isEmpty(email)) {
        toast.info(<LCToast title={v('nothingToUpdate.title')} body={v('nothingToUpdate.body')} />)
      } else {
        updateEmailSettings(email)
      }
    },
  })

  useEffect(() => {
    if (!errorUpdate && dataUpdate) {
      toast.success(<LCToast title={t('successUpdatingEmailSettings.title')} body={t('successUpdatingEmailSettings.body')} />)
      dispatch(setEmailsSettings({ ...initialValues, ...formik.values }))
    } else if (errorUpdate) {
      toast.error(<LCToast title={t('errorUpdatingEmailSettings.title')} body={t('errorUpdatingEmailSettings.body')} />)
    }
  }, [dataUpdate, errorUpdate])

  console.log('formik.values', formik.values)

  return (
    <form className="emails-settings-form" onSubmit={formik.handleSubmit}>
      <div className="settings-content" style={customStyle}>
        <div className="section">
          <h3>{t('title')}</h3>
          <LCFormInputText
            inputId={'SMTPServer'}
            inputType="text"
            label={t('fields.smtpServer')}
            onChange={formik.handleChange}
            value={formik.values.SMTPServer}
            error={formik.errors.SMTPServer}
          />

          <LCFormInputText
            inputId={'SMTPPort'}
            inputType="text"
            label={t('fields.smtpPort')}
            onChange={formik.handleChange}
            value={formik.values.SMTPPort}
            error={formik.errors.SMTPPort}
          />

          <LCFormInputText
            inputId={'SMTPUser'}
            inputType="text"
            label={t('fields.smtpUser')}
            onChange={formik.handleChange}
            value={formik.values.SMTPUser}
            error={formik.errors.SMTPUser}
          />

          <LCFormInputText
            inputId={'SMTPPassword'}
            inputType="text"
            label={t('fields.smtpPassword')}
            onChange={formik.handleChange}
            value={formik.values.SMTPPassword}
            error={formik.errors.SMTPPassword}
          />
        </div>

        <div className="section">
          <LCFormCheckbox
            inputId={'SMTPUseSSL'}
            label={t('fields.smtpUseSSL')}
            checked={formik.values.SMTPUseSSL}
            onChange={formik.handleChange}
          />

          <LCFormCheckbox
            inputId={'sendAssignEvaluationEmail'}
            label={t('fields.sendAssignEvaluationEmail')}
            checked={formik.values.sendAssignEvaluationEmail}
            onChange={formik.handleChange}
          />

          <LCFormCheckbox
            inputId={'sendAssignEvaluationSMS'}
            label={t('fields.sendAssignEvaluationSMS')}
            checked={formik.values.sendAssignEvaluationSMS}
            onChange={formik.handleChange}
          />
        </div>
      </div>
      <div className="update-button-wrapper">
        <LCButton label={t('updateButton')} type="submit" disabled={!formik.dirty || !formik.isValid} />
      </div>{' '}
    </form>
  )
}
