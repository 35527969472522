import { ReactNode } from 'react'

type Props = {
  show: boolean | undefined
  children?: ReactNode
}

export const LCShow: React.FC<Props> = ({ show = false, children }: Props) => {
  return <>{show ? children : null}</>
}
