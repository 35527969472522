import { eAssessmentSizeId, eAssessmentTypeId } from '@logicalcommander/types'
import { IAssessmentSizes, IAssessmentTypeItem } from '../../../../../redux/reducers/settingsSlice'
import { IDropdownOption } from '../../../../../ui/components/lc-dropdown/LCDropdown'

// Get the assessments types based on sizeId
export const getAssessmentsTypesDropdownItems = (
  assessmentsSizes: IAssessmentSizes,
  sizeId: eAssessmentSizeId,
  s: any
): IDropdownOption[] => {
  if (!assessmentsSizes[sizeId]) {
    return []
  }
  const assessmentsTypesDropdown: IDropdownOption[] = Object.keys(assessmentsSizes[sizeId]).map((typeId) => ({
    label: s(`types.${typeId}`),
    value: `${typeId}`,
  }))
  return assessmentsTypesDropdown
}

// Get the assements names based on typeId
export const getAssessmentsNamesDropdownItems = (
  assessmentsSizes: IAssessmentSizes,
  sizeId: eAssessmentSizeId,
  typeId: eAssessmentTypeId,
  s: any
): IDropdownOption[] => {
  const assessmentsNamesDropdown: IDropdownOption[] = assessmentsSizes[sizeId][typeId as any].map((item: IAssessmentTypeItem) => ({
    label: item.customName ? item.customName : s(`names.${item.nameId}`),
    value: `${item.nameId}`,
  }))
  return assessmentsNamesDropdown
}

export const getAssessmentsIdByTypeIdAndNameId = (
  assessmentsSizes: IAssessmentSizes,
  sizeId: eAssessmentSizeId,
  typeId: eAssessmentTypeId,
  nameId: number
): number | undefined => {
  const nameIds: IAssessmentTypeItem[] = assessmentsSizes[sizeId][typeId as any]
  const found: IAssessmentTypeItem | undefined = nameIds.find((item) => item.nameId === +nameId)

  if (found) {
    return found.assessmentId
  }
}

// This is the first selection, it does not depends on other selection
export const getAssessmentsSizesDropdownItems = (s: any): IDropdownOption[] => {
  const sizes: IDropdownOption[] = [
    { label: s(`sizes.1`), value: '1' },
    { label: s(`sizes.2`), value: '2' },
  ]
  return sizes
}
