import './components-sample-page.scss'
import React, { FC, ReactElement } from 'react'
import { useAppSelector, useAppDispatch } from '../../hooks/redux-hooks'
import { selectAuth } from '../../redux/reducers/authSlice'
import { LCAwarePermissions } from '../../ui/awareComponents/lc-aware-permissions/LCAwarePermissions'
import { LCButton } from '../../ui/components/lc-button/LCButton'
import { LCDropdown, IDropdownOption } from '../../ui/components/lc-dropdown/LCDropdown'
import { LCFlag } from '../../ui/components/lc-flag/LCFlag'
import { LCPage } from '../../ui/components/lc-page/LCPage'
import { IconsArray, LCSvg } from '../../ui/components/lc-svg/lc-svg'
import { LCText } from '../../ui/components/lc-text/LCText'
import { changeLanguage } from '../../redux/reducers/generalSlice'

export const ComponentsSamplePage: FC = (): ReactElement => {
  const auth = useAppSelector(selectAuth)
  const dispatch = useAppDispatch()

  const doChangeLanguage = (language: string) => {
    dispatch(changeLanguage(language))
  }

  return (
    <LCPage title="Main">
      Welcome {auth.user.firstName} {auth.user.lastName}
      <h2>LCAwarePermissions component</h2>
      <h3>User has the following permissions</h3>
      <p className="flex-main">{auth.user.calculatedPermissions?.join(', ')}</p>
      <h3>Example of using the LCAwarePermissions component.</h3>
      Wrapped with CREATE_USER permission
      <LCAwarePermissions allowedPermission={'CREATE_USER'}>
        <LCButton label="Create User" />
      </LCAwarePermissions>
      Wrapped with NON_EXISTS permission
      <LCAwarePermissions allowedPermission={'NON_EXISTS'}>
        <LCButton label="Non exists permission" />
      </LCAwarePermissions>
      <h2>LCDropdown component</h2>
      <p>Example of drop down</p>
      <LCDropdown options={['en', 'es']} defaultOption="en" onChange={(args: IDropdownOption) => doChangeLanguage(args.value)} />
      <h2>LCText component</h2>
      <p>Example of translated text</p>
      <LCText labelTX="common.success" />
      <h2>LCFlag component</h2>
      <p>Example of flag</p>
      <LCFlag size="small" flagCode="es" />
      <h2>LCCheckbox component</h2>
      <p>Example of checkbox</p>
      <h2>SVGs</h2>
      <div className="flex-main">
        {IconsArray.map((iconName) => (
          <div key={iconName}>
            {iconName} <LCSvg name={iconName} />
          </div>
        ))}
      </div>
    </LCPage>
  )
}
