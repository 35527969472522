import * as React from 'react'
import { SVGProps } from 'react'

const SvgAddUser = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.125 4.25a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0ZM6.375.5a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5Zm-2.625 9A3.75 3.75 0 0 0 0 13.25v1.5a.75.75 0 0 0 1.5 0v-1.5A2.25 2.25 0 0 1 3.75 11H9a2.25 2.25 0 0 1 2.25 2.25v1.5a.75.75 0 0 0 1.5 0v-1.5A3.75 3.75 0 0 0 9 9.5H3.75ZM15 4.25a.75.75 0 0 1 .75.75v1.5h1.5a.75.75 0 0 1 0 1.5h-1.5v1.5a.75.75 0 0 1-1.5 0V8h-1.5a.75.75 0 0 1 0-1.5h1.5V5a.75.75 0 0 1 .75-.75Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgAddUser
