import './lc-aware-others-image-profile.scss'
import React, { CSSProperties, FC, ReactElement } from 'react'
import { configManager } from '../../../../config/configManager'
import { LCCachedImageAuthenticated } from '../../components/lc-cached-image/LCCachedImageAuthenticated'

interface Props {
  identity?: string
  customStyle?: CSSProperties
}

const LCAwareOthersImageProfileBlob: FC<Props> = ({ identity, customStyle, ...props }: Props): ReactElement => {
  const othersProfileImage = `${configManager.api.GATEWAY_URL}/user/others-profile-image/${identity}`
  return (
    <div style={customStyle} className="lc-aware-others-image-profile-wrapper" {...props}>
      <LCCachedImageAuthenticated cacheKey={`others-profile-image-blob-${identity}`} url={othersProfileImage} alt={identity} {...props} />
    </div>
  )
}

export default React.memo(LCAwareOthersImageProfileBlob)
