import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { FC, useState } from 'react'
import { PropsFormikCustomImpacts, colorsButtonSettings, impactSetingsColor, titleText } from '../SettingsRiskHrImpactRules';
import { useTranslation } from 'react-i18next';

interface propFunction {
    dataUpdateImpactRules: any
}

interface Props {
    impacts: impactSetingsColor[]
    updateTopicsString: ({ dataUpdateImpactRules }: propFunction) => void
    updateColorSettings: ({ color, keyName }: impactSetingsColor) => void
}

interface propsSettingsColor {
    color: string
    keyName: string
}

export const LCSettingsImpacts: FC<Props> = ({ impacts, updateTopicsString, updateColorSettings }: Props) => {

    const { t } = useTranslation('translation', { keyPrefix: 'settings.riskHrImpactRules.impactRules' })
    const { t: TSettingsImpact } = useTranslation('translation', { keyPrefix: 'settings.riskHrImpactRules' })
    const { t: TErrorFormik } = useTranslation('translation', { keyPrefix: 'settings.riskHrImpactRules.impactRules.errorFormik' });
    const { t: TFormikSettings } = useTranslation('translation', { keyPrefix: 'settings.riskHrImpactRules.impactFormikSettings' });
    const { t: TSettingsImpactColor } = useTranslation('translation', { keyPrefix: 'settings.riskHrImpactRules.settingsImpactColor' });

    const [activedCreatePopUp, setActivedCreatePopUp] = useState(true)
    const [actived, setActived] = useState(false)
    const [selectColorSettingsUpdate, setSelectColorSettingsUpdate] = useState<propsSettingsColor>({ color: "", keyName: "" })

    const functionSelectColor = ({ color, keyName }: impactSetingsColor) => {
        const updateImpact = { color, keyName }
        setSelectColorSettingsUpdate(updateImpact)
    }

    const [impactSelectColor, setImpactSelectColor] = useState<string | null>(null)
    const validateForm = ({ customImpact }: { customImpact: string; }) => {
        const errors: Partial<PropsFormikCustomImpacts> = {};

        if (!customImpact.trim()) {
            errors.customImpact = TErrorFormik('requiredName');
        } else if (customImpact.length < 3 || customImpact.length > 25) {
            errors.customImpact = TErrorFormik('requiredcharacters');
        } else if (impacts.findIndex(impact => impact.keyName.toLocaleLowerCase() === customImpact.toLocaleLowerCase()) > 0) {
            errors.customImpact = TErrorFormik('repeatImpact');
        }

        return errors;
    };
    const selectImpactSettingsButtom = ({ impact }: { impact: string }) => {
        setImpactSelectColor(impact)
    }
    const allColors = impacts.map((impact) => impact.color);
    const remainingColors = colorsButtonSettings.filter((color) => !allColors.includes(color));

    return (
        <div className='container-pop-up-custom-impact'>
            <button type='button' className='buttton-settings-impacts' onClick={() => setActived(true)}>{TSettingsImpact('title')}</button>
            {
                actived ?
                    <div className='pop-up-custom-impact-background'>
                        <div className='pop-up-custom-impact'>
                            <div className='container-browser-pop-up'>
                                <button className={`button-view ${activedCreatePopUp ? "actived" : null}`} onClick={() => setActivedCreatePopUp(true)} >{TSettingsImpact('createNewImpact')}</button>
                                <button className={`button-view ${!activedCreatePopUp ? "actived" : null}`} onClick={() => setActivedCreatePopUp(false)} >{TSettingsImpactColor('settingsImpactColorTitle')}</button>
                            </div>
                            {
                                activedCreatePopUp ?
                                    <>
                                        <h3 className='title-pop-up'>{TSettingsImpact('title')}</h3>

                                        <Formik
                                            initialValues={{ customImpact: "" }}
                                            onSubmit={(values, { resetForm }) => { updateTopicsString({ dataUpdateImpactRules: values }); resetForm() }}
                                            validate={validateForm}
                                        >
                                            <Form
                                                className="container-from">
                                                <ErrorMessage name="customImpact" >
                                                    {errorMessage => (
                                                        <div className='container-error-impact'>{errorMessage}</div>
                                                    )}
                                                </ErrorMessage>
                                                <label htmlFor="customImpact" className='container-label-new-impact'>{TFormikSettings('newImpactName')}</label>
                                                <Field
                                                    className="container-text-impact"
                                                    id="customImpact"
                                                    name="customImpact"
                                                    placeholder={TFormikSettings('newImpactNamePlaceholder')}
                                                    type="text"
                                                    autoComplete="off"
                                                />

                                                <button type='submit' className='button-submit-custom-impact'>{TFormikSettings('createImpactButton')}</button>

                                            </Form>
                                        </Formik>
                                        <h5 className='title-impacts'>{TFormikSettings('titleCurrentImpacts')}</h5>
                                        <p className='container-existing-impacts'>
                                            {
                                                impacts.map((impact, index) => <span className='container-impacts-view' key={`vew-impact-current-${impact.keyName}-${index}`} style={{ background: impact.color, color: "white" }}>{titleText({ titleTranslate: t(impact.keyName) })} </span>)
                                            }
                                        </p>

                                    </>
                                    : <>
                                        <h3 className='title-pop-up'>{TSettingsImpactColor('settingsImpactColorTitle')}</h3>
                                        <div className='container-Impacts-settings'>{
                                            impacts.map(({ keyName, color }) => <button className='button-settings-color' key={`button-settings-color-${keyName}`} style={{ background: color, color: "white" }} onClick={() => selectImpactSettingsButtom({ impact: keyName })} >{titleText({ titleTranslate: t(keyName) })}</button>)
                                        }</div>
                                        {
                                            impactSelectColor ?
                                                <>
                                                    <h4>{TSettingsImpactColor('colorCustomizationByImpact')}: {titleText({ titleTranslate: t(impactSelectColor) })}</h4>
                                                    <div className='container-Impacts-settings'>
                                                        {remainingColors.map((color) => <button key={`button-impact-color-settings-${color}`} className='button-colors-settings' style={{ background: `${color}` }} onClick={() => functionSelectColor({ color: color, keyName: impactSelectColor })}></button>)}
                                                    </div>
                                                    <div className='container-button-submit-color'>
                                                        <button type='button' className='button-submit-color-update' onClick={() => updateColorSettings(selectColorSettingsUpdate)} >{TSettingsImpactColor('updateColorButton')}</button>
                                                    </div>
                                                </>
                                                : <span>{TSettingsImpactColor('selectAnImpactToChangeTheColor')}</span>
                                        }
                                    </>

                            }
                            <div className='container-button-update-pop-up'>
                                <button type='button' className='button-submit-custom-impact-cancel' onClick={() => setActived(false)}>{TSettingsImpact('cancelButton')}</button>
                            </div>
                        </div>
                    </div>

                    : null
            }

        </div>
    )
}