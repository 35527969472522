import { devEnv } from './env.dev'
import { localEnv } from './env.local'
import { prodEnv } from './env.prod'
import * as process from 'process'

const config = (env: string) => {
  console.log(`Loading ${env} configuration`)
  switch (env) {
    case 'local':
      return localEnv
    case 'dev':
      return devEnv
    case 'prod':
      return prodEnv
    default:
      return prodEnv
  }
}

export const configManager = config(process?.env?.REACT_APP_ENV || 'prod')
