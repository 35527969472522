import './edit-access-dialog.scss'
import React, { FC, ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { LCFormDropdownDynamic } from '../../../../../../ui/components/forms/lc-form-dropdown-dynamic/lc-form-dropdown-dynamic'
import { IUser } from '@logicalcommander/types'
import { LCButton } from '../../../../../../ui/components/lc-button/LCButton'
import { LCDialog } from '../../../../../../ui/components/lc-dialog/LCDialog'
import { useUpdateAdminAccessMutation } from '../../../../../../services/user.service'
import { SanitizeHTML } from '../../../../../../ui/components/lc-sanitize-html/SanitizeHTML'
import deepEqual from 'deep-equal'
import { useAppSelector } from '../../../../../../hooks/redux-hooks'
import { selectAllRoles } from '../../../../../../redux/reducers/settingsSlice'
import { getAllRolesDropdown } from '../../../../../../utils/list-helper'
import { toast } from 'react-toastify'
import { LCToast } from '../../../../../../ui/components/lc-toast/Toast'

interface Props {
  admin: IUser
  togglerText: string
  onCloseDialog?: () => void
}

export const EditAccessDialog: FC<Props> = ({ admin, togglerText, onCloseDialog }: Props): ReactElement => {
  const { t } = useTranslation('translation', { keyPrefix: 'settings.admins.edit-access' })
  const { t: v } = useTranslation('translation', { keyPrefix: 'validation' })
  const [updateAdminAccess, { isLoading: updateIsLoading }] = useUpdateAdminAccessMutation()
  const roles = useAppSelector(selectAllRoles)

  const [isValidForm, setIsValidForm] = useState(false)
  const dialogRef = useRef<any>(null)

  const initialValues = {
    role: admin.roles[0] as string,
  }

  const validationRules = {
    role: Yup.string().required(v('required', { field: t('role') })),
  }

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object(validationRules),
    onSubmit: (values: any) => {
      updateAdminAccess({ identity: admin.identity, role: values.role })
        .then((response: any) => {
          toast.success(
            <LCToast
              title={t('successUpdatingAdminAccess.title')}
              body={t('successUpdatingAdminAccess.body', { role: values.role, fullname: admin.fullName })}
            />
          )
          onCloseDialog && onCloseDialog()
          dialogRef.current?.close()
        })
        .catch(() => {
          toast.error(
            <LCToast
              title={t('errorUpdatingAdminAccess.title')}
              body={t('errorUpdatingAdminAccess.body', { role: values.role, fullname: admin.fullName })}
            />
          )
        })
    },
  })

  useEffect(() => {
    const touched = !deepEqual(formik.values, initialValues)
    setIsValidForm(touched && formik.isValid)
  }, [formik.isValid, formik.touched, formik.values])

  // console.log('formik.values', formik.values)

  return (
    <LCDialog
      className="edit-access-dialog"
      ref={dialogRef}
      toggler={togglerText}
      isLink={true}
      disabled={updateIsLoading}
      title={t('title', { fullname: admin.fullName })}>
      <div className="description">
        <SanitizeHTML html={t('description')} />
      </div>

      <form onSubmit={formik.handleSubmit}>
        <LCFormDropdownDynamic
          required={false}
          label={t('role')}
          inputId="role"
          selectedValue={formik.values?.role}
          disabled={false}
          setFieldValue={formik.setFieldValue}
          options={getAllRolesDropdown(roles)}
          menuPlacement="bottom"
          error={formik.errors.role}
        />

        <div className="buttons">
          <LCButton label={t('update-button')} type="submit" disabled={!isValidForm} isLoading={updateIsLoading} />
        </div>
      </form>
    </LCDialog>
  )
}
