import 'react-dropdown/style.css'
import './serviceBar.scss'
import { FC } from 'react'
import { Header } from '../header/Header'
import { CSSProperties } from 'react'
import { useAppSelector } from '../../redux/hooks'
import { IPageHeader, selectGeneral } from '../../redux/reducers/generalSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LCLogo } from '../../ui/components/lc-logo/LCLogo'
import { selectAuth } from '../../redux/reducers/authSlice'
import { selectSettings } from '../../redux/reducers/settingsSlice'

export interface IServiceBarProps {
  customStyle?: CSSProperties
}

export const ServiceBar: FC<IServiceBarProps> = ({ customStyle }: IServiceBarProps) => {
  const pageHeader: IPageHeader | undefined = useAppSelector(selectGeneral).pageHeader
  const { companyId } = useAppSelector(selectAuth)?.user
  const { companyInfo } = useAppSelector(selectSettings)

  return (
    <div className="service-bar" style={customStyle}>
      <div className="page-title">
        <div className="logo-container">
          <LCLogo name={companyId || ''} description={companyInfo?.name || ''} logoWidth={40} showCompanyLabels={true} />
        </div>{' '}
        {pageHeader?.icon && <FontAwesomeIcon className="page-title-icon" icon={pageHeader?.icon} />}
        <div className="page-title-text">
          {pageHeader?.title && <h1>{pageHeader.title}</h1>}
          {pageHeader?.subTitle && <div className="sub-title">{pageHeader.subTitle}</div>}
          {pageHeader?.breadcrumbs && <div className="bread-crumbs">{pageHeader.breadcrumbs}</div>}
        </div>
      </div>
      <div className="header-wrapper">
        <Header />
      </div>
    </div>
  )
}
