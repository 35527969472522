import * as React from 'react'
import { SVGProps } from 'react'

const SvgUpload = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M9.184 8.222 7.002 5.999 4.821 8.222M7.002 6v5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M11.578 9.55a2.76 2.76 0 0 0 1.195-1.329 2.827 2.827 0 0 0 .143-1.796 2.783 2.783 0 0 0-.969-1.507 2.695 2.695 0 0 0-1.672-.586h-.687a4.475 4.475 0 0 0-.9-1.766 4.37 4.37 0 0 0-1.567-1.19 4.293 4.293 0 0 0-3.805.144A4.388 4.388 0 0 0 1.84 2.823a4.489 4.489 0 0 0-.77 1.83 4.526 4.526 0 0 0 .09 1.987 4.47 4.47 0 0 0 .934 1.749"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9.184 8.222 7.002 5.999 4.821 8.222" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default SvgUpload
