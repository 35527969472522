import './licenses.helper.scss'

import React from 'react'
import { TFunction } from 'i18next'
import { TooltipProps } from 'recharts'

interface CustomTooltipProps extends TooltipProps<any, any> {
  t: TFunction
}

export const LicenseCustomLabel = ({ viewBox, labelText, value }: any) => {
  const { cx, cy } = viewBox
  return (
    <g>
      <text
        x={cx}
        y={cy - 12}
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
        alignmentBaseline="middle"
        fontSize="16"
        fill="#0568ae"
        fontWeight="600">
        {labelText}
      </text>
      <text
        x={cx}
        y={cy + 10}
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
        alignmentBaseline="middle"
        fill="#0568ae"
        fontSize="22"
        fontFamily="SFPro-Display-Black">
        {value}
      </text>
    </g>
  )
}

export const LicenseCustomizedLegend = (props: any) => {
  const { payload } = props
  return (
    <ul className="legend-list">
      {payload.map((entry: any, index: number) => (
        <li key={`item-${index}`} className="legend-item">
          <div className="legend-label-wrapper">
            <div className="legend-bullet" style={{ backgroundColor: entry.payload.fill }}></div>
            <div className="legend-label">{entry.value}</div>
          </div>
          <div className="legend-value">{entry.payload.value}</div>
        </li>
      ))}
    </ul>
  )
}

export const CustomTooltip = ({ active, payload, label, t }: CustomTooltipProps) => {
  console.log('active, payload, label', active, payload, label)
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{t('toolTipLabel', { type: t(`types.${label}`) })}</p>
        <table>
          <tbody>
            <tr>
              <td>{t('used')}</td>
              <td>{payload[0].value}</td>
            </tr>
            <tr>
              <td>{t('remain')}</td>
              <td>{payload[1].value}</td>
            </tr>
            <tr>
              <td>{t('total')}</td>
              <td>{payload[0].value + payload[1].value}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  return null
}
