import { ITitleValueString } from '@logicalcommander/types'
import './lc-title-value.scss'
import React, { CSSProperties, HTMLAttributes } from 'react'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyles?: CSSProperties
  data: ITitleValueString[]
}
export const LCTitleValue = ({ customStyles, data }: Props,) => {

  return (
    <div className="title-value" style={customStyles}>
      <div className="title">
        {data.map((d) => (
          <div key={d.title}>{d.title}</div>
        ))}
      </div>
      <div className="value">
        {data.map((d) => (
          <div key={d.value}>{d.value}</div>
        ))}
      </div>
    </div>
  )
}
