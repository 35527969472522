import './users.scss'
import React, { CSSProperties, FC, HTMLAttributes, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MultipleUsersActions } from './MultipleUsersActions'
import { useTranslation } from 'react-i18next'
import { Users } from './users'
import { LCButton } from '../../../ui/components/lc-button/LCButton'
import { LCPage } from '../../../ui/components/lc-page/LCPage'
import { IAssessmentSchemaInfo } from '@logicalcommander/types'
import { ePrivateRoutes } from '../../../routes/e-routes'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus'
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers'
import { IUserSummary } from './users.columns'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

export const UsersPage: FC<Props> = ({ customStyle }: Props) => {
  let navigate = useNavigate()
  const [selectedRows, setSelectedRows] = useState<IUserSummary[]>([])
  const { t } = useTranslation('translation', { keyPrefix: 'users' })

  const afterDeleteMultipleUsers = (deletedIdentities: string[]): void => {}

  return (
    <LCPage icon={faUsers} title={t('page_title')} breadcrumbs={t('page_breadcrumbs')}>
      <div className="users" style={customStyle}>
        <div className="right-side">
          {selectedRows.length > 0 ? (
            <MultipleUsersActions
              selectedRows={selectedRows}
              customStyle={{ marginRight: '10px' }}
              onAssignSuccess={(evaluationData: IAssessmentSchemaInfo) => setSelectedRows([])} // Not working
              onDeleteSuccess={(deletedIdentities: string[]) => afterDeleteMultipleUsers(deletedIdentities)}
            />
          ) : null}
          <LCButton
            newIcon={faUserPlus}
            label={t('add_user_button_text')}
            onClick={() => {
              navigate('/app' + ePrivateRoutes.USER_CREATE)
            }}
          />
        </div>
      </div>
      <Users showTableActions={true} onSelectedRows={(rows: IUserSummary[]) => setSelectedRows(rows)} />
    </LCPage>
  )
}
