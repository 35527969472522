import './reset-password.scss'
import React, { FC, useEffect, useState } from 'react'
import { useResetPasswordMutation } from '../../../services/auth.service'
import { LCFormInputText } from '../../../ui/components/forms/lc-form-inputText/lc-form-inputText'
import { LCButton } from '../../../ui/components/lc-button/LCButton'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { IResetPasswordRequest } from '../../../../interfaces/auth.interfaces'
import { LCLanguagesSelection } from '../../../ui/components/lc-languages-selection/LCLanguagesSelection'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { changeLanguage, selectGeneral } from '../../../redux/reducers/generalSlice'
import { useNavigate } from 'react-router'
import { ePublicRoutes } from '../../../routes/e-routes'
import { LCUnauthenticatedPage } from '../../../ui/components/lc-unauthenticated-page/LCUnauthenticatedPage'
import { SanitizeHTML } from '../../../ui/components/lc-sanitize-html/SanitizeHTML'
import deepEqual from 'deep-equal'
import { toast } from 'react-toastify'
import { LCToast } from '../../../ui/components/lc-toast/Toast'

export const ResetPassword: FC = () => {
  const [resetPassword, { data, error, isLoading }] = useResetPasswordMutation()
  const { t } = useTranslation('translation', { keyPrefix: 'auth.resetPassword' })
  const { t: v } = useTranslation('translation', { keyPrefix: 'validation' })
  const [isValidForm, setIsValidForm] = useState(false)
  const dispatch = useAppDispatch()
  let navigate = useNavigate()
  const { selectedLanguage } = useAppSelector(selectGeneral)

  const initialValues = {
    email: '',
    companyId: '',
    language: selectedLanguage || 'en',
  }

  const validationRules = {
    email: Yup.string()
      .email(v('valid', { field: t('email') }))
      .required(v('required', { field: t('email') })),
    companyId: Yup.string().required(v('required', { field: t('companyId') })),
  }

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object(validationRules),
    onSubmit: (values: IResetPasswordRequest) => {
      resetPassword(values)
    },
  })

  useEffect(() => {
    if (data && !error) {
      toast.success(<LCToast title={t('success.title')} body={t('success.body')} />)
      navigate(ePublicRoutes.LOGIN)
    } else if (error) {
      toast.error(<LCToast title={t('error.title')} body={t('error.body')} />)
    }
  }, [data, error])

  useEffect(() => {
    const touched = !deepEqual(formik.values, initialValues)
    setIsValidForm(touched && formik.isValid)
  }, [formik.isValid, formik.touched, formik.values.email])

  const doChangeLanguage = (language: string) => {
    formik.setFieldValue('language', language)
    dispatch(changeLanguage(language))
  }

  return (
    <LCUnauthenticatedPage title={t('title')}>
      <div className="reset-password-page">
        <SanitizeHTML html={t('description')} className="reset-password-description" />
        <form className="form" onSubmit={formik.handleSubmit}>
          <LCLanguagesSelection languageSelected={doChangeLanguage} />

          <LCFormInputText
            label={t('companyId')}
            inputId="companyId"
            inputType="text"
            error={formik.errors.companyId}
            {...formik.getFieldProps('companyId')}
          />

          <LCFormInputText
            label={t('email')}
            inputId="email"
            inputType="email"
            error={formik.errors.email}
            {...formik.getFieldProps('email')}
          />

          <LCButton
            type="submit"
            label={t('resetPassword')}
            isLoading={isLoading}
            disabled={!isValidForm}
            customStyle={{ maxWidth: '200px', alignSelf: 'flex-end', marginTop: '10px' }}
          />
        </form>
      </div>
      <div className="link-blue" onClick={() => navigate(ePublicRoutes.LOGIN)}>
        {t('navigateToLogin')}
      </div>
    </LCUnauthenticatedPage>
  )
}
