import {
  Gender,
  IEnumsPositionGroupSettingsItem,
  IEnumsSettingsItem,
  IRolesAndPermissions,
  eCountries,
  eEvaluationStatus,
  eStates,
} from '@logicalcommander/types'
import { TFunction } from 'i18next'
import { OptionType } from '../ui/components/forms/lc-form-inputText-roles/lc-form-inputText-roles'

export const getDropdownPositionsWithGroups = (positionsGroups: IEnumsPositionGroupSettingsItem[], items: IEnumsSettingsItem[]): any[] => {
  const allPositionsWithGroupsOptions = positionsGroups.map((pg) => {
    const allOptions = items.filter((i) => pg.positions.includes(i.id))
    return {
      label: pg.name,
      options: allOptions.map((i) => {
        return { label: i.name, value: String(i.id) }
      }),
    }
  })
  return allPositionsWithGroupsOptions
}

export const getDropdownOptionsFromDbEnum = (items: IEnumsSettingsItem[]): OptionType[] => {
  const allPositionsOptions: OptionType[] =
    items.map((i: IEnumsSettingsItem) => {
      return {
        label: i.name,
        value: String(i.id),
      } as OptionType
    }) ?? []
  return allPositionsOptions
}

export const getAllAssessmentsSizeDropdown = (t: any): OptionType[] => {
  const allAssessmentsSizeOptions: OptionType[] = [
    { label: t('settings.assessments.sizes.3'), value: '3' },
    { label: t('settings.assessments.sizes.1'), value: '1' },
    { label: t('settings.assessments.sizes.2'), value: '2' },
  ]
  return allAssessmentsSizeOptions
}

export const getAllAssessmentsTypesDropdown = (t: any): OptionType[] => {
  const allAssessmentsTypesOptions: OptionType[] = [
    { label: t('settings.assessments.types.200'), value: '200' },
    { label: t('settings.assessments.types.300'), value: '300' },
  ]
  return allAssessmentsTypesOptions
}

export const getAllAssessmentsNamesDropdown = (t: any): OptionType[] => {
  const allAssessmentsNamesOptions: OptionType[] = [
    { label: t('settings.assessments.names.1000'), value: '1000' },
    { label: t('settings.assessments.names.1010'), value: '1010' },
    { label: t('settings.assessments.names.1020'), value: '1020' },
    { label: t('settings.assessments.names.1030'), value: '1030' },
    { label: t('settings.assessments.names.1040'), value: '1040' },
    { label: t('settings.assessments.names.1050'), value: '1050' },
  ]
  return allAssessmentsNamesOptions
}

export const getAllLanguagesDropdown = (t: any): OptionType[] => {
  const allLanguagesOptions: OptionType[] = ['en', 'es', 'pt', 'hr'].map((i) => {
    return { label: t(`languages.${i}`), value: i }
  })
  return allLanguagesOptions
}

export const getAllRiskSeverityDropdown = (t: any): OptionType[] => {
  const allRiskSeverityOptions: OptionType[] = [
    { label: t('settings.risk.severity.veryLow'), value: 'veryLow' },
    { label: t('settings.risk.severity.low'), value: 'low' },
    { label: t('settings.risk.severity.moderate'), value: 'moderate' },
    { label: t('settings.risk.severity.high'), value: 'high' },
    { label: t('settings.risk.severity.critical'), value: 'critical' },
  ]
  return allRiskSeverityOptions
}

export const getAllMaxImpactPriorityDropdown = (t: any): OptionType[] => {
  const allRiskSeverityOptions: OptionType[] = [
    { label: t('settings.risk.maxImpactPriority.1'), value: '1' },
    { label: t('settings.risk.maxImpactPriority.2'), value: '2' },
    { label: t('settings.risk.maxImpactPriority.3'), value: '3' },
    { label: t('settings.risk.maxImpactPriority.4'), value: '4' },
    { label: t('settings.risk.maxImpactPriority.5'), value: '5' },
  ]
  return allRiskSeverityOptions
}

export const getAllCountriesDropdown = (): OptionType[] => {
  const allCountriesOptions: OptionType[] = Object.keys(eCountries).map((i: any) => {
    return { label: i, value: (eCountries as any)[i] }
  })
  return allCountriesOptions
}

export const getAllStatesDropdown = (): OptionType[] => {
  const allStatesOptions: OptionType[] = Object.keys(eStates).map((i: any) => {
    return { label: i, value: (eStates as any)[i] }
  })
  return allStatesOptions
}

export const getAllGendersDropdown = (): OptionType[] => {
  const allGendersOptions: OptionType[] = Object.keys(Gender).map((i: any) => {
    return { label: i, value: (Gender as any)[i] }
  })
  return allGendersOptions
}

export const getAllRolesDropdown = (data: IRolesAndPermissions[]): OptionType[] => {
  const roles: OptionType[] = data.map((i: IRolesAndPermissions) => {
    return {
      label: String(i.role),
      value: String(i.role),
    } as OptionType
  })
  return roles
}

export const getAllEvaluationStatusesDropdown = (t: any): OptionType[] => {
  const roles: OptionType[] = Object.keys(eEvaluationStatus).map((i: string) => {
    return {
      label: t(`evaluations.${i}`),
      value: i,
    } as OptionType
  })
  return roles
}

export const getTRFDescription = (trfValue: number, t: TFunction): string => {
  const trfDescriptions: { [key: number]: string } = {
    10: 'critical',
    8: 'high',
    6: 'moderate',
    4: 'low',
    2: 'veryLow'
  }
  const descriptionKey = trfDescriptions[trfValue]
  if (!descriptionKey) {
    return 'Unknown'
  }
  const translation = t(descriptionKey)
  return translation
}