import './users.scss'
import './users.print.scss'
import React, { CSSProperties, FC, HTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetUsersMutation } from '../../../services/users.service'
import { IUserSummary, users_columns } from './users.columns'
import { LCTableServerPagination } from '../../../ui/components/lc-table/LCTableServerPagination'
import { useDeleteUserMutation } from '../../../services/user.service'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../hooks/redux-hooks'
import { selectAllRoles, selectPositions, selectSettings } from '../../../redux/reducers/settingsSlice'
import { IPaginationRequest, IPaginationResponse, IPaginationSettings, eTableName, ISettings } from '@logicalcommander/types'
import { getDropdownOptionsFromDbEnum } from '../../../utils/list-helper'
import { OptionType } from '../../../ui/components/forms/lc-form-inputText-roles/lc-form-inputText-roles'
import { toast } from 'react-toastify'
import { LCToast } from '../../../ui/components/lc-toast/Toast'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  showTableActions?: boolean
  customStyle?: CSSProperties
  onSelectedRows?: (rows: IUserSummary[]) => void
}

export const Users: FC<Props> = ({ customStyle, showTableActions = false, onSelectedRows }: Props) => {
  let navigate = useNavigate()
  const settings: ISettings = useAppSelector(selectSettings)
  const paginationSettings: IPaginationSettings = settings.commonUX.pagination
  const [getUsersPagination, { data: dataGet, error: errorGet, isLoading: isGetUserLoading }] = useGetUsersMutation()
  const [deleteUser, { data: dataDelete, error: errorDelete }] = useDeleteUserMutation()
  const [users, setUsers] = useState<IUserSummary[]>([])
  const [pageCount, setPageCount] = useState(0) // Number of pages
  const [pageIndex, setPageIndex] = useState<number>(0) // Current page index
  const [pageSize, setPageSize] = useState<number>(paginationSettings.users) // Page size
  const { t } = useTranslation('translation', { keyPrefix: 'users' })
  const allPositionsOptions: OptionType[] = getDropdownOptionsFromDbEnum(useAppSelector(selectPositions))
  const rolesAndPermissions = useAppSelector(selectAllRoles)

  useEffect(() => {
    if (!errorGet && dataGet) {
      console.log('====> setUsers', dataGet?.data?.length)
      setUsers(dataGet.data)
    }
  }, [dataGet, errorGet])

  useEffect(() => {
    setPageSize(paginationSettings.users)
  }, [paginationSettings])

  useEffect(() => {
    if (!errorDelete && dataDelete) {
      toast.success(<LCToast title={t('notifications.delete_user_success_title')} body={t('notifications.delete_user_success_text')} />)
      const leftUsers: IUserSummary[] = users.filter((user) => user.identity !== dataDelete.identity)
      setUsers(leftUsers)
    } else if (errorDelete) {
      toast.error(<LCToast title={t('notifications.delete_user_error_title')} body={t('notifications.delete_user_error_text')} />)
    }
  }, [dataDelete, errorDelete])

  const fetchData = useCallback(({ pageSize, pageIndex, sortBy, search }: IPaginationRequest) => {
    getUsersPagination({ pageIndex, pageSize, sortBy, search })
      .then((result: any) => {
        const resData: IPaginationResponse<IUserSummary[]> = result.data
        setPageCount(Math.ceil(resData.totalSize / pageSize))
        setPageIndex(pageIndex)
        setPageSize(pageSize)
      })
      .catch((err) => {
        toast.error(<LCToast title={t('notifications.get_users_error_title')} body={t('notifications.get_users_error_text')} />)
      })
  }, [])

  const onDeleteUser = (user: IUserSummary): void => {
    deleteUser(user.identity)
  }

  const columns = useMemo(() => users_columns(navigate, t, allPositionsOptions, rolesAndPermissions, onDeleteUser), [pageIndex])

  return (
    <div style={customStyle}>
      <LCTableServerPagination<IUserSummary>
        tableName={eTableName.USERS_TABLE}
        showCheckbox={true}
        columns={columns}
        data={users}
        fetchData={fetchData}
        loading={isGetUserLoading}
        pageCount={pageCount}
        onSelectRows={(rows: IUserSummary[]) => onSelectedRows && onSelectedRows(rows)}
        initPageSize={pageSize}
        showTableActions={showTableActions}
      />
    </div>
  )
}
