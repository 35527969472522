import './risk-hr-evaluation-details.scss'
import { CSSProperties, FC, HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import { IEvaluationSpecific } from '../../../../../interfaces/evaluations.interface'
import { IKeyValueItem, LCKeyPanelValue } from '../../../../ui/components/lc-key-value-panel/LCKeyValuePanel'
import { datesHelper } from '../../../../utils/dates'
import { LCTopicsWithRisk } from '../../../../ui/components/lc-topics-with-risk/LCTopicsWithRisk'

export interface IRiskHrEvaluationDetailsProps extends HTMLAttributes<HTMLDivElement> {
  evaluation: IEvaluationSpecific | undefined
  customStyle?: CSSProperties
}

export const RiskHrEvaluationDetails: FC<IRiskHrEvaluationDetailsProps> = ({ evaluation, customStyle }: IRiskHrEvaluationDetailsProps) => {
  const { t } = useTranslation('translation')
  if (!evaluation) {
    return null
  }

  const { typeId, nameId, sizeId, language, customName } = evaluation.assessmentInfo
  const data1: IKeyValueItem[] = []
  data1.push({ text: t('evaluations.full_name'), value: evaluation.fullName || '' })
  data1.push({ text: t('evaluations.identity'), value: evaluation.identity })
  data1.push({ text: t('evaluations.evaluation_id'), value: evaluation.evaluationInfo.evaluationId })
  // data1.push({ text: t('evaluations.evaluation_id'), value: String(id) })
  data1.push({ text: t('evaluations.evaluation_size'), value: t(`settings.assessments.sizes.${sizeId}`) })
  data1.push({ text: t('evaluations.evaluation_type'), value: t(`settings.assessments.types.${typeId}`) })
  if (customName) {
    data1.push({ text: t('evaluations.evaluation_name'), value: customName })
  } else {
    data1.push({ text: t('evaluations.evaluation_name'), value: t(`settings.assessments.names.${nameId}`) })
  }
  data1.push({ text: t('evaluations.evaluation_language'), value: t(`languages.${language}`) })

  const data2: IKeyValueItem[] = []
  data2.push({ text: t('evaluations.status'), value: '' })
  data2.push({ text: t('created'), value: evaluation.status.created ? datesHelper.formatLong(evaluation.status.created) : 'N/A' })
  data2.push({ text: t('accepted'), value: evaluation.status.accepted ? datesHelper.formatLong(evaluation.status.accepted) : 'N/A' })
  data2.push({ text: t('started'), value: evaluation.status.started ? datesHelper.formatLong(evaluation.status.started) : 'N/A' })
  data2.push({ text: t('completed'), value: evaluation.status.completed ? datesHelper.formatLong(evaluation.status.completed) : 'N/A' })

  const data3: IKeyValueItem[] = []
  if (evaluation.results?.summary) {
    data3.push({ text: t('evaluations.evaluation_max'), value: String(evaluation.results.summary.maxRisk) })
    data3.push({ text: t('evaluations.evaluation_average'), value: String(evaluation.results.summary.averageRisk) })
    data3.push({ text: t('evaluations.max_severity'), value: String(evaluation.results.summary.maxSeverity) })
    data3.push({
      text: t('evaluations.impact'),
      value: evaluation.results.summary.impact?.map((i: any) => t(`settings.riskHrImpactRules.impacts.${i}.name`)).join(', ') || 'N/A',
    })
    data3.push({
      text: t('evaluations.evaluation_topics_with_risks'),
      value: <LCTopicsWithRisk topicsIds={evaluation.results.summary?.topicsWithRisks} />,
    })
  }

  return (
    <div style={customStyle}>
      <h3>{t('riskHr.details.title')}</h3>
      <div className="risk-hr-evaluation-details">
        <LCKeyPanelValue keyValues={data1} customStyle={{ minWidth: '300px' }} />
        <LCKeyPanelValue keyValues={data2} customStyle={{ minWidth: '400px' }} />
        <LCKeyPanelValue keyValues={data3} customStyle={{ minWidth: '400px' }} />
      </div>
    </div>
  )
}
