import * as React from 'react'
import { SVGProps } from 'react'

const SvgReport = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.75 1h-7c-.464 0-.91.19-1.237.527A1.826 1.826 0 0 0 1 2.8v14.4c0 .477.184.935.513 1.273.328.337.773.527 1.237.527h10.5c.464 0 .91-.19 1.237-.527.329-.338.513-.796.513-1.273V6.4L9.75 1Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.75 1v5.4H15M11.5 10.9h-7M11.5 14.5h-7M6.25 7.3H4.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgReport
