import { SVGProps } from 'react'

const SvgFilterSave = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg" {...props}>
    <polygon points="172.557 473.445 266.319 402.257 266.319 288.354 430.386 46.313 219.297 46.313 8.477 46.313 172.557 288.354" />
    <ellipse strokeWidth="9" stroke="#ffffff" paintOrder="fill" cx="353.584" cy="270.641" rx="140" ry="140" />
    <rect x="273.448" y="190.685" width="160" height="160" paintOrder="fill" fill="#ffffff" rx="11.083" ry="11.083" />
    <rect x="293.247" y="197.17" width="120" height="60" rx="4.849" ry="4.849" />
    <rect x="377.864" y="202.43" width="30" height="50" fill="#ffffff" rx="2.088" ry="2.088" />
    <rect y="287.328" width="140" height="6" x="283.545" />
    <rect y="303.012" width="140" height="6" x="282.851" />
    <rect y="317.705" width="140" height="6" x="283.94" />
    <rect y="332.398" width="140" height="6" x="283.94" />
  </svg>
)

export default SvgFilterSave
