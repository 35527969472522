import * as React from 'react'
import { SVGProps } from 'react'

const SvgEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.051 2.898H2.79A1.79 1.79 0 0 0 1 4.687V17.21A1.79 1.79 0 0 0 2.79 19h12.523a1.79 1.79 0 0 0 1.79-1.79V10.95"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.76 1.556a1.898 1.898 0 0 1 2.684 2.684l-8.498 8.498-3.578.895.894-3.579 8.499-8.498Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgEdit
