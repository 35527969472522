import './settings-permissions.scss'
import React, { CSSProperties, HTMLAttributes, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { LCButton } from '../../../../ui/components/lc-button/LCButton'
import { LCFormToggle } from '../../../../ui/components/forms/lc-form-toggle/lc-form-toggle'
import { IGroupedPermissions, IRolesAndPermissions, eRoleType } from '@logicalcommander/types'
import { cachedStorage, eStorageKey } from '../../../../utils/cachedStorage'
import { useAppSelector } from '../../../../hooks/redux-hooks'
import { isEmpty } from '../../../../utils/object'
import { findObjectDifferences } from '../settingsHelper'
import { useUpdatePermissionsMutation } from '../../../../services/settings.service'
import { ISettingUpdatePermissions, ISettingPermission, ISettingValues, mergePermissions } from './permissions-helper'
import { selectAllRoles } from '../../../../redux/reducers/settingsSlice'
import { toast } from 'react-toastify'
import { LCToast } from '../../../../ui/components/lc-toast/Toast'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

export const SettingsPermissions = ({ customStyle }: Props) => {
  const [updateCompanyPermissions, { data: dataUpdate, error: errorUpdate }] = useUpdatePermissionsMutation()

  const { t } = useTranslation('translation', { keyPrefix: 'settings.permissions' })
  const { t: v } = useTranslation('translation', { keyPrefix: 'validation' })
  const [rolesAndPermissions, setRolesAndPermissions] = useState<IRolesAndPermissions[]>()
  const [keyValueRolesAndPermissions, setKeyValueRolesAndPermissions] = useState<ISettingValues>()
  const groupedPermissions: IGroupedPermissions | undefined = cachedStorage.get<IGroupedPermissions>(eStorageKey.GROUPED_PERMISSIONS)
  const roles: IRolesAndPermissions[] = useAppSelector(selectAllRoles)

  const initialValues: ISettingValues = {}

  useEffect(() => {
    setRolesAndPermissions(roles)
    const _initialValues = mergePermissions(groupedPermissions, roles)
    setKeyValueRolesAndPermissions(_initialValues)
    console.log('_initialValues', _initialValues)
    formik.setValues(_initialValues)
  }, [])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values: any) => {
      console.log('onSubmit', values)
      const permissions: Partial<ISettingValues> = findObjectDifferences<ISettingValues>(keyValueRolesAndPermissions || {}, values)
      console.log('permissions', permissions)
      if (isEmpty(permissions)) {
        toast.info(<LCToast title={v('nothingToUpdate.title')} body={v('nothingToUpdate.body')} />)
      } else {
        const results: ISettingUpdatePermissions = {}
        Object.keys(permissions).forEach((key: string) => {
          const role = key.split('===')[0]
          const permission = key.split('===')[1]
          if (!results[role]) {
            results[role] = []
          }
          const value = !!permissions[key]
          const p: ISettingPermission = { [permission]: value }
          results[role].push(p)
        })
        console.log('results', results)
        updateCompanyPermissions(results)
      }
    },
  })

  useEffect(() => {
    if (!errorUpdate && dataUpdate) {
      toast.success(<LCToast title={t('successUpdatingPermissions.title')} body={t('successUpdatingPermissions.body')} />)
    } else if (errorUpdate) {
      toast.error(<LCToast title={t('errorUpdatingPermissions.title')} body={t('errorUpdatingPermissions.body')} />)
    }
  }, [dataUpdate, errorUpdate])

  console.log('values', formik.values)

  return (
    <form className="settings-form" onSubmit={formik.handleSubmit}>
      <div className="settings-content" style={customStyle}>
        <h3>{t('permissions')}</h3>
        <div className="description">{t('description')}</div>
        {rolesAndPermissions ? (
          Object.keys(formik.values).length > 0 && groupedPermissions ? (
            <table className="permissions-table">
              <thead>
                <tr>
                  <th>Permission Name</th>
                  {rolesAndPermissions.map((item) => (
                    <th className="rotate" key={item.role as string}>
                      <div>{item.role as string}</div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Object.keys(groupedPermissions).map((group) => (
                  <React.Fragment key={group}>
                    <tr>
                      <th className="group-name" colSpan={rolesAndPermissions.length + 1}>
                        {group}
                      </th>
                    </tr>
                    {(groupedPermissions as any)[group as string].map((permission: string) => (
                      <tr key={permission}>
                        <td>{permission}</td>
                        {rolesAndPermissions.map((item) => (
                          <td key={item.role as string}>
                            <LCFormToggle
                              key={`${item.role}===${permission}`}
                              inputId={`${item.role}===${permission}`}
                              disabled={item.type === eRoleType.COMMON}
                              onChange={formik.handleChange}
                              checked={formik.values[`${item.role}===${permission}`]}
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          ) : null
        ) : null}
      </div>

      <div className="update-button-wrapper">
        <LCButton label={t('updateButton')} type="submit" disabled={!formik.dirty || !formik.isValid} />
      </div>
    </form>
  )
}
