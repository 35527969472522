import React, { FC, HTMLAttributes, lazy, useEffect, useState } from 'react'
import { CSSProperties } from 'styled-components'
import { sumBy } from 'lodash'
import { useLazyGetAlertsPercentageMenWomenQuery } from '../../../../services/dashboard.service'
import { ITitleValueNumber, ISearch, eSearchType } from '@logicalcommander/types'
import { useTranslation } from 'react-i18next'
import { BaseDashboardComponent } from '../../../../ui/components/base-dashboard/BaseDashboardComponent'
import { DashboardComponentTitle } from '../../../../ui/components/base-dashboard/DashboardComponentTitle'
import { BarColor, CustomLabels } from '../../../../../interfaces/dashboard.interface'

const LCPieChartUniNew = lazy(() => import('../../../../ui/components/charts/lc-pie-chart'))

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

const RiskHrDashboardRiskPieWomanAndMen: FC<Props> = ({ customStyle }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.riskHr.riskPieWomanAndMen' })
  const [getDashboardAlertWomanMen, { data, error, isLoading }] = useLazyGetAlertsPercentageMenWomenQuery()
  const [riskWomanAndMen, setRiskWomanAndMen] = useState<ITitleValueNumber[]>([])
  const [sumData, setSumData] = useState(0)

  useEffect(() => {
    const search: ISearch[] = [{ keyName: 'aaaa', type: eSearchType.DATE_AFTER, value: '123213' }]
    getDashboardAlertWomanMen({ search })
  }, [])

  useEffect(() => {
    if (!error && data) {
      const dataSorted = [...data]
      const sorted = [
        {
          title: 'Woman',
          value: sumBy(dataSorted, 'Woman'),
        },
        {
          title: 'Men',
          value: sumBy(dataSorted, 'Men'),
        },
      ]
      const sumTotalSorted = sumBy(sorted, 'value')
      setSumData(sumTotalSorted)
      setRiskWomanAndMen(sorted)
    }
  }, [data, error])

  return (
    <BaseDashboardComponent customStyle={customStyle} sectionTitle={<DashboardComponentTitle title={t('title')} subTitle={t('total')} />} isLoading={isLoading} isSuccess={!!riskWomanAndMen} isError={!!error}>
      <LCPieChartUniNew
        data={riskWomanAndMen}
        dataTotal={sumData}
        options={{
          color: BarColor.COLOR_WOMEN_MEN,
          label: CustomLabels.ALERT_EVALUATION_PIE,
          customPercentage: false,
        }}
      />
    </BaseDashboardComponent>
  )
}

export default RiskHrDashboardRiskPieWomanAndMen
