import React from 'react'
import { CSSProperties, FC, HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ILCActionPopupItemData, LCActionPopup } from '../lc-action-popup/LCActionPopup'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

export const LCRiskHrRiskLegendPopup: FC<Props> = ({ customStyle, ...props }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'riskHr.legend' })
  const legendData = []
  legendData.push({ range: '0-60', title: t('0-60.title'), description: t('0-60.description') })
  legendData.push({ range: '61-80', title: t('61-80.title'), description: t('61-80.description') })
  legendData.push({ range: '81-100', title: t('81-100.title'), description: t('81-100.description') })

  const actionPopupLegendData: ILCActionPopupItemData[] = legendData?.map((i: any) => {
    return {
      reactElement: (
        <div>
          <div style={{ fontFamily: 'SF-Pro-Text-Bold' }}>{`${i.range} (${i.title})`}</div>
          <div>{i.description}</div>
        </div>
      ),
    }
  })

  return (
    <div style={customStyle} {...props}>
      {actionPopupLegendData && actionPopupLegendData.length > 0 ? (
        <LCActionPopup
          data={actionPopupLegendData}
          position={['center', 'bottom']}
          customStyle={{ width: '320px' }}
          anchor={
            <div style={{ cursor: 'pointer', color: '#0568ae' }}>
              {<FontAwesomeIcon icon={faCircleExclamation} size="lg" color="#0568ae" />}
            </div>
          }
        />
      ) : null}
    </div>
  )
}
