import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const LCPopUpInformative = ({ nameRequests, requestsData, modifiedData }: any) => {
  const [popUpView, setpopUpView] = useState(false)
  const { t } = useTranslation('translation', { keyPrefix: 'settings.modulePurchase.myRequests' })
  return (
    <div>
      {!popUpView ? (
        <button className="button-see-modification" onClick={() => setpopUpView(true)}>
          {t('buttons.see_modifications')}
        </button>
      ) : (
        <>
          <div className="container-background-pop-up">
            <div className="container-pop-up">
              <h3>{t('pop_up.title')}</h3>
              <div className="container-requests">
                <h4>{t('pop_up.request_for')}</h4>
                <div className="contarner-data-requests">
                  <span> {` ${nameRequests}`}</span>
                </div>
                <h4>{t('pop_up.old_request')}</h4>
                <div className="contarner-data-requests">
                  {requestsData.map((item: any) => (
                    <>
                      {item.product} x {item.quantity}
                      {', '}
                    </>
                  ))}
                </div>
                <h4>{t('pop_up.modified_by')}</h4>
                <div className="contarner-data-requests">
                  <span> {` ${modifiedData[0].modify_name}`}</span>
                </div>
                <h4>{t('pop_up.request_modification')}</h4>
                <div className="contarner-data-requests">
                  {modifiedData.map((item: any) => (
                    <span>
                      {item.product} x {item.quantity}
                      {', '}
                    </span>
                  ))}
                </div>
              </div>
              <button className="button-closed-modification" onClick={() => setpopUpView(false)}>
                Closed
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
