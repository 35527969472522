import './lc-search-user.scss'

import React, { useState, useCallback, useEffect } from 'react'
import Select, { InputActionMeta, SingleValue } from 'react-select'
import debounce from 'lodash.debounce'
import { useSearchUserMutation } from '../../../services/user.service'
import { CSSProperties } from 'styled-components'

// Define the option type for your select component
interface OptionType {
  value: string
  label: string
}

interface LCSearchUserProps {
  onSelectionChange: (selectedOption: OptionType | null) => void
  label?: string
  customStyle?: CSSProperties
}

export const LCSearchUser: React.FC<LCSearchUserProps> = ({ onSelectionChange, label, customStyle }) => {
  const [searchUser, { data, isLoading }] = useSearchUserMutation()
  const [inputValue, setInputValue] = useState<string>('')
  const [options, setOptions] = useState<OptionType[]>([])

  // Adjust the debouncedFetchOptions to directly use the loading state from useLazySearchUserQuery
  const debouncedFetchOptions = useCallback(
    debounce((input: string) => {
      // Trigger the search query
      searchUser(input)
    }, 1000),
    [searchUser]
  )

  useEffect(() => {
    if (inputValue.length >= 3) {
      debouncedFetchOptions(inputValue)
    }
  }, [inputValue, debouncedFetchOptions])

  useEffect(() => {
    // Once data is fetched, transform it into options for the Select component
    if (data) {
      const newOptions = data.map((user) => ({
        value: user.identity, // Assuming 'identity' is a unique identifier for each user
        label: user.fullName, // Display name shown in the dropdown
      })) as OptionType[]
      setOptions(newOptions)
    }
  }, [data])

  const handleInputChange = (newValue: string, actionMeta: InputActionMeta) => {
    setInputValue(newValue)
  }

  const handleChange = (selectedOption: SingleValue<OptionType>) => {
    onSelectionChange(selectedOption)
  }

  return (
    <div className="lc-search-user" style={customStyle}>
      <label className="lc-search-user-label">{label}</label>
      <Select
        onInputChange={handleInputChange}
        onChange={handleChange}
        options={options}
        isLoading={isLoading} // Display loading indicator based on the isLoading state
        isClearable
      />
    </div>
  )
}
