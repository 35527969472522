import React, { useCallback, useMemo } from 'react'
import { invoice_columns } from './component/invoice.columns'
import { LCTableServerPagination } from '../../../../ui/components/lc-table/LCTableServerPagination'
import './purache.scss'
import { eTableName } from '@logicalcommander/types'
import { dataInvoices } from './mockDatainvoices'
import { useTranslation } from 'react-i18next'

export const SettingsInvoices = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'settings.modulePurchase.invoice' })
  const columns = useMemo(() => invoice_columns(t), [])
  const fetchData = useCallback(({ pageSize, pageIndex, sortBy, search }: any) => {}, [])

  return (
    <div className="container-page">
      <h3>{t('title')}</h3>
      <LCTableServerPagination
        tableName={eTableName.TO_APPROVE_PURCHASE_TABLE}
        showCheckbox={false}
        columns={columns}
        data={dataInvoices}
        fetchData={fetchData}
        loading={false}
        pageCount={5}
        initPageSize={0}
      />
    </div>
  )
}
