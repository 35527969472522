export interface ISettingUpdatePermissions {
  [role: string]: ISettingPermission[]
}

export interface ISettingValues {
  [roleAndPermission: string]: boolean
}

export interface ISettingPermission {
  [permission: string]: boolean
}

export const mergePermissions = (availablePermissions: any, specificPermissions: any): ISettingValues => {
  const result: ISettingValues = {}

  for (const rolePermission of specificPermissions) {
    const role = rolePermission.role

    for (const group in availablePermissions) {
      for (const permission of availablePermissions[group]) {
        result[`${role}===${permission}`] = rolePermission.permissions.includes(permission)
      }
    }
  }

  return result
}
