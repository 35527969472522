import React, { CSSProperties, FC, HTMLAttributes, useEffect, useState } from 'react'
import { sumBy } from 'lodash'
import { BarChartLayout, BarColor, CustomLabels } from '../../../../interfaces/dashboard.interface'
import LCBarChartNew from '../../../ui/components/charts/lc-bar-chart'
import { ITitleValueNumber } from '@logicalcommander/types'
import { useTranslation } from 'react-i18next'
import { BaseDashboardComponent } from '../../../ui/components/base-dashboard/BaseDashboardComponent'
import { DashboardComponentTitle } from '../../../ui/components/base-dashboard/DashboardComponentTitle'
import { toast } from 'react-toastify'
import { LCToast } from '../../../ui/components/lc-toast/Toast'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}
const GeneralDashboardDepartament: FC<Props> = ({ customStyle }: Props) => {
  const { t: tError } = useTranslation('translation', { keyPrefix: 'dashboard.graphDataErrors.dataLoadError' })
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.general.employeesByDepartament' })

  const isLoading = false // to remove later
  const error = false // to remove later

  const data = [
    {
      title: 'R&D',
      value: 21,
    },
    {
      title: 'administración',
      value: 82,
    },
    {
      title: 'Contabilidad',
      value: 1,
    },
    {
      title: 'RRHH',
      value: 1,
    },
    {
      title: 'Security',
      value: 1,
    },
    {
      title: 'Accounting',
      value: 1,
    },
    {
      title: 'Sales',
      value: 1,
    },
    {
      title: 'Systems',
      value: 1,
    },
    {
      title: 'Marketing',
      value: 1,
    },
    {
      title: 'Cybersecurity',
      value: 1,
    },
    {
      title: 'Finance',
      value: 31,
    },
    {
      title: 'Administration',
      value: 74,
    },
    {
      title: 'Dispatch',
      value: 60,
    },
    {
      title: 'Graphic Design',
      value: 90,
    },
    {
      title: 'Compliance',
      value: 25,
    },
    {
      title: 'Risk',
      value: 95,
    },
    {
      title: 'Public Relations',
      value: 45,
    },
    {
      title: 'Maintenance',
      value: 18,
    },
    {
      title: 'Production',
      value: 64,
    },
    {
      title: 'Designing',
      value: 29,
    },
    {
      title: 'Communications',
      value: 98,
    },
    {
      title: 'IR - Investors Relation',
      value: 52,
    },
    {
      title: 'Treasury',
      value: 70,
    },
    {
      title: 'Payroll',
      value: 72,
    },
    {
      title: 'Legal',
      value: 100,
    },
    {
      title: 'Procurement',
      value: 66,
    },
    {
      title: 'Projects',
      value: 20,
    },
    {
      title: 'Inspection',
      value: 10,
    },
    {
      title: 'Wellbeing',
      value: 10,
    },
    {
      title: ' Data analyse',
      value: 320,
    },
    {
      title: 'Customer Service',
      value: 10,
    },
  ]
  const [positionRisk, setPositionRisk] = useState<ITitleValueNumber[]>([])
  const [positionSum, setPositionSum] = useState(0)

  useEffect(() => {
    if (data) {
      const sorted = [...data].sort(sortPositionRisk)
      const sumSorted = sumBy(sorted, 'value')
      setPositionSum(sumSorted)
      setPositionRisk(sorted)
    } else {
      toast.error(<LCToast title={tError('title', { title: t('title') })} body={tError('body', { title: t('title') })} />)
    }
  }, [])

  const sortPositionRisk = (a: ITitleValueNumber, b: ITitleValueNumber) => {
    return b.value - a.value
  }

  return (
    <BaseDashboardComponent
      customStyle={customStyle}
      sectionTitle={<DashboardComponentTitle title={t('title')} subTitle={`${t('total')} ${positionSum}`} />}
      isLoading={isLoading}
      isSuccess={!!positionRisk}
      isError={!!error}>
      <LCBarChartNew
        data={positionRisk}
        // totalData={positionSum}
        options={{
          layout: BarChartLayout.HORIZONTAL,
          color: BarColor.COLOR_DEPARTMENT,
          label: CustomLabels.TOTAL_POSITION_EMPLOYEES,
        }}
      />
    </BaseDashboardComponent>
  )
}
export default GeneralDashboardDepartament
