import { IServiceBarProps } from '../../../layout/serviceBar/ServiceBarButton'
import { useAppDispatch } from '../../../redux/hooks'
import { setPageHeader } from '../../../redux/reducers/generalSlice'
import './lc-page.scss'
import { CSSProperties, FC, HTMLAttributes, ReactNode, useEffect } from 'react'

export interface IPageUniqueProps extends HTMLAttributes<HTMLDivElement> {
  title?: string
  subTitle?: string
  icon?: any
  breadcrumbs?: string
  children?: ReactNode | ReactNode[]
  customStyle?: CSSProperties
}

export type IPageProps = IPageUniqueProps & Partial<IServiceBarProps>

export const LCPage: FC<IPageProps> = ({ customStyle, children, title, subTitle, icon, breadcrumbs, ...props }: IPageProps) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setPageHeader({ title, subTitle, breadcrumbs, icon }))
  }, [title, subTitle, breadcrumbs, icon])

  return (
    <div className="lc-page" style={customStyle} {...props}>
      <div className="lc-page-content">
        <div className="children">{children}</div>
      </div>
    </div>
  )
}
