import React, { FC, HTMLAttributes } from 'react'
import { CSSProperties } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { BaseDashboardComponent } from '../../../ui/components/base-dashboard/BaseDashboardComponent'
import { useAppSelector } from '../../../redux/hooks'
import { selectExtendedCompany } from '../../../redux/reducers/extendedCompanySlice'
import { DashboardComponentTitle } from '../../../ui/components/base-dashboard/DashboardComponentTitle'
import { LCompanyFeatures } from '../../../ui/components/lc-company-features/LCCompanyFeatures'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

const CompanyFeatures: FC<Props> = ({ customStyle }: Props) => {
  const { features } = useAppSelector(selectExtendedCompany)
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.licenses.features' })

  return (
    <BaseDashboardComponent
      sectionTitle={<DashboardComponentTitle title={t('title')} />}
      isLoading={false}
      isSuccess={!!features}
      isError={!features}
      customStyle={customStyle}>
      <LCompanyFeatures />
    </BaseDashboardComponent>
  )
}
export default CompanyFeatures
