import React, { FC, HTMLAttributes, useState, useEffect } from "react";
import { Sector, Text, } from "recharts";
import { customLabel } from "../custom-labels";
import { posticionText } from "../positions-for-texts";
import { ITitleValueNumber } from "@logicalcommander/types";
import { calculateVerticalDistance, textOverlapCorrectionFunction, dataTextOverlayCorrection } from "./helpers-lc-pie-charts";
import { barColors } from "../bar-colors";
import { sumBy } from 'lodash'
import { useTranslation } from "react-i18next";

interface Props extends HTMLAttributes<HTMLDivElement> {
    data?: ITitleValueNumber[]
    color: string
    labelCustom: string
    otherData?: ITitleValueNumber | undefined;
    customPieOfPie: boolean
}

export const titleText = ({ titleTranslate }: { titleTranslate: string }) => {
    const title = titleTranslate.match(/[^.]+$/);
    return title ? title[0] : null
}

export const CustomLabelBar = (props: any) => {
    const { x, y, width, height, data, index, level } = props;
    const { Low, Medium, High, Normal, Critial, } = data[index]
    const CritialUndefined = Critial === undefined ? 0 : Critial
    const totalData = Low + Medium + High + Normal + CritialUndefined;
    const value = data[index][level]
    const percent = (value / totalData) * 100;
    const fontSize = 9;
    return (
        <g>
            {percent > 25 && percent !== undefined ?
                <Text
                    x={x + width / 2}
                    y={y + height / 2}
                    fontSize={fontSize}
                    fill={"#FFFFFF"}
                    textAnchor="middle"
                >
                    {`${percent.toFixed(1)}%`}
                </Text>
                : null
            }

        </g>
    );
};

export const RenderCustomLabel: FC<Props> = ((Props: any) => {

    const { t: tLabel } = useTranslation('translation', { keyPrefix: 'dashboard.chartPieTranslate.titleLabel' })
    const { t: tTitle } = useTranslation('translation', { keyPrefix: 'dashboard.chartPieTranslate.titleDataValue' })
    const { fontSize, midAngle, outerRadius, cy, cx, fill, title, percent, value, labelCustom } = Props
    const RADIAN = Math.PI / 180;
    const sin = Math.sin(RADIAN * midAngle);
    const cos = Math.cos(RADIAN * midAngle);
    const startY = cy + outerRadius * -sin;

    const startX = cx + (outerRadius) * cos;
    const middleX = cx + (outerRadius) * cos;
    const middleY = cy + (outerRadius + 50 * Math.abs(sin)) * -sin - 2;
    const endX = startX + (cos >= 0 ? 1 : -1) * 18;
    const Zy = middleY + fontSize / 2;

    const Zx = endX + (cos >= 0 ? 1 : -1) * 5;
    const textAnchor: 'start' | 'end' = cos >= 0 ? 'start' : 'end'
    const percentFormat = (percent * 100).toFixed(1);
    const label1 = `(${titleText({ titleTranslate: tTitle(title) })}: ${percentFormat}%)`;
    const label2 = `${tLabel(customLabel[`${labelCustom}`]())}: ${value.toLocaleString('es-MX')}`;

    return (<>
        {percent > 0.02 ?
            <>
                <path
                    d={`M${startX},${startY}L${middleX},${middleY}L${endX},${middleY}`}
                    fill="none"
                    stroke={fill}
                    strokeWidth={1}
                />
                <circle
                    cx={endX}
                    cy={middleY}
                    r={3}
                    stroke="rgba(244, 247, 254, 1)"
                    strokeWidth={1}
                    fill={fill}
                />
                <Text
                    x={Zx}
                    y={Zy}
                    textAnchor={textAnchor}
                    dy={-6}
                    fontSize={fontSize}
                    fill={"black"}
                >{label1}
                </Text>

                <Text
                    x={Zx}
                    y={Zy}
                    textAnchor={textAnchor}
                    dy={6}
                    fontSize={fontSize}
                    fill={"black"}
                >{label2}
                </Text>
            </> : null
        }
    </>)
})
export const RenderCustomLabelPieofPie: FC<any> = ((Props: any) => {


    const {
        cx,
        cy,
        midAngle,
        outerRadius,
        percent,
        index,
        title,
        fill,
        fontSize,
        data,
        value,
        labelCustom,
        functionUpdatePreviousData,
        previousData,
        percentCustom,
        endAngle,
        otherDataBoolean,
        customPieOfPie,
        keyId,
        startAngle,
        updateDataPieOfPie,
        duplicateDataFunction,
        customDataOther
    } = Props;
    const [{ adjustedZx, adjustedZy, colorText, endAjustedX, middleAjustedY, ajustedStartX, textAnchor }, setDataTextOverlayCorrection] = useState<dataTextOverlayCorrection>({ adjustedZx: 0, adjustedZy: 0, activedTextBoolean: true, colorText: "black", endAjustedX: 0, middleAjustedY: 0, prevOverlap: "NoOverlaps", ajustedStartX: 0, textAnchor: "start" });
    const [count, setCount] = useState(0);
    const parentNode = document.getElementById(keyId) as HTMLElement
    const parenReact = parentNode?.getBoundingClientRect()

    const withParent = parenReact?.width
    const heightParent = parenReact?.height
    const pointReferenceZx = withParent - 5;
    const pointReferenceZy = heightParent / 2;

    const angleInEndradians = (endAngle - 180) * (Math.PI / 180);

    const pointEndAngleX = cx + (- outerRadius) * Math.cos(angleInEndradians);
    const pointEndAngleY = cy + (outerRadius) * Math.sin(angleInEndradians);

    const dataUndefined = { initialPointOtherDataX: 0, initialPointOtherDataY: 0, referencePointX: 0, referencePointy: 0, index: NaN }

    const { y, prevPercent, prevOverlap: preOverlapArray, cos: prevCos } = previousData[index] === undefined ? dataUndefined : previousData[index];

    const firstData = index === 0;

    const percentFormat = percentCustom === undefined ? (percent * 100).toFixed(1) : percentCustom.toFixed(1);
    const label1 = `${customLabel[`${labelCustom}`](1)}: ${value.toLocaleString('es-MX')}`;
    const label2 = `(${title}: ${percentFormat}%)`;
    const RADIAN = Math.PI / 180;
    const sin = Math.sin(RADIAN * midAngle);
    const cos = Math.cos(RADIAN * midAngle);
    const startY = cy + outerRadius * -sin;

    const startX = cx + (outerRadius) * cos;
    const middleX = cx + (outerRadius) * cos;
    const middleY = cy + (outerRadius + 50 * Math.abs(sin)) * -sin - 2;
    const endX = startX + (cos >= 0 ? 1 : -1) * 18;
    const Zy = middleY + fontSize / 2;

    const Zx = endX + (cos >= 0 ? 1 : -1) * 5;

    const labelRadius = 24;
    const labelRadiusSubtractionY = 22;
    const labelMidRadius = 16;

    const distance: number = calculateVerticalDistance(Zy, firstData ? 0 : y)

    const activeLine: boolean = true;
    const activeTextLine = percent > 0.01 || index % 2 === 0 ? true : false;
    const evenData = index % 2 === 1;
    const prevDataTitle = firstData ? "" : data[index - 1].title
    const nextDataTile = index === (data.length - 1) ? "" : data[index + 1].title
    const diferenceRadius = Math.abs(prevPercent - percent) > 0.001;
    const textAnchorAjusted: 'start' | 'end' = cos >= 0 ? 'start' : 'end'

    useEffect(() => {

        const { textAnchorAjusted: textAnchor, Zx: adjustedZx, Zy: adjustedZy, cos: ajustedCos, activeTextLine: activedTextBoolean, colortest: colorText, endX: endAjustedX, middleY: middleAjustedY, prevOverlap, startX: ajustedStartX } = textOverlapCorrectionFunction({ data, cos, cy, diferenceRadius, distance, endX, evenData, firstData, fontSize, labelMidRadius, labelRadius, labelRadiusSubtractionY, middleY, nextDataTile, outerRadius, percent, prevDataTitle, prevPercent, sin, startX, title, y, Zx, Zy, prevOverlap: preOverlapArray, prevCos, viewPointY: heightParent, textAnchorAjusted });

        setDataTextOverlayCorrection({ textAnchor, adjustedZx, adjustedZy, activedTextBoolean, colorText, endAjustedX, middleAjustedY, prevOverlap, ajustedStartX })


        functionUpdatePreviousData({ y: adjustedZy, indexUpdate: (index + 1), prevTitle: title, titleUpdate: title, prevPercent: percent, prevOverlap, cos: ajustedCos, sin });


        updateDataPieOfPie({ initialPointOtherDataX: pointReferenceZx, initialPointOtherDataY: pointReferenceZy, referencePointX: Zx, referencePointy: Zy, index })

        if (count < 50) setCount(count + 1);
        if (count >= 50) duplicateDataFunction(true);
    }, [cx, cy, cos, sin, startAngle, prevDataTitle, count]);


    return (
        adjustedZy !== 0 && adjustedZx !== 0 ?
            <>

                {
                    activeTextLine ?
                        <g >

                            {
                                customPieOfPie === true ?
                                    <>

                                        {index === 0 && otherDataBoolean === undefined && withParent !== undefined && heightParent !== undefined ?
                                            <>
                                                <path
                                                    d={`M${pointEndAngleX},${pointEndAngleY}L ${pointEndAngleX + 100},${pointEndAngleY} L${withParent},${(heightParent / 4) + 5}`}
                                                    fill="none"
                                                    stroke={"#B8B8B8"}
                                                    strokeWidth={1}
                                                />
                                            </>
                                            : null}

                                        {index === data.length - 1 && otherDataBoolean === undefined && withParent !== undefined && heightParent !== undefined ?
                                            <>
                                                <path
                                                    d={`M${pointEndAngleX} , ${pointEndAngleY} L ${pointEndAngleX + 100},${pointEndAngleY}L ${withParent} , ${((heightParent / 4) * 3) - 5}`}
                                                    fill="none"
                                                    stroke={"#B8B8B8"}
                                                    strokeWidth={1}
                                                />
                                            </>
                                            : null}

                                        {
                                            index === 0 && otherDataBoolean !== undefined ?
                                                <>
                                                    <path
                                                        d={`M${0},${220}L${100},${230}L${200},${299}L${400},${299}`}
                                                        fill="none"
                                                        stroke={"#B8B8B8"}
                                                        strokeWidth={1}
                                                    />
                                                    <path
                                                        d={`M${0},${80}L${100},${60}L${200},${10}L${400},${10}`}
                                                        fill="none"
                                                        stroke={"#B8B8B8"}
                                                        strokeWidth={1}
                                                    />
                                                </> : null
                                        }
                                    </>
                                    : null
                            }

                            {activeLine && value > 0 && customDataOther !== true ?
                                <>
                                    <path
                                        d={`M${ajustedStartX},${startY}L${middleX},${middleAjustedY}L${endAjustedX <= 0 ? endX : endAjustedX},${middleAjustedY <= 0 ? middleY : middleAjustedY}`}
                                        fill="none"
                                        stroke={fill}
                                        strokeWidth={1}
                                    />
                                    <circle key={`circle-${index}`} cx={endAjustedX <= 0 ? endX : endAjustedX} cy={middleAjustedY <= 0 ? middleY : middleAjustedY} r={3} stroke="rgba(244, 247, 254, 1)" strokeWidth={1} fill={fill} />
                                    <Text
                                        x={adjustedZx}
                                        y={adjustedZy}
                                        textAnchor={textAnchor}
                                        dy={-6}
                                        fontSize={fontSize}
                                        fill={colorText}
                                    >
                                        {label2}
                                    </Text>
                                    <Text
                                        x={adjustedZx}
                                        y={adjustedZy}
                                        dy={3.8}
                                        fill={colorText}
                                        textAnchor={textAnchor}
                                        fontSize={fontSize - 2}>
                                        {label1}

                                    </Text>
                                </>
                                : null
                            }

                        </g>

                        : null
                }
            </>
            : null
    );
});



export const CustomTooltipPie: FC<any> = (props: any) => {

    const { t: tLabel } = useTranslation('translation', { keyPrefix: 'dashboard.chartPieTranslate.titleLabel' })
    const { active, payload, labelCustom, } = props;

    if (active) {
        const title = payload[0].payload.payload.title
        const labelTooltip = `${titleText({ titleTranslate: tLabel(customLabel[`${labelCustom}`]()) })}: ${payload[0].value.toLocaleString('es-MX')} `;
        return (
            <div className='custom-tooltip' style={{ padding: "8px", }}>
                <p className="label" >{title}</p>
                <p className='label'>{labelTooltip} </p>
            </div>
        )
    }
    return null
}
export const CustomLabelsH = ({ x, y, value, width }: any) => {
    return (
        <Text fill="black" x={x + width / 2} y={y} dy={-4} dx={3} angle={270} className="text-label-bar">
            {value.toLocaleString('es-MX')}
        </Text>
    )
}

export const CustomDualLabel = (props: any) => {
    const { x, y, value, orientation, data, height, width, keyId } = props
    const dataTotal = sumBy(data, keyId);
    const dataPercentagePerBar: number = (value / dataTotal) * 100;
    const textAnchor: "end" | "start" = orientation === "left" ? "start" : "end";
    const valueText = value.toLocaleString('es-MX')
    return (
        <>
            {
                dataPercentagePerBar > 10 ?
                    <Text
                        fill="white"
                        x={x}
                        dx={orientation === "left" ? 5 : -5}
                        y={y + height / 2}
                        dy={4}
                        textAnchor={textAnchor}
                    >
                        {valueText}
                    </Text>
                    : <Text
                        fill="black"
                        x={x + width}
                        y={y + height / 2}
                        dx={orientation === "left" ? 5 : -5}
                        dy={4}
                        textAnchor={textAnchor}
                    >
                        {valueText}
                    </Text>
            }
        </>
    )
}
export const CustomLabelsV = (props: any) => {
    const { data, index, x, y, height, width, overFlowData } = props;
    const { value, title } = data[index];
    const dataTotal = sumBy(data, "value");
    const percent = (value / dataTotal) * 100
    const dataPercentagePerBar: number = dataTotal / data.length;
    const visiblePercentage = (85 * dataPercentagePerBar) / 100;
    const label = `${title} (${value.toLocaleString('es-MX')})`;
    const labelOverFlowData = `${title} ${value.toLocaleString('es-MX')} (${percent.toFixed(1)}%)`

    return (

        <>
            {
                visiblePercentage < value ?
                    <Text
                        fill="white"
                        x={x}
                        dx={5}
                        y={y + height / 2}
                        fontSize={12}
                        dy={3}
                    >
                        {overFlowData ? labelOverFlowData : label}
                    </Text>
                    :
                    <Text
                        fill="black"
                        x={x + width}
                        dx={5}
                        y={y + height / 2}
                        dy={5}
                    >
                        {overFlowData ? labelOverFlowData : label}
                    </Text>
            }
        </>
    )
}

export const CustomizedTickV = ({ payload, y, x }: any) => {
    const { value } = payload
    const valueText = value.toLocaleString('es-MX');
    return (
        <Text
            y={y}
            x={x}
            dy={10}
            textAnchor="middle"
        >
            {valueText}
        </Text>
    )
}

export const CustomizedTickH = ({ payload, y, x }: any) => {
    const { value } = payload
    return (
        <Text
            y={y}
            x={x}
            angle={-48}
            dx={5}
            className="text-label-bar-h"
            style={{
                direction: "rtl",
            }} >
            {value}
        </Text>
    )
}


export const RenderActiveShapePie: FC<any> = ({ cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, stroke, title, riskPercentage, color, index }: any) => {

    const { t: tTitle } = useTranslation('translation', { keyPrefix: 'dashboard.chartPieTranslate.titleDataValue' })
    const words = tTitle(title).split(" ");
    const wordsLength = words.length;

    const riskLevel = function (riskLabel: string) {
        if (riskLabel === '81% - 100% Risk Level' || riskLabel === '61% - 80% Risk Level' || riskLabel === '0% - 60% Risk Level') {
            return true
        } else {
            return false
        }
    }


    const colorFillText = `${barColors[`${color}`](index)}`;
    return (
        <g>
            {words.map((word: string, i: number) => (

                <Text x={cx} y={cy} dy={posticionText[`${wordsLength}`](i)} textAnchor="middle" fill={colorFillText} key={`renderActiveShape-${i}`}>
                    {` ${titleText({ titleTranslate: word })}`}
                </Text>
            ))}
            {riskLevel(riskPercentage) ? (
                <Text x={cx} y={cy} dy={125} textAnchor="middle" fill={colorFillText}  >
                    {riskPercentage}
                </Text>
            ) : (
                null
            )}
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius + 1}
                outerRadius={outerRadius + 1}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={`${fill}`}
                stroke={stroke}
            />
        </g>
    )
}
