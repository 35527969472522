export const cleanObjectImmutable = <T>(obj: T): T => {
  const newObj: any = Array.isArray(obj) ? obj.slice() : { ...obj } // Handle both arrays and objects
  for (const key in newObj) {
    if (typeof newObj[key] === 'object' && newObj[key] !== null) {
      newObj[key] = cleanObjectImmutable(newObj[key]) // Recursive call for nested objects
    }
    if (newObj[key] === '' || newObj[key] === undefined || (typeof newObj[key] === 'object' && Object.keys(newObj[key]).length === 0)) {
      delete newObj[key] // Also delete empty objects resulting from cleaning
    }
  }
  return newObj
}
