import './lc-dialog.scss'
import LCPopup from '../lc-popup/LCPopup'
import { LCButton } from '../lc-button/LCButton'
import React, { ReactElement, forwardRef, useImperativeHandle, useRef } from 'react'
import { LCSvg } from '../lc-svg/lc-svg'

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  toggler: React.ReactElement | string
  title: string
  disabled?: boolean
  buttonSize?: 'xsmall' | 'small' | 'medium' | 'large'
  children?: React.ReactElement[]
  customStyle?: React.CSSProperties
  isLink?: boolean
  onClose?: () => void // Optional onClose callback
}

// Use `forwardRef` to provide a ref from the parent component
export const LCDialog = forwardRef(
  ({ toggler, title, children, disabled, onClose, buttonSize = 'medium', isLink = false, customStyle, ...props }: Props, ref) => {
    const popupRef = useRef<any>(null)

    // Define the `close` method that parent components can call
    useImperativeHandle(ref, () => ({
      close: () => {
        if (popupRef.current) {
          popupRef.current.close()
        }
        if (onClose) {
          onClose() // Invoke the onClose callback, if provided
        }
      },
    }))

    // Close handler that takes care of calling the onClose prop if it exists
    const handleClose = () => {
      if (popupRef.current) {
        popupRef.current.close()
      }
      if (onClose) {
        onClose()
      }
    }

    // Decide the toggler based on the type of `toggler` prop
    let _toggler: ReactElement
    if (typeof toggler === 'string') {
      _toggler = isLink ? <div className="link-button">{toggler}</div> : <LCButton size={buttonSize} label={toggler} />
    } else if (React.isValidElement(toggler)) {
      _toggler = toggler
    } else {
      console.log('toggler is undefined or not a recognized type')
      return null
    }

    return (
      <LCPopup ref={popupRef} position="modal" toggler={_toggler} {...props}>
        <div className="dialog-wrapper" style={customStyle}>
          <div className="title">
            <div className="title-text">{title}</div>
            <div className="title-x">
              <LCSvg name="xIcon" customStyle={{ color: '#ffffff' }} widthAndHeight={[14, 14]} onClick={handleClose} />
            </div>
          </div>
          <div className={`body${disabled ? ' disabled' : ''}`}>{children}</div>
        </div>
      </LCPopup>
    )
  }
)

LCDialog.displayName = 'LCDialog'
