import React, { FC, HTMLAttributes, lazy, useEffect } from 'react'
import { CSSProperties } from 'styled-components'
import { sumBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { BarColor, CustomLabels } from '../../../../../interfaces/dashboard.interface'
import { useLazyGetEvaluationStatusQuery } from '../../../../services/dashboard.service'
import { ITitleValueNumber } from '@logicalcommander/types'
import { BaseDashboardComponent } from '../../../../ui/components/base-dashboard/BaseDashboardComponent'
import { DashboardComponentTitle } from '../../../../ui/components/base-dashboard/DashboardComponentTitle'
const LCPieChartUniNew = lazy(() => import('../../../../ui/components/charts/lc-pie-chart'))

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}
const RiskHrDashboardEvaluationStatus: FC<Props> = ({ customStyle }: Props) => {
  const [getEvaluationStatus, { data, error, isLoading }] = useLazyGetEvaluationStatusQuery()
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.evaluation_status' })

  useEffect(() => {
    getEvaluationStatus()
  }, [])

  const sumData = sumBy(data, 'value') | 0

  return (
    <BaseDashboardComponent
      customStyle={customStyle}
      sectionTitle={<DashboardComponentTitle title={`${t('title_assessments')} ${t('title_status')}`} subTitle={`${t('total')} ${sumData}`} />}
      isLoading={isLoading}
      isSuccess={!!data}
      isError={!!error}>
      <LCPieChartUniNew
        data={data as ITitleValueNumber[]}
        dataTotal={sumData}
        options={{
          color: BarColor.COLOR_STATUS,
          customPercentage: false,
          label: CustomLabels.STATUS_EVALUATION_PIE,
        }}
      />
    </BaseDashboardComponent>
  )
}
export default RiskHrDashboardEvaluationStatus
