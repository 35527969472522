const colorLicense = ['#f39c12', '#2ecc71', '#3498db']

const statusColors = [
  '#2ed573',
  '#1e90ff',
  '#ffa502',
  '#ff6348',
  '#ff4757',
  '#fd79a8',
  '#00a8ff',
  '#2ed573',
  '#1e90ff',
  '#ffa502',
  '#ff6348',
  '#ff4757',
  '#fd79a8',
  '#00a8ff',
]
const barRiskLevel = ['#2ed573', '#00a8ff', '#fea402', '#ff6348', '#C785FF']

const womenMenColors = ['#fd79a8', '#00a8ff']

const riskLevelColor = ['#ff6348', '#f1c40f', '#2ed573']

const riskLevelVs = ['#f1c40f', '#ff6348']

const colorRiskVsNotRisk = ['#ff6348', '#2ed573']

const colorStatusVs = ['#ffa502', '#ff6348']

const emoRisk = ['#7AF94C', '#46C4D7', '#FBB03B', '#FF4D42', '#be74ff', '#747d8c', '#a4b0be']

export const barColors: any = {
  colorEmployees: () => `#5352ed`,
  colorDepartment: () => `#1e90ff`,
  colorPosition: () => '#ffa502',
  alert: () => `#ff6348`,
  colorStatus: (i: number) => (i === undefined ? statusColors : statusColors[i]),
  colorWomanMen: (i: number) => (i === undefined ? womenMenColors : womenMenColors[i]),
  colorLicense: (i: number) => (i === undefined ? colorLicense : colorLicense[i]),
  riskLevelColor: (i: number) => (i === undefined ? riskLevelColor : riskLevelColor[i]),
  colorRiskVsNotRisk: (i: number) => (i === undefined ? colorRiskVsNotRisk : colorRiskVsNotRisk[i]),
  riskLevelVs: (i: number) => (i === undefined ? riskLevelVs : riskLevelVs[i]),
  colorStatusVs: (i: number) => (i === undefined ? colorStatusVs : colorStatusVs[i]),
  emoRisk: (i: number) => (i === undefined ? emoRisk : emoRisk[i]),
  emoRiskLevel: (i: number) => (i === undefined ? barRiskLevel : barRiskLevel[i]),
}
