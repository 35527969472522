import React from 'react'
import { SVGProps } from 'react'

const SvgDashboard = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.353 6.193h5.434c.898 0 1.353-.45 1.353-1.342V2.337C9.14 1.444 8.685 1 7.787 1H2.353C1.46 1 1 1.444 1 2.337V4.85c0 .893.461 1.342 1.353 1.342Zm11.473 4.685h3.815c.904 0 1.359-.45 1.359-1.337V3.26c0-.893-.455-1.342-1.359-1.342h-3.815c-.904 0-1.359.45-1.359 1.342v6.282c0 .887.455 1.337 1.36 1.337ZM3.706 14h4.651c.904 0 1.359-.444 1.359-1.337v-1.786c0-.893-.455-1.337-1.359-1.337H3.705c-.897 0-1.352.444-1.352 1.337v1.786c0 .893.454 1.337 1.352 1.337Z"
      stroke="currentColor"
      strokeWidth={1.5}
    />
  </svg>
)

export default SvgDashboard
