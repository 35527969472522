import './risk-hr-cases.scss'
import React, { HTMLAttributes, CSSProperties, FC, useState, useEffect, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useAppSelector } from '../../../../hooks/redux-hooks'
import { selectSettings } from '../../../../redux/reducers/settingsSlice'
import { useGetCasesMutation } from '../../../../services/cases.service'
import { LCTableServerPagination } from '../../../../ui/components/lc-table/LCTableServerPagination'
import { riskHrCases_columns } from './riskHrCases.columns'
import {
  ISearch,
  ISort,
  IPaginationRequest,
  IPaginationResponse,
  IRiskHRCase,
  IPaginationSettings,
  eTableName,
} from '@logicalcommander/types'
import { selectFilters } from '../../../../redux/reducers/filtersSlice'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  showTableActions?: boolean
  filters?: ISearch[]
  customStyle?: CSSProperties
  onSelectedRows?: (rows: IRiskHRCase[]) => void
}

export const RiskHrCases: FC<Props> = ({ customStyle, filters: initFilters, showTableActions = false, onSelectedRows }: Props) => {
  const paginationSettings: IPaginationSettings = useAppSelector(selectSettings).commonUX.pagination
  const tableFilters = useAppSelector(selectFilters).tableHeader
  const [getCasesPagination, { data, error, isLoading }] = useGetCasesMutation()
  const [cases, setCases] = useState<IRiskHRCase[]>([])
  const [pageCount, setPageCount] = useState(0) // Number of pages
  const [pageIndex, setPageIndex] = useState(0) // Current page index
  const [pageSize, setPageSize] = useState(paginationSettings.cases) // Page size
  const [sortBy, setSortBy] = useState<ISort[]>([]) // Sort by
  const navigate = useNavigate()
  const { t } = useTranslation('translation')

  useEffect(() => {
    if (!error && data) {
      setCases(data.data)
    }
  }, [data, error])

  const fetchData = useCallback(({ pageSize, pageIndex, sortBy, search }: IPaginationRequest) => {
    getCasesPagination({ pageIndex, pageSize, sortBy, search })
      .then((result: any) => {
        const resData: IPaginationResponse<IRiskHRCase[]> = result.data
        setPageCount(Math.ceil(resData.totalSize / pageSize))
        setPageIndex(pageIndex)
        setPageSize(pageSize)
        sortBy && setSortBy(sortBy)
      })
      .catch((err) => {
        console.log('ERROR: ', err)
      })
  }, [])

  useEffect(() => {
    const _filters = tableFilters.filter((i) => i.tableName === eTableName.RISK_HR_CASES_TABLE).map((h) => h.search)
    fetchData({ pageIndex, pageSize, sortBy, search: _filters })
  }, [tableFilters])

  const columns = useMemo(() => riskHrCases_columns(navigate, t), [])

  return (
    <div style={customStyle} className="risk-hr-cases">
      <LCTableServerPagination
        tableName={eTableName.RISK_HR_CASES_TABLE}
        showCheckbox={true}
        columns={columns}
        onSelectRows={(rows: IRiskHRCase[]) => onSelectedRows && onSelectedRows(rows)}
        data={cases}
        fetchData={fetchData}
        initPageSize={pageSize}
        loading={isLoading}
        pageCount={pageCount}
        showTableActions={showTableActions}
      />
    </div>
  )
}
