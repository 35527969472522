import './settings-tabs.scss'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation, useNavigate } from 'react-router'
import { menuStyles, subMenuRootStyles, subMenuItemStyles, menuItemRootStyles } from './settings-tabs-menu.styles'
import { settingsTabsMenuData, SideMenuItem } from './settings-tabs-menu-data'

interface Props {
  currentTabChanged: (currentTab: string) => void
  width?: number
  collapsedWidth?: number
}

export const SettingsTabsNavigation: FC<Props> = ({ currentTabChanged, width = 240, collapsedWidth = 80 }: Props) => {
  const [selectedTab, setSelectedTab] = useState<string>('company-info')
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation('translation', { keyPrefix: 'settings.menu' })

  useEffect(() => {
    var pathSegments = location.pathname.split('/')
    var tabFromURL = pathSegments[pathSegments.length - 1]
    console.log('---> setSelectedTab from URL', tabFromURL)
    currentTabChanged(tabFromURL)
    setSelectedTab(tabFromURL)
  }, [])

  const handleTabClick = (currentTab: SideMenuItem) => {
    const { key, navigate: navigateTo, action } = currentTab
    console.log('---> setSelectedTab', key)
    currentTabChanged(key)
    setSelectedTab(key)
    action && action()
    navigateTo && navigate(navigateTo)
  }

  return (
    <Sidebar
      collapsed={false}
      className="settings-menu-bar"
      backgroundColor="#ffffff"
      width={`${width}px`}
      collapsedWidth={`${collapsedWidth}px`}>
      <Menu menuItemStyles={menuStyles}>
        {settingsTabsMenuData(t).map((tabData, i) =>
          tabData.subTabs ? (
            <SubMenu
              key={tabData.key}
              label={tabData.label}
              icon={tabData.newIcon ? <FontAwesomeIcon icon={tabData.newIcon} size="xl" /> : null}
              rootStyles={subMenuRootStyles}>
              {tabData.subTabs.map((s) => (
                <MenuItem rootStyles={subMenuItemStyles} key={s.key} onClick={() => handleTabClick(s)} active={selectedTab === s.key}>
                  {s.label}
                </MenuItem>
              ))}
            </SubMenu>
          ) : (
            <MenuItem
              key={tabData.key}
              icon={tabData.newIcon ? <FontAwesomeIcon icon={tabData.newIcon} size="xl" /> : null}
              active={selectedTab === tabData.key}
              rootStyles={menuItemRootStyles}
              onClick={() => handleTabClick(tabData)}>
              {tabData.label}
            </MenuItem>
          )
        )}
      </Menu>
    </Sidebar>
  )
}
