import { LCSvg } from '../lc-svg/lc-svg'
import './dashboard-components-loader.scss'
import React, { CSSProperties, FC, HTMLAttributes } from 'react'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

export const DashboardComponentLoader: FC<Props> = ({ customStyle }: Props) => {
  return (
    <div className="bdc-loader" style={customStyle}>
      <div className="bdc-loader-animation">
        <LCSvg name="loader" widthAndHeight={[100, 100]} customStyle={{ color: '#0568ae' }} />
      </div>
      <div className="bdc-loading">Loading please wait ...</div>
    </div>
  )
}
