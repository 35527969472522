import './lc-form-dropDown.scss'
import { CSSProperties, ReactElement } from 'react'

export interface IDropdownOption {
  label: string
  value: string
}

interface Props<ObjectType> {
  inputId: keyof ObjectType
  label?: string
  required?: boolean
  customStyle?: CSSProperties
  error?: any
  hideError?: boolean
  touched?: any
  placeholder?: string
  onChange?: (value: any) => void
  options: IDropdownOption[] | undefined
  initValue?: string
}

export const LCFormDropdown = <ObjectType,>({
  label,
  inputId,
  error,
  hideError,
  required,
  customStyle,
  placeholder,
  onChange,
  initValue = '',
  options,
  ...props
}: Props<ObjectType>): ReactElement => {
  const updateOnChange = (e: any) => {
    onChange && onChange(e.target.value)
  }

  return (
    <div className="dropDown-Wrapper" style={customStyle}>
      {label ? (
        <label className="dropDown-label">
          {label} {required ? '*' : ''}
        </label>
      ) : null}
      <div className="dropDown-select-wrapper">
        <select className="dropDown-select" value={initValue} id={inputId as string} onChange={(e) => updateOnChange(e)} {...props}>
          {placeholder && (
            <option value="" disabled>
              {placeholder}
            </option>
          )}
          {options?.map((option: IDropdownOption, index: number) => {
            return <option key={`${option.value}-${index}`} value={option.value} label={option.label} />
          })}
        </select>
      </div>
      {hideError ? null : <div className="dropDown-error">{error}</div>}
    </div>
  )
}
