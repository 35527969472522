import {
  ICentrixSettings,
  ICompanyInfo,
  IEmailSettings,
  IEmoRiskSettings,
  IEnumsPositionGroupSettingsItem,
  IEnumsSettings,
  IEnumsSettingsItem,
  IRiskHrSettings,
  IRolesAndPermissions,
  ISavedFiltersSettings,
  ISettings,
  ISeverityGroupItem,
  IUser,
  IUXSettings,
} from '@logicalcommander/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface IAssessmentSizes {
  [sizeId: number]: IAssessmentTypes
}

export interface IAssessmentTypes {
  [typeId: number]: IAssessmentTypeItem[]
}

export interface ITRFLevels {
  [id: number]: number
}

export interface IAssessmentTypeItem {
  nameId: number
  customName?: string
  assessmentId: number
}

export interface ISettingsState extends ISettings {
  assessmentsSizes: IAssessmentSizes
  roles: IRolesAndPermissions[]
  admins: IUser[]
}

const initialState: ISettingsState = {
  emails: {} as IEmailSettings,
  emoRisk: {} as IEmoRiskSettings,
  centrix: {} as ICentrixSettings,
  riskHR: {} as IRiskHrSettings,
  filters: [] as ISavedFiltersSettings[],
  commonUX: {} as IUXSettings,
  enums: {} as IEnumsSettings,
  companyInfo: {} as ICompanyInfo,
  assessmentsSizes: [] as IAssessmentSizes,
  roles: [] as IRolesAndPermissions[],
  admins: [] as IUser[],
}

export const settingsSlice = createSlice({
  name: 'settingsReducer',
  initialState,
  reducers: {
    setEmailsSettings: (state: ISettingsState, action: PayloadAction<IEmailSettings>) => {
      console.log('settingsSlice:: setEmailsSettings: action.payload:', action.payload)
      const newState = { ...state }
      newState.emails = action.payload
      console.log('settingsSlice:: setEmailsSettings: state after update:', newState)
      return newState
    },
    setUXSettings: (state: ISettingsState, action: PayloadAction<IUXSettings>) => {
      console.log('settingsSlice:: setUXSettings: action.payload:', action.payload)
      const newState = { ...state }
      newState.commonUX = action.payload
      console.log('settingsSlice:: setUXSettings: state after update:', newState)
      return newState
    },
    setCompanyInfoSettings: (state: ISettingsState, action: PayloadAction<ICompanyInfo>) => {
      console.log('settingsSlice:: setCompanySettings: action.payload:', action.payload)
      const newState = { ...state }
      newState.companyInfo = action.payload
      console.log('settingsSlice:: setCompanySettings: state after update:', newState)
      return newState
    },
    setDepartmentsSettings: (state: ISettingsState, action: PayloadAction<IEnumsSettingsItem[]>) => {
      console.log('settingsSlice:: setDepartmentsSettings: action.payload:', action.payload)
      state.enums.departments = action.payload
      console.log('settingsSlice:: setDepartmentsSettings: state after update:', state)
      return state
    },
    setPositionsSettings: (state: ISettingsState, action: PayloadAction<IEnumsSettingsItem[]>) => {
      console.log('settingsSlice:: setPositionsSettings: action.payload:', action.payload)
      state.enums.positions = action.payload
      console.log('settingsSlice:: setPositionsSettings: state after update:', state)
      return state
    },
    setAssessmentsSizesSettings: (state: ISettingsState, action: PayloadAction<IAssessmentSizes>) => {
      console.log('settingsSlice:: setAssessmentsSizesSettings: action.payload:', action.payload)
      state.assessmentsSizes = action.payload
      console.log('settingsSlice:: setAssessmentsSizesSettings: state after update:', state)
      return state
    },
    setRolesSettings: (state: ISettingsState, action: PayloadAction<IRolesAndPermissions[]>) => {
      console.log('settingsSlice:: setRolesSettings: action.payload:', action.payload)
      state.roles = action.payload
      console.log('settingsSlice:: setRolesSettings: state after update:', state)
      return state
    },
    setAdminsSettings: (state: ISettingsState, action: PayloadAction<IUser[]>) => {
      console.log('settingsSlice:: setAdminsSettings: action.payload:', action.payload)
      state.admins = action.payload
      console.log('settingsSlice:: setAdminsSettings: state after update:', state)
      return state
    },
    addFavoriteFilter: (state: ISettingsState, action: PayloadAction<ISavedFiltersSettings>) => {
      // return produce(state, (draftState: ISettingsState) => {
      //   draftState.filters.push(action.payload)
      // })
    },
    deleteFavoriteFilter: (state: ISettingsState, action: PayloadAction<ISavedFiltersSettings>) => {
      // return produce(state, (draftState: ISettingsState) => {
      //   draftState.filters.filter((item) => item !== action.payload)
      // })
    },
    setAllSettings: (state: ISettingsState, action: PayloadAction<ISettings>) => {
      console.log('settingsSlice:: setSettings: action.payload:', action.payload)
      const newState = { ...state, ...action.payload }
      console.log('settingsSlice:: state after update:', newState)
      return newState
    },
    setSeveritySettings: (state: ISettingsState, action: PayloadAction<any>) => {
      console.log('settingsSlice:: setSeveritySettings: action.payload:', action.payload);
    
      const { severityPositions, severityPositionsGroups } = action.payload;
    
      const updatedRiskHR = { ...state.riskHR };
    
      severityPositions.forEach((newPosition: any) => {
        const existingPosition: any = updatedRiskHR.severityPositions && updatedRiskHR.severityPositions.find(position => position.id === newPosition.id);
        if (existingPosition) {
          Object.keys(newPosition.topicsTRF).forEach(topicIndex => {
            if (newPosition.topicsTRF[topicIndex] !== 'unknown') {
              existingPosition.topicsTRF[topicIndex] = newPosition.topicsTRF[topicIndex];
            }
          });
        } else {
          updatedRiskHR.severityPositions && updatedRiskHR.severityPositions.push(newPosition);
        }
      });
    
      severityPositionsGroups.forEach((newGroup: any) => {
        const existingGroup:any = updatedRiskHR.severityPositionsGroups.find(group => group.id === newGroup.id);
        if (existingGroup) {
          Object.keys(newGroup.topicsTRF).forEach(topicIndex => {
            if (newGroup.topicsTRF[topicIndex] !== 'unknown') {
              existingGroup.topicsTRF[topicIndex] = newGroup.topicsTRF[topicIndex];
            }
          });
        } else {
          updatedRiskHR.severityPositionsGroups.push(newGroup);
        }
      });
    
      state.riskHR = updatedRiskHR;
    
      console.log('settingsSlice:: setSeveritySettings: state after update:', state);
      return state;
    },
  },
})

export const {
  setAllSettings,
  setEmailsSettings,
  setUXSettings,
  setCompanyInfoSettings,
  setDepartmentsSettings,
  setPositionsSettings,
  setAssessmentsSizesSettings,
  setRolesSettings,
  setAdminsSettings,
  addFavoriteFilter,
  deleteFavoriteFilter,
  setSeveritySettings
} = settingsSlice.actions
export default settingsSlice.reducer
export const selectSettings = (state: RootState) => state.settingsReducer
export const selectPositions = (state: RootState) => state.settingsReducer.enums.positions as IEnumsSettingsItem[]
export const selectPositionsGroups = (state: RootState) => state.settingsReducer.enums.positionsGroups as IEnumsPositionGroupSettingsItem[]
export const getTRFPositionsGroups = (state: RootState) => state.settingsReducer.riskHR.severityPositionsGroups as ISeverityGroupItem[]
export const getTRFPositions = (state: RootState) => state.settingsReducer.riskHR.severityPositions as ISeverityGroupItem[]
export const selectDepartments = (state: RootState) => state.settingsReducer.enums.departments as IEnumsSettingsItem[]
export const selectEmployeeStatus = (state: RootState) => state.settingsReducer.enums.employeeStatus as IEnumsSettingsItem[]
export const selectAllRoles = (state: RootState) => state.settingsReducer.roles as IRolesAndPermissions[]
export const selectAllAdmins = (state: RootState) => state.settingsReducer.admins as IUser[]
export const selectTRFLevels = (state: RootState) => {
  return state.settingsReducer.riskHR.severityPositionsGroups.map(group => ({
    id: group.id,
    topicsTRF: group.topicsTRF
  }))
}