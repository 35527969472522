import { CSSProperties, InputHTMLAttributes, ReactElement } from 'react'
import PhoneInput from 'react-phone-input-2'
import './lc-form-inputText-phone.scss'
import 'react-phone-input-2/lib/style.css'

interface Props<ObjectType> extends InputHTMLAttributes<HTMLInputElement> {
  inputId: keyof ObjectType
  disabled?: boolean
  label?: string
  required?: boolean
  customStyle?: CSSProperties
  error?: any
  touched?: any
  placeholder?: string
  countryCode?: string
}

export const LCFormInputTextPhone = <ObjectType,>({
  label,
  inputId,
  disabled,
  error,
  required,
  customStyle,
  placeholder,
  countryCode,
  ...props
}: Props<ObjectType>): ReactElement => {
  const { value, ...restOfProps } = props
  return (
    <div className="inputText-Wrapper" style={customStyle}>
      {label ? (
        <label className="inputText-label">
          {label} {required ? <span className="required">*</span> : ''}
        </label>
      ) : null}

      {/* If the phone prefix matched the list it will automaticlly show it */}
      <PhoneInput
        containerClass={`phone-country-input ${disabled ? 'disabled' : ''}`}
        country={countryCode}
        placeholder={'Phone number with country prefix'}
        value={value?.toString()}
        autoFormat={false}
        disabled={disabled}
        onChange={(e: any) => {
          restOfProps.onChange?.(e)
        }}
        excludeCountries={['ps']}
        inputProps={{ id: inputId, name: inputId, ...restOfProps }}
      />
      {error ? <div className="inputText-error">{error}</div> : null}
    </div>
  )
}
