import './lc-advanced-filter-add-item.scss'
import { useFormik } from 'formik'
import { CSSProperties, FC, HTMLAttributes, useState } from 'react'
import { IDropdownOption } from '../../forms/lc-form-dropDown/lc-form-dropDown'
import { LCButton } from '../../lc-button/LCButton'
import { LCAdvancedFilterAddItemValues } from './LCAdvancedFilterAddItemValues'
import { LCAdvancedFilterAddItemOperator } from './LCAdvancedFilterAddItemOperator'
import { LCAdvancedFilterAddItemKeys } from './LCAdvancedFilterAddItemKeys'
import { IExtendedColumn } from '../../../../pages/riskhr/riskHrEvaluations/columns/riskHrEvaluations.columns'
import { ISearch, eSearchType, eTableName } from '@logicalcommander/types'
import { useAppDispatch } from '../../../../redux/hooks'
import { IFilter, addFilter, eFilterType } from '../../../../redux/reducers/filtersSlice'

export interface IAdvancedFilterData {
  key: IDropdownOption
  valuesStructure: IAdvancedFilterValuesStructure
}

export interface IAdvancedFilterValuesStructure {
  type: 'dropdown' | 'string' | 'number' | 'date' | 'onlyContains'
  hide?: boolean
  values?: IDropdownOption[]
  valueTransform?: (value: any) => void
  operatorOverride?: IDropdownOption[]
}

export interface Props extends HTMLAttributes<any> {
  tableName: eTableName
  columns: IExtendedColumn[]
  customStyle?: CSSProperties
  closePopup?: () => void
}

export const LCAdvancedFilterAddItem: FC<Props> = ({ customStyle, tableName, columns, closePopup, ...props }: Props) => {
  const [selectedColumn, setSelectedColumn] = useState<IExtendedColumn>()
  const dispatch = useAppDispatch()

  const initialValues = {
    key: '',
    operator: '',
    value: '',
  }

  // const validationRules = {
  //   value: Yup.string()
  //     .required('Value is required')
  //     .test('len', 'Value should be at least 3 chars', (val) => (val && val.length >= 3) || false),
  // }

  let formik = useFormik({
    initialValues,
    // validationSchema: Yup.object(validationRules),
    onSubmit: (values: any) => {
      const filter: ISearch = {
        type: values.operator as eSearchType,
        value: values.value,
      }

      // Check if the value is array
      if (values.key.indexOf(',') !== -1) {
        filter.keyNameArray = values.key.split(',')
      } else {
        filter.keyName = values.key
      }

      const filterObject: IFilter = {
        tableName,
        search: filter,
        type: eFilterType.FILTER,
      }

      console.log('add filterObject: ', filterObject)
      dispatch(addFilter(filterObject))
      closePopup && closePopup()
    },
  })

  return (
    <div style={customStyle} className="lc-advanced-filter-add-item" {...props}>
      <form className="form" onSubmit={formik.handleSubmit}>
        <LCAdvancedFilterAddItemKeys
          columns={columns}
          formik={formik}
          onKeySelected={(column: IExtendedColumn) => setSelectedColumn(column)}
        />
        {selectedColumn && <LCAdvancedFilterAddItemOperator column={selectedColumn} formik={formik} />}
        {selectedColumn && <LCAdvancedFilterAddItemValues column={selectedColumn} formik={formik} />}

        <LCButton
          customStyle={{ width: '160px', marginLeft: 'auto' }}
          label="Add"
          type="submit"
          disabled={!formik.values.value || !!formik.errors.value}
        />
      </form>
    </div>
  )
}
