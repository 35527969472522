import './emo-results-summary.scss'
import React, { CSSProperties, FC, HTMLAttributes, useEffect, useState } from 'react'
import { EmoResultsSummary } from './EmoResultsSummary'
import { IEmoResultsSummary } from '../../../../interfaces/emo.interface'
import { IDropdownOption, LCDropdown } from '../../../ui/components/lc-dropdown/LCDropdown'
import { LCPage } from '../../../ui/components/lc-page/LCPage'
import { datesHelper } from '../../../utils/dates'
import { LCSearch } from '../../../ui/components/lc-search/lc-search'
import { ISearch, eSearchType } from '@logicalcommander/types'
import { useTranslation } from 'react-i18next'
import { faGem } from '@fortawesome/free-solid-svg-icons/faGem'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  filters?: ISearch[]
  customStyle?: CSSProperties
}

const buildEmoFilters = (date: IDropdownOption): ISearch[] => {
  const filters: ISearch[] = []
  date &&
    filters.push({
      type: eSearchType.DATE_AFTER,
      keyName: 'date',
      value: date.value,
    })
  return filters
}

export const EmoResultsSummaryPage: FC<Props> = ({ customStyle, filters: filtersInit }: Props) => {
  const [selectedRows, setSelectedRows] = useState<IEmoResultsSummary[]>([])
  const dates = datesHelper.getDatesRangeFromNow()
  const [selectedDates, setSelectedDates] = useState<IDropdownOption>(dates[6])
  const [filters, setFilters] = useState<ISearch[]>(filtersInit || buildEmoFilters(selectedDates))
  const { t } = useTranslation('translation', { keyPrefix: 'menu' })

  useEffect(() => {
    onFiltersChanged(filtersInit || [])
  }, [filtersInit])

  const onFiltersChanged = (filters: ISearch[]) => {
    setFilters(filters)
  }

  const changeDates = (value: IDropdownOption) => {
    setSelectedDates(value)
    setFilters(buildEmoFilters(value))
  }

  return (
    <LCPage icon={faGem} title={t('emo_risk')} breadcrumbs="Results > Per Exam">
      <div style={customStyle}>
        <div className="emo-risk-results">
          <LCSearch
            onSearch={(value: string) =>
              onFiltersChanged([
                {
                  type: eSearchType.CONTAINS,
                  keyName: 'firstName', // ['firstName', 'middleName', 'lastName', 'identity'],
                  value,
                },
              ])
            }
            onClear={() => onFiltersChanged([])}
            minimumChars={3}
          />
          <div className="user-header-dates">
            <LCDropdown options={dates} placeholder="Please select" defaultOption={selectedDates} onChange={changeDates} />
          </div>
          <div className="right-side">{selectedRows.length > 0 ? <div></div> : null}</div>
        </div>
        <EmoResultsSummary
          showTableActions={true}
          filters={filters}
          onSelectedRows={(rows: IEmoResultsSummary[]) => setSelectedRows(rows)}
        />
      </div>
    </LCPage>
  )
}
