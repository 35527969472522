import React, { FC, HTMLAttributes, lazy, useEffect, useState } from 'react'
import { CSSProperties } from 'styled-components'
import { sum } from 'lodash'
import { useTranslation } from 'react-i18next'
import { BarColor, CustomLabels } from '../../../../../interfaces/dashboard.interface'
import { useLazyGetRiskHrTopicRiskQuery } from '../../../../services/dashboard.service'
import { ITitleValueNumber } from '@logicalcommander/types'
import { BaseDashboardComponent } from '../../../../ui/components/base-dashboard/BaseDashboardComponent'
import { DashboardComponentTitle } from '../../../../ui/components/base-dashboard/DashboardComponentTitle'

const LCPieChartUniNew = lazy(() => import('../../../../ui/components/charts/lc-pie-chart'))

export interface Props extends HTMLAttributes<HTMLDListElement> {
  customStyle?: CSSProperties
}
const RiskHrDashboardTopicRisk: FC<Props> = ({ customStyle }: Props) => {
  const [getRiskHRTopicRisk, { data, error, isLoading }] = useLazyGetRiskHrTopicRiskQuery()
  const [topicRiskLevel, setTopicRiskLevel] = useState<ITitleValueNumber[]>([])
  const [sumTopicRiskLevel, setSumTopicRiskLevel] = useState(0)
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.riskhr_topic_risk' })

  useEffect(() => {
    getRiskHRTopicRisk()
  }, [])

  useEffect(() => {
    if (!error && data) {
      const { high, medium, low } = data
      const sumSorted = sum(Object.values(data))
      setSumTopicRiskLevel(sumSorted)
      setTopicRiskLevel([
        // { title: t('critical_risk'), value: critical },
        { title: 'High', value: high },
        { title: 'Preventive', value: medium },
        { title: 'Low', value: low },
      ])
    }
  }, [data, error])

  return (
    <BaseDashboardComponent
      customStyle={customStyle}
      sectionTitle={<DashboardComponentTitle title={`${t('title_Assessment')} ${t('title_status')}`} subTitle={`${t('total')} ${sumTopicRiskLevel}`} />}
      isLoading={isLoading}
      isSuccess={!!topicRiskLevel}
      isError={!!error}>
      <LCPieChartUniNew
        data={topicRiskLevel}
        dataTotal={sumTopicRiskLevel}
        options={{
          color: BarColor.COLOR_RISK_LEVEL,
          label: CustomLabels.TOPIC_RISK_LEVEL,
          customPercentage: false,
        }}
      />
    </BaseDashboardComponent>
  )
}

export default RiskHrDashboardTopicRisk
