import './set-new-password.scss'
import React, { FC, useEffect, useState } from 'react'
import { useSetNewPasswordMutation } from '../../../services/auth.service'
import { LCButton } from '../../../ui/components/lc-button/LCButton'
import { LCLoader } from '../../../ui/components/lc-loader/LCLoader'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { LCFormInputTextPassword } from '../../../ui/components/forms/lc-form-inputText-password/lc-form-inputText-pawword'
import { ResponseMessage } from '../components/response-message/ResponseMessage'
import { SanitizeHTML } from '../../../ui/components/lc-sanitize-html/SanitizeHTML'
import deepEqual from 'deep-equal'

const initialValues = {
  password: '',
  confirmpassword: '',
}

interface IPasswordField {
  password: string
  confirmpassword: string
}

export const SetNewPassword: FC = () => {
  const [setNewPassword, { data, error, isLoading }] = useSetNewPasswordMutation()
  const [isValidForm, setIsValidForm] = useState(false)
  const { t } = useTranslation('translation', { keyPrefix: 'auth.setNewPassword' })
  const { t: v } = useTranslation('translation', { keyPrefix: 'validation' })
  const [response, setResponse] = useState<{ title: string; body: string }>({ title: '', body: '' })
  const [errorToken, setErrorToken] = useState<boolean>(false)

  const validationRules = {
    password: Yup.string()
      .required(v('required', { field: t('newPawword') }))
      .min(6, v('between', { field: t('newPawword'), min: 6, max: 20 }))
      .max(20, v('between', { field: t('newPawword'), min: 6, max: 20 }))
      .matches(/^(?=.*\d)(?=.*[!@#$%^&*])[0-9a-zA-Z!@#$%^&*]{6,20}$/, v('complexPassword')),
    confirmpassword: Yup.string()
      .required(v('required', { field: t('confirmPassword') }))
      .oneOf([Yup.ref('password')], t('matchPasswords')),
  }

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object(validationRules),
    onSubmit: (values: any) => {
      const { password, confirmpassword } = values
      if (password === confirmpassword) {
        const urlParams = new URLSearchParams(window.location.search)
        const token = urlParams.get('token')

        if (token) {
          setNewPassword({ password, token })
        } else {
          setErrorToken(true)
          setResponse({ title: t('errorTokenNotFound.title'), body: t('errorTokenNotFound.body') })
        }
      }
    },
  })

  useEffect(() => {
    if (data && !error) {
      setResponse({ title: t('success.title'), body: t('success.body') })
    } else if (error) {
      setResponse({ title: t('error.title'), body: t('error.body') })
    }
  }, [data, error])

  useEffect(() => {
    const touched = !deepEqual(formik.values, initialValues)
    setIsValidForm(touched && formik.isValid)
  }, [formik.isValid, formik.touched, formik.values])

  return (
    <div className="change-password-screen-wrapper">
      <div className="change-password-top">
        <div className="logo-wrapper">
          <img className="logo" src="./../../../../images/ecommander.png" alt="logo" />
        </div>
        <div className="change-password-screen">
          <h3>{t('title')}</h3>
          <SanitizeHTML html={t('description')} />

          <form className="form" onSubmit={formik.handleSubmit}>
            <LCFormInputTextPassword<IPasswordField>
              label={t('newPawword')}
              inputId="password"
              error={formik.errors.password}
              {...formik.getFieldProps('password')}
            />
            <LCFormInputTextPassword<IPasswordField>
              label={t('confirmPassword')}
              inputId="confirmpassword"
              error={formik.errors.confirmpassword}
              {...formik.getFieldProps('confirmpassword')}
            />
            <LCButton
              type="submit"
              label={t('setNewPassword')}
              disabled={!isValidForm}
              customStyle={{ maxWidth: '200px', alignSelf: 'flex-end', marginTop: '10px' }}
            />
          </form>
          <LCLoader show={isLoading} type="small" />
        </div>
      </div>
      <ResponseMessage title={response.title} html={response.body} isError={error !== undefined || errorToken} />
    </div>
  )
}
