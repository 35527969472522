import * as React from 'react'
import { SVGProps } from 'react'

const Svg3Dots = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 4" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM10 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM18 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
      stroke="currentColor"
      strokeWidth={2}
    />
  </svg>
)

export default Svg3Dots
