/* eslint-disable @typescript-eslint/no-unused-vars */
import './user-results-detailed-page.scss'
import './user-results-detailed-page.print.scss'
import React, { FC, HTMLAttributes, useEffect, useState } from 'react'
import { EmoRiskAttendanceForUser } from '../emoRisk/components/emorisk-attendance/EmoRiskAttendanceForUser'
import { EmoRiskCases } from '../emoRisk/emoRiskCases/EmoRiskCases'
import { RiskHrCases } from '../riskhr/riskHrCases/cases/RiskHrCases'
import { useParams } from 'react-router-dom'
import { fullName } from '../../utils/fullname'
import { useLazyGetUserQuery } from '../../services/user.service'
import { IDropdownOption, LCDropdown } from '../../ui/components/lc-dropdown/LCDropdown'
import { datesHelper } from '../../utils/dates'
import { EmoResultsUserSummary } from '../emoRisk/emoResultsUserSummary/EmoResultsUserSummary'
import { LCPage } from '../../ui/components/lc-page/LCPage'
import { RiskHrEvaluations } from '../riskhr/riskHrEvaluations/evaluations/RiskHrEvaluations'
import { ISearch, IUser } from '@logicalcommander/types'
import { EmoEmotionsResultsUsesSummary } from '../emoRisk/EmoEmotionsResultsUsesSummary/EmoEmotionsResultsUsesSummary'
import { buildRiskHRFilters, buildCasesFilters, buildEmoFilters, getSubtitle, getUserAddress } from './user-results-detailed-page-helper'
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser'
import { UserDetailsHeader } from './components/user-details-header'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  identity?: string
}

export const User: FC<Props> = ({ identity }: Props) => {
  let params = useParams()
  const [getUser, { data, error }] = useLazyGetUserQuery()
  const [user, setUser] = useState<IUser>()
  const dates = datesHelper.getDatesRangeFromNow()
  const [selectedDates, setSelectedDates] = useState<IDropdownOption>(dates[6])
  const [riskHrFilters, setRiskHrFilters] = useState<ISearch[]>(buildRiskHRFilters(params.identity || identity, selectedDates))
  const [casesFilters, setCasesFilters] = useState<ISearch[]>(buildCasesFilters(params.identity || identity, selectedDates))
  const [emoFilters, setEmoFilters] = useState<ISearch[]>(buildEmoFilters(params.identity || identity, selectedDates))

  useEffect(() => {
    getUser(params.identity)
    console.log('user constructor')
  }, [])

  useEffect(() => {
    if (data && !error) {
      setUser(data)
    }
  }, [data, error])

  const doSelectDates = (option: IDropdownOption) => {
    console.log('new date: ', option)
    setSelectedDates(option)
  }

  return (
    <LCPage icon={faUser} className="user-wrapper" title={(user && fullName(user)) || ''} subTitle={user?.email}>
      <UserDetailsHeader user={user} doChangeDate={doSelectDates} />

      <div className="user">
        <div className="content">
          <div id="risk-hr-evaluations" className="table-section">
            <h2>Risk-HR Assessments</h2>
            <RiskHrEvaluations searchArray={riskHrFilters} />
          </div>
          {/* <div id="risk-hr-cases" className="table-section">
              <h2>Risk-HR Cases</h2>
              <RiskHrCases filters={casesFilters} />
            </div>
            <div id="emorisk-emotions" className="table-section">
              <h2>EmoRisk Emotions</h2>
              <EmoResultsUserSummary filters={emoFilters} />
              <EmoEmotionsResultsUsesSummary />
            </div>
            <div id="emorisk-cases" className="table-section">
              <h2>EmoRisk Cases</h2>
              <EmoRiskCases />
            </div>
            <div id="emorisk-attendance" className="table-section">
              <h2>EmoRisk Attendance</h2>
              {user?.identity && <EmoRiskAttendanceForUser identity={user?.identity} />}
            </div> */}
        </div>
      </div>
    </LCPage>
  )
}
