import './personalDetailsForm.scss'
import { FC, ReactElement } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { LCFormInputTextCalendar } from '../../../../../ui/components/forms/lc-form-inputText-calendar/lc-form-inputText-calendar'
import { LCFormTextArea } from '../../../../../ui/components/forms/lc-form-textarea/lc-form-textarea'
import { IUser, IUserPersonalDetails } from '@logicalcommander/types'
import { LCFormDropdownDynamic } from '../../../../../ui/components/forms/lc-form-dropdown-dynamic/lc-form-dropdown-dynamic'
import { UserActionName } from '../../createUserHelper'
import { LCFormInputTextPhone } from '../../../../../ui/components/forms/lc-form-inputText-phone/lc-form-inputText-phone'
import { getAllCountriesDropdown, getAllGendersDropdown } from '../../../../../utils/list-helper'

interface Props {
  user: IUser | undefined
  formik: any
  actionName: UserActionName
  disabled?: boolean
}

export const PersonalDetails: FC<Props> = ({ user, disabled = false, formik, actionName }: Props): ReactElement => {
  const { t } = useTranslation('translation', { keyPrefix: 'user.personal_details' })
  const allCountriesOptions = getAllCountriesDropdown()
  const genderOptions = getAllGendersDropdown()

  return (
    <div className="base-section-wrapper">
      <div className="base-section">
        <LCFormDropdownDynamic
          required={false}
          label={t('gender')}
          inputId="personalDetails.gender"
          selectedValue={formik.values?.personalDetails?.gender}
          setFieldValue={formik.setFieldValue}
          disabled={disabled}
          options={genderOptions}
          error={formik.touched.personalDetails?.gender && formik.errors.personalDetails?.gender}
        />
        <LCFormInputTextCalendar
          label={t('birth_day')}
          inputId="personalDetails.bDate"
          disabled={disabled}
          value={formik.values.personalDetails?.bDate}
          error={formik.touched.personalDetails?.bDate && formik.errors.personalDetails?.bDate}
          defaultValueWhenOpen={moment().subtract(30, 'years').toDate()}
          onDateSelected={(date) => formik.setFieldValue('personalDetails.bDate', date)}
        />
        <LCFormDropdownDynamic
          required={false}
          label={t('birth_place')}
          inputId="personalDetails.bPlace"
          selectedValue={formik.values?.personalDetails?.bPlace}
          disabled={disabled}
          setFieldValue={formik.setFieldValue}
          options={allCountriesOptions}
          error={formik.touched.personalDetails?.bPlace && formik.errors.personalDetails?.bPlace}
        />
      </div>
      <div className="base-section">
        <LCFormDropdownDynamic
          required={false}
          label={t('nationality')}
          inputId="personalDetails.nationality"
          selectedValue={formik.values?.personalDetails?.nationality}
          setFieldValue={formik.setFieldValue}
          disabled={disabled}
          options={allCountriesOptions}
          error={formik.touched.personalDetails?.nationality && formik.errors.personalDetails?.nationality}
        />

        <LCFormInputTextPhone<IUserPersonalDetails>
          label={t('additional_phone')}
          required={true}
          inputId="personalDetails.additionalPhone"
          disabled={disabled}
          error={formik.touched.personalDetails?.additionalPhone && formik.errors.personalDetails?.additionalPhone}
          {...formik.getFieldProps('personalDetails.additionalPhone')}
        />
      </div>
      <div className="base-section">
        <LCFormTextArea<IUserPersonalDetails>
          label={t('comments')}
          inputId="personalDetails.comments"
          disabled={disabled}
          maxLength="1000"
          error={formik.errors.personalDetails?.comments}
          {...formik.getFieldProps('personalDetails.comments')}
        />
      </div>
    </div>
  )
}
