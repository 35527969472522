export const getSeverityFromPercentages = (maxSeverity: number, t: any): string => {
  const percent = Math.round(maxSeverity * 100) / 100
  if (percent <= 25) return t('veryLow')
  else if (percent > 25 && percent <= 50) return t('low')
  else if (percent > 50 && percent <= 70) return t('moderate')
  else if (percent > 70 && percent <= 85) return t('high')
  else if (percent > 85) return t('critical')
  return 'N/A'
}

export const convertSeverityToPercentages = (severityName: string): number => {
  if (severityName === 'veryLow') return 25
  else if (severityName === 'low') return 50
  else if (severityName === 'moderate') return 70
  else if (severityName === 'high') return 85
  else if (severityName === 'critical') return 100
  return 0
}
