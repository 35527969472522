import React from 'react'
import ReactDOM from 'react-dom/client'
import './app/init/beforeAuth/language/i18n'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { store } from './app/redux/store'
import { Provider } from 'react-redux'
import { initBeforeAuth } from './app/init/beforeAuth/initBeforeAuth'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

initBeforeAuth().then(() => {
  root.render(
    <Provider store={store}>
      <App />
    </Provider>
  )
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
