import './lc-action-popup.scss'
import { CSSProperties, FC, ReactElement, useRef } from 'react'
import { ISvgNames, LCSvg } from '../lc-svg/lc-svg'
import LCPopup from '../lc-popup/LCPopup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export interface ILCActionPopupItemData {
  text?: string
  iconName?: ISvgNames
  newIcon?: any
  reactElement?: ReactElement
  disabled?: boolean
  action?: () => void
}

export interface Props {
  anchor: ReactElement
  data: ILCActionPopupItemData[]
  position?: ['center' | 'left' | 'midleft' | 'right' | 'midright', 'center' | 'top' | 'midtop' | 'bottom' | 'midbottom'] | 'modal'
  toggleOn?: 'click' | 'hover'
  customStyle?: CSSProperties
}

export const LCActionPopup: FC<Props> = ({ data, anchor, position, toggleOn, customStyle, ...props }: Props): ReactElement => {
  const ref = useRef<any>(null)

  const ActionComponent = ({ closePopup }: { closePopup: () => void }): ReactElement => {
    const onItemClick = (action: () => void) => {
      action()
      closePopup()
    }

    return (
      <div style={customStyle} {...props}>
        {data?.map((item: ILCActionPopupItemData, index) => (
          <div
            key={index}
            className={`lc-action-popup-inner ${item.disabled ? 'disabled' : ''}`}
            onClick={(event) => item.action && onItemClick(item.action)}>
            {item.reactElement ? (
              <div className="lc-action-item">{item.reactElement}</div>
            ) : (
              <div className="lc-action-item">
                {item.newIcon ? <FontAwesomeIcon icon={item.newIcon} size="lg" /> : null}
                {item.iconName ? <LCSvg name={item.iconName} /> : null}
                <span className="lc-action-item-text">{item.text}</span>
              </div>
            )}
          </div>
        ))}
      </div>
    )
  }

  return (
    <LCPopup ref={ref} toggler={anchor} position={position} toggleOn={toggleOn}>
      <ActionComponent closePopup={() => ref?.current.close()} />
    </LCPopup>
  )
}
