import * as React from 'react'
import { SVGProps } from 'react'

const SvgDelete = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1 4.6h16M5.444 4.6V2.8c0-.477.188-.935.521-1.273A1.767 1.767 0 0 1 7.222 1h3.556c.471 0 .923.19 1.257.527.333.338.52.796.52 1.273v1.8m2.667 0v12.6c0 .477-.187.935-.52 1.273a1.767 1.767 0 0 1-1.258.527H4.556c-.472 0-.924-.19-1.257-.527a1.812 1.812 0 0 1-.521-1.273V4.6h12.444ZM7.222 9.1v5.4M10.778 9.1v5.4"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgDelete
