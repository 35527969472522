import React, { useContext, useEffect, useState } from 'react'
import { License, MyContextPurchase } from './useContextService'
import { useTranslation } from 'react-i18next'

export const ModulePurchase = () => {
  const { t: tButton } = useTranslation('translation', { keyPrefix: 'purchase.buttons' })
  const { t: tSummary } = useTranslation('translation', { keyPrefix: 'purchase.summary' })
  const { dataPurchase, resetPurchase } = useContext(MyContextPurchase)
  const [totalPurches, setTotalPurches] = useState<number>(0)

  useEffect(() => {
    let totalSum = 0
    dataPurchase.forEach((data) => {
      for (const key in data) {
        const license: License = data[key]
        totalSum += parseInt(license.value) * license.amount
      }
    })

    setTotalPurches(totalSum)
  }, [dataPurchase])

  return (
    <div className="purchase-summary-container">
      <h3>{tSummary('title')}</h3>
      <div className="container-summary">
        {dataPurchase.map((item, index) => {
          const keyItem = Object.keys(item)
          const { title, value, amount, currency } = item[keyItem[0]]
          const valueNumber = Number(value)
          const valueAmount = valueNumber * amount
          return amount > 0 ? (
            <div className="container-product-summary" key={`item-summary-${index}`}>
              <h5 className="title-licence">{`${title}`}</h5>
              <span>&nbsp;|&nbsp;x&nbsp;</span>
              <span>{`${amount}`}</span>&nbsp;
              <span>{`${valueAmount.toLocaleString('es-MX')}`}</span>
              <span>&nbsp;{`${currency}`}</span>
            </div>
          ) : null
        })}
      </div>
      <div className="container-total">
        <span className="total-widget">Total:{totalPurches}</span>
        <button
          className={`buttton-purches ${dataPurchase.length === 0 ? 'disabled-button' : null}`}
          disabled={dataPurchase.length === 0 ? true : false}
          onClick={() => {
            resetPurchase()
            console.log('data Purches', dataPurchase)
          }}>
          {tButton('button-toApprove')}
        </button>
        <button
          className={`buttton-purches ${dataPurchase.length === 0 ? 'disabled-button' : null}`}
          disabled={dataPurchase.length === 0 ? true : false}
          onClick={() => {
            resetPurchase()
            console.log('data Purches', dataPurchase)
          }}>
          {tButton('button-buy')}
        </button>
      </div>
    </div>
  )
}
