import { IAssessmentSchemaInfo } from '@logicalcommander/types'
import { IAssessmentSizes, IAssessmentTypeItem, IAssessmentTypes } from '../../redux/reducers/settingsSlice'

export const getAssessmentsSizesFromAssessmentsInfoArray = (assessmentsInfo: IAssessmentSchemaInfo[]): IAssessmentSizes => {
  const groupedBySizeId: IAssessmentSizes = assessmentsInfo.reduce(
    (acc, { id, typeId, nameId, sizeId, customName }) => {
      if (!acc[sizeId]) {
        acc[sizeId] = {}
      }
      if (!acc[sizeId][typeId]) {
        acc[sizeId][typeId] = []
      }
      if (!acc[sizeId][typeId].find((i) => i.nameId === nameId)) {
        const item: IAssessmentTypeItem = {
          nameId,
          customName,
          assessmentId: id,
        }
        acc[sizeId][typeId].push(item)
      }
      return acc
    },
    {} as Record<number, IAssessmentTypes>
  )

  Object.keys(groupedBySizeId).forEach((sizeId) => {
    Object.keys(groupedBySizeId[sizeId as any]).forEach((typeId) => {
      groupedBySizeId[sizeId as any][typeId as any].sort((a, b) => a.nameId - b.nameId)
    })
  })

  console.log('groupedBySizeId', groupedBySizeId)
  return groupedBySizeId
}
