import React, { CSSProperties, FC, HTMLAttributes } from 'react'
import './emo-analytics.scss'
import { LCPage } from '../../../ui/components/lc-page/LCPage'
import { useTranslation } from 'react-i18next'
import { faChartLine } from '@fortawesome/free-solid-svg-icons/faChartLine'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

export const EmoAnalyticsPage: FC<Props> = ({ customStyle }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'menu' })
  return (
    <LCPage icon={faChartLine} title={t('emo_risk')} breadcrumbs="Analytics" iconName={''} text={''}>
      <div className="emo-analytics" style={customStyle}>
        Coming soon
      </div>
    </LCPage>
  )
}
