import { FC, HTMLAttributes } from 'react'
import './lc-charts.scss'
import { CSSProperties } from 'styled-components'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

export const LcStyleNoData: FC<Props> = ({ customStyle }: Props) => {
  return (
    <div style={customStyle} className="styleNotData">
      <h2>Not Data</h2>
    </div>
  )
}
