import './lc-form-checkBox.scss'
import { CSSProperties, InputHTMLAttributes, ReactElement } from 'react'

interface Props<ObjectType> extends InputHTMLAttributes<HTMLInputElement> {
  inputId: keyof ObjectType
  label?: string
  value?: string
  error?: any
  disabled?: boolean
  checked?: boolean
  required?: boolean
  customStyle?: CSSProperties
}

export const LCFormCheckbox = <ObjectType,>({
  label,
  inputId,
  required,
  customStyle,
  error,
  disabled,
  checked,
  value,
  ...props
}: Props<ObjectType>): ReactElement => {

  const classes = []
  classes.push('lc-form-checkbox-wrapper')
  classes.push(disabled ? 'disabled' : '')

  return (
    <div className={classes.join(' ')} style={customStyle}>
      <div className="lc-form-checkbox-input-wrapper">
        <input className="lc-form-checkbox-input" name={inputId as string} type="checkbox" checked={!!checked} value={value} disabled={disabled} {...props} />
        <label className="lc-form-checkbox-label">
          {label} {required ? '*' : ''}
        </label>
      </div>
      <div className="lc-form-checkbox-error">{error}</div>
    </div>
  )
}
