import './lc-aware-others-image-profile.scss'
import React, { CSSProperties, FC, ReactElement } from 'react'
import { configManager } from '../../../../config/configManager'
import { LCCachedImageAuthenticated } from '../../components/lc-cached-image/LCCachedImageAuthenticated'

interface Props {
  customStyle?: CSSProperties
}

const LCAwareProfileImageBlob: FC<Props> = ({ customStyle, ...props }: Props): ReactElement => {
  const profileImage = `${configManager.api.GATEWAY_URL}/user/me/blob/profile-image`
  return (
    <div style={customStyle} className="lc-aware-others-image-profile-wrapper" {...props}>
      <LCCachedImageAuthenticated cacheKey="profile-image-blob" url={profileImage} alt={'profile image'} {...props} />
    </div>
  )
}

export default React.memo(LCAwareProfileImageBlob)
