import * as React from 'react'
import { SVGProps } from 'react'

const SvgVideo = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="17" cy="17" r="17" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 13.2493C9.5 12.8357 9.83549 12.5 10.2497 12.5H18.4981C18.9123 12.5 19.2478 12.8357 19.2478 13.2493V20.7461C19.2478 21.1598 18.9123 21.4954 18.4981 21.4954H10.2497C9.83549 21.4954 9.5 21.1598 9.5 20.7461V13.2493ZM10.2497 11C9.0074 11 8 12.0069 8 13.2493V20.7461C8 21.9885 9.0074 22.9954 10.2497 22.9954H18.4981C19.7404 22.9954 20.7478 21.9885 20.7478 20.7461V18.4548L24.8112 21.3564C25.0398 21.5197 25.3405 21.5415 25.5903 21.4129C25.84 21.2844 25.997 21.027 25.997 20.7461V13.2493C25.997 12.9684 25.84 12.711 25.5903 12.5825C25.3405 12.4539 25.0398 12.4757 24.8112 12.639L20.7478 15.5407V13.2493C20.7478 12.0069 19.7404 11 18.4981 11H10.2497ZM24.497 19.2889L21.2886 16.9977L24.497 14.7065V19.2889Z"
      fill="white"
    />
  </svg>
)

export default SvgVideo
