import './lc-form-toggle.scss'
import { CSSProperties, InputHTMLAttributes, ReactElement } from 'react'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  inputId: string
  label?: string
  value?: string
  isEnabled?: boolean
  disabled?: boolean
  customStyle?: CSSProperties
}

export const LCFormToggle = ({
  label,
  inputId,
  customStyle,
  isEnabled,
  disabled,
  value,
  ...props
}: Props): ReactElement => {

  const wrapperClass = []
  wrapperClass.push(label ? 'toggle-container' : 'toggle-container-no-label')
  wrapperClass.push(disabled ? 'disabled' : '')

  return (
    <div className={wrapperClass.join(' ')} style={customStyle}>
      <label className="switch">
        <div className="label">{label}</div>
        <input className="input"
          id={inputId}
          name={inputId}
          type={'checkbox'}
          checked={!!isEnabled}
          disabled={disabled}
          value={value}
          {...props}
        />
        <span className="slider round"></span>
      </label>
    </div>
  )
}
