import React, { CSSProperties, FC, HTMLAttributes, useState, useEffect } from 'react'
import { Bar, BarChart, Tooltip, Legend, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { IBarEmoOptions } from '../../../../interfaces/dashboard.interface'
import { CustomLabelBar, CustomizedTickH } from './customComponents/custom-lc-pie-charts'
import { LCDropdown } from '../lc-dropdown/LCDropdown'
import { barColors } from './bar-colors'

interface DataEntry {
  id: string
  title: string
  alert?: number
  Normal?: number
  Low?: number
  Medium?: number
  High?: number
  critical?: number
}

export interface IBarChartDataLevel {
  [key: string]: DataEntry[]
}

export interface IValueChartDataAgeEmotion {
  [key: string]: DataEntry[]
}

interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
  data: IBarChartDataLevel | IValueChartDataAgeEmotion
  options: IBarEmoOptions
}

function containsNumber(str: string) {
  return /\d/.test(str)
}

const CustomTooltip = (props: any) => {
  const { active, label, payload } = props

  if (active && payload && payload.length) {
    const payloadReverse = payload.reverse()
    return (
      <div className="custom-tooltip">
        <p className="custom-label">{label}</p>
        {payloadReverse.map((key: any, index: number) => {
          return (
            <p key={`key-text-tooltip${index}`} style={{ color: `${key.color}` }}>
              {key.name}: <span style={{ color: 'black' }}>{key.value}</span>
            </p>
          )
        })}
      </div>
    )
  }
  return <></>
}

export const LCBarChartLevel: FC<Props> = ({ customStyle, data, options }: Props) => {
  const { color } = options
  const dataKey = Object.keys(data).sort()
  const optionsDropdown: { label: string; value: string }[] = dataKey.map((option) => ({ label: option, value: option }))
  const firstOption: string = optionsDropdown[0].label
  const [dataSelect, setDataSelect] = useState(
    data[firstOption].sort(function (a, b) {
      var titleA = a.title.toUpperCase()
      var titleB = b.title.toUpperCase()

      if (!containsNumber(titleA) && !containsNumber(titleB)) {
        return titleA.localeCompare(titleB)
      } else {
        return 0
      }
    })
  )

  const keysDataSelect = Object.keys(dataSelect[0]).filter((item) => item !== 'id' && item !== 'title')

  const onInputChange = (event: any) => {
    const { value } = event
    setDataSelect(data[value])
  }

  function findLongestWord(matriz: any) {
    let longestWord = { title: '' }

    for (let i = 0; i < matriz.length; i++) {
      const wordCurrent = matriz[i].title
      if (wordCurrent.length > longestWord.title.length) {
        longestWord = { title: `${matriz[i].title}` }
      }
    }
    return longestWord.title.length
  }

  const [marginBottom, setMarginBottom] = useState({ top: 25, bottom: 0, right: 30, left: 10 })

  useEffect(() => {
    const longestWord = findLongestWord(dataSelect)
    const marginBottom = longestWord * 3.4

    setMarginBottom((prevState) => {
      return {
        ...prevState,
        bottom: marginBottom,
      }
    })
  }, [data])

  return (
    <div className="chart-widget" style={customStyle}>
      <div className="container-chart">
        <div className="container-dropdown">
          <LCDropdown options={optionsDropdown} defaultOption={firstOption} onChange={onInputChange} />
        </div>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={dataSelect} maxBarSize={35} margin={marginBottom}>
            <XAxis dataKey={'title'} type="category" dx={-5} tick={<CustomizedTickH />} interval={0} textAnchor="end" padding={{ left: 4, right: 4 }} tickLine={false} />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Legend wrapperStyle={{ position: 'absolute', bottom: '280px' }} verticalAlign="top" />
            {keysDataSelect.map((key: any, i: number) => (
              <Bar key={`${key}-${i}`} dataKey={key} stackId="alert" radius={keysDataSelect.length <= 1 ? [5, 5, 0, 0] : 0} fill={barColors[`${color}`](i)} label={<CustomLabelBar data={dataSelect} level={key} />} />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
