import './lc-sidemenu.scss'

import React, { FC, useEffect, useState } from 'react'
import { SideMenuItem, sideMenuTabsData } from './lcSideMenuData'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../redux/hooks'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation, useNavigate } from 'react-router'
import { menuStyles, subMenuRootStyles, subMenuItemStyles, menuItemRootStyles } from './lc-side-menu.styles'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { cachedStorage, eStorageKey } from '../../utils/cachedStorage'
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons'
import { resetState } from '../../redux/reducers/authSlice'
import { LCSvg } from '../../ui/components/lc-svg/lc-svg'

interface Props {
  width?: number
  collapsedWidth?: number
}

export const LCSideMenuNew: FC<Props> = ({ width = 240, collapsedWidth = 80 }: Props) => {
  const dispatch = useAppDispatch()
  const [selectedTab, setSelectedTab] = useState('dashboard')
  const [collapsed, setCollapsed] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation('translation', { keyPrefix: 'menu' })

  useEffect(() => {
    const isCollapsedFromLS = cachedStorage.get<boolean>(eStorageKey.LEFT_MENU_EXPANDED) || false
    if (isCollapsedFromLS) {
      setCollapsed(isCollapsedFromLS)
    }

    const tabFromURL = location.pathname
    setSelectedTab(tabFromURL)
  }, [])

  const handleTabClick = (currentTab: SideMenuItem) => {
    const { key, navigate: navigateTo, action } = currentTab
    console.log('---> setSelectedTab', key)
    setSelectedTab(key)
    action && action()
    navigateTo && navigate(navigateTo)
    // if (!action && !navigateTo && subTabs) {
    //   setShowSubTabs(!showSubTabs)
    // }
  }

  const expandCollapse = () => {
    const newValue = !collapsed
    setCollapsed(newValue)
    cachedStorage.set(eStorageKey.LEFT_MENU_EXPANDED, newValue)
  }

  const doSignOut = () => {
    dispatch(resetState())
    navigate('/login')
  }

  return (
    <div className="side-menu-wrapper">
      <div className="side-menu">
        <Sidebar
          collapsed={collapsed}
          className="side-menu-bar"
          backgroundColor="#ffffff"
          width={`${width}px`}
          collapsedWidth={`${collapsedWidth}px`}>
          <Menu menuItemStyles={menuStyles}>
            {sideMenuTabsData(t).map((tabData, i) =>
              tabData.subTabs ? (
                <SubMenu
                  key={tabData.key}
                  label={tabData.label}
                  icon={<FontAwesomeIcon icon={tabData.newIcon} size="xl" />}
                  rootStyles={subMenuRootStyles}>
                  {tabData.subTabs.map((s) => (
                    <MenuItem rootStyles={subMenuItemStyles} key={s.key} onClick={() => handleTabClick(s)} active={selectedTab === s.key}>
                      {s.label}
                    </MenuItem>
                  ))}
                </SubMenu>
              ) : (
                <MenuItem
                  key={tabData.key}
                  icon={<FontAwesomeIcon icon={tabData.newIcon} size="xl" />}
                  active={selectedTab === tabData.key}
                  rootStyles={menuItemRootStyles}
                  onClick={() => handleTabClick(tabData)}>
                  {tabData.label}
                </MenuItem>
              )
            )}
          </Menu>
        </Sidebar>
        <div>
          <Menu menuItemStyles={menuStyles}>
            <MenuItem icon={<FontAwesomeIcon icon={faRightToBracket} size="xl" />} rootStyles={menuItemRootStyles} onClick={doSignOut}>
              {collapsed ? null : t('sign_out')}
            </MenuItem>
          </Menu>
        </div>
      </div>
      <div className="expand-collapse-wrapper">
        <LCSvg name="expandCollapseSideMenu" widthAndHeight={[20, 124]} customStyle={{ color: 'white' }} onClick={expandCollapse} />
        <FontAwesomeIcon className="expand-collapse" icon={collapsed ? faChevronRight : faChevronLeft} size="xl" onClick={expandCollapse} />
      </div>
    </div>
  )
}
