import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit'
import { resetState } from '../reducers/authSlice'

export const unauthenticatedMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    const inLogin = window.location.href.indexOf('/login') !== -1
    if (isRejectedWithValue(action) && action.payload.status === 401 && !inLogin) {
      console.log('Resetting state')
      dispatch(resetState())
      window.location.href = '/login'
    }

    return next(action)
  }
