import { ITitleValueNumber } from '@logicalcommander/types'
import { sumBy } from 'lodash'

interface calculateAdjustedZyIncrementY {
  Zy: number
  y: number
  labelMidRadius: number
  firstData: boolean
  title: string
}

export interface previousDataProps {
  y: number
  prevPercent: number
  indexUpdate: number
  titleUpdate: string
  prevTitle: string
  prevOverlap: string
  cos: number
  sin: number
}
export interface previusDataPieOfPie {
  referencePointX: number
  referencePointy: number
  initialPointOtherDataX: number
  initialPointOtherDataY: number
  index: number
}

export interface dataTextOverlayCorrection {
  adjustedZx: number
  adjustedZy: number
  activedTextBoolean: boolean
  colorText: string
  endAjustedX: number
  middleAjustedY: number
  prevOverlap: string
  ajustedStartX: number
  textAnchor: 'start' | 'end'
}
interface ConditionsArguments {
  data: ITitleValueNumber[]
  firstData: boolean
  cos: number
  percent: number
  labelRadius: number
  distance: number
  startX: number
  outerRadius: number
  cy: number
  sin: number
  middleY: number
  fontSize: number
  endX: number
  Zy: number
  Zx: number
  y: number
  diferenceRadius: boolean
  evenData: boolean
  labelMidRadius: number
  title: string
  prevDataTitle: string
  nextDataTile: string
  prevPercent: number
  labelRadiusSubtractionY: number
  prevOverlap: string
  prevCos: number
  viewPointY: number
  textAnchorAjusted: 'start' | 'end'
}
interface ConditionResult {
  colortest: string
  prevOverlap: string
  activeTextLine: boolean
  endX: number
  middleY: number
  Zy: number
  Zx: number
  startX: number
  textAnchorAjusted: 'start' | 'end'
  cos: number
}

interface propsAjustedIntervalZx {
  nextLabel: string
  prevLabel: string
  Zx: number
  endX: number
  actived: boolean
  staticIncrement: number
  title: string
}

interface ICalculateAdjustedZySubtractionLeftY {
  Zy: number
  y: number
  labelMidRadius: number
  firstData: boolean
  title: string
}

interface ICalcualteAdjustedincrementZx {
  sin: number
  distance: number
  title: string
  Zx: number
  endX: number
  viewPointY: number
  Zy: number
  labelRadius: number
  range: number
}

interface calculateFontSizeProps {
  inputPercentage: number
  maxPercentage: number
  minPercentage: number
  maxFontSize: number
  minFontSize: number
}

export function calculateFontSize({
  inputPercentage,
  maxPercentage,
  minPercentage,
  maxFontSize,
  minFontSize,
}: calculateFontSizeProps): number {
  if (inputPercentage >= maxPercentage) {
    return maxFontSize
  } else if (inputPercentage <= minPercentage) {
    return minFontSize
  } else {
    const percentageRange = maxPercentage - minPercentage
    const fontSizeRange = maxFontSize - minFontSize
    const relativePercentage = inputPercentage - minPercentage
    const ratio = relativePercentage / percentageRange
    const calculatedFontSize = minFontSize + ratio * fontSizeRange
    return calculatedFontSize
  }
}
export function dataPieOfPie(array: ITitleValueNumber[], dataTotal: number): ITitleValueNumber[][] {
  if (array.length <= 15) {
    return [array]
  } else {
    const dataContainer = array
    const bigData = dataContainer.slice(0, 22)
    const otherData = dataContainer.slice(22)

    const OtherData = sumBy(otherData, 'value')

    const newBigData = [
      { title: 'Other Data', value: OtherData, percentCustom: (OtherData * 100) / dataTotal, customDataOther: true },
      ...bigData,
    ]

    const data = [newBigData].concat(dataPieOfPie(otherData, dataTotal))

    return data
  }
}
export function idTitleKey(inputString: string): string {
  const words = inputString.split(' ')

  const transformedString = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join('')

  return transformedString
}
export const sortDepartmentRisk = (a: ITitleValueNumber, b: ITitleValueNumber) => {
  return b.value - a.value
}

export const calculateVerticalDistance = (y1: number, y2: number) => {
  return Math.abs(y2 - y1)
}

export function calculateDistance(x1: number, y1: number, x2: number, y2: number) {
  const distanceX = x2 - x1
  const distanceY = y2 - y1
  const distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY)
  return distance
}
export function customPercentage(array: ITitleValueNumber[], dataTotal: number) {
  const percentCustom = array.map((data) => {
    return { ...data, percentCustom: (data.value * 100) / dataTotal }
  })
  return percentCustom
}

export const calculateAdjustedPointInitial = (angleInitial: number, distance: number) => {
  let angleUpdate = angleInitial

  if (distance >= 1) {
    if (distance >= 150) {
      angleUpdate += 10
    } else if (distance < 150 && distance >= 0) {
      const incrementalValue = 10 * (distance / 150)
      angleUpdate += incrementalValue >= 1 ? incrementalValue : 1
    }
  }
  return angleUpdate
}

const calculateAdjustedZySubtractionLeftY = ({ Zy, y, labelMidRadius, firstData, title }: ICalculateAdjustedZySubtractionLeftY) => {
  let distance = calculateVerticalDistance(Zy, y)
  let prevDistance = distance
  let adjustedZy = Zy

  while (distance > labelMidRadius && !firstData && prevDistance >= distance) {
    adjustedZy = adjustedZy -= 1
    prevDistance = distance !== prevDistance ? distance : prevDistance
    distance = calculateVerticalDistance(adjustedZy, y)
  }
  return { adjustedZy }
}

const calculateAdjustedZySubtractionRightY = ({ Zy, y, labelMidRadius, firstData, title }: ICalculateAdjustedZySubtractionLeftY) => {
  let distance = calculateVerticalDistance(Zy, y)
  let prevDistance = distance
  let adjustedZy = Zy

  while (distance > labelMidRadius && !firstData && prevDistance >= distance) {
    adjustedZy = adjustedZy += 1
    prevDistance = distance !== prevDistance ? distance : prevDistance
    distance = calculateVerticalDistance(adjustedZy, y)
  }
  return { adjustedZy }
}

const calculateAdjustedZyIncrementRightY = ({ Zy, y, labelMidRadius, firstData, title }: calculateAdjustedZyIncrementY) => {
  let distance = calculateVerticalDistance(Zy, y)
  let prevDistance = distance
  let adjustedZy = Zy

  const step = 1
  while (labelMidRadius <= distance && !firstData && prevDistance >= distance) {
    adjustedZy += step
    prevDistance = distance !== prevDistance ? distance : prevDistance
    distance = calculateVerticalDistance(adjustedZy, y)
  }

  return { adjustedZy }
}

const calculateAdjustedZyIncrementLeftY = ({ Zy, y, labelMidRadius, firstData, title }: calculateAdjustedZyIncrementY) => {
  let distance = calculateVerticalDistance(Zy, y)
  let prevDistance = distance
  let adjustedZy = Zy

  const step = 1
  while (labelMidRadius >= distance && !firstData && prevDistance <= distance) {
    adjustedZy += step
    prevDistance = distance !== prevDistance ? distance : prevDistance
    distance = calculateVerticalDistance(adjustedZy, y)
  }

  return { adjustedZy }
}

export const calcualteAdjustedincrementZx = ({ Zy, viewPointY, distance, Zx, labelRadius, range }: ICalcualteAdjustedincrementZx) => {
  const approximateDistance = distance - labelRadius
  let ajustexZx = Zx
  let ajustedEndX = 0

  if (Zy > viewPointY / 2 && approximateDistance > 0) {
    ajustexZx = ajustexZx - approximateDistance * range
    ajustedEndX = ajustexZx + 10
  }
  return { ajustexZx, ajustedEndX }
}

export const calculateAdjustedintervalZx = ({
  nextLabel,
  prevLabel,
  Zx,
  endX,
  actived,
  staticIncrement,
  title,
}: propsAjustedIntervalZx) => {
  let ajustedZx = Zx
  let ajustedEndX = endX
  let titleResponsiveX = title.length <= 5 ? 17 : 8

  if (actived && nextLabel.length > prevLabel.length) {
    ajustedZx = Zx - nextLabel.length * 8 - staticIncrement
    ajustedEndX = ajustedZx + 10
  } else if (actived) {
    ajustedZx = Zx - prevLabel.length * titleResponsiveX - staticIncrement
    ajustedEndX = ajustedZx + 10
  }
  return { ajustedZx, ajustedEndX }
}

export function textOverlapCorrectionFunction({
  data,
  firstData,
  cos,
  percent,
  labelRadius,
  distance,
  startX,
  outerRadius,
  cy,
  sin,
  middleY,
  fontSize,
  endX,
  Zy,
  Zx,
  y,
  diferenceRadius,
  evenData,
  labelMidRadius,
  title,
  prevDataTitle,
  nextDataTile,
  prevPercent,
  labelRadiusSubtractionY,
  prevOverlap,
  prevCos,
  viewPointY,
  textAnchorAjusted,
}: ConditionsArguments): ConditionResult {
  const initialResult: ConditionResult = {
    colortest: 'black',
    prevOverlap: 'noOverlap',
    activeTextLine: true,
    endX,
    startX,
    middleY,
    Zy,
    Zx,
    textAnchorAjusted,
    cos,
  }

  let result: ConditionResult = { ...initialResult }

  if (data.length > 10 && y !== undefined && !firstData) {
    if (title === '')
      console.log(
        { firstData },
        { cos },
        { percent },
        { labelRadius },
        { distance },
        { startX },
        { outerRadius },
        { cy },
        { sin },
        { middleY },
        { fontSize },
        { endX },
        { Zy },
        { Zx },
        { y },
        { diferenceRadius },
        { evenData },
        { labelMidRadius },
        { title },
        { prevDataTitle },
        { nextDataTile },
        { prevPercent },
        { labelRadiusSubtractionY },
        { prevOverlap },
        { prevCos },
        { viewPointY },
        { textAnchorAjusted }
      )

    if (cos > 0 && sin > 0 && distance > labelRadius) {
      const { adjustedZy } = calculateAdjustedZyIncrementRightY({
        Zy,
        y,
        firstData,
        title,
        labelMidRadius: distance / 1.3,
      })

      result = {
        ...result,
        middleY: adjustedZy - 7,
        Zy: adjustedZy,
      }
    } else if (
      (cos > 0 && sin < 0 && prevCos < 0 && y < viewPointY && labelMidRadius > distance && Zy < y) ||
      (cos > 0 && sin < 0 && prevCos < 0 && y >= viewPointY - 7 && labelMidRadius < distance && Zy < y)
    ) {
      const prevOverlapUpdate = 'overlapDataInvertZyViewPorty'
      let adjustedEndXInvert = endX
      let ZxAjusted = Zx
      let texAnchor: 'start' | 'end' = 'start'
      const adjustedZy = viewPointY - 8

      if (adjustedZy > y && labelMidRadius > distance) {
        adjustedEndXInvert = startX + (cos <= 0 ? 1 : -1) * 2 - 30
        ZxAjusted = adjustedEndXInvert + (cos <= 0 ? 1 : -1) * 5
        texAnchor = 'end'
      }
      result = {
        ...result,
        Zy: adjustedZy,
        middleY: adjustedZy - 7,
        prevOverlap: prevOverlapUpdate,
        endX: adjustedEndXInvert,
        Zx: ZxAjusted,
        textAnchorAjusted: texAnchor,
      }
    } else if (prevCos < 0 && cos > 0 && labelMidRadius < distance && percent < 0.5 && y < viewPointY - 30) {
      const prevOverlapUpdate = 'overlapDataInvertZx'
      let adjustedEndXInvert = startX + (cos <= 0 ? 1 : -1) * 2 - 30
      let Zx = adjustedEndXInvert + (cos <= 0 ? 1 : -1) * 5
      const { adjustedZy } = calculateAdjustedZySubtractionLeftY({ Zy, y, labelMidRadius: labelRadiusSubtractionY, firstData, title })
      const { ajustexZx, ajustedEndX } = calcualteAdjustedincrementZx({
        sin,
        distance: startX,
        endX: adjustedEndXInvert,
        title,
        Zx,
        Zy: adjustedZy,
        viewPointY,
        labelRadius,
        range: 0.1,
      })

      const cosUpdate = cos * -1
      result = {
        ...result,
        Zx: ajustexZx,
        endX: ajustedEndX,
        Zy: adjustedZy,
        middleY: adjustedZy - 6,
        textAnchorAjusted: 'end',
        cos: cosUpdate,
        prevOverlap: prevOverlapUpdate,
      }
    } else if (
      (prevCos < 0 && cos > 0 && labelMidRadius > distance && percent < 0.5) ||
      (prevCos < 0 && cos > 0 && labelMidRadius < distance && percent < 0.5 && y < viewPointY - 7)
    ) {
      const prevOverlapUpdate = 'overlapDataInvertZxAndZy'
      let adjustedEndXInvert = startX + (cos <= 0 ? 1 : -1) * 2 - 30
      let Zx = adjustedEndXInvert + (cos <= 0 ? 1 : -1) * 5
      const { adjustedZy } = calculateAdjustedZyIncrementLeftY({
        Zy,
        y,
        firstData,
        title,
        labelMidRadius: labelMidRadius + (prevOverlap !== prevOverlapUpdate ? 4 : 0),
      })
      const ajustedViewPointZy = y <= viewPointY - 30 ? adjustedZy : viewPointY - 7

      const { ajustexZx, ajustedEndX } = calcualteAdjustedincrementZx({
        sin,
        distance: startX,
        endX: adjustedEndXInvert,
        title,
        Zx,
        Zy: ajustedViewPointZy,
        viewPointY,
        labelRadius,
        range: 0.1,
      })

      const cosUpdate = cos * -1

      result = {
        ...result,
        Zx: ajustexZx,
        endX: ajustedEndX,
        Zy: ajustedViewPointZy,
        middleY: ajustedViewPointZy - 6,
        textAnchorAjusted: 'end',
        cos: cosUpdate,
        prevOverlap: prevOverlapUpdate,
      }
    } else if (cos > 0 && sin < 0 && labelRadius < distance && prevCos > 0) {
      const prevOverlapUpdate = 'overlapIncrementRight'
      const { adjustedZy } = calculateAdjustedZySubtractionRightY({ Zy, y, labelMidRadius: labelRadiusSubtractionY, firstData, title })

      result = {
        ...result,
        Zy: adjustedZy,
        colortest: '',
        middleY: adjustedZy - 6,
        prevOverlap: prevOverlapUpdate,
      }
    } else if (cos < 0 && sin < 0.2 && percent <= prevPercent && labelRadius < distance && Zy > y) {
      const prevOverlapUpdate = 'overlapDataSubstraction'

      const { adjustedZy } = calculateAdjustedZySubtractionLeftY({ Zy, y, labelMidRadius: labelRadiusSubtractionY, firstData, title })

      const { ajustexZx, ajustedEndX } = calcualteAdjustedincrementZx({
        sin,
        distance,
        endX,
        title,
        Zx,
        Zy: adjustedZy,
        viewPointY,
        labelRadius,
        range: 0.5,
      })

      result = {
        ...result,
        prevOverlap: prevOverlapUpdate,
        Zy: adjustedZy,
        middleY: adjustedZy - 6,
        Zx: ajustexZx,
        endX: ajustedEndX,
      }
    } else if ((cos < 0 && percent < 0.05 && labelRadius > distance) || (Zy < y && percent < 0.05 && cos < 0)) {
      const prevOverlapUpdate = 'overlapDataIncrement'
      Zy = Zy < y ? y + 1 : y

      const { adjustedZy } = calculateAdjustedZyIncrementLeftY({
        Zy,
        y,
        firstData,
        title,
        labelMidRadius: labelMidRadius + (prevOverlap !== prevOverlapUpdate ? 2 : 0),
      })
      const { ajustedZx, ajustedEndX } = calculateAdjustedintervalZx({
        prevLabel: prevDataTitle,
        nextLabel: nextDataTile,
        Zx,
        endX,
        actived: evenData,
        staticIncrement: 30,
        title,
      })

      result = {
        ...result,
        prevOverlap: prevOverlapUpdate,
        Zy: prevCos > 0 ? 15 : adjustedZy < viewPointY - 10 ? adjustedZy : viewPointY - 6,
        middleY: prevCos > 0 ? 13 : adjustedZy < viewPointY - 10 ? adjustedZy - 6 : viewPointY - 8,
        endX: ajustedEndX,
        Zx: ajustedZx,
      }
    }
  } else if (result.prevOverlap === 'noOverlap') {
    result = {
      ...result,
    }
  }

  return result
}

export function removeItemsFromIndex(array: previousDataProps[], index: number) {
  if (index < 0 || index >= array.length) {
    return array
  }

  return [...array.slice(0, index)]
}
