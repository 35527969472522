import './lc-risk-balloon.scss'
import { CSSProperties, FC, HTMLAttributes } from 'react'
import ClassNames from 'classnames'

export interface Props {
  value: number
  customStyle?: CSSProperties
}

export const LCRiskBalloon: FC<Props & HTMLAttributes<HTMLDivElement>> = ({ value, customStyle, ...props }: Props) => {
  const wrapper = ClassNames({
    balloonWrapper: true,
    veryLow: value <= 25,
    low: value > 25 && value <= 50,
    medium: value > 50 && value <= 75,
    high: value > 75,
  })

  return (
    <div className={wrapper} style={customStyle} {...props}>
      {Math.round(value * 100) / 100} %
    </div>
  )
}
