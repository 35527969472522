import './lc-modal.scss'
import Modal from 'react-modal'
import { CSSProperties, FC, HTMLAttributes, useEffect, useState } from 'react'
import { LCSvg } from '../lc-svg/lc-svg'

export interface Props extends HTMLAttributes<any> {
  isOpen: boolean
  title: string
  customStyle?: CSSProperties
  inlineStyle?: CSSProperties
  onClose: () => void
}

Modal.setAppElement('#root')

export const LCModal: FC<Props> = ({ customStyle, inlineStyle, children, title, isOpen = false, onClose, ...props }: Props) => {
  const [modalOpen, setModalOpen] = useState(isOpen)

  useEffect(() => {
    setModalOpen(isOpen)
  }, [isOpen])

  const closeModal = () => {
    setModalOpen(false)
    onClose()
  }

  return (
    <div style={customStyle} {...props}>
      <Modal isOpen={modalOpen} className="Modal" overlayClassName="Overlay" contentLabel="Example Modal">
        <div style={inlineStyle} className="lc-modal">
          <div className="lc-modal-header">
            <h3>{title}</h3>
            <LCSvg name="xIcon" widthAndHeight={[12, 12]} onClick={() => closeModal()} />
          </div>
          <div className="lc-modal-content">{children}</div>
        </div>
      </Modal>
    </div>
  )
}
