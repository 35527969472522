import { CSSProperties, FC, HTMLAttributes, lazy, useEffect, useState } from 'react'
import { BarChartLayout, BarColor, CustomLabels } from '../../../../../interfaces/dashboard.interface'
import { useLazyGetRisksPerTopicQuery } from '../../../../services/dashboard.service'
import { ITitleValueNumber, ISearch, eSearchType } from '@logicalcommander/types'
import { useTranslation } from 'react-i18next'
import { BaseDashboardComponent } from '../../../../ui/components/base-dashboard/BaseDashboardComponent'
import { DashboardComponentTitle } from '../../../../ui/components/base-dashboard/DashboardComponentTitle'
const LCBarChartNew = lazy(() => import('../../../../ui/components/charts/lc-bar-chart'))

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

const RiskHrDashboardRisksPerTopicNew: FC<Props> = ({ customStyle }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.riskHr.riskPerTopicNew' })
  const [getDashboardRisksPerTopic, { data, error, isLoading }] = useLazyGetRisksPerTopicQuery()
  const [risksPerTopic, setRisksPerTopic] = useState<ITitleValueNumber[]>([])

  useEffect(() => {
    const search: ISearch[] = [{ keyName: 'aaaa', type: eSearchType.DATE_AFTER, value: '123213' }]
    getDashboardRisksPerTopic({ search })
  }, [])

  useEffect(() => {
    if (!error && data) {
      const sorted = [...data].sort(sortByRisk)
      setRisksPerTopic(sorted)
    }
  }, [data, error])

  const sortByRisk = (a: ITitleValueNumber, b: ITitleValueNumber) => {
    return b.value - a.value
  }

  return (
    <BaseDashboardComponent customStyle={customStyle} sectionTitle={<DashboardComponentTitle title={t('title')} subTitle={t('total')} />} isLoading={isLoading} isSuccess={risksPerTopic.length > 0} isError={!!error}>
      <LCBarChartNew
        data={risksPerTopic}
        options={{
          layout: BarChartLayout.VERTICAL,
          color: BarColor.ALERT,
          label: CustomLabels.ALERT_TOTAL,
        }}
      />
    </BaseDashboardComponent>
  )
}

export default RiskHrDashboardRisksPerTopicNew
