import './ModulePurchase.scss'
import { LCWidgetProduct } from './LCWidgetProduct'
import { purchaseMock } from './mockdataPurchase'
import { ProviderContextService } from './useContextService'
import { ModulePurchase } from './ModulePurchase'

interface Props {
  moduleName: string
}

export const PurchaseModule = ({ moduleName }: Props) => {
  const data = purchaseMock[`${moduleName}`]

  return (
    <div className="container-module-purchase" key={moduleName}>
      <ProviderContextService>
        <div className="module-purchase">
          <h3 className="title-product-module">{moduleName}</h3>
          <div className="container-product">
            {data.map((item, i) => (
              <LCWidgetProduct data={item} key={`module-puches-${item.title}-${i}`} />
            ))}
          </div>
          <div className="container-purchase">
            <ModulePurchase />
          </div>
        </div>
      </ProviderContextService>
    </div>
  )
}
