import './lc-radio-button.scss'
import React, { useState, ChangeEvent } from 'react'

export enum eLCRadioButtonLayout {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

type RadioButtonProps = {
  options: { label: string; value: string }[]
  name: string // Used to group radio buttons together
  layout?: eLCRadioButtonLayout
  selected?: string
  onChange: (value: string) => void // Callback function to handle change
}

export const LCRadioButton: React.FC<RadioButtonProps> = ({ options, name, layout, selected, onChange }) => {
  // State to keep track of the selected option
  const [selectedOption, setSelectedOption] = useState<string>(selected || '')

  // Handle change event for radio button
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value)
    onChange(event.target.value) // Call the passed onChange handler
  }

  return (
    <div className={`radio-button-wrapper ${layout?.toLocaleLowerCase()}`}>
      {options.map((option, index) => (
        <label key={index} className="label">
          <input
            className="input"
            type="radio"
            name={name}
            value={option.value}
            checked={selectedOption === option.value}
            onChange={handleChange}
          />
          {option.label}
        </label>
      ))}
    </div>
  )
}
