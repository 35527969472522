import './risk-hr-case.scss'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import React, { CSSProperties, FC, HTMLAttributes, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import 'react-vertical-timeline-component/style.min.css'
import { RiskHrEvaluationDetailedCard } from '../../components/risk-hr-evaluation-detailed-card/RiskHrEvaluationDetailedCard'
import { CaseDetails } from './components/CaseDetails/CaseDetails'
import { User } from '../../../user-detailed-page/user-results-detailed-page'
import { useTranslation } from 'react-i18next'
import { useGetSpecificCaseMutation } from '../../../../services/cases.service'
import { LCPage } from '../../../../ui/components/lc-page/LCPage'
import { LCTabs } from '../../../../ui/components/lc-tabs/lc-tabs'
import { IRiskHRCase } from '@logicalcommander/types'
import { faWindowMaximize } from '@fortawesome/free-solid-svg-icons/faWindowMaximize'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

export const RiskHrCase: FC<Props> = ({ customStyle }: Props) => {
  const [getRiskHrCase, { data, error }] = useGetSpecificCaseMutation()
  const [rhCase, setRhCase] = useState<IRiskHRCase>()
  const params = useParams()
  const { t } = useTranslation('translation', { keyPrefix: 'risk_hr_case' })

  useEffect(() => {
    if (!error && data) {
      setRhCase(data)
    }
  }, [data, error])

  useEffect(() => {
    getRiskHrCase({ caseId: params.caseId })
  }, [])

  return (
    <LCPage icon={faWindowMaximize} title={t('page_title')} breadcrumbs={t('page_breadcrumbs')}>
      {rhCase ? (
        <div className="tabs">
          <LCTabs
            tabs={[
              {
                id: '1',
                title: t('tabs.case_details'),
                component: <CaseDetails rhCaseData={rhCase} />,
              },
              {
                id: '2',
                title: t('tabs.evaluation_details'),
                component: (
                  <RiskHrEvaluationDetailedCard hidePageHeader={true} identity={rhCase.identity} evaluationId={rhCase.evaluationId} />
                ),
              },
              {
                id: '3',
                title: t('tabs.user_details'),
                component: <User identity={rhCase.identity} />,
              },
              {
                id: '4',
                title: t('tabs.comparison_report'),
                component: <User identity={rhCase.identity} />,
              },
            ]}
          />
        </div>
      ) : null}
    </LCPage>
  )
}
