import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { addPDFHeader } from './addPDFHeaderFooter'
import { IExportPDFData } from '../LCTableServerPaginationHelper'

export const generateTablePDF = (columns: any, data: any, fileType: string, fileName: string, additionalData: IExportPDFData) => {
  return new Promise<void>((resolve) => {
    const headers = columns.map((i: any) => i.Header)

    const doc = new jsPDF({
      orientation: additionalData.orientation || 'portrait',
      unit: 'mm',
      format: 'a4',
    })
    doc.setFont('Helvetica', 'normal')
    let currentHeight = addPDFHeader(doc, additionalData)

    // Title
    if (additionalData.company.title) {
      doc.setFont('Helvetica', 'bold')
      console.log('doc.getFontList()', doc.getFontList())
      doc.setFontSize(20)
      doc.setTextColor('#000000')
      const pageWidth = doc.internal.pageSize.getWidth()
      const xPosition = pageWidth / 2
      currentHeight += 10
      doc.text(additionalData.company.title, xPosition, currentHeight, { align: 'center' })
    }

    // Build the table
    doc.setFont('Helvetica', 'normal')
    autoTable(doc, {
      theme: 'striped',
      startY: currentHeight + 6,
      head: [headers],
      body: data,
      margin: { horizontal: 10 },
      styles: {
        fontSize: 10, // Set the font size for the table content
      },
      headStyles: {
        fontSize: 10, // Set the font size for the table headers
      },
      footStyles: {
        fontSize: 10, // Set the font size for the table footers if any
      },
    })

    // addPDFFooter(doc)

    const pdfData = doc.output('datauristring')
    const pdfWindow = window.open()
    pdfWindow?.document.write(`<iframe width='100%' height='100%' src='${pdfData}'></iframe>`)

    resolve()
  })
}
