import { SVGProps } from 'react'

const SvgDanger = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M10 6.25V10M10 12.625V13" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" />
    <path
      d="M1.208 13.762 8.515 1.789c.641-1.052 2.33-1.052 2.972 0l7.305 11.973C19.405 14.77 18.59 16 17.304 16H2.694c-1.284 0-2.1-1.23-1.485-2.238h0Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgDanger
