import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import generalReducer from './reducers/generalSlice'
import authReducer from './reducers/authSlice'
import evaluationsReducer from './reducers/evaluationsSlice'
import assessmentsReducer from './reducers/assessmentsSlice'
import settingsReducer from './reducers/settingsSlice'
import filtersReducer from './reducers/filtersSlice'
import extendedCompanyReducer from './reducers/extendedCompanySlice'
import { authApi } from '../services/auth.service'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import { unauthenticatedMiddleware } from './middleWares/unauthenticatedMiddleware'
import { usersApi } from '../services/users.service'
import { userApi } from '../services/user.service'
import { notificationsApi } from '../services/notifications.service'
import { evaluationsApi } from '../services/evaluations.service'
import { settingsApi } from '../services/settings.service'
import { emoApi } from '../services/emo.service'
import { casesApi } from '../services/cases.service'
import { dashboardApi } from '../services/dashboard.service'
import { mailerApi } from '../services/mailer.service'
import { rolesApi } from '../services/roles.service'
import { permissionsApi } from '../services/permissions.service'
import { assessmentsApi } from '../services/assessments.service'
import { companyApi } from '../services/company.service'
import { activityApi } from '../services/activitylog.service'
import { registerApi } from '../services/register.services'
import { acceptanceApi } from '../services/acceptance.service'

export const store = configureStore({
  reducer: {
    generalReducer,
    authReducer,
    evaluationsReducer,
    assessmentsReducer,
    settingsReducer,
    extendedCompanyReducer,
    filtersReducer,

    // Add the generated reducer as a specific top-level slice
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
    [evaluationsApi.reducerPath]: evaluationsApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [companyApi.reducerPath]: companyApi.reducer,
    [casesApi.reducerPath]: casesApi.reducer,
    [rolesApi.reducerPath]: rolesApi.reducer,
    [permissionsApi.reducerPath]: permissionsApi.reducer,
    [emoApi.reducerPath]: emoApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
    [mailerApi.reducerPath]: mailerApi.reducer,
    [assessmentsApi.reducerPath]: assessmentsApi.reducer,
    [activityApi.reducerPath]: activityApi.reducer,
    [acceptanceApi.reducerPath]: acceptanceApi.reducer,
    [registerApi.reducerPath]: registerApi.reducer,
  },

  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(unauthenticatedMiddleware)
      .concat(authApi.middleware)
      .concat(userApi.middleware)
      .concat(notificationsApi.middleware)
      .concat(evaluationsApi.middleware)
      .concat(dashboardApi.middleware)
      .concat(companyApi.middleware)
      .concat(casesApi.middleware)
      .concat(rolesApi.middleware)
      .concat(permissionsApi.middleware)
      .concat(emoApi.middleware)
      .concat(settingsApi.middleware)
      .concat(assessmentsApi.middleware)
      .concat(mailerApi.middleware)
      .concat(usersApi.middleware)
      .concat(activityApi.middleware)
      .concat(acceptanceApi.middleware)
      .concat(registerApi.middleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
setupListeners(store.dispatch)
