import React, { useState } from 'react'
import { DeleteUserModal } from '../deleteUserModal/DeleteUserModal'
import { IAdvancedFilterValuesStructure } from '../../../ui/components/advanced-filter/advanced-filter-add-item/LCAdvancedFilterAddItem'
import { LCFlag } from '../../../ui/components/lc-flag/LCFlag'
import { LCSvg } from '../../../ui/components/lc-svg/lc-svg'
import { ILCActionPopupItemData, LCActionPopup } from '../../../ui/components/lc-action-popup/LCActionPopup'
import { LCModal } from '../../../ui/components/lc-modal/LCModal'
import { IAssessmentSchemaInfo, IRolesAndPermissions } from '@logicalcommander/types'
import { AssignEvaluationModal } from '../../../ui/awareComponents/lc-assign/LCAssignModal'
import { getCountryNameByCode } from '../../../utils/general'
import LCAwareOthersImageProfile from '../../../ui/awareComponents/lc-profile-image/LCAwareOthersImageProfile'
import { LCNameLetters } from '../../../ui/components/lc-name-letters/LCNameLetters'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons/faTrashCan'
import { faNewspaper } from '@fortawesome/free-solid-svg-icons/faNewspaper'
import { faUserPen } from '@fortawesome/free-solid-svg-icons/faUserPen'
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser'
import { OptionType } from '../../../ui/components/forms/lc-form-inputText-roles/lc-form-inputText-roles'
import { getAllCountriesDropdown, getAllRolesDropdown } from '../../../utils/list-helper'

export interface IUserSummary {
  identity: string
  profileImage: string
  firstName: string
  middleName: string
  lastName: string
  fullName: string
  mobile: string
  positionId: number
  roles: string[]
  nationality: string
}

export const users_columns = (
  navigate: any,
  t: any,
  allPositionsOptions: OptionType[],
  rolesAndPermissions: IRolesAndPermissions[] | undefined,
  onDeleteUser: (user: IUserSummary) => void
) => [
  // {
  //   Header: t('columns.select'),
  //   Cell: (row: any) => {
  //     const user: IUser = row.row.original
  //     return (
  //       <Link className={'user-details-link'} to={`/user/${user.identity}`}>
  //         <LCSvg name={'details'} />
  //       </Link>
  //     )
  //   },
  //   filter: {
  //     hide: true,
  //   } as IAdvancedFilterValuesStructure,
  // },
  {
    Header: t('columns.identity'),
    accessor: 'identity',
  },
  {
    Header: t('columns.image'),
    accessor: 'profileImage',
    Cell: (row: any) => {
      const user: IUserSummary = row.row.original
      if (user.profileImage) {
        return <LCAwareOthersImageProfile identity={user.identity} />
      }
      return <LCNameLetters firstName={user.firstName} lastName={user.lastName} />
    },
    disableExport: true,
    disableSortBy: true,
    filter: {
      hide: true,
    } as IAdvancedFilterValuesStructure,
  },
  {
    Header: t('columns.fullName'),
    accessor: 'firstName,middleName,lastName',
    Cell: (row: any) => {
      const user: IUserSummary = row.row.original
      return (
        <button style={{ textAlign: 'left' }} className="link-button" onClick={() => navigate(`/app/user/${user.identity}`)}>
          {user.fullName}
        </button>
      )
    },
    getCellExportValue: (row: any, column: any) => {
      const user: IUserSummary = row.original
      return user.fullName
    },
    disableSortBy: true,
  },
  {
    Header: t('columns.mobile'),
    accessor: 'mobile',
  },
  {
    Header: t('columns.position'),
    accessor: 'positionId',
    Cell: (row: any) => {
      const user: IUserSummary = row.row.original
      const positionId = user?.positionId
      const position: OptionType | undefined = allPositionsOptions.find((i) => i.value === String(positionId))
      return <div> {position?.label || ''} </div>
    },
    getCellExportValue: (row: any, column: any) => {
      const user: IUserSummary = row.original
      const positionId = user?.positionId
      const position: OptionType | undefined = allPositionsOptions.find((i) => i.value === String(positionId))
      return position?.label || ''
    },
    filter: {
      type: 'dropdown',
      values: allPositionsOptions,
      valueTransform: (value: string) => Number(value),
    } as IAdvancedFilterValuesStructure,
  },
  {
    Header: t('columns.roles'),
    accessor: 'roles',
    Cell: (row: any) => {
      const user: IUserSummary = row.row.original
      return <div> {user.roles.join(', ')} </div>
    },
    getCellExportValue: (row: any, column: any) => {
      const user: IUserSummary = row.original
      return user.roles.join(', ')
    },
    filter: {
      type: 'dropdown',
      values: rolesAndPermissions && getAllRolesDropdown(rolesAndPermissions),
    } as IAdvancedFilterValuesStructure,
  },
  {
    Header: t('columns.nationality'),
    accessor: 'nationality',
    Cell: (row: any) => {
      const user: IUserSummary = row.row.original
      return user?.nationality ? <LCFlag size="xsmall" flagCode={user?.nationality} /> : ''
    },
    getCellExportValue: (row: any, column: any) => {
      const user: IUserSummary = row.original
      return user?.nationality ? getCountryNameByCode(user?.nationality) : ''
    },
    filter: {
      type: 'dropdown',
      values: getAllCountriesDropdown(),
    } as IAdvancedFilterValuesStructure,
  },
  {
    Header: t('columns.action'),
    Cell: (row: any) => {
      const [modalOpen, setModalOpen] = useState(false)
      const [assignModalOpen, setAssignModalOpen] = useState(false)

      const user: IUserSummary = row.row.original
      const actionPopupData = (user: IUserSummary, navigate: any): ILCActionPopupItemData[] => {
        return [
          {
            text: t('actions.view_user'),
            newIcon: faUser,
            action: () => navigate(`/app/user/view/${user.identity}`),
          },
          {
            text: t('actions.edit_user'),
            newIcon: faUserPen,
            action: () => navigate(`/app/user/edit/${user.identity}`),
          },
          {
            text: t('actions.delete_user'),
            newIcon: faTrashCan,
            action: () => setModalOpen(true),
          },
          {
            text: t('actions.assign_evaluation'),
            newIcon: faNewspaper,
            action: () => {
              setAssignModalOpen(true)
            },
          },
        ]
      }

      return (
        <div>
          <LCModal isOpen={assignModalOpen} title={t('assign_evaluation_users_title')} onClose={() => setAssignModalOpen(false)}>
            <AssignEvaluationModal
              identities={[user.identity]}
              onCloseModal={() => {
                setAssignModalOpen(false)
              }}
              onAssign={(assessmentInfo: IAssessmentSchemaInfo) => {
                setAssignModalOpen(false)
              }}
            />
          </LCModal>
          <LCModal isOpen={modalOpen} title={t('delete_user_modal_title')} onClose={() => setModalOpen(false)}>
            <DeleteUserModal
              fullName={user.fullName || ''}
              onCloseModal={() => setModalOpen(false)}
              onDeleteUser={() => {
                onDeleteUser(user)
                setModalOpen(false)
              }}
            />
          </LCModal>
          <LCActionPopup
            toggleOn={'click'}
            position={['midleft', 'bottom']}
            data={actionPopupData(user, navigate)}
            anchor={
              <div>
                <LCSvg name="3dots" />
              </div>
            }
          />
        </div>
      )
    },
    disableExport: true,
    disableSortBy: true,
    filter: {
      hide: true,
    } as IAdvancedFilterValuesStructure,
  },
]
