import React, { useState, useRef, useEffect } from 'react'
import './lc-form-inputTextEdit.scss'
import { LCSvg } from '../../lc-svg/lc-svg'
import { LCFormInputText, LCFormInputTextProps } from '../lc-form-inputText/lc-form-inputText'

interface Props<ObjectType> extends LCFormInputTextProps<ObjectType> {
  onSave: (value: string) => void
  onDelete: (value: string) => void
}

const LCFormInputTextEdit = <ObjectType,>({ onSave, onDelete, inputId, inputType, customStyle, ...props }: Props<ObjectType>) => {
  const [isEditing, setIsEditing] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [originalValue, setOriginalValue] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus()
    }
  }, [isEditing])

  useEffect(() => {
    setInputValue((props.value as string) || '')
    setOriginalValue((props.value as string) || '')
  }, [props.value])

  const toggleEdit = () => setIsEditing(true)

  const handleSave = () => {
    onSave(inputValue)
    setOriginalValue(inputValue)
    setIsEditing(false)
  }

  const handleDelete = () => {
    onDelete(inputValue)
    setIsEditing(false)
  }

  const handleCancel = () => {
    setInputValue(originalValue)
    setIsEditing(false)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  // Extracting value and onChange from props to ensure they don't override internal state management
  const { value, onChange, ...restProps } = props

  return (
    <div className="inputTextEdit-Wrapper" style={customStyle}>
      <LCFormInputText
        inputId={String(inputId)}
        inputType={inputType}
        disabled={!isEditing}
        value={inputValue}
        className={`inputText-input ${!isEditing ? 'disabled' : ''}`}
        onChange={handleInputChange}
        {...restProps}
      />

      <div className="inputText-icons">
        {!isEditing ? (
          <>
            <LCSvg name="action-edit" onClick={toggleEdit} />
            <LCSvg name="action-delete" onClick={handleDelete} />
          </>
        ) : (
          <>
            <LCSvg name="action-save" onClick={handleSave} />
            <LCSvg name="action-cancel" onClick={handleCancel} />
          </>
        )}
      </div>
    </div>
  )
}

export default LCFormInputTextEdit
