import React, { FC, HTMLAttributes, lazy, useEffect, useState } from 'react'
import { CSSProperties } from 'styled-components'
import { sum } from 'lodash'
import { useTranslation } from 'react-i18next'
import { BarColor, CustomLabels } from '../../../../../interfaces/dashboard.interface'
import { useLazyGetRiskHrRiskLevelQuery } from '../../../../services/dashboard.service'
import { ITitleValueNumber } from '@logicalcommander/types'
import { BaseDashboardComponent } from '../../../../ui/components/base-dashboard/BaseDashboardComponent'
import { DashboardComponentTitle } from '../../../../ui/components/base-dashboard/DashboardComponentTitle'

const LCPieChartUniNew = lazy(() => import('../../../../ui/components/charts/lc-pie-chart'))

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

const RiskHrDashboardRiskLevel: FC<Props> = ({ customStyle }: Props) => {
  const [getRiskHRRiskLevel, { data, error, isLoading }] = useLazyGetRiskHrRiskLevelQuery()
  const [riskLevelData, setRiskLevelData] = useState<ITitleValueNumber[]>([])
  const [sumRiskLevelData, setSumRiskLevelData] = useState(0)
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.riskHr.riskLevel' })

  useEffect(() => {
    getRiskHRRiskLevel()
  }, [])

  useEffect(() => {
    if (!error && data) {
      const { high, medium, low } = data
      const sumSorted = sum(Object.values(data))
      setSumRiskLevelData(sumSorted)
      setRiskLevelData([
        // { title: t('critical_risk'), value: critical },
        { title: 'High', value: high },
        { title: 'Medium', value: medium },
        { title: 'Low', value: low },
      ])
    }
  }, [data, error])

  return (
    <BaseDashboardComponent
      customStyle={customStyle}
      sectionTitle={<DashboardComponentTitle title={t('title')} subTitle={`${t('total')} ${sumRiskLevelData}`} />}
      isLoading={isLoading}
      isSuccess={riskLevelData && riskLevelData.length > 0}
      isError={!!error}>
      <LCPieChartUniNew
        data={riskLevelData}
        dataTotal={sumRiskLevelData}
        options={{
          color: BarColor.COLOR_RISK_LEVEL,
          customPercentage: false,
          label: CustomLabels.ALERT_RISK_LEVEL,
        }}
      />
    </BaseDashboardComponent>
  )
}

export default RiskHrDashboardRiskLevel
