import { ISvgNames } from '../../../ui/components/lc-svg/lc-svg'

export interface SideMenuItem {
  key: string
  label: string
  iconName?: ISvgNames
  newIcon?: any
  navigate?: string
  subTabs?: SideMenuItem[]
  action?: () => void
}

export const settingsTabsMenuData = (t: any): SideMenuItem[] => [
  {
    key: 'company-info',
    label: t('company-info'),
    navigate: '/app/settings/company-info',
  },
  {
    key: 'management',
    label: t('management'),
    subTabs: [
      {
        key: 'management-admins',
        label: t('management-admins'),
        navigate: '/app/settings/management-admins',
      },
      {
        key: 'management-permissions',
        label: t('management-permissions'),
        navigate: '/app/settings/management-permissions',
      },
      {
        key: 'management-permissions-by-role',
        label: t('management-permissions-by-role'),
        navigate: '/app/settings/management-permissions-by-role',
      },
    ],
  },
  {
    key: 'organizational-structure',
    label: t('organizational-structure'),
    navigate: '/app/settings/organizational-structure',
  },
  {
    key: 'emails',
    label: t('emails'),
    navigate: '/app/settings/emails',
  },
  {
    key: 'ux',
    label: t('ux'),
    navigate: '/app/settings/ux',
  },
  {
    key: 'moduleRiskHr',
    label: t('moduleRiskHr'),
    subTabs: [
      {
        key: 'modules-risk-hr-assessments',
        label: t('modules-risk-hr-assessments'),
        navigate: '/app/settings/modules-risk-hr-assessments',
      },
      {
        key: 'modules-risk-hr-severity',
        label: t('modules-risk-hr-severity'),
        navigate: '/app/settings/modules-risk-hr-severity',
      },
      {
        key: 'modules-risk-hr-impact',
        label: t('modules-risk-hr-impact'),
        navigate: '/app/settings/modules-risk-hr-impact',
      },
    ],
  },
  {
    key: 'modules-emo-risk',
    label: t('modules-emo-risk'),
  },
  {
    key: 'purchase',
    label: t('purchase'),
    subTabs: [
      {
        key: 'purchase-invoices',
        label: t('purchase-invoices'),
        navigate: '/app/settings/purchase-invoices',
      },
      {
        key: 'purchase-to-approve',
        label: t('purchase-to-approve'),
        navigate: '/app/settings/purchase-to-approve',
      },
      {
        key: 'purchase-my-requests',
        label: t('purchase-my-requests'),
        navigate: '/app/settings/purchase-my-requests',
      },
    ],
  },
]
