import { CSSProperties, FC, HTMLAttributes, useEffect, useMemo, useState } from 'react'
import { IDropdownOption } from '../../forms/lc-form-dropDown/lc-form-dropDown'
import { IAdvancedFilterValuesStructure } from './LCAdvancedFilterAddItem'
import { eSearchType } from '@logicalcommander/types'
import { IExtendedColumn } from '../../../../pages/riskhr/riskHrEvaluations/columns/riskHrEvaluations.columns'
import { LCFormDropdownDynamic } from '../../forms/lc-form-dropdown-dynamic/lc-form-dropdown-dynamic'

export interface Props extends HTMLAttributes<any> {
  column: IExtendedColumn
  formik: any // FormikConfig<FormikValues>
  customStyle?: CSSProperties
}

export const LCAdvancedFilterAddItemOperator: FC<Props> = ({ column, formik, customStyle }: Props) => {
  const [selectedColumn, setSelectedColumn] = useState<IExtendedColumn>(column)

  const onSelectedOperator = (value: string) => {
    console.log('formik onSelectedOperator: ', value)
    formik.setFieldValue('operator', value)
  }

  useEffect(() => {
    setSelectedColumn(column)
  }, [column])

  const buildOperator = (): IDropdownOption[] => {
    if (selectedColumn.filter) {
      if (selectedColumn.filter.operatorOverride && selectedColumn.filter.operatorOverride.length > 0) {
        return selectedColumn.filter.operatorOverride
      }

      const filter: IAdvancedFilterValuesStructure = selectedColumn.filter
      switch (filter.type) {
        case 'date':
          return [
            { label: eSearchType.DATE_AFTER, value: eSearchType.DATE_AFTER },
            { label: eSearchType.DATE_BEFORE, value: eSearchType.DATE_BEFORE },
            { label: eSearchType.EXISTS, value: eSearchType.EXISTS },
          ] as IDropdownOption[]
        case 'dropdown':
          return [{ label: eSearchType.EXACTLY, value: eSearchType.EXACTLY }] as IDropdownOption[]
        case 'onlyContains':
          return [{ label: eSearchType.CONTAINS, value: eSearchType.CONTAINS }]
        case 'number':
          return [
            { label: eSearchType.LOWER, value: eSearchType.LOWER },
            { label: eSearchType.GREATER, value: eSearchType.GREATER },
            { label: eSearchType.EXACTLY, value: eSearchType.EXACTLY },
          ] as IDropdownOption[]
      }
    }
    // Default
    return [
      { label: eSearchType.CONTAINS, value: eSearchType.CONTAINS },
      { label: eSearchType.EXACTLY, value: eSearchType.EXACTLY },
      { label: eSearchType.EXISTS, value: eSearchType.EXISTS },
      { label: eSearchType.NOT_EXISTS, value: eSearchType.NOT_EXISTS },
    ] as IDropdownOption[]
  }

  const allOptions: IDropdownOption[] = useMemo(() => buildOperator(), [selectedColumn?.filter])

  // if (allOptions.length > 1) {
  return (
    <LCFormDropdownDynamic
      customStyle={customStyle}
      inputId="operator"
      selectedValue={String(formik.values.operator)}
      placeholder="Please select operator"
      setFieldValue={(key: string, value: string) => {
        onSelectedOperator(value)
      }}
      options={allOptions}
      {...formik.getFieldProps('operator')}
    />
  )
  // } else {
  //   return (
  //     <LCFormInputText<any>
  //       customStyle={customStyle}
  //       inputId="type"
  //       inputType="text"
  //       hideError={true}
  //       disabled={true}
  //       value={allOptions[0].value}
  //     />
  //   )
  // }
}
