import './lc-search.scss'
import { CSSProperties, FC, useEffect, useMemo } from 'react'
import debounce from 'lodash.debounce'
import { useTranslation } from 'react-i18next'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons/faMagnifyingGlass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  customStyle?: CSSProperties
  minimumChars?: number
  onSearch: (value: string) => void
  onClear?: () => void
}

export const LCSearch: FC<Props> = ({ customStyle, minimumChars, onSearch, onClear, ...props }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'common' })
  const classes = ['lc-search'].join(' ')

  const handleChange = (e: any) => {
    const value = e?.target?.value
    if (minimumChars) {
      value?.length >= minimumChars && onSearch(value)
    } else {
      onSearch(value)
    }
    if (value.length === 0) {
      onClear && onClear()
    }
  }

  const debouncedResults = useMemo(() => {
    return debounce(handleChange, 300)
  }, [])

  useEffect(() => {
    return () => {
      debouncedResults.cancel()
    }
  })

  return (
    <div className={classes} {...props} style={customStyle}>
      <FontAwesomeIcon icon={faMagnifyingGlass} size="xl" className="search-icon" />
      <input className="search-input" type={'text'} placeholder={t('quickSearch')} onChange={debouncedResults} />
    </div>
  )
}
