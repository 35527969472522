import React from 'react'
import './general-dashboard.scss'
import { CSSProperties, FC, HTMLAttributes } from 'react'
import GeneralDashboardPosition from './component/GeneralDashboardPosition'
import GeneralDashboardDepartament from './component/GeneralDashboardDepartament'
import GeneralDashboardWomanMen from './component/GeneralDashboardWomanMen'
import GeneralDashboardWomanMenPie from './component/GeneralDashboardWomanMenPie'
import GeneralDashboardCountry from './component/GeneralDashboardCountry'
import GeneralDashboardCity from './component/GeneralDashboardCity'
import { LCPage } from '../../ui/components/lc-page/LCPage'
import CompanyFeatures from './component/CompanyFeatures'
import CompanyInformation from './component/CompanyInformation'
import EmoRiskLicenses from './component/EmoRiskLicenses'
import RiskHrLicenses from './component/RiskHrLicenses'
import { faGauge } from '@fortawesome/free-solid-svg-icons/faGauge'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

export const GeneralDashboardPage: FC<Props> = ({ customStyle }: Props) => {
  return (
    <LCPage icon={faGauge} title="General" breadcrumbs="Dashboard">
      <div className="general-dashboard" style={customStyle}>
        <RiskHrLicenses customStyle={{ maxWidth: '620px', height: '300px' }} />
        <EmoRiskLicenses customStyle={{ maxWidth: '300px', height: '300px' }} />
        <CompanyFeatures customStyle={{ maxWidth: '300px', height: '300px' }} />
        <CompanyInformation customStyle={{ maxWidth: '620px', height: '300px' }} />
        <GeneralDashboardWomanMen customStyle={{ maxWidth: '620px', height: '300px' }} />
        <GeneralDashboardWomanMenPie customStyle={{ maxWidth: '300px', height: '240px' }} />
        <GeneralDashboardCountry customStyle={{ maxWidth: '300px', height: '240px' }} />
        <GeneralDashboardCity customStyle={{ maxWidth: '620px', height: '300px' }} />
        <GeneralDashboardPosition customStyle={{ maxWidth: '620px', height: '300px' }} />
        <GeneralDashboardDepartament customStyle={{ maxWidth: '620px', height: '300px' }} />
      </div>
    </LCPage>
  )
}
