import './cases/risk-hr-cases.scss'
import React, { CSSProperties, FC, HTMLAttributes, useEffect, useState } from 'react'
import { RiskHrCases } from './cases/RiskHrCases'
import { LCPage } from '../../../ui/components/lc-page/LCPage'
import { ISearch } from '@logicalcommander/types'
import { useTranslation } from 'react-i18next'
import { faWindowRestore } from '@fortawesome/free-solid-svg-icons/faWindowRestore'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  filters?: ISearch[]
  customStyle?: CSSProperties
}

export const RiskHrCasesPage: FC<Props> = ({ customStyle, filters: initFilters }: Props) => {
  const [filters, setFilters] = useState<ISearch[]>(initFilters || []) // Filters
  const { t } = useTranslation('translation', { keyPrefix: 'menu' })

  useEffect(() => {
    initFilters && setFilters(initFilters)
  }, [initFilters])

  return (
    <LCPage icon={faWindowRestore} title={t('risk_hr')} breadcrumbs="Risk HR > Cases">
      <RiskHrCases filters={filters} customStyle={customStyle} showTableActions={true} />
    </LCPage>
  )
}
