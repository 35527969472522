import { ISearch, IUser, eSearchType } from '@logicalcommander/types'
import { IDropdownOption } from '../../ui/components/lc-dropdown/LCDropdown'
import moment from 'moment'

export const buildIdentityFilter = (identity: string): ISearch => {
  return {
    type: eSearchType.EXACTLY,
    keyName: 'identity',
    value: identity,
  }
}

export const buildRiskHRFilters = (identity: string, date: IDropdownOption): ISearch[] => {
  const filters: ISearch[] = []
  identity && filters.push(buildIdentityFilter(identity))
  date &&
    filters.push({
      type: eSearchType.DATE_AFTER,
      keyName: 'status.created',
      value: date.value,
    })
  return filters
}

export const buildCasesFilters = (identity: string, date: IDropdownOption): ISearch[] => {
  const filters: ISearch[] = []
  identity && filters.push(buildIdentityFilter(identity))
  date &&
    filters.push({
      type: eSearchType.DATE_AFTER,
      keyName: 'createdAt',
      value: date.value,
    })
  return filters
}

export const buildEmoFilters = (identity: string, date: IDropdownOption): ISearch[] => {
  const filters: ISearch[] = []
  identity && filters.push(buildIdentityFilter(identity))
  date &&
    filters.push({
      type: eSearchType.DATE_AFTER,
      keyName: 'date',
      value: date.value,
    })
  return filters
}

export const getSubtitle = (user?: IUser) => {
  if (!user) return ''
  const result = []
  if (user?.personalDetails?.gender) {
    result.push(user?.personalDetails?.gender)
  }
  if (user?.personalDetails?.bDate) {
    const age = moment(user?.personalDetails?.bDate).fromNow()
    result.push(age)
  }
  return result.join(' ')
}

export const getUserAddress = (user?: IUser) => {
  if (!user) return ''
  let address = `${user?.personalAddress?.country || ''} ${user?.personalAddress?.city || ''} ${user?.personalAddress?.addressLine1 || ''} ${user?.personalAddress?.addressLine2 || ''}`
  address = address.trim().replace(' ', ', ')
  return address
}
