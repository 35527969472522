import { SanitizeHTML } from '../../../../ui/components/lc-sanitize-html/SanitizeHTML'
import './response-message.scss'
import React, { CSSProperties, FC, HTMLAttributes, ReactElement } from 'react'

export interface IResponseMessage extends HTMLAttributes<HTMLDivElement> {
  title: string
  html: string
  isError?: boolean
  customStyle?: CSSProperties
}

export const ResponseMessage: FC<IResponseMessage> = ({ title, html, isError, customStyle }: IResponseMessage): ReactElement => {
  return (
    <div className={`response-message${isError ? ' error' : ' success'}`} style={customStyle}>
      <div className="title">{title}</div>
      <div className="body">
        <SanitizeHTML html={html} />
      </div>
    </div>
  )
}
