export const dataToApprove: any = [
  {
    applicant: 'John Doe',
    requested_product: 'Product 1',
    application_date: '2024-03-07',
    price_of_products: '1200.50',
    currency: 'USD',
    order_request_status: {
      status: 'modification',
      description: 'reason x for change',
    },
  },
  {
    applicant: 'Jane Smith',
    requested_product: 'Product 2',
    application_date: '2024-02-15',
    price_of_products: '950.75',
    currency: 'British Pound',
    order_request_status: {
      status: 'modification',
      description: 'reason x for change',
    },
  },
  {
    applicant: 'Alice Johnson',
    requested_product: 'Product 3',
    application_date: '2024-04-22',
    price_of_products: '800.00',
    currency: 'Euro',
    order_request_status: {
      status: 'modification',
      description: 'reason x for change',
    },
  },
  {
    applicant: 'Bob Williams',
    requested_product: 'Product 4',
    application_date: '2024-01-10',
    price_of_products: '1100.25',
    currency: 'USD',
    order_request_status: {
      status: 'modification',
      description: 'reason x for change',
    },
  },
  {
    applicant: 'Charlie Davis',
    requested_product: 'Product 5',
    application_date: '2024-05-18',
    price_of_products: '1300.80',
    currency: 'Euro',
    order_request_status: {
      status: 'reject',
      description: 'reason x for change',
    },
  },
  {
    applicant: 'Eva Martin',
    requested_product: 'Product 6',
    application_date: '2024-03-30',
    price_of_products: '750.50',
    currency: 'British Pound',
    order_request_status: {
      status: 'reject',
      description: 'reason x for change',
    },
  },
  {
    applicant: 'David Wilson',
    requested_product: 'Product 7',
    application_date: '2024-06-05',
    price_of_products: '900.30',
    currency: 'Euro',
    order_request_status: {
      status: 'reject',
      description: 'reason x for change',
    },
  },
  {
    applicant: 'Grace Taylor',
    requested_product: 'Product 8',
    application_date: '2024-02-01',
    price_of_products: '1000.00',
    currency: 'British Pound',
    order_request_status: {
      status: 'reject',
      description: 'reason x for change',
    },
  },
  {
    applicant: 'Frank White',
    requested_product: 'Product 9',
    application_date: '2024-04-12',
    price_of_products: '850.20',
    currency: 'USD',
    order_request_status: {
      status: 'success',
      description: '',
    },
  },
  {
    applicant: 'Helen Anderson',
    requested_product: 'Product 10',
    application_date: '2024-05-25',
    price_of_products: '1200.75',
    currency: 'British Pound',
    order_request_status: {
      status: 'success',
      description: '',
    },
  },
  {
    applicant: 'George Harris',
    requested_product: 'Product 1',
    application_date: '2024-03-15',
    price_of_products: '950.60',
    currency: 'British Pound',
    order_request_status: {
      status: 'success',
      description: '',
    },
  },
  {
    applicant: 'Ivy Miller',
    requested_product: 'Product 2',
    application_date: '2024-01-28',
    price_of_products: '800.90',
    currency: 'British Pound',
    order_request_status: {
      status: 'success',
      description: '',
    },
  },
  {
    applicant: 'Jack Thompson',
    requested_product: 'Product 3',
    application_date: '2024-04-08',
    price_of_products: '1100.40',
    currency: 'British Pound',
    order_request_status: {
      status: 'in_progress',
      description: '',
    },
  },
  {
    applicant: 'Karen Hall',
    requested_product: 'Product 4',
    application_date: '2024-06-20',
    price_of_products: '1300.25',
    currency: 'Euro',
    order_request_status: {
      status: 'success',
      description: '',
    },
  },
  {
    applicant: 'Leo Brown',
    requested_product: 'Product 5',
    application_date: '2024-02-12',
    price_of_products: '750.80',
    currency: 'Euro',
    order_request_status: {
      status: 'in_progress',
      description: '',
    },
  },
  {
    applicant: 'Mia Clark',
    requested_product: 'Product 6',
    application_date: '2024-05-02',
    price_of_products: '900.50',
    currency: 'Euro',
    order_request_status: {
      status: 'in_progress',
      description: '',
    },
  },
  {
    applicant: 'Nathan Turner',
    requested_product: 'Product 7',
    application_date: '2024-03-10',
    price_of_products: '1000.20',
    currency: 'Euro',
    order_request_status: {
      status: 'in_progress',
      description: '',
    },
  },
  {
    applicant: 'Olivia Adams',
    requested_product: 'Product 8',
    application_date: '2024-04-30',
    price_of_products: '850.70',
    currency: 'USD',
    order_request_status: {
      status: 'success',
      description: '',
    },
  },
  {
    applicant: 'Paula Reed',
    requested_product: 'Product 9',
    application_date: '2024-01-18',
    price_of_products: '1200.30',
    currency: 'USD',
    order_request_status: {
      status: 'success',
      description: '',
    },
  },
  {
    applicant: 'Quincy Moore',
    requested_product: 'Product 10',
    application_date: '2024-06-15',
    price_of_products: '950.40',
    currency: 'USD',
    order_request_status: {
      status: 'in_progress',
      description: '',
    },
  },
]
