import React, { CSSProperties, FC, HTMLAttributes, useEffect, useState } from 'react'
import { BarColor, CustomLabels, BarChartLayout } from '../../../../../interfaces/dashboard.interface'
import LCBarChartNew from '../../../../ui/components/charts/lc-bar-chart'
import { ITitleValueNumber } from '@logicalcommander/types'
import { useTranslation } from 'react-i18next'
import { BaseDashboardComponent } from '../../../../ui/components/base-dashboard/BaseDashboardComponent'
import { DashboardComponentTitle } from '../../../../ui/components/base-dashboard/DashboardComponentTitle'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

const EmotionRiskPosition: FC<Props> = ({ customStyle }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.emoRisk.emotionRiskPosition' })
  const [dataPositionRisk, setDataPositionRisk] = useState<ITitleValueNumber[]>([])

  const isLoading = false
  const error = false

  const data = [
    {
      title: 'CFO',
      value: 50,
    },
    {
      title: 'Depression',
      value: 50,
    },
    {
      title: 'Happiness',
      value: 50,
    },
    {
      title: 'Anger',
      value: 50,
    },
    {
      title: 'Stress',
      value: 50,
    },
    {
      title: 'Excitement',
      value: 50,
    },
    {
      title: 'Sadness',
      value: 50,
    },
    {
      title: 'MentalLoad',
      value: 350,
    },
    {
      title: 'Discomfort',
      value: 74,
    },
    {
      title: 'Dissatisfaction',
      value: 60,
    },
    {
      title: 'Burnout',
      value: 90,
    },
    {
      title: 'Confidence',
      value: 90,
    },
    {
      title: 'Extreme Emotion',
      value: 90,
    },
  ]

  useEffect(() => {
    const sorted = [...data].sort(sortPositionRisk)
    setDataPositionRisk(sorted)
    if (data) {
      const sorted = [...data].sort(sortPositionRisk)
      setDataPositionRisk(sorted)
    }
  }, [])

  const sortPositionRisk = (a: ITitleValueNumber, b: ITitleValueNumber) => {
    return +b.value - +a.value
  }

  return (
    <BaseDashboardComponent customStyle={customStyle} sectionTitle={<DashboardComponentTitle title={t('title')} subTitle={t('total')} />} isLoading={isLoading} isSuccess={!!dataPositionRisk} isError={!!error}>
      <LCBarChartNew
        data={dataPositionRisk}
        options={{
          color: BarColor.COLOR_POSITION,
          label: CustomLabels.ALERT_TOTAL,
          layout: BarChartLayout.HORIZONTAL,
        }}
      />
    </BaseDashboardComponent>
  )
}

export default EmotionRiskPosition
