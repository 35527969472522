import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../../../redux/hooks'
import { selectExtendedCompany } from '../../../../../redux/reducers/extendedCompanySlice'
import './assign-licenses-info.scss'

import React, { CSSProperties, FC } from 'react'

export interface Props {
  customStyle?: CSSProperties
}

export const AssignLicensesInfo: FC<Props> = ({ customStyle }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'riskHr.assign' })
  const { licenses, licensesUsed } = useAppSelector(selectExtendedCompany)

  return (
    <div className="assign-licenses-info" style={customStyle}>
      <div className="assign-licenses-info-item">
        <div>{t('usedLicensesDemo')}:</div>
        <div>{`${licensesUsed.riskHRLicenses.DEMO} / ${licenses.riskHRLicenses.DEMO}`}</div>
      </div>
      <div className="assign-licenses-info-item">
        <div>{t('usedLicensesBasic')}:</div>
        <div>{`${licensesUsed.riskHRLicenses.BASIC} / ${licenses.riskHRLicenses.BASIC}`}</div>
      </div>
      <div className="assign-licenses-info-item">
        <div>{t('usedLicensesComprehensive')}:</div>
        <div>{`${licensesUsed.riskHRLicenses.COMPREHENSIVE} / ${licenses.riskHRLicenses.COMPREHENSIVE}`}</div>
      </div>
    </div>
  )
}
