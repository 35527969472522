import './lc-aware-sign-out-menu.scss'
import React, { CSSProperties, FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector, useAppDispatch } from '../../../hooks/redux-hooks'
import { selectAuth, resetState } from '../../../redux/reducers/authSlice'
import { LCSvg } from '../../components/lc-svg/lc-svg'
import { ILCActionPopupItemData, LCActionPopup } from '../../components/lc-action-popup/LCActionPopup'
import LCAwareProfileImageBlob from '../lc-profile-image/LCAwareProfileImageBlob'
import { useNavigate } from 'react-router'
import { ePublicRoutes } from '../../../routes/e-routes'
import { faUserPen } from '@fortawesome/free-solid-svg-icons/faUserPen'
import { faGear } from '@fortawesome/free-solid-svg-icons/faGear'
import { faKey } from '@fortawesome/free-solid-svg-icons/faKey'
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons/faRightToBracket'
import { faCartShopping } from '@fortawesome/free-solid-svg-icons/faCartShopping'

interface Props {
  customStyle?: CSSProperties
}

export const LCAwareSignOutMenu: FC<Props> = ({ customStyle, ...props }: Props): ReactElement => {
  const { user } = useAppSelector(selectAuth)
  const dispatch = useAppDispatch()
  const { t } = useTranslation('translation', { keyPrefix: 'common' })

  const navigate = useNavigate()

  const signOut = () => {
    dispatch(resetState())
    navigate(ePublicRoutes.LOGIN)
  }

  const actionsButtons: ILCActionPopupItemData[] = [
    {
      text: t('change_password'),
      action: signOut,
      newIcon: faKey,
    },
    {
      text: t('edit_me'),
      action: () => navigate(`/app/user/edit/${user.identity}`),
      newIcon: faUserPen,
    },
    {
      text: t('settings'),
      action: () => navigate('/app/settings/companyInfo'),
      newIcon: faGear,
    },
    {
      text: t('purchase'),
      action: () => navigate('/app/purchase/risk-hr'),
      newIcon: faCartShopping,
    },
    {
      text: t('sign_out'),
      action: signOut,
      newIcon: faRightToBracket,
    },
  ]

  return (
    <div style={customStyle} className="lc-aware-sign-out-menu">
      <div className="icon">
        <LCAwareProfileImageBlob />
      </div>
      <div className="name">
        <div className="fullname-role">
          <div className="fullname">{user.fullName}</div>
          <div className="role">{user.roles[0] as string}</div>
        </div>
        <div className="name-arrow">
          <LCActionPopup
            position={['midleft', 'bottom']}
            anchor={
              <div>
                <LCSvg name="arrowBottom" customStyle={{ color: '#ffffff' }} widthAndHeight={[12, 12]} />
              </div>
            }
            data={actionsButtons}
          />
        </div>
      </div>
    </div>
  )
}
