import './lc-assign-modal.scss'
import React, { CSSProperties, FC, useEffect, useState } from 'react'
import { LCButton } from '../../components/lc-button/LCButton'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../hooks/redux-hooks'
import { selectSettings } from '../../../redux/reducers/settingsSlice'
import { useAssignEvaluationMutation } from '../../../services/evaluations.service'
import { IAssessmentSchemaInfo, IAssignResult, IOneAssignedResult, eAssessmentSizeId, eAssessmentTypeId } from '@logicalcommander/types'
import { LCRadioButton, eLCRadioButtonLayout } from '../../components/lc-radio-button/LCRadioButton'
import { IDropdownOption, LCDropdown } from '../../components/lc-dropdown/LCDropdown'
import { LCShow } from '../../components/lc-show/LCShow'
import {
  getAssessmentsIdByTypeIdAndNameId,
  getAssessmentsNamesDropdownItems,
  getAssessmentsTypesDropdownItems,
} from '../../../pages/Settings/components/riskHR/assessments/settings-riskhr-helper'
import { AssignLicensesInfo } from './components/licenses-details/AssignLicensesInfo'
import { getAllAssessmentsSizeDropdown } from '../../../utils/list-helper'
import { toast } from 'react-toastify'
import { LCToast } from '../../components/lc-toast/Toast'

export interface Props {
  onCloseModal: () => void
  identities: string[]
  onAssign: (assessmentInfo: IAssessmentSchemaInfo) => void
  customStyle?: CSSProperties
}

export const AssignEvaluationModal: FC<Props> = ({ customStyle, identities, onCloseModal, onAssign }: Props) => {
  const [assignEvaluation, { data, error, isLoading }] = useAssignEvaluationMutation()

  const { t } = useTranslation('translation', { keyPrefix: 'riskHr.assign' })
  const { t: s } = useTranslation('translation', { keyPrefix: 'settings.assessments' })
  const { t: l } = useTranslation('translation', { keyPrefix: 'languages' })
  const { t: all } = useTranslation('translation')

  const { assessmentsSizes, riskHR } = useAppSelector(selectSettings)

  // Prepare the dropdowns data
  const supportedLanguages: IDropdownOption[] = riskHR.assessments.language.map((i) => ({ label: l(i), value: i }))
  const [assessmentsTypesDropdown, setAssessmentsTypesDropdown] = useState<IDropdownOption[]>()
  const [assessmentNamesDropdown, setAssessmentNamesDropdown] = useState<IDropdownOption[]>()
  const [selectedSizeId, setSelectedSizeId] = useState<number>(eAssessmentSizeId.DEMO)
  const [selectedTypeId, setSelectedTypeId] = useState<number>(eAssessmentTypeId.PERIODIC)
  const [selectedNameId, setSelectedNameId] = useState<number>(1000)
  const [selectedLanguage, setSelectedLanguage] = useState<string>('en')

  const radioOptions = getAllAssessmentsSizeDropdown(all)

  const [hideTypes, setHideTypes] = useState<boolean>()

  // Handle success or error assignment
  useEffect(() => {
    const assessmentInfo: any = {} // getAssessmentInfo()

    if (error) {
      toast.error(<LCToast title={t('errorAssign.title')} body={t('errorAssign.body')} />)
      assessmentInfo && onAssign(assessmentInfo)
    }

    if (!error && data) {
      const assignResults: IAssignResult = data
      const notificationBody: string[] = []
      const totalSucess = assignResults.success.length
      const totalErrors = assignResults.errors.length

      assignResults.success.forEach((item: IOneAssignedResult) => {
        const { evaluationId, fullName } = item
        notificationBody.push(t('successAssign.bodySuccess', { evaluationId, fullName, isEmailSent: true }))
      })

      assignResults.errors.forEach((item: IOneAssignedResult) => {
        const { evaluationId, fullName } = item
        notificationBody.push(t('successAssign.bodyError', { evaluationId, fullName }))
      })

      const toastMessage = <LCToast title={t('successAssign.title')} body={notificationBody.join('\r\n')} />
      if (totalSucess > 0 && totalErrors === 0) {
        toast.success(toastMessage)
      }
      if (totalSucess > 0 && totalErrors > 0) {
        toast.warn(toastMessage)
      }
      if (totalSucess === 0 && totalErrors > 0) {
        toast.error(toastMessage)
      }

      assessmentInfo && onAssign(assessmentInfo)
    }
  }, [data, error])

  const doAssign = () => {
    const assessmentId = getAssessmentsIdByTypeIdAndNameId(assessmentsSizes, selectedSizeId, selectedTypeId, selectedNameId)
    console.log('===== assessmentId', assessmentId)
    assessmentId && assignEvaluation({ identities, assessmentId, language: selectedLanguage })
  }

  // Update the typeId dropdown when selectedSizeId changed.
  useEffect(() => {
    if (selectedSizeId) {
      console.log(`[LCAssignModal.tsx] selectedSizeId changed to: ${selectedSizeId}`)
      const _types: IDropdownOption[] = getAssessmentsTypesDropdownItems(assessmentsSizes, selectedSizeId, s)
      if (_types.length === 0) {
        setHideTypes(true)
        return
      }
      setHideTypes(false)
      _types && setAssessmentsTypesDropdown(_types)
      setSelectedTypeId(+_types[0].value)
    }
  }, [selectedSizeId])

  // console.log(`[LCAssignModal.tsx] selectedSizeId: ${selectedSizeId}`)
  // console.log(`[LCAssignModal.tsx] selectedTypeId: ${selectedTypeId}`)
  // console.log(`[LCAssignModal.tsx] selectedNameId: ${selectedNameId}`)

  // Update the selectedNameId when selectedTypeId changed.
  useEffect(() => {
    if (selectedTypeId) {
      console.log(`[LCAssignModal.tsx] selectedTypeId changed to: ${selectedTypeId}`)
      const _names = getAssessmentsNamesDropdownItems(assessmentsSizes, selectedSizeId, selectedTypeId, s)
      _names && setAssessmentNamesDropdown(_names)
      setSelectedNameId(+_names[0].value)
    }
  }, [selectedTypeId])

  const onSelectedAssesstmentTypeChanged = (event: any) => {
    event?.value && setSelectedTypeId(event.value)
  }

  const onSelectedAssesstmentNameChanged = (event: any) => {
    event?.value && setSelectedNameId(event.value)
  }

  const onSelectedAssesstmentLanguageChanged = (event: any) => {
    event?.value && setSelectedLanguage(event.value)
  }

  const handleRadioChange = (selectedValue: string) => {
    console.log(`[LCAssignModal.tsx] handleRadioChange: selectedValue sizeId ${selectedValue}`)
    setSelectedSizeId(+selectedValue)
  }

  return (
    <div style={customStyle} className="assign-modal">
      <div className="description-title">{t('description', { count: identities.length })}</div>
      <div className="description-users">{identities.join(', ')}</div>

      <AssignLicensesInfo customStyle={{ width: '360px' }} />

      <div className="item">
        <div className="key">{t('selectLicenseSize')}</div>

        <LCRadioButton
          selected={String(selectedSizeId)}
          layout={eLCRadioButtonLayout.HORIZONTAL}
          options={radioOptions}
          name="assessmentSize"
          onChange={handleRadioChange}
        />
      </div>

      <div className="item">
        <div className="key">{t('selectAssessmentType')}</div>
        <LCShow show={assessmentsTypesDropdown !== undefined && !hideTypes}>
          <LCDropdown
            options={assessmentsTypesDropdown || []}
            defaultOption={selectedTypeId + ''}
            onChange={onSelectedAssesstmentTypeChanged}
          />
        </LCShow>
      </div>

      <div className="item">
        <div className="key">{t('selectAssessmentName')}</div>
        <LCShow show={assessmentNamesDropdown !== undefined}>
          <LCDropdown
            options={assessmentNamesDropdown || []}
            defaultOption={selectedNameId + ''}
            onChange={onSelectedAssesstmentNameChanged}
          />
        </LCShow>
      </div>

      <div className="item">
        <div className="key">{t('selectAssessmentLanguage')}</div>
        <LCShow show={assessmentNamesDropdown !== undefined}>
          <LCDropdown options={supportedLanguages} defaultOption={selectedLanguage} onChange={onSelectedAssesstmentLanguageChanged} />
        </LCShow>
      </div>

      <div className="buttons">
        <LCButton label={t('buttons.cancel')} onClick={onCloseModal} />
        <LCButton disabled={!selectedTypeId || !selectedNameId} label={t('buttons.assign')} isLoading={isLoading} onClick={doAssign} />
      </div>
    </div>
  )
}
