import React, { useContext, useEffect, useState } from 'react'
import { MyContextPurchase } from './useContextService'
import { useTranslation } from 'react-i18next'

type License = {
  title: string
  type: string
  description: string
  value: string
  package: string | null
  currency: string
}

interface Props {
  data: License
}

export const LCWidgetProduct = ({ data }: Props) => {
  const { t: tAmount } = useTranslation('translation', { keyPrefix: 'purchase.widgetProduct' })
  const { updatePurchaseMost } = useContext(MyContextPurchase)
  const [countLicence, setCountLicence] = useState<number>(0)
  const { currency, description, title, value } = data
  useEffect(() => {
    setCountLicence(0)
  }, [data])

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  const handleIncrement = () => {
    setCountLicence((prevCount) => prevCount + 1)
  }
  const handleDecrement = () => {
    setCountLicence((prevCount) => Math.max(prevCount - 1, 0))
  }

  useEffect(() => {
    updatePurchaseMost([
      {
        [`${title}`]: {
          ...data,
          amount: countLicence,
        },
      },
    ])
  }, [countLicence])

  return (
    <div className="container-widget-product">
      <h4>{title}</h4>
      <span>{description}</span>
      <form className="container-from-amount">
        <span className="product">Product $ {`${value} ${currency}`}</span>
        <label htmlFor="amount" id="label-amount">
          {tAmount('amount')}
        </label>
        <div id="input-amount">
          <input
            type="number"
            value={countLicence}
            min={0}
            max={10}
            autoComplete="off"
            name="amount"
            id="input-amound-value"
            onChange={(e) => setCountLicence(parseInt(e.target.value))}
            inputMode="numeric"
            onKeyDown={handleKeyPress}
          />
          <button className="buttton-purches" type="button" onClick={handleIncrement}>
            +
          </button>
          <button className="buttton-purches" type="button" onClick={handleDecrement}>
            -
          </button>
        </div>
      </form>
    </div>
  )
}
