import './lc-charts.scss'
import { HTMLAttributes, SetStateAction, useCallback, useEffect, useState } from 'react'
import { CSSProperties } from 'styled-components'
import { Bar, Tooltip, BarChart, ResponsiveContainer, XAxis, YAxis, Legend, Cell } from 'recharts'
import { barColors } from './bar-colors'
import { customLabel } from './custom-labels'
import { IDashboardRisksPerDepartment, IBarOptions, BarChartLayout } from '../../../../interfaces/dashboard.interface'
import { ITitleValueNumber } from '@logicalcommander/types'
import { CustomLabelsH, CustomLabelsV, CustomizedTickH, CustomizedTickV } from './customComponents/custom-lc-pie-charts'
import { useTranslation } from 'react-i18next'
import React from 'react'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  data: ITitleValueNumber[] | IDashboardRisksPerDepartment[]
  customStyle?: CSSProperties
  options: IBarOptions
  overFlowData?: boolean
  legendCustom?: boolean
}
const marginh = { top: 0, bottom: 0, right: 40, left: 0 }

const LCBarChartNew = ({ customStyle, data, options, overFlowData, legendCustom }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.chartPieTranslate.titleLabel' })
  const { color, layout, label } = options

  function findLongestWord(matriz: ITitleValueNumber[]) {
    let longestWord = { title: '' }
    if (data?.length === 0) return 0

    for (let i = 0; i < matriz.length; i++) {
      const wordCurrent = matriz[i].title
      if (wordCurrent.length > longestWord.title.length) {
        longestWord = { title: matriz[i].title }
      }
    }
    return longestWord.title.length
  }

  const [marginBottom, setMarginBottom] = useState({ top: -8, bottom: 0, right: 30, left: 0 })

  useEffect(() => {
    const longestWord = layout === BarChartLayout.HORIZONTAL ? findLongestWord(data) : 0
    const marginBottom = longestWord * 3.4

    setMarginBottom((prevState) => {
      return {
        ...prevState,
        bottom: marginBottom,
      }
    })
  }, [data])

  const heightResponsive = 300 + 11 * data?.length

  const [activeIndex, setActiveIndex] = useState(0)
  const onPieEnter = useCallback(
    (_: any, index: SetStateAction<number>) => {
      setActiveIndex(index)
    },
    [setActiveIndex]
  )

  return (
    <div className="chart-widget" style={customStyle}>
      <div className="container-chart">
        <ResponsiveContainer width="100%" height={overFlowData ? heightResponsive : '100%'}>
          <BarChart layout={layout} data={data} margin={layout === BarChartLayout.VERTICAL ? marginh : marginBottom}>
            {layout === BarChartLayout.VERTICAL ? (
              <XAxis type="number" tick={<CustomizedTickV />} />
            ) : (
              <XAxis
                dataKey={'title'}
                type="category"
                dx={-5}
                tick={<CustomizedTickH />}
                interval={0}
                textAnchor="end"
                padding={{ left: 4, right: 4 }}
                tickLine={false}
              />
            )}
            {layout === BarChartLayout.VERTICAL ? (
              <YAxis dataKey={'title'} type="category" tick={false} />
            ) : (
              <YAxis type={'number'} padding={{ top: 25 }} />
            )}

            <Tooltip />
            {legendCustom === undefined ? (
              <Legend
                wrapperStyle={layout === BarChartLayout.VERTICAL ? {} : { position: 'absolute', bottom: '280px' }}
                verticalAlign="top"
                payload={[{ value: `${t(customLabel[`${label}`]())}`, type: 'square', color: `${barColors[`${color}`]()}` }]}
              />
            ) : null}
            <Bar
              activeIndex={activeIndex}
              onMouseEnter={onPieEnter}
              dataKey={'value'}
              radius={layout === BarChartLayout.VERTICAL ? [0, 5, 5, 0] : [5, 5, 0, 0]}
              maxBarSize={28}
              label={layout === BarChartLayout.VERTICAL ? <CustomLabelsV data={data} overFlowData={overFlowData} /> : <CustomLabelsH />}>
              {data.map((data, index) => (
                <Cell
                  key={index}
                  fill={Array.isArray(barColors[`${color}`]()) ? barColors[`${color}`](index) : barColors[`${color}`]()}
                  fillOpacity={activeIndex === index ? 1 : 0.9}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default LCBarChartNew
