import React, { CSSProperties, FC, HTMLAttributes } from 'react'
import { BarColor, CustomLabels } from '../../../../../interfaces/dashboard.interface'
import { IValueChartDataAgeEmotion, LCBarChartLevel } from '../../../../ui/components/charts/lc-bar-chart-level'
import { useTranslation } from 'react-i18next'
import { BaseDashboardComponent } from '../../../../ui/components/base-dashboard/BaseDashboardComponent'
import { DashboardComponentTitle } from '../../../../ui/components/base-dashboard/DashboardComponentTitle'

interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

export const EmoRiskEmotionAge: FC<Props> = ({ customStyle }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.emoRisk.emoRiskEmotionAge' })

  const isLoading = false
  const error = false

  const data: IValueChartDataAgeEmotion = {
    Depression: [
      { id: 'Depression', title: '18-24', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: '25-29', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: '30-34', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: '35-39', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: '40-44', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: '45-49', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: '50-54', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: '55-59', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: '60-64', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: '65-69', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: '+70', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
    ],
    Happiness: [
      { id: 'Happiness', title: '18-24', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: '25-29', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: '30-34', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: '35-39', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: '40-44', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: '45-49', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: '50-54', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: '55-59', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: '60-64', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: '65-69', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: '+70', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
    ],
    Anger: [
      { id: 'Anger', title: '18-24', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: '25-29', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: '30-34', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: '35-39', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: '40-44', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: '45-49', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: '50-54', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: '55-59', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: '60-64', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: '65-69', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: '+70', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
    ],
    Excitement: [
      { id: 'Excitement', title: '18-24', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: '25-29', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: '30-34', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: '35-39', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: '40-44', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: '45-49', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: '50-54', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: '55-59', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: '60-64', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: '65-69', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: '+70', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
    ],
    Sadness: [
      { id: 'Sadness', title: '18-24', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: '25-29', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: '30-34', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: '35-39', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: '40-44', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: '45-49', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: '50-54', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: '55-59', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: '60-64', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: '65-69', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: '+70', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
    ],
    MentalLoad: [
      { id: 'MentalLoad', title: '18-24', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), critical: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: '25-29', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), critical: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: '30-34', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), critical: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: '35-39', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), critical: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: '40-44', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), critical: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: '45-49', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), critical: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: '50-54', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), critical: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: '55-59', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), critical: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: '60-64', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), critical: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: '65-69', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), critical: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: '+70', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), critical: Math.floor(Math.random() * 50) },
    ],
    Discomfort: [
      { id: 'Discomfort', title: '18-24', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: '25-29', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: '30-34', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: '35-39', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: '40-44', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: '45-49', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: '50-54', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: '55-59', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: '60-64', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: '65-69', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: '+70', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
    ],
    Dissatisfaction: [
      { id: 'Dissatisfaction', title: '18-24', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: '25-29', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: '30-34', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: '35-39', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: '40-44', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: '45-49', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: '50-54', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: '55-59', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: '60-64', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: '65-69', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: '+70', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
    ],
    Burnout: [
      { id: 'Burnout', title: '18-24', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), critical: Math.floor(Math.random() * 50) },
      { id: 'Burnout', title: '25-29', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), critical: Math.floor(Math.random() * 50) },
      { id: 'Burnout', title: '30-34', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), critical: Math.floor(Math.random() * 50) },
      { id: 'Burnout', title: '35-39', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), critical: Math.floor(Math.random() * 50) },
      { id: 'Burnout', title: '40-44', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), critical: Math.floor(Math.random() * 50) },
      { id: 'Burnout', title: '45-49', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), critical: Math.floor(Math.random() * 50) },
      { id: 'Burnout', title: '50-54', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), critical: Math.floor(Math.random() * 50) },
      { id: 'Burnout', title: '55-59', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), critical: Math.floor(Math.random() * 50) },
      { id: 'Burnout', title: '60-64', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), critical: Math.floor(Math.random() * 50) },
      { id: 'Burnout', title: '65-69', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), critical: Math.floor(Math.random() * 50) },
      { id: 'Burnout', title: '+70', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), critical: Math.floor(Math.random() * 50) },
    ],
  }

  return (
    <BaseDashboardComponent customStyle={customStyle} sectionTitle={<DashboardComponentTitle title={t('title')} />} isLoading={isLoading} isSuccess={!!data} isError={!!error}>
      <LCBarChartLevel
        data={data}
        options={{
          color: BarColor.EMO_RISK_LEVEL,
          emotionRisk: 'default',
          label: CustomLabels.EMO_RISK_EMOTION_AGE,
        }}
      />
    </BaseDashboardComponent>
  )
}
