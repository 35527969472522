import { useCallback } from 'react'
import { ensurePluginOrder } from 'react-table'

function getFirstDefined(...args: any) {
  for (let i = 0; i < args.length; i += 1) {
    if (typeof args[i] !== 'undefined') {
      return args[i]
    }
  }
}

interface ExportFileNameOptions {
  fileType: string
  all: boolean
}

const defaultGetExportFileName = ({ fileType, all }: ExportFileNameOptions): string => {
  return `${all ? 'all-' : ''}data`
}

const defaultGetAdditionalData = () => {
  return undefined
}

interface Column {
  Header?: string
  id?: string
  accessor?: string | ((row: any) => any)
  isVisible?: boolean
  disableExport?: boolean
  getColumnExportValue?: (col: Column) => string
  getCellExportValue?: (row: Row, col: Column) => string
  canExport?: boolean
  exportValue?: string
}

interface Row {
  values: { [key: string]: any }
}

const defaultGetColumnExportValue = (col: Column): string => {
  let name = col.Header
  if (typeof name === 'object' || typeof name === 'function') {
    name = col.id
  }
  return name || ''
}

const defaultGetCellExportValue = (row: Row, col: Column): any => {
  return row.values[col.id as string]
}

const defaultGetExportFileBlob = (): Blob => {
  throw new Error('React Table: Export Blob is mandatory')
}

interface UseInstanceProps {
  rows: Row[]
  initialRows?: Row[]
  allColumns: Column[]
  disableExport?: boolean
  getExportFileName?: (opts: ExportFileNameOptions) => string
  getExportFileBlob?: (opts: any) => Blob
  getAdditionalData?: <T>() => T
  plugins: any[]
}

export const useExportData = (hooks: any): void => {
  hooks.useInstance.push(useInstance)
}

useExportData.pluginName = 'useExportData'

function useInstance(instance: UseInstanceProps): void {
  const {
    rows,
    initialRows = [],
    allColumns,
    disableExport,
    getExportFileName = defaultGetExportFileName,
    getExportFileBlob = defaultGetExportFileBlob,
    getAdditionalData = defaultGetAdditionalData,
    plugins,
  } = instance

  ensurePluginOrder(plugins, ['useColumnOrder', 'useColumnVisibility', 'useFilters', 'useSortBy'], 'useExportData')

  allColumns.forEach((column) => {
    const { accessor, getColumnExportValue = defaultGetColumnExportValue } = column

    const canExport = accessor
      ? getFirstDefined(column.disableExport === true ? false : undefined, disableExport === true ? false : undefined, true)
      : false

    column.canExport = canExport
    column.exportValue = getColumnExportValue(column)
  })

  const exportData = useCallback(
    (fileType: string, all: boolean = false) => {
      const exportableColumns = allColumns.filter((col) => col.canExport && (all || col.isVisible))

      if (exportableColumns.length === 0) {
        console.warn('No exportable columns are available')
      }

      let exportableRows = (all ? initialRows : rows).map((row) => {
        return exportableColumns.map((col) => {
          const { getCellExportValue = defaultGetCellExportValue } = col

          return getCellExportValue(row, col)
        })
      })

      const fileName = getExportFileName({ fileType, all })
      const additionalData = getAdditionalData()

      let fileBlob = getExportFileBlob({
        columns: exportableColumns,
        data: exportableRows,
        fileName,
        fileType,
        additionalData,
      })

      if (fileBlob) {
        downloadFileViaBlob(fileBlob, fileName, fileType)
      }
    },
    [getExportFileBlob, getExportFileName, getAdditionalData, initialRows, rows, allColumns]
  )

  Object.assign(instance, {
    exportData,
  })
}

function downloadFileViaBlob(fileBlob: Blob, fileName: string, type: string): void {
  const dataUrl = URL.createObjectURL(fileBlob)
  const link = document.createElement('a')
  link.download = `${fileName}.${type}`
  link.href = dataUrl
  link.click()
}
