import './evaluations/risk-hr-evaluations.scss'
import './evaluations/risk-hr-evaluations.print.scss'
import React, { CSSProperties, FC, HTMLAttributes, useEffect, useState } from 'react'
import { MultipleEvaluationsActions } from './MultipleEvaluationsActions'
import { RiskHrEvaluations } from './evaluations/RiskHrEvaluations'
import { LCPage } from '../../../ui/components/lc-page/LCPage'
import { ISearch } from '@logicalcommander/types'
import { useTranslation } from 'react-i18next'
import { faTemperatureThreeQuarters } from '@fortawesome/free-solid-svg-icons/faTemperatureThreeQuarters'
import { IEvaluationSummary } from './columns/riskHrEvaluations.columns'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
  searchArray?: ISearch[]
}

export const RiskHrEvaluationsPage: FC<Props> = ({ customStyle, searchArray }: Props) => {
  const [, setFilters] = useState<ISearch[]>(searchArray || [])
  const [selectedRows, setSelectedRows] = useState<IEvaluationSummary[]>([])
  const { t } = useTranslation('translation', { keyPrefix: 'menu' })

  useEffect(() => {
    onFiltersChanged(searchArray || [])
  }, [searchArray])

  const onFiltersChanged = (filters: ISearch[]) => {
    setFilters(filters)
  }

  // const onlyRisksFilter60 = () => {
  //   setFilters([{ keyName: 'averageRisk', type: eSearchType.GREATER, value: '60' }])
  // }

  // const onlyRisksFilter60LastYear = () => {
  //   setFilters([
  //     { keyName: 'averageRisk', type: eSearchType.GREATER, value: '60' },
  //     { keyName: 'status.completed', type: eSearchType.DATE_AFTER, value: '2022-09-26T05:53:59.297Z' },
  //   ])
  // }

  // const onlyRisksFilter80 = () => {
  //   setFilters([{ keyName: 'averageRisk', type: eSearchType.GREATER, value: '80' }])
  // }

  return (
    <LCPage icon={faTemperatureThreeQuarters} title={t('risk_hr')} breadcrumbs="Assessments > Results">
      <div style={customStyle}>
        <div className="risk-hr-evaluations">
          {/* <div className="predefined-filters">
            <LCButton label={'Risks 60'} onClick={onlyRisksFilter60} />
            <LCButton label={'Risks 60 last year'} onClick={onlyRisksFilter60LastYear} />
            <LCButton label={'Risks 80'} onClick={onlyRisksFilter80} />
          </div> */}

          <div className="right-side">
            {selectedRows.length > 0 ? (
              <MultipleEvaluationsActions
                selectedRows={selectedRows}
                customStyle={{ marginRight: '10px' }}
                onOpenCaseSuccess={(casesIds: string[]) => {}}
              />
            ) : null}
          </div>
        </div>
        <RiskHrEvaluations showTableActions={true} searchArray={searchArray} onSelectedRows={(rows) => setSelectedRows(rows)} />
      </div>
    </LCPage>
  )
}
