import './lc-company-features.scss'

import React, { FC } from 'react'
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ILicenseFeatures } from '@logicalcommander/types'
import { faListCheck } from '@fortawesome/free-solid-svg-icons/faListCheck'
import { faComments } from '@fortawesome/free-solid-svg-icons/faComments'
import { faFileLines } from '@fortawesome/free-solid-svg-icons/faFileLines'
import { faHelicopterSymbol } from '@fortawesome/free-solid-svg-icons/faHelicopterSymbol'
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons/faCalendarDays'
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons/faEnvelopeOpenText'
import { faTemperatureThreeQuarters } from '@fortawesome/free-solid-svg-icons/faTemperatureThreeQuarters'
import { faFaceSmile } from '@fortawesome/free-solid-svg-icons/faFaceSmile'
import { faQuestion } from '@fortawesome/free-solid-svg-icons/faQuestion'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../redux/hooks'
import { selectExtendedCompany } from '../../../redux/reducers/extendedCompanySlice'

export interface ICompanyFeaturesProps {
  customStyle?: React.CSSProperties
}

export const LCompanyFeatures: FC<ICompanyFeaturesProps> = ({ customStyle, ...props }: ICompanyFeaturesProps) => {
  const { features } = useAppSelector(selectExtendedCompany)
  const { t } = useTranslation('translation', { keyPrefix: 'settings.companyFeatures' })

  const getIcon = (feature: string) => {
    switch (feature) {
      case 'tasks':
        return faListCheck
      case 'messages':
        return faComments
      case 'sos':
        return faHelicopterSymbol
      case 'documents':
        return faFileLines
      case 'calendar':
        return faCalendarDays
      case 'complaints':
        return faEnvelopeOpenText
      case 'assessments':
        return faTemperatureThreeQuarters
      case 'emoRisk':
        return faFaceSmile
      default:
        return faQuestion
    }
  }

  return (
    <div className="company-features" style={customStyle} {...props}>
      {Object.keys(features).map((f) => (
        <div className={`company-feature-item${features[f as keyof ILicenseFeatures] ? '' : ' _disabled'}`} key={f}>
          <div className="company-feature-label-wrapper">
            <FontAwesomeIcon icon={getIcon(f)} />
            {t(f)}
          </div>
          <FontAwesomeIcon
            color={features[f as keyof ILicenseFeatures] ? 'green' : 'red'}
            icon={features[f as keyof ILicenseFeatures] ? faCircleCheck : faBan}
          />
        </div>
      ))}
    </div>
  )
}
