import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { configManager } from '../../config/configManager'
import { v4 as uuidv4 } from 'uuid'
import { RootState } from '../redux/store'
import { IAcceptanceDetails, ILCHttpResponse } from '@logicalcommander/types'

export interface IActivityLogEntry {
  identity: string
  ipAddress: string
  fullName: string
  roles: string
  outcome: string
  details: {
    action: string
    duration: number
  }
  createdAt: Date
}

export const acceptanceApi = createApi({
  reducerPath: 'acceptanceApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${configManager.api.GATEWAY_URL}/acceptance`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('x-request-id', uuidv4())
      const { token, companyToken } = (getState() as RootState).authReducer
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      if (companyToken) {
        headers.set('x-company-token', companyToken)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    acceptTermsAndConditions: builder.mutation<IAcceptanceDetails, void>({
      query: () => ({
        url: '/accept-terms',
        method: 'POST',
      }),
      transformResponse: (rawResult: ILCHttpResponse<IAcceptanceDetails>, meta) => {
        return rawResult.data as IAcceptanceDetails
      },
    }),
    isVersionAccepted: builder.mutation<IAcceptanceDetails, { version: string; token: string; companyToken: string }>({
      query: ({ token, companyToken, version }) => ({
        headers: [
          ['authorization', `Bearer ${token}`],
          ['x-company-token', companyToken],
        ],
        url: `/is-accepted/${version}`,
        method: 'GET',
      }),
      transformResponse: (rawResult: ILCHttpResponse<IAcceptanceDetails>, meta) => {
        return rawResult.data as IAcceptanceDetails
      },
    }),
  }),
})

export const { useAcceptTermsAndConditionsMutation, useIsVersionAcceptedMutation } = acceptanceApi
