import { SVGProps } from 'react'

const SvgMessage = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.4315 0.00292924C17.6009 0.0177936 17.7662 0.0900544 17.8959 0.219712C18.112 0.435795 18.1686 0.750927 18.0659 1.01896L12.2579 17.6132C12.1563 17.9034 11.8878 18.1023 11.5806 18.1148C11.2733 18.1273 10.9895 17.951 10.8646 17.67L7.72709 10.6105C7.68367 10.5824 7.64252 10.5492 7.60444 10.5111C7.56632 10.473 7.53317 10.4318 7.50497 10.3884L0.4454 7.25077C0.164389 7.12587 -0.0119082 6.8421 0.000627218 6.53484C0.0131626 6.22758 0.211991 5.9591 0.502241 5.85752L17.1055 0.0463598C17.1459 0.0314717 17.1873 0.0201729 17.2292 0.0124631C17.2965 3.24512e-05 17.3645 -0.00300602 17.4315 0.00292924ZM9.12154 10.0547L11.4671 15.3321L15.5717 3.60455L9.12154 10.0547ZM14.5114 2.54353L8.06095 8.99398L2.78331 6.64836L14.5114 2.54353Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgMessage
