import { faGear } from '@fortawesome/free-solid-svg-icons/faGear'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router-dom'
import { LCPage } from '../../ui/components/lc-page/LCPage'
import { AdminManagementList } from './components/adminManagement/AdminManagementList'
import { CompanyInfoSettings } from './components/companyInfo/CompanyInfoSettings'
import { EmailSettings } from './components/email/EmailSettings'
import { SettingsEmoRisk } from './components/emoRisk/SettingsEmoRisk'
import { SettingsEnumeratedTypesTabs } from './components/enumerated/SettingsEnumeratedTypesTabs'
import { SettingsPermissions } from './components/permissions/SettingsPermissions'
import { SettingsPermissionsByRole } from './components/permissions/SettingsPermissionsByRole'
import { SettingsInvoices } from './components/purchase/SettingsInvoices'
import { SettingsMyRequests } from './components/purchase/SettingsMyRequests'
import { SettingsToApprove } from './components/purchase/SettingsToApprove'
import { SettingsRiskHrTabs } from './components/riskHR/assessments/SettingsRiskHrTabs'
import { SettingsRiskHrImpactRules } from './components/riskHR/SettingsRiskHrImpactRules'
import { UXSettings } from './components/uxSettings/UXSettings'
import { eSettingsRoutes } from './tabs/e-settins-routes'
import './tabs/settings-tabs.scss'
import { SettingsTabsNavigation } from './tabs/settingsTabsNavigation'
import { SettingsRiskSeverityRules } from './components/riskHR/severityAndImpact/SettingsRiskSeverityRules/SettingsRiskSeverityRules'

export const SettingsTabs: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'settings' })
  const [currentTab, setCurrentTab] = useState<string>('company-info')

  return (
    <div className="settings-tabs">
      <div className="tabs-wrapper">
        <div className="tabs-menu">
          <SettingsTabsNavigation currentTabChanged={(currentTab) => setCurrentTab(currentTab)} />
        </div>
        <div className="tabs-content">
          <LCPage icon={faGear} title={t('page_title')} breadcrumbs={t(`menu.${currentTab}`)}>
            <Routes>
              <Route path={eSettingsRoutes.COMPANY_INFO} element={<CompanyInfoSettings />} />
              <Route path={eSettingsRoutes.ADMIN_MANAGMENT} element={<AdminManagementList />} />
              <Route path={eSettingsRoutes.PERMISSIONS} element={<SettingsPermissions />} />
              <Route path={eSettingsRoutes.PERMISSIONS_BY_ROLE} element={<SettingsPermissionsByRole />} />
              <Route path={eSettingsRoutes.ORGANIZATIONAL_STRUCTURE} element={<SettingsEnumeratedTypesTabs />} />
              <Route path={eSettingsRoutes.EMAIL} element={<EmailSettings />} />
              <Route path={eSettingsRoutes.UX_SETTINGS} element={<UXSettings />} />
              <Route path={eSettingsRoutes.RISK_HR_ASSESSMENTS} element={<SettingsRiskHrTabs />} />
              <Route path={eSettingsRoutes.RISK_HR_SEVERITY_RULE} element={<SettingsRiskSeverityRules />} />
              <Route path={eSettingsRoutes.RISK_HR_IMPACT_RULES} element={<SettingsRiskHrImpactRules />} />
              <Route path={eSettingsRoutes.EMO_RISK} element={<SettingsEmoRisk />} />
              <Route path={eSettingsRoutes.PURCHASE_TO_APPROVE} element={<SettingsToApprove />} />
              <Route path={eSettingsRoutes.PURCHASE_INVOICE} element={<SettingsInvoices />} />
              <Route path={eSettingsRoutes.PURCHASE_MY_REQUESTS} element={<SettingsMyRequests />} />
            </Routes>
          </LCPage>
        </div>
      </div>
    </div>
  )
}
