import { IRegisterLeadCompanyRequest } from '../../../services/register.services'

export const registerCompanyDetailsInitialValues: IRegisterLeadCompanyRequest = {
  token: '',
  language: '',
  user: {
    identity: '',
    password: '',
    email: '',
    firstName: '',
    middleName: '',
    lastName: '',
    mobile: '',
  },
  company: {
    companyId: '',
    name: '',
    phone: '',
    address: {
      country: '',
      city: '',
      state: '',
      addressLine1: '',
      addressLine2: '',
      postcode: '',
    },
    website: '',
  },
}

export const registerCompanyDetailsInitialValuesMock: IRegisterLeadCompanyRequest = {
  token: '',
  language: 'en',
  user: {
    identity: '1112223',
    password: 'Aa123456!',
    email: '',
    firstName: 'Israel',
    middleName: '',
    lastName: 'Lavi',
    mobile: '+972528545788',
  },
  company: {
    companyId: 'bbb',
    name: 'Bibi company',
    phone: '+97237788777',
    address: {
      country: 'IL',
      city: 'Tel Aviv',
      state: '',
      addressLine1: 'Haslom 5',
      addressLine2: '',
      postcode: '111222',
    },
    website: 'http://www.walla.co.il',
  },
}
