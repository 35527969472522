import { IKeyValueItem } from '../lc-key-value-panel/LCKeyValuePanel'
import './lc-key-value.scss'
import { CSSProperties, FC } from 'react'

export interface ILCKeyValueProps {
  keyValue: IKeyValueItem
  customStyle?: CSSProperties
}

export const LCKeyValue: FC<ILCKeyValueProps> = ({ keyValue, customStyle, ...props }: ILCKeyValueProps) => {
  return (
    <div className="key-value" style={customStyle} {...props}>
      <div className="key">{keyValue.text}:</div>
      <div className="value">{keyValue.value}</div>
    </div>
  )
}
