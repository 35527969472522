export const isEmpty = (obj: object): boolean => {
  return Object.keys(obj).length === 0
}

type GenericObject = { [key: string]: any }

export const filterEmptyStringsAndObjectsAndArrays = <T extends GenericObject>(obj: T): T => {
  const filterRecursive = (value: any): any => {
    if (Array.isArray(value)) {
      // Filter array elements and remove empty strings and objects
      return value
        .map((item) => filterRecursive(item))
        .filter((item) => item !== '' && !(typeof item === 'object' && Object.keys(item).length === 0))
    } else if (typeof value === 'object' && value !== null) {
      // Recursively filter object properties and check for empty objects
      const filteredObj = Object.entries(value).reduce((acc: GenericObject, [key, val]) => {
        const filteredVal = filterRecursive(val)
        // Only add non-empty values and non-empty objects
        if (filteredVal !== '' && !(typeof filteredVal === 'object' && Object.keys(filteredVal).length === 0)) {
          acc[key] = filteredVal
        }
        return acc
      }, {})

      // Check if the object is empty after filtering
      if (Object.keys(filteredObj).length === 0) {
        return {}
      }

      return filteredObj as T
    }
    return value
  }

  const result = filterRecursive(obj)
  // Check and return empty object as {} to ensure type consistency
  return typeof result === 'object' && Object.keys(result).length === 0 ? ({} as T) : result
}

export const filterEmptyStringsAndObjects = <T extends GenericObject>(obj: T): T => {
  const filterRecursive = (value: any): any => {
    if (Array.isArray(value)) {
      // Filter array elements but allow empty arrays to remain
      return value
        .map((item) => filterRecursive(item))
        .filter((item) => item !== '' && !(typeof item === 'object' && Object.keys(item).length === 0 && !Array.isArray(item))) // Allow empty arrays
    } else if (typeof value === 'object' && value !== null) {
      // Recursively filter object properties and check for empty objects, but allow empty arrays
      const filteredObj = Object.entries(value).reduce((acc: GenericObject, [key, val]) => {
        const filteredVal = filterRecursive(val)
        // Only add non-empty values, non-empty objects, and allow empty arrays
        if (
          filteredVal !== '' &&
          !(typeof filteredVal === 'object' && Object.keys(filteredVal).length === 0 && !Array.isArray(filteredVal))
        ) {
          acc[key] = filteredVal
        }
        return acc
      }, {})

      // Check if the object is empty after filtering, but this does not apply to arrays
      if (Object.keys(filteredObj).length === 0 && !Array.isArray(filteredObj)) {
        return {}
      }

      return filteredObj as T
    }
    return value
  }

  const result = filterRecursive(obj)
  // Check and return empty object as {} to ensure type consistency, except for arrays which are allowed to be empty
  return typeof result === 'object' && Object.keys(result).length === 0 && !Array.isArray(result) ? ({} as T) : result
}

export const arraysEqual = (arr1: any[], arr2: any[]) => {
  // Check if the arrays have the same length
  if (arr1.length !== arr2.length) {
    return false
  }

  // Compare elements at each index
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false
    }
  }

  return true
}

type AnyObject = { [key: string]: any }

export const deepObjectDiff = (obj1: AnyObject, obj2: AnyObject): AnyObject => {
  const diff: AnyObject = {}

  Object.keys(obj1).forEach((key) => {
    if (!obj2.hasOwnProperty(key)) {
      diff[key] = 'Not in Obj2'
    } else if (typeof obj1[key] === 'object' && obj1[key] !== null && typeof obj2[key] === 'object' && obj2[key] !== null) {
      const deeperDiff = deepObjectDiff(obj1[key], obj2[key])
      if (Object.keys(deeperDiff).length > 0) {
        diff[key] = deeperDiff
      }
    } else if (obj1[key] !== obj2[key]) {
      diff[key] = obj2[key]
    }
  })

  Object.keys(obj2).forEach((key) => {
    if (!obj1.hasOwnProperty(key)) {
      diff[key] = obj2[key]
    }
  })

  return diff
}

export const deepMerge = <T extends object, S extends object>(target: T, source: S): T & S => {
  const result: Partial<T & S> = { ...target }

  Object.keys(source).forEach((key) => {
    const targetValue = target[key as keyof T]
    const sourceValue = source[key as keyof S]

    // Check if the source value is null, if so, use the target value instead
    if (sourceValue === null) {
      result[key as keyof typeof result] = targetValue as any
    } else if (
      typeof targetValue === 'object' &&
      typeof sourceValue === 'object' &&
      targetValue !== null &&
      sourceValue !== null &&
      !Array.isArray(targetValue) &&
      !Array.isArray(sourceValue)
    ) {
      // Deep merge objects unless the source value is null
      result[key as keyof typeof result] = deepMerge(targetValue, sourceValue) as any
    } else {
      // Use source value
      result[key as keyof typeof result] = sourceValue as any
    }
  })

  return result as T & S
}
