import { HTMLAttributes } from 'preact/compat'
import React, { FC, lazy, useEffect, useState } from 'react'
import { CSSProperties } from 'styled-components'
import { BarColor, CustomLabels } from '../../../../../interfaces/dashboard.interface'
import { ITitleValueNumber } from '@logicalcommander/types'
import { useTranslation } from 'react-i18next'
import { BaseDashboardComponent } from '../../../../ui/components/base-dashboard/BaseDashboardComponent'
import { DashboardComponentTitle } from '../../../../ui/components/base-dashboard/DashboardComponentTitle'

const LCPieChartUniNew = lazy(() => import('../../../../ui/components/charts/lc-pie-chart'))

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}
const RiskHrDashboardRiskPiePosition: FC<Props> = ({ customStyle }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.riskHr.riskPiePosition' })

  const isLoading = false
  const error = false

  const data = [
    {
      title: 'Account Manager',
      value: 21,
    },
    {
      title: 'Backend Engineer',
      value: 82,
    },
    {
      title: 'CEO',
      value: 1,
    },
    {
      title: 'CFO',
      value: 1,
    },
    {
      title: 'CHRO',
      value: 1,
    },
    {
      title: 'CIO',
      value: 1,
    },
    {
      title: 'CSIO',
      value: 1,
    },
    {
      title: 'CMO',
      value: 1,
    },
    {
      title: 'CSO',
      value: 1,
    },
    {
      title: 'CTO',
      value: 1,
    },
    {
      title: 'Customer Success Manager',
      value: 31,
    },
    {
      title: 'Driver',
      value: 74,
    },
    {
      title: 'Engineering Manager',
      value: 60,
    },
    {
      title: 'Finance',
      value: 90,
    },
    {
      title: 'Frontend Engineer',
      value: 25,
    },
    {
      title: 'HR',
      value: 95,
    },
    {
      title: 'HR Manager',
      value: 45,
    },
    {
      title: 'Marketing',
      value: 18,
    },
    {
      title: 'Operations',
      value: 64,
    },
    {
      title: 'Product Management',
      value: 29,
    },
    {
      title: 'Production',
      value: 99,
    },
    {
      title: 'Risk',
      value: 99,
    },
    {
      title: 'Sales',
      value: 70,
    },
    {
      title: 'Sales Person',
      value: 66,
    },
    {
      title: 'Security',
      value: 40,
    },
    {
      title: 'Security Engineer',
      value: 66,
    },
    {
      title: 'VP HR',
      value: 1,
    },
    {
      title: 'VP Marketing',
      value: 1,
    },
    {
      title: 'VP R&D',
      value: 1,
    },
    {
      title: 'VP Product Management',
      value: 2,
    },
    {
      title: 'VP Sales',
      value: 100,
    },
  ]

  const [positionRisk, setPositionRisk] = useState<ITitleValueNumber[]>([])

  useEffect(() => {
    if (data) {
      const sorted = [...data].sort(sortPositionRisk)
      setPositionRisk(sorted)
    }
  }, [])

  const sortPositionRisk = (a: ITitleValueNumber, b: ITitleValueNumber) => {
    return b.value - a.value
  }

  return (
    <BaseDashboardComponent customStyle={customStyle} sectionTitle={<DashboardComponentTitle title={t('title')} />} isLoading={isLoading} isSuccess={positionRisk.length > 0} isError={!!error}>
      <LCPieChartUniNew
        data={positionRisk}
        dataTotal={0}
        options={{
          color: BarColor.MULTI_DEPARTMENT,
          label: CustomLabels.STATUS_EVALUATION_PIE,
          customPercentage: false,
        }}
      />
    </BaseDashboardComponent>
  )
}

export default RiskHrDashboardRiskPiePosition
