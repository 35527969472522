import moment from 'moment'
import i18n, { initI18n } from './i18n'

export const initLanguage = async (selectedLanguage: string): Promise<boolean> => {
  try {
    // Define a mapping of language codes to moment.js locale files
    const momentLocaleMap: { [key: string]: string } = {
      en: 'en-il',
      es: 'es-do',
    }

    // Load the appropriate moment.js locale file based on the selected language
    const momentLocale = momentLocaleMap[selectedLanguage] || selectedLanguage
    require(`moment/locale/${momentLocale}`)
    console.log(`initLanguage.ts:: moment language was changed to: ${momentLocale}`)

    // Set the moment.js locale
    moment.locale(selectedLanguage)

    // Initialize i18n and change the language
    await initI18n(selectedLanguage)
    await i18n.changeLanguage(selectedLanguage)
    console.log(`initLanguage.ts:: success locale: ${selectedLanguage}`)

    return true
  } catch (e) {
    console.error('initLanguage.ts:: error', e)
    return false
  }
}
