import './lc-severity.scss'
import { CSSProperties, FC, HTMLAttributes } from 'react'
import ClassNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { getSeverityFromPercentages } from './lc-severity-helper'

export interface ILCSeverityProps {
  maxSeverity: number
  customStyle?: CSSProperties
}

export const LCSeverity: FC<ILCSeverityProps & HTMLAttributes<HTMLDivElement>> = ({
  maxSeverity,
  customStyle,
  ...props
}: ILCSeverityProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'settings.risk.severity' })

  const wrapper = ClassNames({
    balloonWrapper: true,
    veryLow: maxSeverity <= 25,
    low: maxSeverity > 25 && maxSeverity <= 50,
    medium: maxSeverity > 50 && maxSeverity <= 70,
    high: maxSeverity > 70 && maxSeverity <= 85,
    critical: maxSeverity > 86,
  })

  return (
    <div className={wrapper} style={customStyle} {...props}>
      {getSeverityFromPercentages(maxSeverity, t)}
    </div>
  )
}
