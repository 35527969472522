import * as React from 'react'
import { SVGProps } from 'react'

const SvgArrowBottom = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
    <path d="M9 1 5 5 1 1" stroke="currentColor" strokeWidth={1.5} strokeLinejoin="round" />
  </svg>
)

export default SvgArrowBottom
