import './lc-name-letters.scss'

import React from 'react'
import { CSSProperties, FC } from 'react'
import { getInitials } from '../../../utils/fullname'

export interface ILogoProps {
  firstName: string
  lastName: string
  customStyle?: CSSProperties
}

export const LCNameLetters: FC<ILogoProps> = ({ firstName, lastName, customStyle, ...props }: ILogoProps) => {
  const initials = getInitials(lastName, firstName)
  return (
    // <div className="name-letters-wrapper" style={{ ...customStyle, backgroundColor: getSoftColorForLetter(initials.charAt(0)) }} {...props}>
    <div className="name-letters-wrapper" style={{ ...customStyle, backgroundColor: '#CCCCFF' }} {...props}>
      <div className="name-letters">{initials}</div>
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getSoftColorForLetter(letter: string): string {
  const softColorMap: Record<string, string> = {
    a: '#FFCCCC', // Light Red
    b: '#CCFFCC', // Light Green
    c: '#CCCCFF', // Light Blue
    d: '#FFFFCC', // Light Yellow
    e: '#FFCCFF', // Light Magenta
    f: '#CCFFFF', // Light Cyan
    g: '#E6B8AF', // Soft Maroon
    h: '#B7E1CD', // Soft Olive
    i: '#8FAACC', // Soft Navy
    j: '#F9E0A2', // Soft Yellow Green
    k: '#D5A6BD', // Soft Purple
    l: '#92CDDC', // Soft Teal
    m: '#EAD1DC', // Soft Silver
    n: '#C9C0BB', // Soft Gray
    o: '#FCE5CD', // Soft Orange
    p: '#FFF2CC', // Soft Lime
    q: '#D9EAD3', // Soft Sky Blue
    r: '#F4CCCC', // Soft Pink
    s: '#D9D2E9', // Soft Violet
    t: '#FFFCCC', // Very Light Yellow
    u: '#FFE599', // Very Light Peach
    v: '#D0E0E3', // Very Light Green
    w: '#EAD1DC', // Very Pale Green
    x: '#C9DAF8', // Very Light Blue
    y: '#CFE2F3', // Very Pale Blue
    z: '#D9D2E9', // Very Lavender
  }

  return softColorMap[letter.toLowerCase()] || '#F2F2F2' // Very Light Gray if not found
}
