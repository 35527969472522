import './lc-flag.scss'
import { CSSProperties, FC, useEffect, useState } from 'react'
import { FlagCodes } from './flags.codes'

export interface IFlagProps {
  flagCode: FlagCodes | string
  label?: string
  disabled?: boolean
  size?: 'xxsmall' | 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge'
  customStyle?: CSSProperties
  onClick?: () => void
}

export const LCFlag: FC<IFlagProps> = ({ label, disabled, flagCode, size = 'medium', customStyle, onClick, ...props }: IFlagProps) => {
  // const classes = ['lc-flag', disabled ? 'lc-flag--disabled' : ''].join(' ')
  const pathToSvg = `./../../../../flags/${flagCode.toLowerCase()}.svg`
  const [calculatedSize, setCalculatedSize] = useState(30)

  useEffect(() => {
    switch (size) {
      case 'xxsmall':
        setCalculatedSize(14)
        break
      case 'xsmall':
        setCalculatedSize(24)
        break
      case 'small':
        setCalculatedSize(30)
        break
      case 'medium':
        setCalculatedSize(60)
        break
      case 'large':
        setCalculatedSize(100)
        break
      case 'xlarge':
        setCalculatedSize(200)
        break
      default:
        setCalculatedSize(40)
    }
  }, [size])

  return (
    <div className="lc-flag-wrapper" {...props} style={{ height: `${calculatedSize}px` }}>
      <img src={pathToSvg} alt="flag" />
      {/* <ReactSVG
        className={classes}
        src={pathToSvg}
        beforeInjection={(svg) => {
          svg.setAttribute('style', `height: ${calculatedSize}px; width: auto`)
        }}
        fallback={() => <span>Error!</span>}
      /> */}
      {label ? <div className="lc-flag-label">{label}</div> : ''}
    </div>
  )
}
