import React, { CSSProperties, FC, HTMLAttributes, useEffect, useState } from 'react'
import { sumBy } from 'lodash'
import { BarChartLayout, BarColor, CustomLabels } from '../../../../interfaces/dashboard.interface'
import LCBarChartNew from '../../../ui/components/charts/lc-bar-chart'
import { ITitleValueNumber } from '@logicalcommander/types'
import { useTranslation } from 'react-i18next'
import { BaseDashboardComponent } from '../../../ui/components/base-dashboard/BaseDashboardComponent'
import { DashboardComponentTitle } from '../../../ui/components/base-dashboard/DashboardComponentTitle'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

const GeneralDashboardPosition: FC<Props> = ({ customStyle }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.general.employeesByPosition' })

  const isLoading = false // to remove later
  const error = false // to remove later

  const data = [
    {
      title: 'Account Manager',
      value: 21,
    },
    {
      title: 'Backend Engineer',
      value: 82,
    },
    {
      title: 'CEO',
      value: 1,
    },
    {
      title: 'CFO',
      value: 1,
    },
    {
      title: 'CHRO',
      value: 1,
    },
    {
      title: 'CIO',
      value: 1,
    },
    {
      title: 'CSIO',
      value: 1,
    },
    {
      title: 'CMO',
      value: 1,
    },
    {
      title: 'CSO',
      value: 1,
    },
    {
      title: 'CTO',
      value: 1,
    },
    {
      title: 'Customer Success Manager',
      value: 31,
    },
    {
      title: 'Driver',
      value: 74,
    },
    {
      title: 'Engineering Manager',
      value: 60,
    },
    {
      title: 'Finance',
      value: 90,
    },
    {
      title: 'Frontend Engineer',
      value: 25,
    },
    {
      title: 'HR',
      value: 95,
    },
    {
      title: 'HR Manager',
      value: 45,
    },
    {
      title: 'Marketing',
      value: 18,
    },
    {
      title: 'Operations',
      value: 64,
    },
    {
      title: 'Product Management',
      value: 29,
    },
    {
      title: 'Production',
      value: 98,
    },
    {
      title: 'Risk',
      value: 102,
    },
    {
      title: 'Sales',
      value: 70,
    },
    {
      title: 'Sales Person',
      value: 72,
    },
    {
      title: 'Security',
      value: 200,
    },
    {
      title: 'Security Engineer',
      value: 66,
    },
    {
      title: 'VP HR',
      value: 20,
    },
    {
      title: 'VP Marketing',
      value: 10,
    },
    {
      title: 'VP R&D',
      value: 10,
    },
    {
      title: 'VP Product Management',
      value: 200,
    },
    {
      title: 'VP Sales',
      value: 10,
    },
  ]

  const [positionRisk, setPositionRisk] = useState<ITitleValueNumber[]>([])
  const [positionSum, setPositionSum] = useState(0)

  useEffect(() => {
    if (data) {
      const sorted = [...data].sort(sortPositionRisk)
      const sumSorted = sumBy(sorted, 'value')
      setPositionSum(sumSorted)
      setPositionRisk(sorted)
    }
  }, [])

  const sortPositionRisk = (a: ITitleValueNumber, b: ITitleValueNumber) => {
    return b.value - a.value
  }

  return (
    <BaseDashboardComponent customStyle={customStyle} sectionTitle={<DashboardComponentTitle title={t('title')} subTitle={`${t('total')} ${positionSum}`} />} isLoading={isLoading} isSuccess={!!positionRisk} isError={!!error}>
      <LCBarChartNew
        data={positionRisk}
        options={{
          layout: BarChartLayout.HORIZONTAL,
          color: BarColor.COLOR_POSITION,
          label: CustomLabels.TOTAL_POSITION_EMPLOYEES,
        }}
      />
    </BaseDashboardComponent>
  )
}

export default GeneralDashboardPosition
