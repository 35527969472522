import { SVGProps } from 'react'

const SvgRisk40 = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 200 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect y="2" width="200" height="4" rx="2" fill="#E4ECFF" />
    <rect y="2" width="80" height="4" rx="2" fill="#FBB03B" />
    <circle cx="80" cy="4" r="3" fill="#E4ECFF" stroke="#555B6A" strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M80 12.9999V9L81.7716 10.5185C82.128 10.824 82.5802 10.9942 83.049 10.9999H92C93.1046 10.9999 94 11.8953 94 12.9999V20.9999C94 22.1044 93.1046 22.9999 92 22.9999H82C80.8954 22.9999 80 22.1044 80 20.9999V15V12.9999Z"
      fill="#E4ECFF"
    />
    <path
      d="M85.4023 19H85.918V18.124H86.5186V17.6523H85.918V14.7725H85.1533C85.0342 14.9482 84.9131 15.1299 84.79 15.3174C84.6689 15.5049 84.5469 15.6953 84.4238 15.8887C84.3008 16.082 84.1777 16.2773 84.0547 16.4746C83.9336 16.6699 83.8135 16.8662 83.6943 17.0635C83.5752 17.2588 83.458 17.4521 83.3428 17.6436V18.124H85.4023V19ZM83.8818 17.6553V17.6201C83.9736 17.4639 84.0684 17.3057 84.166 17.1455C84.2656 16.9834 84.3662 16.8213 84.4678 16.6592C84.5693 16.4971 84.6709 16.3359 84.7725 16.1758C84.876 16.0156 84.9775 15.8594 85.0771 15.707C85.1768 15.5527 85.2744 15.4043 85.3701 15.2617H85.4053V17.6553H83.8818ZM88.748 19.0996C89.0605 19.0996 89.3281 19.0098 89.5508 18.8301C89.7734 18.6504 89.9443 18.3955 90.0635 18.0654C90.1846 17.7334 90.2451 17.3408 90.2451 16.8877V16.8818C90.2451 16.4287 90.1846 16.0371 90.0635 15.707C89.9443 15.377 89.7734 15.1221 89.5508 14.9424C89.3281 14.7627 89.0605 14.6729 88.748 14.6729C88.4355 14.6729 88.168 14.7627 87.9453 14.9424C87.7246 15.1221 87.5547 15.377 87.4355 15.707C87.3164 16.0371 87.2568 16.4287 87.2568 16.8818V16.8877C87.2568 17.3408 87.3164 17.7334 87.4355 18.0654C87.5547 18.3955 87.7246 18.6504 87.9453 18.8301C88.168 19.0098 88.4355 19.0996 88.748 19.0996ZM88.748 18.6396C88.5469 18.6396 88.374 18.5684 88.2295 18.4258C88.0869 18.2832 87.9775 18.0811 87.9014 17.8193C87.8252 17.5576 87.7871 17.2471 87.7871 16.8877V16.8818C87.7871 16.5225 87.8252 16.2129 87.9014 15.9531C87.9775 15.6914 88.0869 15.4902 88.2295 15.3496C88.374 15.207 88.5469 15.1357 88.748 15.1357C88.9512 15.1357 89.124 15.207 89.2666 15.3496C89.4111 15.4902 89.5215 15.6914 89.5977 15.9531C89.6758 16.2129 89.7148 16.5225 89.7148 16.8818V16.8877C89.7148 17.2471 89.6758 17.5576 89.5977 17.8193C89.5215 18.0811 89.4111 18.2832 89.2666 18.4258C89.124 18.5684 88.9512 18.6396 88.748 18.6396Z"
      fill="#555B6A"
    />
  </svg>
)

export default SvgRisk40
