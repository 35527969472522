import './mainLayout.scss'
import React, { FC, useRef } from 'react'
import { selectSettings } from '../redux/reducers/settingsSlice'
import { useAppSelector } from '../redux/hooks'
import { ISettings } from '@logicalcommander/types'
import { eInitializeState, selectGeneral } from '../redux/reducers/generalSlice'
import { ServiceBar } from './serviceBar/ServiceBar'
import { PrivateRoutes } from '../routes/PrivateRoutes'
import { LCSideMenuNew } from './lc-sidemenu/LCSideMenu'

export const MainLayout: FC = () => {
  const { initializeState } = useAppSelector(selectGeneral)
  const settings: ISettings = useAppSelector(selectSettings)
  const componentRef = useRef<HTMLDivElement | null>(null)

  console.log('initializeState', initializeState)

  if (initializeState !== eInitializeState.FINSIHED) {
    return <div></div>
  }

  const { opened, closed } = settings.commonUX.dimensions.leftMenuWidth

  return (
    <div>
      <ServiceBar />
      <div className="main-wrapper">
        {/* <LCSideMenu expandedChangedEvent={(expanded) => setExpand(expanded)} /> */}
        <LCSideMenuNew width={opened} collapsedWidth={closed} />
        <div className="content-wrapper" ref={componentRef}>
          <div className="main-content">
            <PrivateRoutes />
          </div>
        </div>
      </div>
    </div>
  )
}
