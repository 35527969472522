export const dataInvoices: any[] = [
  {
    id: 1,
    pay_day: '2024-03-14',
    customer_name: 'Ana García',
    product_and_quantity: [
      {
        product: 'Documents',
        quantity: 1,
      },
      {
        product: 'SOS',
        quantity: 1,
      },
    ],
    payment_status: 'successful',
    total: '500',
    currency: 'USD',
  },
  {
    id: 2,
    pay_day: '2024-03-15',
    customer_name: 'Pedro López',
    product_and_quantity: [
      {
        product: 'Internal Complaints',
        quantity: 1,
      },
      {
        product: 'EmoRisk',
        quantity: 1,
      },
    ],
    payment_status: 'pending',
    total: '1005',
    currency: 'USD',
  },
  {
    id: 3,
    pay_day: '2024-03-16',
    customer_name: 'María Pérez',
    product_and_quantity: [
      {
        product: 'Investigate',
        quantity: 1,
      },
    ],
    payment_status: 'successful',
    total: '750',
    currency: 'USD',
  },
  {
    id: 4,
    pay_day: '2024-03-17',
    customer_name: 'Juan Martín',
    product_and_quantity: [
      {
        product: 'Risk-HR Short',
        quantity: 1,
      },
    ],
    payment_status: 'failed',
    total: '1250',
    currency: 'USD',
  },
  {
    id: 5,
    pay_day: '2024-03-18',
    customer_name: 'Sofía Gómez',
    product_and_quantity: [
      {
        product: 'Risk-HR Long',
        quantity: 1,
      },
    ],
    payment_status: 'pending',
    total: '1500',
    currency: 'USD',
  },
  {
    id: 6,
    pay_day: '2024-03-19',
    customer_name: 'David Martínez',
    product_and_quantity: [
      {
        product: 'Packages Platinium',
        quantity: 1,
      },
    ],
    payment_status: 'successful',
    total: '2000',
    currency: 'USD',
  },
  {
    id: 7,
    pay_day: '2024-03-20',
    customer_name: 'Laura Fernández',
    product_and_quantity: [
      {
        product: 'Packages Gold',
        quantity: 2,
      },
    ],
    payment_status: 'successful',
    total: '2500',
    currency: 'USD',
  },
  {
    id: 8,
    pay_day: '2024-03-21',
    customer_name: 'Miguel Rodríguez',
    product_and_quantity: [
      {
        product: 'Packages Silver',
        quantity: 1,
      },
    ],
    payment_status: 'pending',
    total: '3000',
    currency: 'USD',
  },
  {
    id: 9,
    pay_day: '2024-03-22',
    customer_name: 'Elena Ruiz',
    product_and_quantity: [
      {
        product: 'Documents',
        quantity: 1,
      },
      {
        product: 'Internal Complaints',
        quantity: 1,
      },
    ],
    payment_status: 'successful',
    total: '3500',
    currency: 'USD',
  },
  {
    id: 10,
    pay_day: '2024-03-23',
    customer_name: 'Alejandro Sánchez',
    product_and_quantity: [
      {
        product: 'SOS',
        quantity: 1,
      },
    ],
    payment_status: 'failed',
    total: '3500',
    currency: 'USD',
  },
]
