import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { v4 as uuidv4 } from 'uuid'
import { RootState } from '../redux/store'
import { ILCHttpResponse, IPaginationRequest, IPaginationResponse, IUser } from '@logicalcommander/types'
import { configManager } from '../../config/configManager'
import { IUserSummary } from '../pages/users/usersList/users.columns'

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${configManager.api.GATEWAY_URL}/user`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('x-request-id', uuidv4())
      const { token, companyToken } = (getState() as RootState).authReducer
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      if (companyToken) {
        headers.set('x-company-token', companyToken)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    getAdmins: builder.query<IUser[], void>({
      query: () => {
        return {
          url: '/admins',
        }
      },
      transformResponse: (rawResult: ILCHttpResponse<IUser[]>, meta) => {
        return rawResult.data as IUser[]
      },
    }),
    getUsers: builder.mutation<IPaginationResponse<IUserSummary[]>, IPaginationRequest>({
      query: ({ pageIndex, pageSize, sortBy, search }) => {
        return {
          url: '/multiple/pagination',
          method: 'POST',
          body: {
            pageIndex,
            pageSize,
            sortBy,
            search,
          },
        }
      },
      transformResponse: (rawResult: ILCHttpResponse<IPaginationResponse<IUserSummary[]>>, meta) => {
        return rawResult.data as IPaginationResponse<IUserSummary[]>
      },
    }),
    deleteUsers: builder.mutation<string[], string[]>({
      query: (identities) => ({
        url: '/multiple',
        method: 'DELETE',
        body: {
          identities,
        },
      }),
      transformResponse: (rawResult: ILCHttpResponse<string[]>, meta) => {
        return rawResult.data as string[]
      },
    }),
  }),
})

export const { useGetUsersMutation, useDeleteUsersMutation, useLazyGetAdminsQuery } = usersApi
