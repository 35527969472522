export const parse2string = (object: any): string => {
  try {
    return JSON.stringify(object)
  } catch (err) {
    return object
  }
}

export const parse2Json = (text: string | null): any => {
  try {
    return text && JSON.parse(text)
  } catch (err) {
    return text
  }
}
