import { LCPopUpInformative } from './LCPopUpInformative'

export const myRequests_columns = (t: any) => [
  {
    Header: t('columns.update_date'),
    accessor: 'update_date',
  },
  {
    Header: t('columns.customer_name'),
    accessor: 'customer_name',
  },
  {
    Header: t('columns.product_and_quantity'),
    accessor: 'product_and_quantity',
    Cell: (row: any) => {
      const product = row.row.original.product_and_quantity
      return <div>{product.map((item: { product: string; quantity: string }) => `${item.product}; `)}</div>
    },
  },
  {
    Header: t('columns.status_requests'),
    accessor: 'status_requests',
    Cell: (row: any) => {
      const modifiedBoolean = row.row.original.modified !== undefined
      const nameRequests = row.row.original.customer_name
      const status = row.row.original.status_requests
      const RequestsData = row.row.original.product_and_quantity
      const modifiedData = modifiedBoolean ? row.row.original.modified : ''
      return (
        <div>
          <span>{status}</span>
          {modifiedBoolean ? (
            <LCPopUpInformative nameRequests={nameRequests} requestsData={RequestsData} modifiedData={modifiedData} />
          ) : null}
        </div>
      )
    },
  },
]
