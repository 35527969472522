import './positions-groups-mapping.scss'

import React, { CSSProperties, HTMLAttributes, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../../hooks/redux-hooks'
import { selectSettings } from '../../../../redux/reducers/settingsSlice'
import { IEnumsPositionGroupSettingsItem } from '@logicalcommander/types'
import { useUpdateGroupPositionsMutation } from '../../../../services/settings.service'
import { toast } from 'react-toastify'
import { LCToast } from '../../../../ui/components/lc-toast/Toast'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { LCButton } from '../../../../ui/components/lc-button/LCButton'
import { arraysEqual } from '../../../../utils/object'
import deepEqual from 'deep-equal'

export interface IPositionsGroupsMappingProps extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

export const PositionsGroupsMapping = ({ customStyle }: IPositionsGroupsMappingProps) => {
  const [updateGroupsPositions, { data: dataUpdate, error: errorUpdate }] = useUpdateGroupPositionsMutation()
  const { t } = useTranslation('translation', { keyPrefix: 'settings.enumeratedType.positionsGroupsMapping' })
  const { positionsGroups, positions } = useAppSelector(selectSettings).enums
  const [groups, setGroups] = useState([...positionsGroups])

  const positionsMapping = positions.reduce((acc: any, position) => {
    acc[position.id] = position.name
    return acc
  }, {})

  useEffect(() => {
    if (!errorUpdate && dataUpdate && dataUpdate?.length > 0) {
      toast.success(<LCToast title={t('successUpdate.title')} body={t('successUpdate.body')} />)
      setGroups(dataUpdate)
    } else if (errorUpdate) {
      toast.error(<LCToast title={t('errorUpdate.title')} body={t('errorUpdate.body')} />)
    }
  }, [dataUpdate, errorUpdate])

  const onDragEnd = (result: any) => {
    const { destination, source, draggableId } = result

    if (!destination) return

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }

    const startGroup = groups.find((i) => String(i.id) === source.droppableId)
    const endGroup = groups.find((i) => String(i.id) === destination.droppableId)
    console.log('======= startGroup, endGroup', startGroup, endGroup)

    if (startGroup && startGroup === endGroup) {
      console.log('<======= if (startGroup && startGroup === endGroup)')
      const newPositionIds = [...startGroup?.positions] || []
      newPositionIds.splice(source.index, 1)
      newPositionIds.splice(destination.index, 0, Number(draggableId))

      const newGroup: IEnumsPositionGroupSettingsItem = {
        ...startGroup,
        positions: newPositionIds,
      }

      const updatedGroups = groups.map((g) => {
        if (g.id === newGroup.id) {
          return newGroup
        }
        return g
      })
      setGroups(updatedGroups)

      return
    }

    // Moving from one group to another
    let newStartGroup: IEnumsPositionGroupSettingsItem
    if (startGroup && endGroup) {
      console.log('<======= if (startGroup && endGroup)')
      const startPositionIds = [...startGroup?.positions] || []
      startPositionIds.splice(source.index, 1)
      newStartGroup = {
        ...startGroup,
        positions: startPositionIds,
      }

      const endPositionIds = [...endGroup?.positions] || []
      endPositionIds.splice(destination.index, 0, Number(draggableId))
      const newEndGroup: IEnumsPositionGroupSettingsItem = {
        ...endGroup,
        positions: endPositionIds,
      }

      if (groups && newStartGroup && newEndGroup) {
        // Replacing the groups
        const updatedGroups = groups.map((g) => {
          if (g.id === newStartGroup.id) {
            return newStartGroup
          }
          if (g.id === newEndGroup.id) {
            return newEndGroup
          }
          return g
        })
        setGroups(updatedGroups)
      }
    }
  }

  const submit = () => {
    const changedGroups: IEnumsPositionGroupSettingsItem[] = groups.filter((initial) => {
      const after = positionsGroups.find((g) => g.id === initial.id)
      return !arraysEqual(initial.positions, after?.positions || [])
    })
    updateGroupsPositions(changedGroups)
  }

  return (
    <div className="positions-groups-mapping" style={customStyle}>
      <h2>{t('title')}</h2>
      <div className="description">{t('description')}</div>
      <div className="sections">
        <DragDropContext onDragEnd={onDragEnd}>
          {groups.map((group: IEnumsPositionGroupSettingsItem) => {
            const positionsIds: number[] = group.positions
            return (
              <Droppable key={group.id} droppableId={String(group.id)}>
                {(provided, snapshot) => (
                  <div
                    className="section"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{ backgroundColor: snapshot.isDraggingOver ? '#eeeeee' : '#fafafa' }}>
                    <h3>{group.name}</h3>
                    {positionsIds.map((positionId: number, index: number) => (
                      <Draggable key={positionId} draggableId={String(positionId)} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="draggable"
                            style={{
                              backgroundColor: snapshot.isDragging ? '#cacaca' : '#eaeaea',
                              ...provided.draggableProps.style,
                            }}>
                            {positionsMapping[positionId]}
                            {positionId}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            )
          })}
        </DragDropContext>
      </div>
      <LCButton label={t('submitButton')} onClick={submit} disabled={deepEqual(positionsGroups, groups)} />
    </div>
  )
}
