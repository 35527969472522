import * as Yup from 'yup'
import { lcRegex } from '../../../utils/regex'

const registerUserValidationRules = (t: any, v: any) =>
  Yup.object().shape({
    identity: Yup.string()
      .required(v('required', { field: t('fields.identity') }))
      .min(5, v('short', { field: t('fields.identity'), short: 5, long: 50 }))
      .max(50, v('long', { field: t('fields.identity'), short: 5, long: 50 })),
    firstName: Yup.string()
      .required(v('required', { field: t('fields.firstName') }))
      .min(3, v('short', { field: t('fields.firstName'), short: 3, long: 30 }))
      .max(30, v('long', { field: t('fields.firstName'), short: 3, long: 30 })),
    middleName: Yup.string()
      .min(3, v('short', { field: t('fields.middleName'), short: 3, long: 30 }))
      .max(30, v('long', { field: t('fields.middleName'), short: 3, long: 30 })),
    lastName: Yup.string()
      .required(v('required', { field: t('fields.lastName') }))
      .min(3, v('short', { field: t('fields.lastName'), short: 3, long: 30 }))
      .max(30, v('long', { field: t('fields.lastName'), short: 3, long: 30 })),
    mobile: Yup.string()
      .required(v('required', { field: t('fields.mobile') }))
      .matches(lcRegex.mobileNumber, v('valid', { field: t('fields.mobile') }))
      .min(6, v('short', { field: t('fields.mobile'), short: 6, long: 20 }))
      .max(20, v('long', { field: t('fields.mobile'), short: 6, long: 20 })),
    email: Yup.string()
      .required(v('required', { field: t('fields.email') }))
      .email(v('valid', { field: t('fields.email') }))
      .min(6, v('short', { field: t('fields.email'), short: 6, long: 50 }))
      .max(50, v('long', { field: t('fields.email'), short: 6, long: 50 })),
    password: Yup.string()
      .required(v('required', { field: t('fields.password') }))
      .min(6, v('between', { field: t('fields.password'), min: 6, max: 20 }))
      .max(20, v('between', { field: t('fields.password'), min: 6, max: 20 }))
      .matches(/^(?=.*\d)(?=.*[!@#$%^&*])[0-9a-zA-Z!@#$%^&*]{6,20}$/, v('complexPassword')),
    confirmPassword: Yup.string()
      .required(v('required', { field: t('fields.confirmPassword') }))
      .oneOf([Yup.ref('password')], v('matchPasswords')),
  })

const registerCompanyAddressValidationRules = (t: any, v: any) =>
  Yup.object().shape({
    country: Yup.string().required(v('required', { field: t('fields.country') })),
    city: Yup.string()
      .required(v('required', { field: t('fields.city') }))
      .min(2, v('short', { field: t('fields.city'), short: 2, long: 30 }))
      .max(30, v('long', { field: t('fields.city'), short: 2, long: 30 })),
    addressLine1: Yup.string()
      .min(3, v('short', { field: t('fields.addressLine1'), short: 3, long: 50 }))
      .max(50, v('long', { field: t('fields.addressLine1'), short: 3, long: 50 })),
    addressLine2: Yup.string()
      .min(3, v('short', { field: t('fields.addressLine2'), short: 3, long: 50 }))
      .max(50, v('long', { field: t('fields.addressLine2'), short: 3, long: 50 })),
    postcode: Yup.string()
      .min(3, v('short', { field: t('fields.postcode'), short: 3, long: 20 }))
      .max(20, v('long', { field: t('fields.postcode'), short: 3, long: 20 })),
  })

const registerCompanyValidationRules = (t: any, v: any) =>
  Yup.object().shape({
    companyId: Yup.string()
      .required(v('required', { field: t('fields.companyId') }))
      .min(3, v('short', { field: t('fields.companyId'), short: 3, long: 30 }))
      .max(30, v('long', { field: t('fields.companyId'), short: 3, long: 30 })),
    name: Yup.string()
      .required(v('required', { field: t('fields.name') }))
      .min(6, v('short', { field: t('fields.name'), short: 6, long: 50 }))
      .max(50, v('long', { field: t('fields.name'), short: 6, long: 50 })),
    website: Yup.string()
      .min(6, v('short', { field: t('fields.website'), short: 6, long: 100 }))
      .max(100, v('long', { field: t('fields.website'), short: 6, long: 100 })),
    phone: Yup.string()
      .matches(lcRegex.mobileNumber, v('valid', { field: t('company_details.company_mobile') }))
      .min(6, v('short', { field: t('company_details.company_mobile'), short: 6, long: 20 }))
      .max(20, v('long', { field: t('company_details.company_mobile'), short: 6, long: 20 })),
    address: registerCompanyAddressValidationRules(t, v),
  })

export const registerCompanyValidationSchema = (t: any, v: any) =>
  Yup.object().shape({
    user: registerUserValidationRules(t, v),
    company: registerCompanyValidationRules(t, v),
  })
