const statusEvaluationPie = 'assessments'
const alertEvaluationPie = 'assessments'
const allAssesmentRiskHr = 'assessments'
const alertRiskLevels = 'questions'
const topicAlertRisk = 'topic'
const alertRisk = 'cases'
const totalDepartment = 'alert'
const totalPosition = 'alert'
const alertTotal = 'alert'
const totalEmployees = 'Employees'
const totalPosionEmployes = 'Position'
const totalDepartamentsEmployes = 'Departaments'
const emoRiskAttendanceWomanMen = ['Woman', 'Men']
const emoRiskAttendanceBar = ['Normal Risk', 'Low risk', 'Medium Risk', 'High Risk', 'Critical Risk']

const emoRiskEmotionAge = [
  'Energy',
  'Happiness',
  'Anger',
  'Stress',
  'Excitement',
  'Sadness',
  'MentalLoad',
  'Discomfort',
  'ExtremeEmotion',
  'EmotionalStress',
  'Dissatisfaction',
  'Burnout',
  'Depression',
]

export const customLabel: any = {
  statusEvaluationPie: (i: number) => (typeof i === 'number' ? statusEvaluationPie[i] : statusEvaluationPie),
  alertEvaluationPie: (i: number) => (typeof i === 'number' ? alertEvaluationPie[i] : alertEvaluationPie),
  alertRiskLevels: (i: number) => (typeof i === 'number' ? alertRiskLevels[i] : alertRiskLevels),
  topicAlertRisk: (i: number) => (typeof i === 'number' ? topicAlertRisk[i] : topicAlertRisk),
  alertRisk: (i: number) => (typeof i === 'number' ? alertRisk[i] : alertRisk),
  totalDepartment: (i: number) => (typeof i === 'number' ? totalDepartment[i] : totalDepartment),
  totalPosition: (i: number) => (typeof i === 'number' ? totalPosition[i] : totalPosition),
  totalEmployees: (i: number) => (typeof i === 'number' ? totalEmployees[i] : totalEmployees),
  alertTotal: (i: number) => (typeof i === 'number' ? alertTotal[i] : alertTotal),
  emoRiskAttendanceBar: (i: number) => (typeof i === 'number' ? emoRiskAttendanceBar[i] : emoRiskAttendanceBar),
  emoRiskAttendanceWomanMen: (i: number) => (typeof i === 'number' ? emoRiskAttendanceWomanMen[i] : emoRiskAttendanceWomanMen),
  emoRiskEmotionAge: (i: number) => (typeof i === 'number' ? emoRiskEmotionAge[i] : emoRiskEmotionAge),
  totalPosionEmployes: (i: number) => (typeof i === 'number' ? totalPosionEmployes[i] : totalPosionEmployes),
  totalDepartamentsEmployes: (i: number) => (typeof i === 'number' ? totalDepartamentsEmployes[i] : totalDepartamentsEmployes),
  allAssesmentRiskHr: (i: number) => (typeof i === 'number' ? allAssesmentRiskHr[i] : allAssesmentRiskHr),
}
