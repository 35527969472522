import * as React from 'react'
import { SVGProps } from 'react'

const SvgArrowUp = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M9 5 5 1 1 5" stroke="#555B6A" strokeWidth={1.5} strokeLinejoin="round" />
  </svg>
)

export default SvgArrowUp
