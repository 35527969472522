import { IEnumsSettingsItem } from '@logicalcommander/types'

export const findObjectDifferences = <T extends Record<string, any>>(initialValues: T, newValues: T): Partial<T> => {
  const diff: Partial<T> = {}

  for (const key in initialValues) {
    if (Object.prototype.hasOwnProperty.call(initialValues, key)) {
      const initialValue = initialValues[key]
      const newValue = newValues[key]

      if (initialValue !== newValue) {
        if (typeof initialValue === 'object' && typeof newValue === 'object') {
          // Recursively check nested objects
          const nestedDiff = findObjectDifferences(initialValue, newValue)
          if (Object.keys(nestedDiff).length > 0) {
            // Use type assertion here
            diff[key] = nestedDiff as T[Extract<keyof T, string>]
          }
        } else {
          diff[key] = newValue
        }
      }
    }
  }

  return diff
}

export const findConfigurationDataItemDifferences = (arr1: IEnumsSettingsItem[], arr2: IEnumsSettingsItem[]): IEnumsSettingsItem[] => {
  const diff: IEnumsSettingsItem[] = []

  const map1 = arr1.reduce(
    (acc, obj) => {
      acc[obj.id] = obj
      return acc
    },
    {} as Record<number, IEnumsSettingsItem>
  )

  const map2 = arr2.reduce(
    (acc, obj) => {
      acc[obj.id] = obj
      return acc
    },
    {} as Record<number, IEnumsSettingsItem>
  )

  for (const id in map1) {
    if (!map2[id] || map2[id].name !== map1[id].name) {
      diff.push(map1[id])
    }
  }

  for (const id in map2) {
    if (!map1[id] || map1[id].name !== map2[id].name) {
      diff.push(map2[id])
    }
  }

  return diff
}
