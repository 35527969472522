import './lc-form-textarea.scss'
import { CSSProperties, ReactElement, TextareaHTMLAttributes } from 'react'

interface Props<ObjectType> extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  inputId: keyof ObjectType
  label: string
  required?: boolean
  customStyle?: CSSProperties
  error?: any
}

export const LCFormTextArea = <ObjectType,>({
  label,
  inputId,
  required,
  customStyle,
  error,
  ...props
}: Props<ObjectType>): ReactElement => {
  return (
    <div className="textarea-Wrapper" style={customStyle}>
      <label className="textarea-label">
        {label} {required ? '*' : ''}
      </label>
      <textarea className="textarea-input" name={inputId as string} {...props} />
      <div className="textarea-error">{error}</div>
    </div>
  )
}
