import { FC, ReactElement } from 'react'
import { IFilter, addFilter, selectFilters } from '../../../../redux/reducers/filtersSlice'
import { ILCActionPopupItemData, LCActionPopup } from '../../lc-action-popup/LCActionPopup'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { useTranslation } from 'react-i18next'
import { eTableName } from '@logicalcommander/types'
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons/faClockRotateLeft'
import { LCToolbarButton } from '../../lc-toolbar-button/LCToolbarButton'

export interface Props {
  tableName: eTableName
}

export const LCTableActionFiltersHistory: FC<Props> = ({ tableName }: Props): ReactElement => {
  const filters = useAppSelector(selectFilters).history
  const dispatch = useAppDispatch()
  const { t } = useTranslation('translation', { keyPrefix: 'filters' })

  const _addFilter = (f: IFilter) => {
    dispatch(addFilter(f))
  }

  const actionPopupData: ILCActionPopupItemData[] = filters
    .filter((i) => i.tableName === tableName)
    .map((f) => {
      const text = []
      text.push(t(`keys.${f.search.keyName}`))
      text.push(f.search.type)
      text.push(f.search.value)
      return { text: text.join(' '), iconName: 'details', action: () => _addFilter(f) }
    })

  return actionPopupData.length === 0 ? (
    <div style={{ display: 'none' }}></div>
  ) : (
    <LCActionPopup
      position={['midleft', 'bottom']}
      data={actionPopupData}
      anchor={
        <div>
          <LCToolbarButton icon={faClockRotateLeft} text="History" />
        </div>
      }
    />
  )
}
