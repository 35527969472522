import './lc-employees-dropdown.scss'
import { CSSProperties, ReactElement } from 'react'
import { LCSearchUser } from '../../components/lc-search-user/lc-search-user'

export interface OptionType {
  value: number | string
  label: string
  isDisabled?: boolean
}

export interface GroupType {
  label: string
  options: OptionType[]
}

interface Props {
  inputId: string
  label: string
  required?: boolean
  customStyle?: CSSProperties
  error?: any
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

export const LCEmployeesDropdown = ({ label, inputId, required, customStyle, error, setFieldValue }: Props): ReactElement => {
  const handleUserSelection = (selectedOption: OptionType | null) => {
    setFieldValue(inputId, selectedOption?.value)
  }

  return (
    <div className="lc-dropdown-dynamic-Wrapper" style={customStyle}>
      <label className="lc-dropdown-dynamic-label">
        {label} {required && <span className="required">*</span>}
      </label>
      <LCSearchUser onSelectionChange={handleUserSelection} />
      {error ? <div className="lc-dropdown-dynamic-error">{error}</div> : null}
    </div>
  )
}
