import './widgets-general.scss'
import React, { CSSProperties, FC, HTMLAttributes, lazy, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BarColor, CustomLabels, BarChartLayout } from '../../../../interfaces/dashboard.interface'
import { useLazyGetTotalMenVsWomenQuery } from '../../../services/dashboard.service'
import { IDashboardWomanVsMen } from '@logicalcommander/types'
import { BaseDashboardComponent } from '../../../ui/components/base-dashboard/BaseDashboardComponent'
import { DashboardComponentTitle } from '../../../ui/components/base-dashboard/DashboardComponentTitle'

const LCDualBarChartNew = lazy(() => import('../../../ui/components/charts/lc-dual-bar-chart'))

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

const GeneralDashboardWomanMen: FC<Props> = ({ customStyle }: Props) => {
  const [getTotalMenVsWomen, { data, error, isLoading }] = useLazyGetTotalMenVsWomenQuery()
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.general.womanVsMenByAgeRange' })
  const [sumUsers, setSumUsers] = useState(0)

  useEffect(() => {
    getTotalMenVsWomen()
    setSumUsers(1433)
  }, [])

  return (
    <BaseDashboardComponent
      customStyle={customStyle}
      sectionTitle={<DashboardComponentTitle title={t('title')} subTitle={`${t('total')} ${sumUsers}`} />}
      isLoading={isLoading}
      isSuccess={!!data}
      isError={!!error}>
      <LCDualBarChartNew
        data={data as IDashboardWomanVsMen[]}
        options={{
          color: BarColor.COLOR_WOMEN_MEN,
          label: CustomLabels.ALERT_RISK,
          layout: BarChartLayout.VERTICAL,
        }}
      />
    </BaseDashboardComponent>
  )
}

export default GeneralDashboardWomanMen
