import './settings-severity-table.scss'

import { IEnumsSettingsItem, ISeverityTopicTRF } from '@logicalcommander/types'
import { TFunction } from 'i18next'
import * as React from 'react'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { faChevronDown, faChevronUp, faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAppSelector } from '../../../../../../hooks/redux-hooks'
import {
  getTRFPositions,
  getTRFPositionsGroups,
  selectPositions,
  selectPositionsGroups,
} from '../../../../../../redux/reducers/settingsSlice'
import { IUpdateSeveritySettings } from '../../../../../../services/settings.service'
import { LCDropdown } from '../../../../../../ui/components/lc-dropdown/LCDropdown'
import LCPopup from '../../../../../../ui/components/lc-popup/LCPopup'
import { getTRFDescription } from '../../../../../../utils/list-helper'
import { HandleDropdownChangeInterface, SettingsSeverityTableInterface } from '../SettingsRiskSeverityRules/severityAndImpact.interface'

const MARGIN_COLLAPSABLE = '0.15%'
function createData(
  id: number,
  name: string,
  positions: (IEnumsSettingsItem | undefined)[],
  trfValues: Record<string, { trf: string; topicIndex: string }>,
  severityPositions: Record<number, Record<string, { trf: string; topicIndex: string }>>
) {
  return {
    id,
    name,
    positions,
    trfValues,
    severityPositions,
  }
}

const isCustomCell = (positionId: number, topicIndex: string, positionGroupTRF: { id: number; topicsTRF: ISeverityTopicTRF }[]) => {
  const position = positionGroupTRF.find((entry) => entry.id === positionId)
  return position && position.topicsTRF[topicIndex as keyof ISeverityTopicTRF] !== undefined
}

function Row(props: {
  row: ReturnType<typeof createData>
  topicsCount: number
  trfLevels: { id: number; topicsTRF: ISeverityTopicTRF }[]
  editing: boolean
  t: TFunction
  tSeverity?: TFunction
  onChange: (changes: IUpdateSeveritySettings) => void
}) {
  const { t, tSeverity, row, trfLevels, editing, onChange } = props
  const [open, setOpen] = React.useState(false)

  let trfOptions = [
    {
      value: '2',
      label: tSeverity && tSeverity('veryLow'),
    },
    {
      value: '4',
      label: tSeverity && tSeverity('low'),
    },
    {
      value: '6',
      label: tSeverity && tSeverity('moderate'),
    },
    {
      value: '8',
      label: tSeverity && tSeverity('high'),
    },
    {
      value: '10',
      label: tSeverity && tSeverity('critical'),
    },
  ]

  const handleDropdownChange = ({ trf, topicIndex, positionId, topic }: HandleDropdownChangeInterface) => {
    const trfObject = { [`${topicIndex}`]: trf }
    const changes = generateChanges({ positionId, topicIndex, trfObject, topic })
    onChange(changes)
  }

  function generateChanges({
    positionId,
    topicIndex,
    trfObject,
    topic,
  }: {
    positionId?: number
    topicIndex: string
    trfObject: { [key: string]: number }
    topic?: string
  }) {
    const severityPositions: { id: number; topicsTRF: Record<string, number> }[] = []

    if (positionId !== undefined) {
      // Update severityPositions for a specific position
      let positionEntry = severityPositions.find((entry) => entry.id === positionId)
      if (!positionEntry) {
        positionEntry = { id: positionId, topicsTRF: {} }
        severityPositions.push(positionEntry)
      }
      positionEntry.topicsTRF = { ...positionEntry.topicsTRF, ...trfObject } // Fill with trfObject

      // Return only the updated object
      return {
        severityPositions: [positionEntry],
      }
    } else if (row && row.positions) {
      // Update all child positions with the group value
      row.positions.forEach((position) => {
        if (position) {
          let positionEntry = severityPositions.find((entry) => entry.id === position.id)
          if (!positionEntry) {
            positionEntry = { id: position.id, topicsTRF: {} }
            severityPositions.push(positionEntry)
          }
          positionEntry.topicsTRF = { ...positionEntry.topicsTRF, ...trfObject } // Fill with trfObject
        }
      })
    }

    // Update all rows for a specific topic
    if (topic) {
      row.positions.forEach((position) => {
        if (position) {
          let positionEntry = severityPositions.find((entry) => entry.id === position.id)
          if (!positionEntry) {
            positionEntry = { id: position.id, topicsTRF: {} }
            severityPositions.push(positionEntry)
          }
          positionEntry.topicsTRF[topicIndex] = trfObject[topicIndex]
        }
      })
    }

    return {
      severityPositions,
    }
  }

  const toggler = (
    <div style={{ cursor: 'pointer', marginLeft: 5 }}>{<FontAwesomeIcon icon={faCircleExclamation} size="lg" color="#555B6A" />}</div>
  )

  return (
    <Fragment>
      <div className="severity-table-topic-row">
        <div className="severity-table-topic-item topic-question-text">
          <div className="button" aria-label="expand row" onClick={() => setOpen(!open)}>
            {open ? (
              <FontAwesomeIcon icon={faChevronUp} size="sm" color="#555B6A" />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} size="sm" color="#555B6A" />
            )}
          </div>
          {row.name}
        </div>
        <>
          {Object.keys(row.trfValues).map((topic, index) => (
            <div key={index} className="severity-table-topic-item">
              {editing ? (
                <LCDropdown
                  classNameFromParent="severity-table-dropdown"
                  options={trfOptions}
                  defaultOption={row.trfValues[topic].trf}
                  onChange={(newValue) =>
                    handleDropdownChange({
                      trf: Number(newValue.value),
                      topicIndex: row.trfValues[topic].topicIndex,
                      positionId: undefined,
                      topic,
                    })
                  }
                />
              ) : (
                <>
                  {row.trfValues[topic].trf}
                  {row.positions.some((position) => position && isCustomCell(position.id, row.trfValues[topic].topicIndex, trfLevels)) && (
                    <LCPopup toggler={toggler}>
                      <span>This topic has custom values</span>
                    </LCPopup>
                  )}
                </>
              )}
            </div>
          ))}
        </>
      </div>
      {open && (
        <div style={{ margin: MARGIN_COLLAPSABLE }}>
          {row.positions.map(
            (position) =>
              position && (
                <div key={position.id} className="severity-table-topic-row expanded-content">
                  <div className="severity-table-topic-item expanded-content-text">{position.name}</div>
                  {Object.keys(row.trfValues).map((topic, index) => (
                    <div
                      key={index}
                      className={`severity-table-topic-item ${isCustomCell(position.id, row.trfValues[topic].topicIndex, trfLevels) ? 'custom-cell' : ''}`}>
                      {editing ? (
                        <LCDropdown
                          classNameFromParent="severity-table-dropdown"
                          options={trfOptions}
                          defaultOption={
                            row.severityPositions[position.id]?.[topic]?.trf === t('unknownTrf')
                              ? row.trfValues[topic].trf
                              : row.severityPositions[position.id]?.[topic]?.trf || row.trfValues[topic].trf
                          }
                          onChange={(newValue) =>
                            handleDropdownChange({
                              trf: Number(newValue.value),
                              topicIndex: row.trfValues[topic].topicIndex,
                              positionId: position.id,
                              topic,
                            })
                          }
                        />
                      ) : row.severityPositions[position.id]?.[topic]?.trf === t('unknownTrf') ? (
                        row.trfValues[topic].trf
                      ) : (
                        row.severityPositions[position.id]?.[topic]?.trf || row.trfValues[topic].trf
                      )}
                    </div>
                  ))}
                </div>
              )
          )}
        </div>
      )}
    </Fragment>
  )
}

export function SettingsSeverityTable({ topics, editing, onSave }: SettingsSeverityTableInterface) {
  const { t } = useTranslation('translation', { keyPrefix: 'settings.severityRules' })
  const { t: TSeverity } = useTranslation('translation', { keyPrefix: 'settings.risk.severity' })
  const topicsArray = topics && Object.values(topics)
  const positionsGroups = useAppSelector(selectPositionsGroups)
  const positionsInsideGroup = useAppSelector(selectPositions)
  const positionGroupTRF = useAppSelector(getTRFPositions)
  const positionInsideGroupTRF = useAppSelector(getTRFPositionsGroups)

  const rows = positionsGroups
    .map((group) => {
      const positions = group.positions.map((id) => positionsInsideGroup.find((pos) => pos.id === id)).filter(Boolean)
      const trfValues =
        topicsArray &&
        topicsArray.reduce(
          (acc, topic) => {
            const topicKey = Object.keys(topics).find((key) => topics[key] === topic) as keyof ISeverityTopicTRF
            if (!topicKey) return acc

            const trfLevel = positionInsideGroupTRF.find((level) => level.id === group.id)
            const trfValue = trfLevel && trfLevel.topicsTRF[topicKey]
            const trfDescription = trfValue ? 'N/A' && getTRFDescription(trfValue, TSeverity) : t('unknownTrf')
            acc[topic] = { trf: trfDescription, topicIndex: topicKey }
            return acc
          },
          {} as Record<string, { trf: string; topicIndex: string }>
        )

      const severityPositions = positions.reduce(
        (acc, position) => {
          if (position) {
            acc[position.id] = topicsArray
              ? topicsArray.reduce(
                  (topicAcc, topic: string) => {
                    const topicKey = Object.keys(topics).find((key) => topics[key] === topic) as keyof ISeverityTopicTRF
                    if (!topicKey) return topicAcc

                    const trfLevel = positionGroupTRF.find((level) => level.id === position.id)
                    const trfValue = trfLevel
                      ? trfLevel.topicsTRF[topicKey] || (trfValues && trfValues[topic].trf)
                      : trfValues && trfValues[topic].trf

                    const trfDescription = getTRFDescription(
                      typeof trfValue === 'string' ? parseInt(trfValue, 10) : trfValue !== undefined ? trfValue : 0,
                      TSeverity
                    )
                    topicAcc[topic] = { trf: trfDescription, topicIndex: topicKey }
                    return topicAcc
                  },
                  {} as Record<string, { trf: string; topicIndex: string }>
                )
              : {}
          }
          return acc
        },
        {} as Record<number, Record<string, { trf: string; topicIndex: string }>>
      )

      if (trfValues) return createData(group.id, group.name, positions, trfValues, severityPositions)
      return null
    })
    .filter(Boolean)

  const handleChange = (changes: IUpdateSeveritySettings) => {
    onSave(changes)
  }

  return (
    <div className="severity-table-topics">
      <div className="severity-table-topics-header">
        <div className="severity-table-topics-header-item header-text">{t('topics')}</div>

        {topicsArray &&
          topicsArray.map((topic) => (
            <div key={topic} className="severity-table-topics-header-item">
              {topic}
            </div>
          ))}
      </div>
      {rows.map(
        (row) =>
          row && (
            <Row
              t={t}
              tSeverity={TSeverity}
              key={row.name}
              row={row}
              topicsCount={topicsArray ? topicsArray.length : 0}
              trfLevels={positionGroupTRF}
              editing={editing}
              onChange={handleChange}
            />
          )
      )}
    </div>
  )
}
