import moment from 'moment'
import { IExtendedColumn } from '../../riskHrEvaluations/columns/riskHrEvaluations.columns'
import { IAdvancedFilterValuesStructure } from '../../../../ui/components/advanced-filter/advanced-filter-add-item/LCAdvancedFilterAddItem'
import { LCAdminName } from '../../../../ui/components/lc-admin-name/LCAdminName'
import { datesHelper } from '../../../../utils/dates'
import { IRiskHRCase, eCasePriority, eCaseStatus } from '@logicalcommander/types'
import { ICachedAdmin, cachedStorage, eStorageKey } from '../../../../utils/cachedStorage'

export const riskHrCases_columns = (navigate: any, t: any) =>
  [
    {
      Header: t('evaluations.case_id'),
      accessor: 'id',
      Cell: (row: any) => {
        const caseDetails: IRiskHRCase = row.row.original
        return (
          <button className="link-button" onClick={() => navigate(`/app/riskhr/case/${caseDetails.id}`)}>
            {caseDetails.id}
          </button>
        )
      },
    },
    {
      Header: t('evaluations.created_at'),
      accessor: 'createdAt',
      Cell: (row: any) => {
        const _case: IRiskHRCase = row.row.original
        return <div>{_case.createdAt && moment(_case.createdAt).fromNow()}</div>
      },
      filter: {
        type: 'date',
        values: datesHelper.getDatesRangeFromNow(),
      } as IAdvancedFilterValuesStructure,
    },
    {
      Header: t('evaluations.evaluation_id'),
      accessor: 'evaluationId',
      Cell: (row: any) => {
        const caseDetails: IRiskHRCase = row.row.original
        return (
          <button
            className="link-button"
            onClick={() => navigate(`/app/riskhr/report/${caseDetails.identity}/${caseDetails.evaluationId}`)}>
            {caseDetails.evaluationId}
          </button>
        )
      },
    },
    {
      Header: t('user.mandatory_fields.first_name'),
      accessor: 'userInfo.firstName',
      Cell: (row: any) => {
        const caseDetails: IRiskHRCase = row.row.original
        return (
          <button className="link-button" onClick={() => navigate(`/app/user/${caseDetails.identity}`)}>
            {caseDetails.userInfo ? caseDetails.userInfo.firstName : ''}
          </button>
        )
      },
    },
    {
      Header: t('user.mandatory_fields.middle_name'),
      accessor: 'userInfo.middleName',
    },
    {
      Header: t('user.mandatory_fields.last_name'),
      accessor: 'userInfo.lastName',
    },
    {
      Header: t('risk_hr_case.details.status'),
      accessor: 'status',
      Cell: (row: any) => {
        const _case: IRiskHRCase = row.row.original
        return <span>{_case?.status ? t(`caseStatus.${_case?.status}`) : ''}</span>
      },
      filter: {
        type: 'dropdown',
        values: Object.keys(eCaseStatus).map((i) => {
          return { label: t(`caseStatus.${i}`), value: i }
        }),
      } as IAdvancedFilterValuesStructure,
    },
    {
      Header: t('risk_hr_case.details.priority'),
      accessor: 'priority',
      Cell: (row: any) => {
        const _case: IRiskHRCase = row.row.original
        return <span>{_case?.priority ? t(`casePriority.${_case?.priority}`) : ''}</span>
      },
      filter: {
        type: 'dropdown',
        values: Object.keys(eCasePriority).map((i) => {
          return { label: t(`casePriority.${i}`), value: i }
        }),
      } as IAdvancedFilterValuesStructure,
    },
    {
      Header: t('evaluations.assigned_to'),
      Cell: (row: any) => {
        const caseDetails: IRiskHRCase = row.row.original
        return <LCAdminName identity={caseDetails.assignTo} />
      },
      accessor: 'assignTo',
      filter: {
        type: 'dropdown',
        values: cachedStorage.get<ICachedAdmin[]>(eStorageKey.ADMINS)?.map((i) => {
          return { label: i.name, value: i.identity }
        }),
      } as IAdvancedFilterValuesStructure,
    },
  ] as IExtendedColumn[]
