import React, { CSSProperties, FC, useState } from 'react'
import { useGetOthersProfileImageQuery } from '../../../services/user.service'
import { LCSvg } from '../../components/lc-svg/lc-svg'

interface Props {
  identity: string
  customStyle?: CSSProperties
  width?: number
  height?: number
}

const LCAwareOthersImageProfile: FC<Props> = ({ identity, customStyle, width = 40, height = 40 }) => {
  const { data: profileImageData, isFetching } = useGetOthersProfileImageQuery(identity ?? '', {
    skip: !identity, // Skip the query if identity is not provided
  })

  const [imageError, setImageError] = useState(false)

  const handleError = () => {
    setImageError(true) // Trigger fallback to LCSvg or a default image
  }

  if (isFetching) return <div>Loading...</div> // Show a loading state or spinner as needed

  return (
    <div style={{ ...customStyle, width, height, borderRadius: `${width / 2}px` }} className="lc-aware-others-image-profile-wrapper">
      {!imageError && profileImageData ? (
        <img src={profileImageData.url} alt={identity} onError={handleError} width={width} height={height} />
      ) : imageError ? (
        <LCSvg name="user-error" />
      ) : (
        <LCSvg name="user" />
      )}
    </div>
  )
}

export default React.memo(LCAwareOthersImageProfile)
