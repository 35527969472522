type License = {
  title: string
  type: string
  description: string
  value: string
  package: string | null
  currency: string
}
interface PropsMockData {
  [key: string]: License[]
}
export const purchaseMock: PropsMockData = {
  'risk-hr': [
    {
      title: 'Risk-HR Large',
      type: 'Risk Hr',
      description:
        'Risk-HR Large is a comprehensive license tailored to large organizations seeking to effectively manage various human resources risks. This license includes advanced features such as predictive analytics, real-time monitoring, and customizable reporting tools. With Risk-HR Large, organizations can proactively identify and address potential HR issues, ensuring a productive and compliant workforce.',
      value: '70',
      package: null,
      currency: 'USD',
    },
    {
      title: 'Risk-HR Short',
      type: 'Risk Hr',
      description:
        'Risk-HR Short is a streamlined license designed for small to medium-sized businesses looking to address short-term human resources risks. This license offers essential HR management tools, including employee tracking, performance evaluation, and compliance monitoring. With Risk-HR Short, organizations can quickly mitigate HR challenges and maintain operational efficiency.',
      value: '50',
      package: null,
      currency: 'USD',
    },
    {
      title: 'Focus',
      type: 'Other',
      description:
        'Focus is a specialized license focused on enhancing concentration and productivity in the workplace. This license includes features such as task prioritization, time management tools, and cognitive enhancement exercises. With Focus, users can optimize their workflow, minimize distractions, and achieve peak performance in their professional endeavors.',
      value: '20',
      package: null,
      currency: 'USD',
    },
  ],
  emoRisk: [
    {
      title: 'Licencia 1: emoRisk',
      type: 'emoRisk',
      description:
        'License 1 is an exciting license designed to address emotional risks in various personal and professional settings. This license offers innovative tools and techniques for managing emotions effectively, including mood tracking, stress management exercises, and emotional intelligence assessments. With License 1, users can develop resilience, navigate challenges, and enhance their emotional well-being.',
      value: '70',
      package: null,
      currency: 'USD',
    },
    {
      title: 'Licencia 2: emoRisk',
      type: 'emoRisk',
      description:
        'License 2 is an innovative license offering advanced solutions for managing emotional risks in diverse environments. This license includes features such as sentiment analysis, emotion regulation techniques, and personalized coping strategies. With License 2, users can gain insights into their emotional patterns, cultivate self-awareness, and build emotional resilience.',
      value: '50',
      package: null,
      currency: 'USD',
    },
    {
      title: 'Licencia 3: emoRisk',
      type: 'emoRisk',
      description:
        'License 3 is a unique license designed to explore and understand the complexities of emotional risks. This license offers comprehensive assessments, in-depth analysis tools, and personalized interventions to support emotional growth and development. With License 3, users can embark on a journey of self-discovery, gain clarity on their emotional landscape, and cultivate a deeper sense of well-being.',
      value: '20',
      package: null,
      currency: 'USD',
    },
  ],
  internal_complaints: [
    {
      title: 'Licencia 1: Internal_complaints',
      type: 'Internal_complaints',
      description:
        'License 1 is a comprehensive license designed to efficiently manage internal complaints within organizations. This license offers features such as case management, escalation workflows, and resolution tracking tools. With License 1, organizations can streamline their complaint handling processes, promote transparency, and foster a positive work culture.',
      value: '70',
      package: null,
      currency: 'USD',
    },
    {
      title: 'Licencia 2: Internal_complaints',
      type: 'Internal_complaints',
      description:
        'License 2 is an advanced license offering specialized solutions for addressing internal organization issues. This license includes features such as root cause analysis, conflict resolution strategies, and performance improvement plans. With License 2, organizations can identify systemic issues, implement targeted interventions, and enhance organizational effectiveness.',
      value: '50',
      package: null,
      currency: 'USD',
    },
    {
      title: 'Licencia 3: Internal_complaints',
      type: 'Internal_complaints',
      description:
        'License 3 is a premium license designed to resolve internal disputes quickly and effectively. This license offers expedited resolution processes, expert mediation services, and comprehensive conflict management strategies. With License 3, organizations can mitigate risks, minimize disruptions, and maintain a harmonious work environment.',
      value: '20',
      package: null,
      currency: 'USD',
    },
  ],
  sos: [
    {
      title: 'Licencia 1: SOS',
      type: 'SOS',
      description:
        'License 1 is an emergency license designed for critical situations requiring immediate assistance. This license includes features such as emergency contact lists, GPS tracking, and real-time alerts. With License 1, users can quickly access help, communicate vital information, and coordinate emergency responses.',
      value: '70',
      package: null,
      currency: 'USD',
    },
    {
      title: 'Licencia 2: SOS',
      type: 'SOS',
      description:
        'License 2 is a vital license offering essential support for emergency interventions. This license includes features such as emergency response protocols, crisis management tools, and remote assistance capabilities. With License 2, organizations can optimize their emergency preparedness, mitigate risks, and ensure the safety of individuals in distress.',
      value: '50',
      package: null,
      currency: 'USD',
    },
    {
      title: 'Licencia 3: SOS',
      type: 'SOS',
      description:
        'License 3 is an essential license providing emergency assistance and support around the clock. This license offers 24/7 access to emergency services, instant communication channels, and rapid response teams. With License 3, users can rest assured knowing that help is always just a call away, regardless of the situation.',
      value: '20',
      package: null,
      currency: 'USD',
    },
  ],
  documents: [
    {
      title: 'Licencia 1: Documents',
      type: 'Documents',
      description:
        'License 1 is a license designed to organize and manage documents digitally. This license includes features such as document categorization, version control, and secure access controls. With License 1, users can streamline document management processes, improve collaboration, and ensure data security.',
      value: '70',
      package: null,
      currency: 'USD',
    },
    {
      title: 'Licencia 2: Documents',
      type: 'Documents',
      description:
        'License 2 is an advanced license offering efficient management of electronic documents. This license includes features such as automated workflows, document lifecycle management, and integration with third-party applications. With License 2, users can optimize document processes, enhance productivity, and drive business growth.',
      value: '50',
      package: null,
      currency: 'USD',
    },
    {
      title: 'Licencia 3: Documents',
      type: 'Documents',
      description:
        'License 3 is a comprehensive license providing comprehensive solutions for archiving and managing important documents. This license offers features such as records retention policies, data migration tools, and compliance auditing capabilities. With License 3, users can ensure regulatory compliance, mitigate legal risks, and maintain accurate records.',
      value: '20',
      package: null,
      currency: 'USD',
    },
  ],
  packages_silver: [
    {
      title: 'emoRisk: Silver',
      type: 'emoRisk',
      description:
        'emoRisk is a license designed to efficiently manage emotional risks. This license offers features such as mood tracking, emotion regulation techniques, and stress management tools. With emoRisk, users can enhance their emotional well-being, build resilience, and improve overall quality of life.',
      value: '70',
      package: 'Silver',
      currency: 'USD',
    },
    {
      title: 'Documents: Silver',
      type: 'Documents',
      description:
        'Documents is a license designed to organize and manage documents digitally. This license includes features such as document categorization, version control, and secure access controls. With Documents, users can streamline document management processes, improve collaboration, and ensure data security.',
      value: '50',
      package: 'Silver',
      currency: 'USD',
    },
  ],
  packages_gold: [
    {
      title: 'emoRisk: Gold',
      type: 'emoRisk',
      description:
        'emoRisk is a license designed to efficiently manage emotional risks. This license offers features such as mood tracking, emotion regulation techniques, and stress management tools. With emoRisk, users can enhance their emotional well-being, build resilience, and improve overall quality of life.',
      value: '70',
      package: 'Gold',
      currency: 'USD',
    },
    {
      title: 'Documents: Gold',
      type: 'Documents',
      description:
        'Documents is a license designed to organize and manage documents digitally. This license includes features such as document categorization, version control, and secure access controls. With Documents, users can streamline document management processes, improve collaboration, and ensure data security.',
      value: '50',
      package: 'Gold',
      currency: 'USD',
    },
    {
      title: 'Internal Complaints: Gold',
      type: 'Internal Complaints',
      description:
        'Internal Complaints is a license designed to efficiently manage internal complaints within organizations. This license offers features such as case management, escalation workflows, and resolution tracking tools. With Internal Complaints, organizations can streamline their complaint handling processes, promote transparency, and foster a positive work culture.',
      value: '20',
      package: 'Gold',
      currency: 'USD',
    },
  ],
  packages_platinium: [
    {
      title: 'emoRisk: Platinum',
      type: 'emoRisk',
      description:
        'emoRisk is a license designed to efficiently manage emotional risks. This license offers features such as mood tracking, emotion regulation techniques, and stress management tools. With emoRisk, users can enhance their emotional well-being, build resilience, and improve overall quality of life.',
      value: '70',
      package: 'Platinum',
      currency: 'USD',
    },
    {
      title: 'Documents: Platinum',
      type: 'Documents',
      description:
        'Documents is a license designed to organize and manage documents digitally. This license includes features such as document categorization, version control, and secure access controls. With Documents, users can streamline document management processes, improve collaboration, and ensure data security.',
      value: '50',
      package: 'Platinum',
      currency: 'USD',
    },
    {
      title: 'Internal Complaints: Platinum',
      type: 'Internal Complaints',
      description:
        'Internal Complaints is a license designed to efficiently manage internal complaints within organizations. This license offers features such as case management, escalation workflows, and resolution tracking tools. With Internal Complaints, organizations can streamline their complaint handling processes, promote transparency, and foster a positive work culture.',
      value: '20',
      package: 'Platinum',
      currency: 'USD',
    },
    {
      title: 'SOS: Platinum',
      type: 'SOS',
      description:
        'SOS is a license designed for emergency situations requiring immediate assistance. This license includes features such as emergency contact lists, GPS tracking, and real-time alerts. With SOS, users can quickly access help, communicate vital information, and coordinate emergency responses.',
      value: '70',
      package: 'Platinum',
      currency: 'USD',
    },
  ],
}
