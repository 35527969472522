import * as React from 'react'
import { SVGProps } from 'react'

const SvgDetails = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10 19a9 9 0 1 0 0-18 9 9 0 0 0 0 18ZM10 13.6V10M10 6.4h.009"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgDetails
