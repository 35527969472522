import React, { useState } from 'react'
import './lc-form-inputText-password.scss'
import { CSSProperties, InputHTMLAttributes } from 'react'
import { LCSvg } from '../../lc-svg/lc-svg'
import LCPopup from '../../lc-popup/LCPopup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash'
import { SanitizeHTML } from '../../lc-sanitize-html/SanitizeHTML'

export interface LCFormInputTextPasswordProps<ObjectType> extends InputHTMLAttributes<HTMLInputElement> {
  inputId: keyof ObjectType
  label?: string
  labelPosition?: 'top' | 'left'
  required?: boolean
  disabled?: boolean
  customStyle?: CSSProperties
  error?: any
  hideError?: boolean
  touched?: any
  placeholder?: string
}

export const LCFormInputTextPassword = <ObjectType,>({
  label,
  labelPosition,
  inputId,
  error,
  hideError,
  required,
  disabled,
  customStyle,
  placeholder,
  ...props
}: LCFormInputTextPasswordProps<ObjectType>) => {
  const classes = ['inputText-input-password-wrapper', disabled ? 'disabled' : '', labelPosition ? labelPosition : 'top']
  const [showPassword, setShowPassword] = useState<boolean>(false)

  // Ensure value is defined to avoid switching from uncontrolled to controlled
  const controlledValue = props.value === undefined ? '' : props.value

  const toggleShowPassword = (event: React.MouseEvent) => {
    event.preventDefault()
    try {
      setShowPassword((prevState) => !prevState)
    } catch (error) {
      // Handle or log the error
      console.error('Caught error in toggleShowPassword:', error)
    }
  }

  return (
    <div className={classes.join(' ')} style={customStyle}>
      {label && (
        <label htmlFor={String(inputId)} className="inputText-label">
          {label} {required && <span className="required">*</span>}
        </label>
      )}

      <div className="inputText-input-wrapper">
        <input
          className="inputText-input"
          id={String(inputId)}
          type={showPassword ? 'text' : 'password'}
          placeholder={placeholder}
          disabled={disabled}
          value={controlledValue}
          {...props}
        />

        <div className="inputText-input-eye-icon" onClick={toggleShowPassword}>
          {showPassword ? <FontAwesomeIcon icon={faEye} size="lg" /> : <FontAwesomeIcon icon={faEyeSlash} size="lg" />}
        </div>

        {!hideError && error && (
          <LCPopup
            position={['midleft', 'bottom']}
            toggleOn={'hover'}
            toggler={
              <div className="inputText-password-error-icon">
                <LCSvg name="error" customStyle={{ color: 'red' }} />
              </div>
            }>
            <div className="inputText-error">
              <SanitizeHTML html={error} />
            </div>
          </LCPopup>
        )}
      </div>
    </div>
  )
}
