/* eslint-disable @typescript-eslint/no-unused-vars */
import './user-details-header.scss'
import React, { FC, HTMLAttributes, useEffect, useState } from 'react'
import { IUser } from '@logicalcommander/types'
import { IDropdownOption, LCDropdown } from '../../../ui/components/lc-dropdown/LCDropdown'
import { datesHelper } from '../../../utils/dates'
import { getUserAddress } from '../user-results-detailed-page-helper'
import LCAwareOthersImageProfile from '../../../ui/awareComponents/lc-profile-image/LCAwareOthersImageProfile'
import { LCKeyValue } from '../../../ui/components/lc-key-value/LCKeyValue'
import { LCButton } from '../../../ui/components/lc-button/LCButton'
import { useNavigate } from 'react-router'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  user?: IUser
  doChangeDate: (option: IDropdownOption) => void
}

export const UserDetailsHeader: FC<Props> = ({ user, doChangeDate }: Props) => {
  const dates = datesHelper.getDatesRangeFromNow()
  const [selectedDates, setSelectedDates] = useState<IDropdownOption>(dates[6])
  const navigate = useNavigate()

  const changeDates = (value: IDropdownOption) => {
    setSelectedDates(value)
    doChangeDate(value)
  }

  return (
    <div className="user-details-header">
      {user ? (
        <div className="user-info">
          {user?.profileImage ? <LCAwareOthersImageProfile identity={user.identity} width={100} height={100} /> : null}
          <div className="user-info-text">
            <LCKeyValue keyValue={{ text: 'Email', value: user?.email }} />
            <LCKeyValue keyValue={{ text: 'Mobile', value: user?.mobile }} />
            <LCKeyValue keyValue={{ text: 'Address', value: getUserAddress(user) }} />
            <LCButton
              customStyle={{ marginTop: '20px', width: '150px' }}
              label={'Edit user'}
              onClick={() => navigate(`/app/user/edit/${user.identity}`)}
            />
          </div>
        </div>
      ) : null}

      <div className="dates-selection">
        <LCDropdown options={dates} placeholder="Please select" defaultOption={selectedDates} onChange={changeDates} />
      </div>
      <ul className="internal-links">
        <li>
          <a href="#risk-hr-evaluations">Risk-HR Assessments</a>
        </li>
        <li>
          <a href="#risk-hr-cases">Risk-HR Cases</a>
        </li>
        <li>
          <a href="#emorisk-emotions">EmoRisk Emotions</a>
        </li>
        <li>
          <a href="#emorisk-cases">EmoRisk Cases</a>
        </li>
        <li>
          <a href="#emorisk-attendance">EmoRisk Attendance</a>
        </li>
      </ul>
    </div>
  )
}
