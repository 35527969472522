import './lc-languages-selection.scss'

import React from 'react'
import { CSSProperties, FC } from 'react'
import { LCDropdown, IDropdownOption } from '../lc-dropdown/LCDropdown'
import { useAppSelector } from '../../../redux/hooks'
import { selectSettings } from '../../../redux/reducers/settingsSlice'
import { useTranslation } from 'react-i18next'
import { selectGeneral } from '../../../redux/reducers/generalSlice'

export interface Props {
  languageSelected: (languageCode: string) => void
  customStyle?: CSSProperties
  label?: string
  required?: boolean
  defaultLanguage?: string
}

export const LCLanguagesSelection: FC<Props> = ({ customStyle, label, required, defaultLanguage, languageSelected, ...props }: Props) => {
  const { riskHR } = useAppSelector(selectSettings)
  const { t: l } = useTranslation('translation', { keyPrefix: 'languages' })
  const { selectedLanguage } = useAppSelector(selectGeneral)

  let supportedLanguages: IDropdownOption[]
  if (riskHR.assessments) {
    supportedLanguages = riskHR.assessments.language.map((i) => ({ label: l(i), value: i }))
  } else {
    // Work around for now because we can not get the riskHR.assessments in login page.
    supportedLanguages = ['en', 'es', 'pt'].map((i) => ({ label: l(i), value: i }))
  }

  return (
    <div className="lc-languages-selection" style={customStyle} {...props}>
      {label && (
        <label className="inputText-label">
          {label} {required && <span className="required">*</span>}
        </label>
      )}

      <LCDropdown
        options={supportedLanguages}
        defaultOption={defaultLanguage || selectedLanguage || 'en'}
        onChange={(args: IDropdownOption) => languageSelected(args.value)}
      />
    </div>
  )
}
