import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { ICompanyInfo, IExtendedCompany, ILicenseFeatures, ILicenses } from '@logicalcommander/types'

const initialState: IExtendedCompany = {
  companyInfo: {} as ICompanyInfo,
  companyId: '',
  licenses: {} as ILicenses,
  licensesUsed: {} as ILicenses,
  features: {} as ILicenseFeatures,
  expirationDate: '',
}

export const extendedCompanySlice = createSlice({
  name: 'extendedCompanyReducer',
  initialState,
  reducers: {
    setExtendedCompany: (state: IExtendedCompany, action: PayloadAction<IExtendedCompany>) => {
      console.log('extendedCompanySlice:: setExtendedCompany: action.payload:', action.payload)
      const newState = { ...state, ...action.payload }
      console.log('extendedCompanySlice:: setExtendedCompany: state after update:', newState)
      return newState
    },
    setCompanyInfo: (state: IExtendedCompany, action: PayloadAction<ICompanyInfo>) => {
      console.log('extendedCompanySlice:: setCompanyInfo: action.payload:', action.payload)
      const newState = { ...state, ...action.payload }
      console.log('extendedCompanySlice:: setCompanyInfo: state after update:', newState)
      return newState
    },
  },
})

export const { setExtendedCompany, setCompanyInfo } = extendedCompanySlice.actions
export default extendedCompanySlice.reducer
export const selectExtendedCompany = (state: RootState) => state.extendedCompanyReducer
