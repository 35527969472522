import './personalAddressForm.scss'
import { FC, ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { LCFormInputText } from '../../../../../ui/components/forms/lc-form-inputText/lc-form-inputText'
import { IAddress, IUser } from '@logicalcommander/types'
import { LCFormDropdownDynamic } from '../../../../../ui/components/forms/lc-form-dropdown-dynamic/lc-form-dropdown-dynamic'
import { UserActionName } from '../../createUserHelper'
import { getAllCountriesDropdown, getAllStatesDropdown } from '../../../../../utils/list-helper'

interface Props {
  user: IUser | undefined
  formik: any
  actionName: UserActionName
  disabled?: boolean
}

export const PersonalAddress: FC<Props> = ({ user, disabled = false, formik, actionName }: Props): ReactElement => {
  const { t } = useTranslation('translation', { keyPrefix: 'user.personal_address' })
  const allCountriesOptions = getAllCountriesDropdown()
  const allStatesOptions = getAllStatesDropdown()

  console.log('formik.values', formik.values)

  useEffect(() => {
    if (formik.values?.personalAddress?.country !== 'US') {
      formik.values.personalAddress.state = undefined
    }
  }, [formik.values?.personalAddress?.country])

  return (
    <div className="base-section-wrapper">
      <div className="base-section">
        <LCFormDropdownDynamic
          required={false}
          label={t('country')}
          inputId="personalAddress.country"
          selectedValue={formik.values?.personalAddress?.country}
          disabled={disabled}
          setFieldValue={formik.setFieldValue}
          options={allCountriesOptions}
          error={formik.touched.personalAddress?.country && formik.errors.personalAddress?.country}
        />
        <LCFormInputText<IAddress>
          label={t('city')}
          required={false}
          inputId="personalAddress.city"
          inputType="text"
          disabled={disabled}
          error={formik.touched.personalAddress?.city && formik.errors.personalAddress?.city}
          {...formik.getFieldProps('personalAddress.city')}
        />

        <LCFormDropdownDynamic
          required={false}
          label={t('state')}
          inputId="personalAddress.state"
          selectedValue={formik.values?.personalAddress?.state}
          disabled={formik.values?.personalAddress?.country === 'US' ? false : disabled || true}
          setFieldValue={formik.setFieldValue}
          options={allStatesOptions}
          error={formik.touched.personalAddress?.state && formik.errors.personalAddress?.state}
        />

        <LCFormInputText<IAddress>
          label={t('post_code')}
          required={false}
          inputId="personalAddress.postcode"
          inputType="text"
          disabled={disabled}
          error={formik.touched.personalAddress?.postcode && formik.errors.personalAddress?.postcode}
          {...formik.getFieldProps('personalAddress.postcode')}
        />
      </div>
      <div className="base-section">
        <LCFormInputText<IAddress>
          label={t('address_line_1')}
          labelPosition="top"
          required={false}
          inputId="personalAddress.addressLine1"
          inputType="text"
          disabled={disabled}
          error={formik.touched.personalAddress?.addressLine1 && formik.errors.personalAddress?.addressLine1}
          {...formik.getFieldProps('personalAddress.addressLine1')}
        />

        <LCFormInputText<IAddress>
          label={t('address_line_2')}
          required={false}
          inputId="personalAddress.addressLine2"
          inputType="text"
          disabled={disabled}
          error={formik.touched.personalAddress?.addressLine2 && formik.errors.personalAddress?.addressLine2}
          {...formik.getFieldProps('personalAddress.addressLine2')}
        />
      </div>
    </div>
  )
}
