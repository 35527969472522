import { CSSProperties, FC, HTMLAttributes } from 'react'
import { IDropdownOption } from '../../forms/lc-form-dropDown/lc-form-dropDown'
import { searchColumnByKey } from './lc-advanced-filter-add-item-helper'
import { IExtendedColumn } from '../../../../pages/riskhr/riskHrEvaluations/columns/riskHrEvaluations.columns'
import { LCFormDropdownDynamic } from '../../forms/lc-form-dropdown-dynamic/lc-form-dropdown-dynamic'

export interface Props extends HTMLAttributes<any> {
  columns: IExtendedColumn[]
  formik: any // FormikConfig<FormikValues>
  onKeySelected: (column: IExtendedColumn) => void
  customStyle?: CSSProperties
}

export const LCAdvancedFilterAddItemKeys: FC<Props> = ({ columns, onKeySelected, formik, customStyle }: Props) => {
  const onSelectedKey = (value: string) => {
    console.log('formik onSelectedKey: ', value)
    formik.setFieldValue('key', value)
    onKeySelected(searchColumnByKey(columns, value))
  }

  console.log('formik.values', formik.values)

  const buildDropDownOptionsFromColumns = (columns: IExtendedColumn[]): IDropdownOption[] => {
    const dropDownOptionsResult: IDropdownOption[] = []
    columns.forEach((column: IExtendedColumn) => {
      if (column.columns) {
        column.columns.forEach((innerColumn: IExtendedColumn) => {
          if (!innerColumn.filter || (innerColumn.filter && innerColumn.filter.hide !== true)) {
            dropDownOptionsResult.push({
              label: `${column.Header as string} --> ${innerColumn.Header as string}`,
              value: innerColumn.accessor as string,
            })
          }
        })
      } else {
        if (!column.filter || (column.filter && column.filter.hide !== true)) {
          dropDownOptionsResult.push({ label: column.Header as string, value: column.accessor as string })
        }
      }
    })
    return dropDownOptionsResult
  }

  return (
    <LCFormDropdownDynamic
      customStyle={customStyle}
      inputId="key"
      selectedValue={String(formik.values.key)}
      placeholder="Select the key"
      setFieldValue={(key: string, value: string) => {
        onSelectedKey(value)
      }}
      options={buildDropDownOptionsFromColumns(columns)}
      {...formik.getFieldProps('key')}
    />
  )
}
