import './open-case-modal.scss'
import { CSSProperties, FC, useState } from 'react'
import { LCButton } from '../../../../../../ui/components/lc-button/LCButton'
import { useTranslation } from 'react-i18next'
import { LCInputText } from '../../../../../../ui/components/lc-input-text/LCInputText'

export interface Props {
  title: string
  onCloseModal: () => void
  onOpenCase: (title: string) => void
  customStyle?: CSSProperties
}

export const OpenCaseModal: FC<Props> = ({ customStyle, title, onCloseModal, onOpenCase }: Props) => {
  const [titleValue, setTitleValue] = useState<string>(title)
  const { t } = useTranslation('translation', { keyPrefix: 'risk_hr_case' })

  return (
    <div style={customStyle} className="open-case-modal">
      <LCInputText value={titleValue} onChange={setTitleValue} />
      <div className="buttons">
        <LCButton
          customStyle={{ marginRight: '10px' }}
          label={t('open_case_modal_button_open_case')}
          onClick={() => onOpenCase(titleValue)}
        />
        <LCButton label={t('open_case_modal_button_cancel')} onClick={onCloseModal} />
      </div>
    </div>
  )
}
