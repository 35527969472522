import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { v4 as uuidv4 } from 'uuid'
import { RootState } from '../redux/store'
import {
  IAssignEvaluationRequest,
  IEvaluationSpecific,
  IGetEvaluationRequest,
  IGetSpecificEvaluationRequest,
  IResendEmailRequest,
  IResendEmailResult,
  IUnAssignEvaluationRequest,
} from '../../interfaces/evaluations.interface'
import {
  IAssignResult,
  IEvaluationPreSignedQuestionAnsweredUrlRequest,
  ILCHttpResponse,
  IPaginationResponse,
  IPreSignedIndexedURL,
} from '@logicalcommander/types'
import { configManager } from '../../config/configManager'
import { IEvaluationSummary } from '../pages/riskhr/riskHrEvaluations/columns/riskHrEvaluations.columns'

export const evaluationsApi = createApi({
  reducerPath: 'evaluationsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${configManager.api.GATEWAY_URL}/evaluations`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('x-request-id', uuidv4())
      const { token, companyToken } = (getState() as RootState).authReducer
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      if (companyToken) {
        headers.set('x-company-token', companyToken)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    assignEvaluation: builder.mutation<IAssignResult, IAssignEvaluationRequest>({
      query: (assignRequest: IAssignEvaluationRequest) => ({
        url: `/assign`,
        method: 'POST',
        body: assignRequest,
      }),
      transformResponse: (rawResult: ILCHttpResponse<IAssignResult>, meta) => {
        return rawResult.data as IAssignResult
      },
    }),
    unassignEvaluation: builder.mutation<string[], IUnAssignEvaluationRequest[]>({
      query: (unassignRequest: IUnAssignEvaluationRequest[]) => ({
        url: `/unassign`,
        method: 'POST',
        body: unassignRequest,
      }),
      transformResponse: (rawResult: ILCHttpResponse<string[]>, meta) => {
        return rawResult.data as string[]
      },
    }),
    resendEvaluationEmail: builder.mutation<IResendEmailResult, IResendEmailRequest[]>({
      query: (resendAssignEmailRequest: IResendEmailRequest[]) => ({
        url: `/assign/resend`,
        method: 'POST',
        body: resendAssignEmailRequest,
      }),
      transformResponse: (rawResult: ILCHttpResponse<IResendEmailResult>, meta) => {
        return rawResult.data as IResendEmailResult
      },
    }),
    getEvaluationsResults: builder.mutation<IPaginationResponse<IEvaluationSummary[]>, IGetEvaluationRequest>({
      query: ({ pageIndex, pageSize, sortBy, search }) => {
        return {
          url: 'get-pagination',
          method: 'POST',
          body: {
            pageIndex,
            pageSize,
            sortBy,
            search,
          },
        }
      },
      transformResponse: (rawResult: ILCHttpResponse<IPaginationResponse<IEvaluationSummary[]>>, meta) => {
        return rawResult.data as IPaginationResponse<IEvaluationSummary[]>
      },
    }),
    getSpecificEvaluationsResults: builder.mutation<IEvaluationSpecific, IGetSpecificEvaluationRequest>({
      query: ({ identity, evaluationId }) => {
        return {
          url: `results/${identity}/${evaluationId}`,
          method: 'GET',
        }
      },
      transformResponse: (rawResult: ILCHttpResponse<IEvaluationSpecific>, meta) => {
        return rawResult.data as IEvaluationSpecific
      },
    }),
    getPreSignedEvaluationQuestionSoundFile: builder.mutation<IPreSignedIndexedURL[], IEvaluationPreSignedQuestionAnsweredUrlRequest>({
      query: (body) => {
        return {
          url: '/pre-signed/evaluation-question',
          method: 'POST',
          body,
        }
      },
      transformResponse: (rawResult: ILCHttpResponse<IPreSignedIndexedURL[]>, meta) => {
        return rawResult.data as IPreSignedIndexedURL[]
      },
    }),
  }),
})

export const {
  useAssignEvaluationMutation,
  useGetEvaluationsResultsMutation,
  useGetSpecificEvaluationsResultsMutation,
  useGetPreSignedEvaluationQuestionSoundFileMutation,
  useResendEvaluationEmailMutation,
  useUnassignEvaluationMutation,
} = evaluationsApi
