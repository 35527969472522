import { FC } from 'react'
import sanitizeHtml from 'sanitize-html'

export interface Props {
  html: string
  className?: string
}

export const SanitizeHTML: FC<Props> = ({ html, className }: Props) => {
  return html ? <div className={className} dangerouslySetInnerHTML={{ __html: sanitizeHtml(html) }}></div> : null
}
