import React, { FC, HTMLAttributes, ReactElement } from 'react'
import { CSSProperties } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { BaseDashboardComponent } from '../../../ui/components/base-dashboard/BaseDashboardComponent'
import { useAppSelector } from '../../../redux/hooks'
import { selectExtendedCompany } from '../../../redux/reducers/extendedCompanySlice'
import { CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, BarChart, ResponsiveContainer } from 'recharts'
import { DashboardComponentTitle } from '../../../ui/components/base-dashboard/DashboardComponentTitle'
import { CustomTooltip } from './helpers/licenses.helper'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

const EmoRiskLicenses: FC<Props> = ({ customStyle }: Props) => {
  const { licenses } = useAppSelector(selectExtendedCompany)
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.licenses.emoRisk' })

  const data = [
    {
      name: 'Users',
      remain: licenses.centrixLicenses.users - 10,
      used: 10, // TODO:// Israel
    },
  ]

  // Wrapper function for CustomTooltip
  const renderCustomTooltip = (props: any) => {
    return <CustomTooltip {...props} t={t} />
  }

  const LicenesBarChart = (): ReactElement => {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          layout="horizontal"
          margin={{
            top: 10,
            right: 50,
            left: 0,
            bottom: 74,
          }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" angle={-90} textAnchor="end" interval={0} height={70} />
          <YAxis />
          <Tooltip content={renderCustomTooltip} />
          <Legend
            layout="vertical"
            align="right"
            verticalAlign="middle"
            wrapperStyle={{ top: '40px', right: 20, transform: 'translate(10px, -50%)', width: '10%' }}
          />
          <Bar dataKey="used" name={t('used')} stackId="a" fill="#02A8FF" barSize={30} />
          <Bar dataKey="remain" name={t('remain')} stackId="a" fill="#80D4FF" barSize={30} radius={[5, 5, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>
    )
  }

  return (
    <BaseDashboardComponent
      customStyle={customStyle}
      sectionTitle={<DashboardComponentTitle title={t('title')} />}
      isLoading={false}
      isSuccess={!!data}
      isError={!data}>
      <LicenesBarChart />
    </BaseDashboardComponent>
  )
}

export default EmoRiskLicenses
