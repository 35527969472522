import { FC, ReactElement } from 'react'
import { LCAdvancedFilterItem } from '../advanced-filter/advanced-filter-item/LCAdvancedFilterItem'
import { useAppSelector } from '../../../hooks/redux-hooks'
import { LCTableActionAddFilter } from './components/LCTableActionAddFilter'
import { LCTableActionSearch } from './components/LCTableActionSearch'
import { IFilter, deleteFilter, eFilterType, selectFilters } from '../../../redux/reducers/filtersSlice'
import { LCTableActionFiltersHistory } from './components/LCTableActionFiltersHistory'
import { useAppDispatch } from '../../../redux/hooks'
import { LCTableActionAddFavoriteFilter } from './components/LCTableActionAddFavoriteFilter'
import { eTableName } from '@logicalcommander/types'
import { faPrint } from '@fortawesome/free-solid-svg-icons/faPrint'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons/faFileExcel'
import { LCToolbarButton } from '../lc-toolbar-button/LCToolbarButton'

export interface Props {
  columns: any
  tableName: eTableName
  exportData: any
}

export const LCTableServerPaginationActions: FC<Props> = (props: Props): ReactElement => {
  const { columns, tableName, exportData } = props
  const dispatch = useAppDispatch()
  const tableFilters: IFilter[] = useAppSelector(selectFilters).tableHeader.filter(
    (f) => f.tableName === tableName && f.type === eFilterType.FILTER
  )

  const deleteTableHeaderFilter = (f: IFilter) => {
    dispatch(deleteFilter(f))
  }

  const exportPDF = () => {
    exportData('pdf', false)
  }

  return (
    <div className="table-actions-container">
      <div className="table-actions-container-left">
        <LCTableActionSearch tableName={tableName} />
        {tableFilters.map((h, index: number) => (
          <LCAdvancedFilterItem key={index} index={index} filter={h.search} onDeleteFilter={() => deleteTableHeaderFilter(h)} />
        ))}
      </div>
      <div className="table-actions-container-right">
        <LCToolbarButton icon={faPrint} text="Print" action={exportPDF} />
        <LCToolbarButton icon={faFileExcel} text="Export" action={() => exportData('xlsx', false)} />
        <LCTableActionAddFilter tableName={tableName} columns={columns} />
        <LCTableActionAddFavoriteFilter tableName={tableName} />
        <LCTableActionFiltersHistory tableName={tableName} />
      </div>
    </div>
  )
}
