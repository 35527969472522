import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { v4 as uuidv4 } from 'uuid'
import { RootState } from '../redux/store'
import { configManager } from '../../config/configManager'
import { IAssessmentAugmantin, IAssessmentSchemaInfo, IAssessmentsQuestionItemSchema, ILCHttpResponse } from '@logicalcommander/types'

export interface IAssessmentTopics {
  [topicId: string]: string
}

export interface IAssessmentQuestions {
  [questionId: string]: IAssessmentsQuestionItemSchema
}

export const assessmentsApi = createApi({
  reducerPath: 'assessmentsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${configManager.api.GATEWAY_URL}/assessments`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('x-request-id', uuidv4())
      const { token, companyToken } = (getState() as RootState).authReducer
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      if (companyToken) {
        headers.set('x-company-token', companyToken)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    getAssessmentsInfo: builder.query<IAssessmentSchemaInfo[], void>({
      query: () => ({
        url: 'info',
      }),
      transformResponse: (rawResult: ILCHttpResponse<IAssessmentSchemaInfo[]>, meta) => {
        return rawResult.data as IAssessmentSchemaInfo[]
      },
    }),
    getAssessmentsTopics: builder.query<IAssessmentTopics, string>({
      query: (language) => ({
        url: `topics/${language}`,
      }),
      transformResponse: (rawResult: ILCHttpResponse<IAssessmentTopics>, meta) => {
        return rawResult.data as IAssessmentTopics
      },
    }),
    getAssessmentsQuestions: builder.query<IAssessmentQuestions, string>({
      query: (language) => ({
        url: `questions/${language}`,
      }),
      transformResponse: (rawResult: ILCHttpResponse<IAssessmentQuestions>, meta) => {
        return rawResult.data as IAssessmentQuestions
      },
    }),
    getAssessmentAugmantin: builder.query<IAssessmentAugmantin, { assessmentId: number; language: string }>({
      query: ({ assessmentId, language }) => ({
        url: `${assessmentId}/${language}`,
      }),
      transformResponse: (rawResult: ILCHttpResponse<IAssessmentAugmantin>, meta) => {
        return rawResult.data as IAssessmentAugmantin
      },
    }),
    createCustomAssessment: builder.mutation<IAssessmentAugmantin, any>({
      query: (assessmentToCreate: any) => ({
        url: `custom`,
        method: 'POST',
        body: assessmentToCreate,
      }),
    }),
  }),
})

export const {
  useCreateCustomAssessmentMutation,
  useLazyGetAssessmentsInfoQuery,
  useLazyGetAssessmentsQuestionsQuery,
  useLazyGetAssessmentAugmantinQuery,
  useLazyGetAssessmentsTopicsQuery,
} = assessmentsApi
