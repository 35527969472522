import { SVGProps } from 'react'

const SvgSignOut = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.0002 8.94315L16.0006 12.5431M10.9004 8.94315H19.0002H10.9004ZM19.0002 8.94315L16.0006 5.34323L19.0002 8.94315Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.7998 4.44324V2.79987C11.7998 2.55091 11.7481 2.30466 11.6481 2.07669C11.548 1.84871 11.4018 1.64397 11.2186 1.4754C11.0354 1.30683 10.8192 1.17809 10.5837 1.09733C10.3482 1.01658 10.0985 0.985547 9.85041 1.00621L2.65056 1.6056C2.20062 1.64307 1.78119 1.84828 1.47546 2.18051C1.16973 2.51275 1.00002 2.94776 1 3.39926V14.487C1.00002 14.9385 1.16973 15.3735 1.47546 15.7058C1.78119 16.038 2.20062 16.2432 2.65056 16.2807L9.85041 16.881C10.0986 16.9016 10.3483 16.8706 10.5839 16.7898C10.8195 16.709 11.0357 16.5801 11.2189 16.4115C11.4021 16.2428 11.5483 16.038 11.6483 15.8099C11.7483 15.5818 11.7999 15.3354 11.7998 15.0864V13.443"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgSignOut
