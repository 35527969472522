import * as React from 'react'
import { SVGProps } from 'react'

const SvgEye = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M1 12s4-7 11-7 11 7 11 7-4 7-11 7-11-7-11-7Z" stroke="currentColor" strokeWidth={2} />
    <path d="M12 15c1.656 0 3-1.567 3-3.5S13.656 8 12 8s-3 1.567-3 3.5 1.344 3.5 3 3.5Z" stroke="currentColor" strokeWidth={2} />
  </svg>
)

export default SvgEye
