import './register-company-details-page.scss'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { LCLanguagesSelection } from '../../../ui/components/lc-languages-selection/LCLanguagesSelection'
import { changeLanguage } from '../../../redux/reducers/generalSlice'
import { LCFormInputText } from '../../../ui/components/forms/lc-form-inputText/lc-form-inputText'
import { LCButton } from '../../../ui/components/lc-button/LCButton'
import { useEffect, useState } from 'react'
import { registerCompanyValidationSchema } from './register-company-validation.schema'
import { LCFormInputTextPhone } from '../../../ui/components/forms/lc-form-inputText-phone/lc-form-inputText-phone'
import { IRegisterLeadCompanyRequest, useRegisterLeadCompanyMutation } from '../../../services/register.services'
import { SanitizeHTML } from '../../../ui/components/lc-sanitize-html/SanitizeHTML'
import { LCUnauthenticatedPage } from '../../../ui/components/lc-unauthenticated-page/LCUnauthenticatedPage'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { registerCompanyDetailsInitialValues } from './register-company.helper'
import deepEqual from 'deep-equal'
import { LCFormInputTextPassword } from '../../../ui/components/forms/lc-form-inputText-password/lc-form-inputText-pawword'
import { LCFormDropdownDynamic } from '../../../ui/components/forms/lc-form-dropdown-dynamic/lc-form-dropdown-dynamic'
import { LCInlineMessage } from '../../../ui/components/lc-inline-message/LCInlineMessage'
import { ePublicRoutes } from '../../../routes/e-routes'
import { cleanObjectImmutable } from '../../../utils/cleanObject'
import { getAllCountriesDropdown, getAllStatesDropdown } from '../../../utils/list-helper'

export const RegisterCompanyDetailsPage = () => {
  const dispatch = useDispatch()
  const [isValidForm, setIsValidForm] = useState(false)
  const [submitFormRegister, { data, error, isLoading }] = useRegisterLeadCompanyMutation()
  const { t } = useTranslation('translation', { keyPrefix: 'auth.leadRegistration.leadCompanyPage' })
  const { t: v } = useTranslation('translation', { keyPrefix: 'validation' })
  const { email, emailType, language, token } = useParams()
  const navigate = useNavigate()

  const doChangeLanguage = (language: string) => {
    formik.setFieldValue('language', language)
    dispatch(changeLanguage(language))
  }

  const initialValues = {
    ...registerCompanyDetailsInitialValues,
    language,
    user: { ...registerCompanyDetailsInitialValues.user, email, confirmPassword: '' },
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: registerCompanyValidationSchema(t, v),
    onSubmit: (values: any) => {
      const params: any = { ...values, token }
      params.user.confirmPassword = undefined
      submitFormRegister(cleanObjectImmutable<IRegisterLeadCompanyRequest>(params))
      console.log('transformedData', 'status')
    },
  })

  useEffect(() => {
    const touched = !deepEqual(formik.values, initialValues)
    setIsValidForm(touched && formik.isValid)
  }, [formik.isValid, formik.touched, formik.values])

  const emailTypeString = emailType === 'c' ? 'company' : 'free'

  return (
    <LCUnauthenticatedPage title={t(`${emailTypeString}.title`)}>
      <div className="register-company-details-page">
        <div className="description">
          <SanitizeHTML html={t(`${emailTypeString}.description`)} />
          <div className="link-blue" onClick={() => navigate(ePublicRoutes.LOGIN)}>
            {t('navigateToLogin')}
          </div>
          <LCInlineMessage
            customStyle={{ marginTop: '20px' }}
            clientErrorCode={(error as any)?.data?.clientErrorCode}
            successMessage={data ? { title: t(`${emailTypeString}.success.title`), body: t(`${emailTypeString}.success.body`) } : undefined}
          />
        </div>

        <form className="details-form" onSubmit={formik.handleSubmit}>
          <div className="fields-wrapper">
            <div className="fields-group">
              <LCLanguagesSelection label={t('fields.language')} required languageSelected={doChangeLanguage} />

              <LCFormInputText
                label={t('fields.identity')}
                inputId="user.identity"
                inputType="text"
                {...formik.getFieldProps('user.identity')}
                required
                error={(formik.errors.user as any)?.identity}
              />

              <LCFormInputText
                label={t('fields.firstName')}
                inputId="user.firstName"
                inputType="text"
                {...formik.getFieldProps('user.firstName')}
                required
                error={(formik.errors.user as any)?.firstName}
              />

              <LCFormInputText
                label={t('fields.middleName')}
                inputId="user.middleName"
                inputType="text"
                {...formik.getFieldProps('user.middleName')}
                error={(formik.errors.user as any)?.middleName}
              />

              <LCFormInputText
                label={t('fields.lastName')}
                inputId="user.lastName"
                inputType="text"
                {...formik.getFieldProps('user.lastName')}
                required
                error={(formik.errors.user as any)?.lastName}
              />

              <LCFormInputText
                label={t('fields.email')}
                inputId="user.email"
                inputType="email"
                {...formik.getFieldProps('user.email')}
                required
                disabled
                error={(formik.errors.user as any)?.email}
              />

              <LCFormInputTextPassword
                label={t('fields.password')}
                inputId="user.password"
                required
                error={(formik.errors.user as any)?.password}
                {...formik.getFieldProps('user.password')}
              />

              <LCFormInputTextPassword
                label={t('fields.confirmPassword')}
                inputId="user.confirmPassword"
                required
                error={(formik.errors.user as any)?.confirmPassword}
                {...formik.getFieldProps('user.confirmPassword')}
              />

              <LCFormInputTextPhone<any>
                label={t('fields.mobile')}
                required={true}
                inputId="user.mobile"
                error={(formik.errors.user as any)?.mobile}
                // countryCode={'us'}
                {...formik.getFieldProps('user.mobile')}
              />
            </div>

            <div className="fields-group">
              <LCFormInputText
                label={t('fields.companyId')}
                inputId="company.companyId"
                inputType="text"
                {...formik.getFieldProps('company.companyId')}
                error={(formik.errors.company as any)?.companyId}
                required
              />

              <LCFormInputText
                label={t('fields.name')}
                inputId="company.name"
                inputType="text"
                {...formik.getFieldProps('company.name')}
                error={(formik.errors.company as any)?.name}
                required
              />

              <LCFormInputText
                label={t('fields.phone')}
                inputId="company.phone"
                inputType="text"
                {...formik.getFieldProps('company.phone')}
                error={(formik.errors.company as any)?.phone}
              />

              <LCFormInputText
                label={t('fields.website')}
                inputId="company.website"
                inputType="text"
                {...formik.getFieldProps('company.website')}
                error={(formik.errors.company as any)?.website}
              />

              <LCFormDropdownDynamic
                required
                label={t('fields.country')}
                inputId="company.address.country"
                selectedValue={formik.values?.company.address.country}
                setFieldValue={formik.setFieldValue}
                options={getAllCountriesDropdown()}
                error={(formik.errors.company as any)?.address?.country}
              />

              <LCFormInputText
                label={t('fields.city')}
                required
                inputId="company.address.city"
                inputType="text"
                error={(formik.errors.company as any)?.address?.city}
                {...formik.getFieldProps('company.address.city')}
              />

              <LCFormDropdownDynamic
                required={false}
                label={t('fields.state')}
                inputId="company.address.state"
                selectedValue={formik.values?.company.address.state}
                setFieldValue={formik.setFieldValue}
                options={getAllStatesDropdown()}
                error={(formik.errors.company as any)?.address?.state}
              />

              <LCFormInputText
                label={t('fields.addressLine1')}
                inputId="company.address.addressLine1"
                inputType="text"
                error={(formik.errors.company as any)?.address?.addressLine1}
                {...formik.getFieldProps('company.address.addressLine1')}
              />

              <LCFormInputText
                label={t('fields.addressLine2')}
                inputId="company.address.addressLine2"
                inputType="text"
                error={(formik.errors.company as any)?.address?.addressLine2}
                {...formik.getFieldProps('company.address.addressLine2')}
              />

              <LCFormInputText
                label={t('fields.postcode')}
                inputId="company.address.postcode"
                inputType="text"
                error={(formik.errors.company as any)?.address?.postcode}
                {...formik.getFieldProps('company.address.postcode')}
              />
            </div>
          </div>
          <div className="regiter-button">
            <LCButton
              type="submit"
              label={t(`${emailTypeString}.activate-trial-button`)}
              isLoading={isLoading}
              disabled={!isValidForm}
              customStyle={{ maxWidth: '200px', alignSelf: 'flex-end', marginTop: '10px' }}
            />
          </div>
        </form>
      </div>
    </LCUnauthenticatedPage>
  )
}
