import './mandatoryFieldsForm.scss'
import React, { FC, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IEnumsPositionGroupSettingsItem, IUser } from '@logicalcommander/types'
import { LCAwareProfileImageUpload } from '../../../../../ui/awareComponents/lc-profile-image-upload/LCAwareProfileImageUpload'
import { LCFormInputTextPhone } from '../../../../../ui/components/forms/lc-form-inputText-phone/lc-form-inputText-phone'
import { LCFormInputText } from '../../../../../ui/components/forms/lc-form-inputText/lc-form-inputText'
import { UserActionName } from '../../createUserHelper'
import { LCFormDropdownDynamic, OptionType } from '../../../../../ui/components/forms/lc-form-dropdown-dynamic/lc-form-dropdown-dynamic'
import { useAppSelector } from '../../../../../redux/hooks'
import { selectEmployeeStatus, selectPositions, selectPositionsGroups } from '../../../../../redux/reducers/settingsSlice'
import { getDropdownOptionsFromDbEnum, getDropdownPositionsWithGroups } from '../../../../../utils/list-helper'

interface Props {
  user: IUser | undefined
  formik: any
  actionName: UserActionName
  disabled?: boolean
}

export const Mandatory: FC<Props> = ({ user, disabled = false, formik, actionName }: Props): ReactElement => {
  const { t } = useTranslation('translation', { keyPrefix: 'user.mandatory_fields' })
  const [positionGroupName, setPositionGroupName] = useState('')

  // console.log('formik.values', formik.values)
  // console.log('formik.errors', formik.errors)

  const positionsGroups: IEnumsPositionGroupSettingsItem[] = useAppSelector(selectPositionsGroups)
  const allPositionsOptions: OptionType[] = getDropdownPositionsWithGroups(positionsGroups, useAppSelector(selectPositions))
  const allEmployeeStatusOptions: OptionType[] = getDropdownOptionsFromDbEnum(useAppSelector(selectEmployeeStatus))

  useEffect(() => {
    if (positionsGroups && formik.values.companyDetails?.positionId) {
      const pg = positionsGroups.find((pg) => pg.positions.includes(Number(formik.values.companyDetails?.positionId)))
      pg?.name && setPositionGroupName(pg?.name)
    }
  }, [positionsGroups, formik.values.companyDetails?.positionId])

  return (
    <div className="base-section-wrapper">
      <div className="base-section">
        <LCAwareProfileImageUpload
          disabled={disabled}
          srcURL={formik.values.profileImage}
          identity={actionName === UserActionName.CREATE ? '' : formik.values.identity}
          featchImage={formik.values.profileImage !== undefined}
          imageUploaded={(url: string) => (formik.values.profileImage = url)}
        />
      </div>

      <div className="base-section">
        <LCFormInputText<string>
          label={t('first_name')}
          required={true}
          inputId="firstName"
          inputType="text"
          disabled={disabled}
          error={formik.errors.firstName}
          {...formik.getFieldProps('firstName')}
        />
        <LCFormInputText<string>
          label={t('middle_name')}
          inputId="middleName"
          inputType="text"
          disabled={disabled}
          error={formik.errors.middleName}
          {...formik.getFieldProps('middleName')}
        />
        <LCFormInputText<string>
          label={t('last_name')}
          required={true}
          inputId="lastName"
          inputType="text"
          disabled={disabled}
          error={formik.errors.lastName}
          {...formik.getFieldProps('lastName')}
        />

        <LCFormDropdownDynamic
          required={true}
          label={`${t('position')} ${positionGroupName ? `(${positionGroupName})` : ''}`}
          inputId="companyDetails.positionId"
          selectedValue={String(formik.values.companyDetails?.positionId)}
          disabled={disabled}
          setFieldValue={formik.setFieldValue}
          options={allPositionsOptions}
          error={formik.errors.companyDetails?.positionId}
          {...formik.getFieldProps('companyDetails.positionId')}
        />

        <LCFormDropdownDynamic
          required={false}
          label={t('employeeStatus')}
          inputId="employeeStatusId"
          selectedValue={String(formik.values.employeeStatusId)}
          disabled={disabled}
          setFieldValue={formik.setFieldValue}
          options={allEmployeeStatusOptions}
          error={formik.errors.employeeStatusId}
          {...formik.getFieldProps('employeeStatusId')}
        />
      </div>

      <div className="base-section">
        <LCFormInputText<string>
          label={actionName === UserActionName.EDIT ? t('identity_number_disabled') : t('identity_number')}
          required={true}
          inputId="identity"
          inputType="text"
          disabled={disabled || actionName === UserActionName.EDIT}
          error={formik.errors.identity}
          {...formik.getFieldProps('identity')}
        />
        <LCFormInputTextPhone<any>
          label={t('mobile_number')}
          required={true}
          inputId="mobile"
          disabled={disabled}
          error={formik.errors.mobile}
          // countryCode={'us'}
          {...formik.getFieldProps('mobile')}
        />
        <LCFormInputText<any>
          label={actionName === UserActionName.EDIT ? t('email_address_disabled') : t('email_address')}
          required={true}
          inputId="email"
          inputType="email"
          disabled={disabled || actionName === UserActionName.EDIT}
          error={formik.errors.email}
          {...formik.getFieldProps('email')}
        />
      </div>
    </div>
  )
}
