import { ISvgNames } from '../../ui/components/lc-svg/lc-svg'
import { faGauge } from '@fortawesome/free-solid-svg-icons/faGauge'
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers'
import { faGear } from '@fortawesome/free-solid-svg-icons/faGear'
import { faShieldHalved } from '@fortawesome/free-solid-svg-icons/faShieldHalved'
import { faFaceSmile } from '@fortawesome/free-solid-svg-icons/faFaceSmile'
import { faTemperatureThreeQuarters } from '@fortawesome/free-solid-svg-icons/faTemperatureThreeQuarters'

export interface SideMenuItem {
  key: string
  label: string
  iconName?: ISvgNames
  newIcon?: any
  navigate?: string
  subTabs?: SideMenuItem[]
  action?: () => void
}

export const sideMenuTabsData = (t: any): SideMenuItem[] => [
  {
    key: '/app/general-dashboard',
    label: 'General',
    newIcon: faGauge,
    navigate: '/app/general-dashboard',
  },
  {
    key: 'riskhr',
    label: t('risk_hr'),
    newIcon: faTemperatureThreeQuarters,
    subTabs: [
      {
        key: '/app/riskhr/dashboard',
        label: t('dashboard'),
        navigate: '/app/riskhr/dashboard',
      },
      {
        key: '/app/riskhr/analytics',
        label: t('analytics'),
        navigate: '/app/riskhr/analytics',
      },
      {
        key: '/app/riskhr/assessments',
        label: t('evaluations'),
        navigate: '/app/riskhr/assessments',
      },
      {
        key: '/app/riskhr/cases',
        label: t('cases'),
        navigate: '/app/riskhr/cases',
      },
    ],
  },
  {
    key: 'emorisk',
    label: t('emo_risk'),
    newIcon: faFaceSmile,
    subTabs: [
      {
        key: '/app/emo/dashboard',
        label: t('dashboard'),
        navigate: '/app/emo/dashboard',
      },
      {
        key: '/app/emo/analytics',
        label: t('analytics'),
        navigate: '/app/emo/analytics',
      },
      {
        key: '/app/emo/results',
        label: t('emotions'),
        navigate: '/app/emo/results',
      },
      {
        key: '/app/emo/cases',
        label: t('cases'),
        navigate: '/app/general-dashboard',
      },
    ],
  },

  {
    key: '/app/users',
    label: t('employees'),
    newIcon: faUsers,
    navigate: '/app/users',
  },
  {
    key: '/app/settings/company-info',
    label: t('settings'),
    newIcon: faGear,
    navigate: '/app/settings/company-info',
  },
  {
    key: 'security',
    label: t('security'),
    newIcon: faShieldHalved,
    subTabs: [
      {
        key: '/app/activity/log',
        label: t('activity_log'),
        navigate: '/app/activity/log',
      },
    ],
  },
]
