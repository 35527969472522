import * as React from 'react'
import { SVGProps } from 'react'

const SvgCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.667 2.2H2.333C1.597 2.2 1 2.737 1 3.4v8.4c0 .663.597 1.2 1.333 1.2h9.334c.736 0 1.333-.537 1.333-1.2V3.4c0-.663-.597-1.2-1.333-1.2ZM9.667 1v2.4M4.333 1v2.4M1 5.8h12"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgCalendar
