import './grant-access-dialog.scss'
import React, { FC, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OptionType } from '../../../../../../ui/components/forms/lc-form-inputText-roles/lc-form-inputText-roles'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useAppSelector } from '../../../../../../redux/hooks'
import { selectAllRoles, selectSettings } from '../../../../../../redux/reducers/settingsSlice'
import { LCFormDropdownDynamic } from '../../../../../../ui/components/forms/lc-form-dropdown-dynamic/lc-form-dropdown-dynamic'
import { LCButton } from '../../../../../../ui/components/lc-button/LCButton'
import { LCDialog } from '../../../../../../ui/components/lc-dialog/LCDialog'
import { LCSearchUser } from '../../../../../../ui/components/lc-search-user/lc-search-user'
import { SanitizeHTML } from '../../../../../../ui/components/lc-sanitize-html/SanitizeHTML'
import deepEqual from 'deep-equal'
import { getAllRolesDropdown } from '../../../../../../utils/list-helper'

interface Props {
  togglerText: string
}

export const GrantAccessDialog: FC<Props> = ({ togglerText }: Props): ReactElement => {
  const { t } = useTranslation('translation', { keyPrefix: 'settings.admins.grant-access' })
  const { t: v } = useTranslation('translation', { keyPrefix: 'validation' })

  const { companyInfo } = useAppSelector(selectSettings)
  const [isValidForm, setIsValidForm] = useState(false)
  const roles = useAppSelector(selectAllRoles)

  const initialValues = {
    identity: '',
    role: '',
  }

  const validationRules = {
    identity: Yup.string().required(v('required', { field: t('identity') })),
    role: Yup.string().required(v('required', { field: t('role') })),
  }

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object(validationRules),
    onSubmit: (values: any) => {
      alert(
        'We need to update the roles in the database, send an email to the user, and develop a page where they can establish their password. Additionally, we must generate a token that allows them to set their password within a specified timeframe.'
      )
    },
  })

  useEffect(() => {
    const touched = !deepEqual(formik.values, initialValues)
    setIsValidForm(touched && formik.isValid)
  }, [formik.isValid, formik.touched, formik.values])

  const handleUserSelection = (selectedOption: OptionType | null) => {
    formik.setFieldValue('identity', selectedOption?.value)
    console.log(selectedOption) // Or any other logic to handle the selected user
  }

  console.log('-------- formik.values', formik.values)
  console.log('-------- formik.errors', formik.errors)
  console.log('-------- formik.touched', formik.touched)

  return (
    <LCDialog
      className="grant-access-dialog"
      toggler={togglerText}
      title={t('title', { companyName: companyInfo.name })}
      customStyle={{ width: '500px' }}>
      <div className="description">
        <SanitizeHTML html={t('description', { hours: 48 })} />
      </div>

      <form className="body" onSubmit={formik.handleSubmit}>
        <LCSearchUser label={t('search')} onSelectionChange={handleUserSelection} />

        <LCFormDropdownDynamic
          required={false}
          label={t('role')}
          inputId="role"
          selectedValue={formik.values?.role}
          disabled={false}
          setFieldValue={formik.setFieldValue}
          options={getAllRolesDropdown(roles)}
          error={formik.errors.role}
        />

        <div className="buttons">
          <LCButton label={t('grant-button')} type="submit" disabled={!isValidForm} />
        </div>
      </form>
    </LCDialog>
  )
}
