import { CSSProperties, FC, ReactElement, useEffect, useState } from 'react'
import { LCPermissions } from '../../components/lc-permissions/LCPermissions'
import React from 'react'
import { useAppSelector } from '../../../hooks/redux-hooks'
import { selectAuth } from '../../../redux/reducers/authSlice'

interface Props {
  allowedPermission: string
  children?: ReactElement
  hidden?: boolean
  collapse?: boolean
  customStyle?: CSSProperties
}

export const LCAwarePermissions: FC<Props> = ({ allowedPermission, children, hidden, collapse, customStyle, ...props }: Props) => {
  const auth = useAppSelector(selectAuth)
  const [userPermissions, setUserPermissions] = useState<string[]>([])

  useEffect(() => {
    setUserPermissions(auth.user.calculatedPermissions || [])
  }, [auth])

  return (
    <LCPermissions
      hidden={hidden}
      collapse={collapse}
      customStyle={customStyle}
      userPermissions={userPermissions}
      allowedPermission={allowedPermission}
      {...props}>
      {children}
    </LCPermissions>
  )
}
