import * as React from 'react'
import { SVGProps } from 'react'

const SvgUserError = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19 17v-1.627c0-.863-.353-1.69-.981-2.301a3.4 3.4 0 0 0-2.368-.953H8.953a3.4 3.4 0 0 0-2.368.953 3.208 3.208 0 0 0-.98 2.3V17M12.302 8.864c1.85 0 3.349-1.456 3.349-3.254 0-1.797-1.5-3.254-3.35-3.254-1.849 0-3.348 1.457-3.348 3.254 0 1.798 1.5 3.254 3.349 3.254Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M4.684 3.088V4.58M4.684 5.624v.149" stroke="#fff" strokeWidth={2} strokeLinecap="round" />
    <path
      d="m1.085 6.076 2.99-4.762c.263-.419.955-.419 1.217 0l2.99 4.762c.25.4-.083.89-.609.89h-5.98c-.525 0-.86-.49-.607-.89h0Z"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M4.684 3.088V4.58M4.684 5.624v.149" stroke="red" strokeWidth={0.8} strokeLinecap="round" />
    <path
      d="m1.085 6.076 2.99-4.762c.263-.419.955-.419 1.217 0l2.99 4.762c.25.4-.083.89-.609.89h-5.98c-.525 0-.86-.49-.607-.89h0Z"
      stroke="red"
      strokeWidth={0.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgUserError
