import './widgets-general.scss'
import React, { CSSProperties, FC, HTMLAttributes, lazy, useEffect, useState } from 'react'
import { sumBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { BarColor, CustomLabels } from '../../../../interfaces/dashboard.interface'
import { useLazyGetTotalMenVsWomenQuery } from '../../../services/dashboard.service'
import { ITitleValueNumber } from '@logicalcommander/types'
import { BaseDashboardComponent } from '../../../ui/components/base-dashboard/BaseDashboardComponent'
import { DashboardComponentTitle } from '../../../ui/components/base-dashboard/DashboardComponentTitle'

const LCPieChartUniNew = lazy(() => import('../../../ui/components/charts/lc-pie-chart'))

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

const GeneralDashboardWomanMenPie: FC<Props> = ({ customStyle }: Props) => {
  const [getTotalMenVsWomen, { data, error, isLoading }] = useLazyGetTotalMenVsWomenQuery()
  const [womenVsMen, setWomenVsMen] = useState<ITitleValueNumber[]>([])
  const [sumWomenVsMen, setSumWomenVsMen] = useState(0)
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.general.totalWomanVsMen' })

  useEffect(() => {
    getTotalMenVsWomen()
  }, [])

  useEffect(() => {
    if (!error && data) {
      const sorted = [
        {
          title: 'women',
          value: sumBy(data, 'women'),
        },
        {
          title: 'men',
          value: sumBy(data, 'men'),
        },
      ]
      const sumSorted = sumBy(sorted, 'value')
      setSumWomenVsMen(sumSorted)
      setWomenVsMen(sorted)
    }
  }, [data, error])

  return (
    <BaseDashboardComponent customStyle={customStyle} sectionTitle={<DashboardComponentTitle title={t('title')} subTitle={`${t('total')} ${sumWomenVsMen}`} />} isLoading={isLoading} isSuccess={!!womenVsMen} isError={!!error}>
      <LCPieChartUniNew
        data={womenVsMen}
        options={{
          color: BarColor.COLOR_WOMEN_MEN,
          label: CustomLabels.TOTAL_EMPLOYEES,
          customPercentage: false,
        }}
      />
    </BaseDashboardComponent>
  )
}

export default GeneralDashboardWomanMenPie
