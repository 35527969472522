import './risk-hr-evaluation-detailed-card.scss'
import React, { CSSProperties, FC, HTMLAttributes, ReactElement, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { RiskHrEvaluationTopics } from '../risk-hr-evaluation-topics/RiskHrEvaluationTopics'
import { useGetSpecificEvaluationsResultsMutation } from '../../../../services/evaluations.service'
import { LCPage } from '../../../../ui/components/lc-page/LCPage'
import { faClipboardList } from '@fortawesome/free-solid-svg-icons/faClipboardList'
import { RiskHrEvaluationDetails } from '../risk-hr-evaluation-details/RiskHrEvaluationDetails'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  hidePageHeader?: boolean
  identity?: string
  evaluationId?: string
  customStyle?: CSSProperties
}

export const RiskHrEvaluationDetailedCard: FC<Props> = ({ identity, evaluationId, customStyle, hidePageHeader }: Props) => {
  const params = useParams()
  const [getSpecificEvaluation, { data }] = useGetSpecificEvaluationsResultsMutation()

  useEffect(() => {
    getSpecificEvaluation({ identity: params.identity || identity, evaluationId: params.evaluationId || evaluationId })
  }, [])

  const renderInner = (): ReactElement => (
    <div className="risk-hr-evaluation-detailed-card" style={customStyle}>
      <RiskHrEvaluationDetails evaluation={data} customStyle={{ marginBottom: '20px' }} />
      <RiskHrEvaluationTopics evaluation={data} customStyle={{ marginBottom: '20px' }} />
      {/* <EmoRiskEmotionsLegend /> */}
      {/* <LCDiamondChart data={evaluation?.results?.emotions} /> */}
    </div>
  )

  return hidePageHeader ? (
    renderInner()
  ) : (
    <LCPage icon={faClipboardList} title="RiskHR" breadcrumbs="Report of Candidate > Report">
      {renderInner()}
    </LCPage>
  )
}
