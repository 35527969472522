import './lc-cached-image.scss'
import React from 'react'
import { CSSProperties, FC, useEffect, useState } from 'react'
import { cachedImagesStorage } from '../../../utils/cachedImagesStorage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWarehouse } from '@fortawesome/free-solid-svg-icons/faWarehouse'

export interface ICachedImageProps {
  cacheKey: string
  url: string
  alt?: string
  height?: number
  width?: number
  customStyle?: CSSProperties
}

export const LCCachedImage: FC<ICachedImageProps> = ({ url, cacheKey, alt, customStyle, height, width, ...props }: ICachedImageProps) => {
  const [imageURL, setImageURL] = useState<string | undefined>()
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    const response = cacheKey && cachedImagesStorage.getImage(cacheKey)
    if (response) {
      setImageURL(response)
    } else {
      console.log('Fetching ==== ', cacheKey)
      url &&
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            // Create a blob URL from the fetched image data
            const objectURL = URL.createObjectURL(blob)

            // Cache the image URL in localStorage for future use
            cacheKey && cachedImagesStorage.addImage(cacheKey, objectURL)

            // Set the image URL to display the image
            setImageURL(objectURL)
          })
          .catch((error) => {
            console.error('Error fetching image:', error)
          })
    }
  }, [url, cacheKey])

  return (
    <div style={customStyle} {...props}>
      {isError || !imageURL ? (
        <FontAwesomeIcon icon={faWarehouse} size="xl" />
      ) : (
        <img
          className="lc-cached-image"
          src={imageURL}
          height={height || 40}
          width={width || 40}
          alt={alt}
          onError={() => setIsError(true)}
        />
      )}
    </div>
  )
}
