import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { configManager } from '../../config/configManager'
import { v4 as uuidv4 } from 'uuid'
import { RootState } from '../redux/store'
import { ILCHttpResponse, IPaginationRequest, IPaginationResponse } from '@logicalcommander/types'
import { IActivityLogSummary } from '../pages/Security/ActivityLog/ActivityListColumns'

export interface IActivityLogEntry {
  identity: string
  ipAddress: string
  fullName: string
  roles: string
  outcome: string
  details: {
    action: string
    duration: number
  }
  createdAt: Date
}

export const activityApi = createApi({
  reducerPath: 'activityApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${configManager.api.GATEWAY_URL}/activity`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('x-request-id', uuidv4())
      const { token, companyToken } = (getState() as RootState).authReducer
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      if (companyToken) {
        headers.set('x-company-token', companyToken)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    getLog: builder.mutation<IPaginationResponse<IActivityLogSummary[]>, IPaginationRequest>({
      query: ({ pageIndex, pageSize, search, sortBy }) => {
        return {
          url: '/log',
          method: 'POST',
          body: {
            pageIndex,
            pageSize,
            sortBy,
            search,
          },
        }
      },
      transformResponse: (rawResult: ILCHttpResponse<IPaginationResponse<IActivityLogSummary[]>>, meta) => {
        return rawResult.data as IPaginationResponse<IActivityLogSummary[]>
      },
    }),
  }),
})

export const { useGetLogMutation } = activityApi
