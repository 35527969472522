import './createUserTabs.scss'
import React, { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { LCButton } from '../../../ui/components/lc-button/LCButton'
import { useCreateUserMutation, useLazyGetUserQuery, useUpdateUserMutation } from '../../../services/user.service'
import { LCPage } from '../../../ui/components/lc-page/LCPage'
import { useTranslation } from 'react-i18next'
import { IUser } from '@logicalcommander/types'
import { LCTabs, TabData } from '../../../ui/components/lc-tabs/lc-tabs'
import { Mandatory } from './components/mandatory/mandatoryFieldsForm'
import { useFormik } from 'formik'
import { UserActionName, userInitialValues, validationRules } from './createUserHelper'
import { PersonalDetails } from './components/personalDetails/personalDetailsForm'
import { PersonalAddress } from './components/personalAddress/personalAddressForm'
import { CompanyDetails } from './components/companyDetails/companyDetailsForm'
import { deepMerge, deepObjectDiff, filterEmptyStringsAndObjects } from '../../../utils/object'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus'
import { faUserPen } from '@fortawesome/free-solid-svg-icons/faUserPen'
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons/faPenToSquare'
import deepEqual from 'deep-equal'
import { toast } from 'react-toastify'
import { LCToast } from '../../../ui/components/lc-toast/Toast'

const updateUrl = '/user/edit'
const viewUrl = '/user/view'
const createUrl = '/user/create'

enum eTabsName {
  mandatory = 'mandatory',
  'personal-details' = 'personal-details',
  'personal-address' = 'personal-address',
  'company-details' = 'company-details',
}

export const CreateUserTabs: FC = () => {
  let params = useParams()
  const [createUser, { data: dataCreate, error: errorCreate }] = useCreateUserMutation()
  const [updateUser, { data: dataUpdate, error: errorUpdate, isLoading: isLoadingUpdate }] = useUpdateUserMutation()
  const [getUser, { data: user, error: errorGettingUser }] = useLazyGetUserQuery()
  const [actionName, setActionName] = useState<UserActionName>(UserActionName.VIEW)
  const [isValidForm, setIsValidForm] = useState(false)
  const navigate = useNavigate()
  const { t: v } = useTranslation('translation', { keyPrefix: 'validation' })
  const { t } = useTranslation('translation', { keyPrefix: 'user' })

  const initialValues = actionName === UserActionName.CREATE ? userInitialValues : deepMerge(userInitialValues, user || {})

  const formik = useFormik({
    initialValues,
    validationSchema: validationRules(v, t),
    enableReinitialize: true,
    onSubmit: (values: IUser) => {
      console.log('onSubmit', values)
      if (actionName === UserActionName.CREATE) {
        // filter values that are empty strings (because the initialValues contains empty strings to not show
        // the error that uncontrolled component to become controlled).
        const filtered = filterEmptyStringsAndObjects<IUser>(values)
        console.log('filtered create: ', filtered)
        createUser(filtered)
      } else if (actionName === UserActionName.EDIT) {
        const updatedValues = deepObjectDiff(user || {}, values)
        const filtered = filterEmptyStringsAndObjects<IUser>(updatedValues as IUser)
        console.log('filtered edit: ', filtered)
        user?.identity && updateUser({ user: filtered, identity: user.identity })
      }
    },
  })

  useEffect(() => {
    const touched = !deepEqual(formik.values, initialValues)
    setIsValidForm(touched && formik.isValid)
  }, [formik.isValid, formik.touched, formik.values])

  const mandatoryTabHasErrors = (): boolean => {
    return (
      !!formik.errors?.identity ||
      !!formik.errors?.profileImage ||
      !!formik.errors?.firstName ||
      !!formik.errors?.middleName ||
      !!formik.errors?.lastName ||
      !!formik.errors?.mobile ||
      !!formik.errors?.email ||
      !!formik.errors?.roles
    )
  }

  const checkMandatoryField = (errors: any, name: string) => {
    if (formik.errors && (formik.errors as any)[name]) {
      errors[name] = (formik.errors as any)[name]
    }
  }

  const mandatoryTabErrors = () => {
    const errors: any = {}
    checkMandatoryField(errors, 'identity')
    checkMandatoryField(errors, 'profileImage')
    checkMandatoryField(errors, 'firstName')
    checkMandatoryField(errors, 'middleName')
    checkMandatoryField(errors, 'lastName')
    checkMandatoryField(errors, 'mobile')
    checkMandatoryField(errors, 'email')
    checkMandatoryField(errors, 'roles')
    return errors
  }

  // const companyDetailsErrors = () => {
  //   const errors: any = formik.errors?.companyDetails || {}
  //   if (errors?.positionId || errors?.positionId === '') {
  //     delete errors.positionId // Delete positionId because it moved to mandatory field
  //   }
  //   return errors
  // }

  // const companyDetailsTabHasErrors = (): boolean => {
  //   return Object.keys(companyDetailsErrors()).length > 0
  // }

  const tabs: TabData[] = [
    {
      id: eTabsName.mandatory,
      title: t('create.tabs.mandatory_fields'),
      hasError: mandatoryTabHasErrors(),
      errors: mandatoryTabErrors(),
      component: <Mandatory user={user} formik={formik} actionName={actionName} disabled={actionName === UserActionName.VIEW} />,
    },
    {
      id: eTabsName['personal-details'],
      title: t('create.tabs.personal_details'),
      hasError: !!formik.errors?.personalDetails && Object.keys(formik.errors?.personalDetails).length > 0,
      errors: formik.errors?.personalDetails,
      component: <PersonalDetails user={user} formik={formik} actionName={actionName} disabled={actionName === UserActionName.VIEW} />,
    },
    {
      id: eTabsName['personal-address'],
      title: t('create.tabs.personal_address'),
      hasError: !!formik.errors?.personalAddress && Object.keys(formik.errors?.personalAddress).length > 0,
      errors: formik.errors?.personalAddress,
      component: <PersonalAddress user={user} formik={formik} actionName={actionName} disabled={actionName === UserActionName.VIEW} />,
    },
    {
      id: eTabsName['company-details'],
      title: t('create.tabs.company_details'),
      hasError: !!formik.errors?.companyDetails && Object.keys(formik.errors?.companyDetails).length > 0,
      errors: formik.errors?.companyDetails,
      component: <CompanyDetails user={user} formik={formik} actionName={actionName} disabled={actionName === UserActionName.VIEW} />,
    },
  ]

  // console.log('========> formik.errors', formik.errors)
  // console.log('========> formik.values', formik.values)

  useEffect(() => {
    const url = window.location.href
    if (url.indexOf(updateUrl) !== -1) {
      setActionName(UserActionName.EDIT)
      params.identity && getUser(params.identity)
    } else if (url.indexOf(createUrl) !== -1) {
      setActionName(UserActionName.CREATE)
    } else if (url.indexOf(viewUrl) !== -1) {
      setActionName(UserActionName.VIEW)
      params.identity && getUser(params.identity)
    }
  }, [window.location.href])

  useEffect(() => {
    if (errorGettingUser) {
      toast.error(<LCToast title={t('notifications.get_user_error_title')} body={t('notifications.get_user_error_text')} />)
    }
  }, [user, errorGettingUser])

  useEffect(() => {
    if (!errorCreate && dataCreate) {
      toast.success(
        <LCToast
          title={t('notifications.create_user_success_title')}
          body={t('notifications.create_user_success_text', { FULL_NAME: dataCreate.fullName })}
        />
      )
      navigate(`/app/user/edit/${formik.values.identity}`) // ResetForm to edit user, so now can upload image
    } else if (errorCreate) {
      toast.error(<LCToast title={t('notifications.create_user_error_title')} body={t('notifications.create_user_error_text')} />)
    }
  }, [dataCreate, errorCreate])

  useEffect(() => {
    if (!errorUpdate && dataUpdate) {
      toast.success(
        <LCToast
          title={t('notifications.update_user_success_title')}
          body={t('notifications.update_user_success_text', { FULL_NAME: dataUpdate.fullName })}
        />
      )
      navigate(`/app/user/edit/${formik.values.identity}`) // ResetForm
    } else if (errorCreate) {
      toast.error(<LCToast title={t('notifications.update_user_error_title')} body={t('notifications.update_user_error_text')} />)
    }
  }, [dataUpdate, errorUpdate])

  const breadCrumbs =
    actionName === UserActionName.CREATE
      ? t('page_breadcrumbs_create')
      : actionName === UserActionName.EDIT
        ? t('page_breadcrumbs_edit')
        : t('page_breadcrumbs_view')

  const icon = actionName === UserActionName.CREATE ? faUserPlus : actionName === UserActionName.EDIT ? faUserPen : faUser
  const buttonLabel = actionName === UserActionName.CREATE ? t('create.button_create_label') : t('create.button_update_label')

  return (
    <LCPage icon={icon} title={t('page_title')} breadcrumbs={breadCrumbs}>
      <form className="form" onSubmit={formik.handleSubmit}>
        <div className="user-tabs-wrapper">
          <div className="user-tabs-title">
            {actionName === UserActionName.CREATE ? (
              <></> // t('create.title')
            ) : (
              <div className="fullname">
                {user?.fullName} ({user?.identity})
              </div>
            )}
          </div>
          <div className="user-tabs-content">
            <LCTabs tabs={tabs} customStyle={{ minHeight: '300px' }} />
          </div>
          <div className="user-tabs-buttons">
            {(actionName === UserActionName.CREATE || actionName === UserActionName.EDIT) && (
              <LCButton newIcon={faPenToSquare} isLoading={isLoadingUpdate} label={buttonLabel} type="submit" disabled={!isValidForm} />
            )}
          </div>
        </div>
      </form>
    </LCPage>
  )
}
