import { FC } from 'react'
import './Toast.scss'

type ToastProps = {
  title: string
  body: string
}

export const LCToast: FC<ToastProps> = ({ title, body }) => {
  return (
    <div className="toast-wrapper">
      <div className="toast-title">{title}</div>
      <div className="toast-body">{body}</div>
    </div>
  )
}
