import React, { useCallback, useMemo } from 'react'
import './purache.scss'
import { LCTableServerPagination } from '../../../../ui/components/lc-table/LCTableServerPagination'
import { eTableName } from '@logicalcommander/types'
import { myRequests_columns } from './component/myRequests.columns'
import { dataMyRequests } from './mockDataMyRequests'
import { useTranslation } from 'react-i18next'

export const SettingsMyRequests = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'settings.modulePurchase.myRequests' })
  const fetchData = useCallback(({ pageSize, pageIndex, sortBy, search }: any) => {}, [])
  const columns = useMemo(() => myRequests_columns(t), [])

  return (
    <div className="container-page">
      <h3>{t('title')}</h3>
      <LCTableServerPagination
        tableName={eTableName.MY_REQUESTS_TABLE}
        showCheckbox={false}
        columns={columns}
        data={dataMyRequests}
        fetchData={fetchData}
        loading={false}
        pageCount={5}
        initPageSize={0}
      />
    </div>
  )
}
