import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { v4 as uuidv4 } from 'uuid'
import { RootState } from '../redux/store'
import {
  IAddSpecificCaseCommentRequest,
  IGetCasesRequest,
  IGetSpecificCaseRequest,
  IOpenCaseRequest,
  IUpdateSpecificCaseAssigneeRequest,
  IUpdateSpecificCasePriorityRequest,
  IUpdateSpecificCaseStatusRequest,
} from '../../interfaces/cases.interface'
import { IPaginationResponse, IRiskHRCase } from '@logicalcommander/types'
import { configManager } from '../../config/configManager'

export const casesApi = createApi({
  reducerPath: 'casesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${configManager.api.GATEWAY_URL}/cases`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('x-request-id', uuidv4())
      const { token, companyToken } = (getState() as RootState).authReducer
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      if (companyToken) {
        headers.set('x-company-token', companyToken)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    getCases: builder.mutation<IPaginationResponse<IRiskHRCase[]>, IGetCasesRequest>({
      query: ({ pageIndex, pageSize, sortBy, search }) => {
        return {
          url: 'cases-summary',
          method: 'POST',
          body: {
            pageIndex,
            pageSize,
            sortBy,
            search,
          },
        }
      },
    }),
    getSpecificCase: builder.mutation<IRiskHRCase, IGetSpecificCaseRequest>({
      query: ({ caseId }) => {
        return {
          url: `${caseId}`,
          method: 'GET',
        }
      },
    }),
    updateSpecificCaseStatus: builder.mutation<IRiskHRCase, IUpdateSpecificCaseStatusRequest>({
      query: ({ caseId, status }) => {
        return {
          url: `${caseId}/status`,
          method: 'PUT',
          body: { status },
        }
      },
    }),
    updateSpecificCasePriority: builder.mutation<IRiskHRCase, IUpdateSpecificCasePriorityRequest>({
      query: ({ caseId, priority }) => {
        return {
          url: `${caseId}/priority`,
          method: 'PUT',
          body: { priority },
        }
      },
    }),
    updateSpecificCaseAssignee: builder.mutation<IRiskHRCase, IUpdateSpecificCaseAssigneeRequest>({
      query: ({ caseId, assignTo }) => {
        return {
          url: `${caseId}/assignee`,
          method: 'PUT',
          body: { assignTo },
        }
      },
    }),
    addSpecificCaseComment: builder.mutation<IRiskHRCase, IAddSpecificCaseCommentRequest>({
      query: ({ caseId, comment }) => {
        return {
          url: `${caseId}/comment`,
          method: 'POST',
          body: { comment },
        }
      },
    }),
    openCase: builder.mutation<IRiskHRCase, IOpenCaseRequest>({
      query: ({ title, evaluation }) => {
        return {
          url: 'open',
          method: 'POST',
          body: {
            title,
            evaluation,
          },
        }
      },
    }),
  }),
})

export const {
  useGetCasesMutation,
  useGetSpecificCaseMutation,
  useUpdateSpecificCaseStatusMutation,
  useUpdateSpecificCasePriorityMutation,
  useUpdateSpecificCaseAssigneeMutation,
  useAddSpecificCaseCommentMutation,
  useOpenCaseMutation,
} = casesApi
