import * as React from 'react'
import { SVGProps } from 'react'

const SvgUsers = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.593 3.843a2.343 2.343 0 1 1 4.687 0 2.343 2.343 0 0 1-4.687 0ZM6.936 0a3.843 3.843 0 1 0 0 7.686 3.843 3.843 0 0 0 0-7.686ZM3.75 8.998A3.75 3.75 0 0 0 0 12.748v1.5a.75.75 0 0 0 1.5 0v-1.5a2.25 2.25 0 0 1 2.25-2.25h5.998a2.25 2.25 0 0 1 2.25 2.25v1.5a.75.75 0 0 0 1.5 0v-1.5a3.75 3.75 0 0 0-3.75-3.75H3.749Zm10.522.66a.75.75 0 0 1 .913-.539 3.75 3.75 0 0 1 2.812 3.628v1.5a.75.75 0 0 1-1.5 0v-1.5a2.25 2.25 0 0 0-1.687-2.175.75.75 0 0 1-.538-.914ZM12.184.121a.75.75 0 1 0-.372 1.453 2.25 2.25 0 0 1 0 4.358.75.75 0 1 0 .372 1.453 3.75 3.75 0 0 0 0-7.264Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgUsers
