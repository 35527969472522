import React, { FC, HTMLAttributes, lazy, useEffect, useState } from 'react'
import { CSSProperties } from 'styled-components'
import { IDashboardRisksPerDepartment, BarChartLayout, BarColor, CustomLabels } from '../../../../../interfaces/dashboard.interface'
import { useLazyGetRisksPerTopicDepartmentQuery } from '../../../../services/dashboard.service'
import { ISearch, eSearchType } from '@logicalcommander/types'
import { useTranslation } from 'react-i18next'
import { BaseDashboardComponent } from '../../../../ui/components/base-dashboard/BaseDashboardComponent'
import { DashboardComponentTitle } from '../../../../ui/components/base-dashboard/DashboardComponentTitle'

const LCBarChartNew = lazy(() => import('../../../../ui/components/charts/lc-bar-chart'))

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

const RiskHrDashboardRisksDepartment: FC<Props> = ({ customStyle }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.riskDepartament' })
  const [getDashboardDepartmentTopic, { data, error, isLoading }] = useLazyGetRisksPerTopicDepartmentQuery()
  const [departmentRiskTopic, setDepartmentRiskTopic] = useState<IDashboardRisksPerDepartment[]>([])

  useEffect(() => {
    const search: ISearch[] = [{ keyName: 'aaaa', type: eSearchType.DATE_AFTER, value: '123213' }]
    getDashboardDepartmentTopic({ search })
  }, [])

  useEffect(() => {
    if (!error && data) {
      const sorted = [...data].sort(sortDepartmentRisk)
      setDepartmentRiskTopic(sorted)
    }
  }, [data, error])

  const sortDepartmentRisk = (a: IDashboardRisksPerDepartment, b: IDashboardRisksPerDepartment) => {
    return b.value - a.value
  }

  return (
    <BaseDashboardComponent customStyle={customStyle} sectionTitle={<DashboardComponentTitle title={t('title')} subTitle={t('total')} />} isLoading={isLoading} isSuccess={departmentRiskTopic.length > 0} isError={!!error}>
      <LCBarChartNew
        data={departmentRiskTopic}
        options={{
          layout: BarChartLayout.HORIZONTAL,
          color: BarColor.COLOR_DEPARTMENT,
          label: CustomLabels.ALERT_RISK,
        }}
      />
    </BaseDashboardComponent>
  )
}
export default RiskHrDashboardRisksDepartment
