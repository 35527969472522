import { SVGProps } from 'react'

const SvgPhone = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.5677 0.419296L3.63547 0.104893C4.22041 -0.0677828 4.84954 -0.0262151 5.40547 0.22184C5.9614 0.469895 6.40615 0.907491 6.65673 1.45297L7.48204 3.24981C7.69748 3.719 7.75748 4.24245 7.65361 4.74671C7.54974 5.25097 7.28721 5.71074 6.90286 6.06152L5.53588 7.30928C5.49592 7.34573 5.47061 7.39498 5.46451 7.44812C5.42425 7.80372 5.67038 8.49613 6.23767 9.45904C6.65033 10.1586 7.02455 10.6504 7.34205 10.9254C7.56347 11.1179 7.68516 11.1591 7.73732 11.1448L9.57642 10.5939C10.0787 10.4435 10.6164 10.4508 11.1142 10.6146C11.612 10.7785 12.045 11.0907 12.3525 11.5076L13.5246 13.0984C13.881 13.5822 14.046 14.1761 13.9889 14.77C13.9319 15.3639 13.6567 15.9173 13.2144 16.3275L12.4037 17.0799C11.9734 17.4788 11.445 17.7618 10.8698 17.9014C10.2947 18.0409 9.69241 18.0323 9.12168 17.8762C6.60183 17.1874 4.34275 15.1057 2.31882 11.6742C0.292146 8.23815 -0.418791 5.27865 0.236332 2.79478C0.383804 2.23614 0.675783 1.72411 1.08413 1.30804C1.49247 0.89197 2.00341 0.585887 2.5677 0.419296ZM2.96388 1.70647C2.62541 1.8063 2.31889 1.98977 2.07386 2.23922C1.82883 2.48866 1.65355 2.79568 1.56488 3.13068C1.01406 5.21953 1.64448 7.84314 3.50738 11.0024C5.36753 14.1572 7.37041 16.0024 9.49133 16.5828C9.83366 16.6761 10.1948 16.6812 10.5397 16.5973C10.8846 16.5135 11.2015 16.3437 11.4594 16.1045L12.271 15.3521C12.4722 15.1656 12.5973 14.9139 12.6232 14.6439C12.6491 14.3739 12.5741 14.1039 12.4119 13.884L11.2408 12.294C11.101 12.1043 10.9041 11.9622 10.6776 11.8877C10.4512 11.8131 10.2065 11.8099 9.9781 11.8784L8.13442 12.4311C7.0639 12.7428 6.09219 11.8999 5.04911 10.1308C4.34641 8.93951 4.01885 8.01691 4.10028 7.29943C4.14329 6.9268 4.31988 6.58284 4.59986 6.32666L5.96684 5.0789C6.14162 4.91947 6.26102 4.71047 6.30828 4.48123C6.35554 4.25198 6.32829 4.014 6.23035 3.80069L5.40504 2.00385C5.29114 1.7561 5.08911 1.55734 4.83661 1.44461C4.5841 1.33189 4.29833 1.3129 4.03257 1.39117L2.96388 1.70647Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgPhone
