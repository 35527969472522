import { FC, ReactElement } from 'react'
import { ISearch, eSearchType, eTableName } from '@logicalcommander/types'
import { CSSProperties } from 'styled-components'
import { IFilter, clearSearchFilter, eFilterType, setSearchFilter } from '../../../../redux/reducers/filtersSlice'
import { useAppDispatch } from '../../../../redux/hooks'
import { LCSearch } from '../../lc-search/lc-search'

export interface Props {
  tableName: eTableName
  customStyle?: CSSProperties
}

export const LCTableActionSearch: FC<Props> = (props: Props): ReactElement => {
  const { tableName } = props
  const dispatch = useAppDispatch()

  const buildSearchObject = (value: string): ISearch => {
    return { type: eSearchType.CONTAINS, keyNameArray: ['firstName', 'middleName', 'lastName', 'identity'] as any, value }
  }

  const onSearch = (search: ISearch) => {
    const filterObject: IFilter = {
      tableName,
      search,
      type: eFilterType.SEARCH,
    }
    dispatch(setSearchFilter(filterObject))
  }

  const onClear = () => {
    dispatch(clearSearchFilter({}))
  }

  return <LCSearch onSearch={(value: string) => onSearch(buildSearchObject(value))} onClear={() => onClear()} minimumChars={3} />
}
