import React, { useCallback, useMemo } from 'react'
import { toAprove_columns } from './component/toAprove.columns'
import { LCTableServerPagination } from '../../../../ui/components/lc-table/LCTableServerPagination'
import { eTableName } from '@logicalcommander/types'
import './purache.scss'
import { dataToApprove } from './mockDataToApprove'
import { useTranslation } from 'react-i18next'

export const SettingsToApprove = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'settings.modulePurchase.toApprove' })
  const fetchData = useCallback(({ pageSize, pageIndex, sortBy, search }: any) => {}, [])
  const columns = useMemo(() => toAprove_columns(t), [])

  return (
    <div className="container-page">
      <h3>{t('title')}</h3>
      <div>
        <LCTableServerPagination
          tableName={eTableName.TO_APPROVE_PURCHASE_TABLE}
          columns={columns}
          data={dataToApprove}
          fetchData={fetchData}
          loading={false}
          pageCount={0}
          initPageSize={0}
        />
      </div>
    </div>
  )
}
