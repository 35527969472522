import './lc-loader.scss'
import { FC, ReactElement } from 'react'
import loadingGif from '../../../../assets/loading.gif'

interface Props {
  show?: boolean
  type?: 'small' | 'big'
  message?: string
}

export const LCLoader: FC<Props> = ({ show = false, type = 'big', message }: Props): ReactElement | null => {
  if (!show) {
    return null
  }

  return type === 'big' ? (
    <div>
      <div className="loaderBackground"></div>
      <div className="loaderWrapper">
        <img className="loader-logo" src={loadingGif} alt="logo" />
        <div className="loader-text-wrapper">
          <div className="loader-caption">Loading data</div>
          <div className="loader-wait">Please wait ...</div>
          <div className="loader-wait">{message}</div>
        </div>
      </div>
    </div>
  ) : (
    <div>
      <div className="loaderBackground"></div>
      <div className="loader-small">
        <div className="loader-inside">Loading ...</div>
      </div>
    </div>
  )
}
