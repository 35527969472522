export const localEnv = {
  api: {
    GATEWAY_URL: 'http://localhost:5788/v1',
    SOCKET_URL: 'http://localhost:5788',
  },
  SHORT_DATE_FORMAT: 'DD/MM/YYYY',
  SHORT_TIME_FORMAT: 'hh:mm:ss',
  SHORT_DATE_CALENDAR_FORMAT: 'YYYY-MM-DD',
  LONG_DATE_FORMAT: 'DD/MM/YYYY hh:mm:ss',
}
