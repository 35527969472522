import { FC } from 'react'
import { Routes, Route } from 'react-router-dom'
import { Login } from '../pages/auth/login/Login'
import { ResetPassword } from '../pages/auth/resetPassword/ResetPassword'
import { SetNewPassword } from '../pages/auth/setNewPassword/SetNewPassword'
import { Page404 } from '../pages/auth/page-404/Page404'
import { ePublicRoutes } from './e-routes'
import { RegisterCompanyDetailsPage } from '../pages/auth/registerNewCompany/RegisterCompanyDetailsPage'
import { RegisterCompanyEmailPage } from '../pages/auth/registerNewCompany/RegisterCompanyEmailPage'

export const PublicRoutes: FC = () => {
  return (
    <div className="main-wrapper">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path={ePublicRoutes.LOGIN} element={<Login />} />
        <Route path={ePublicRoutes.RESET_PASSWORD} element={<ResetPassword />} />
        <Route path={ePublicRoutes.SET_NEW_PASSWORD} element={<SetNewPassword />} />
        <Route path={ePublicRoutes.TRIAL_REGISTRATION_EMAIL} element={<RegisterCompanyEmailPage />} />
        <Route path={ePublicRoutes.TRIAL_REGISTRATION_COMPANY} element={<RegisterCompanyDetailsPage />} />
        <Route path="/*" element={<Page404 />} />
      </Routes>
    </div>
  )
}
