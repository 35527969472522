import { CSSProperties, FC, HTMLAttributes, ReactElement, useState } from 'react'
import { LCFormDropdown } from '../../forms/lc-form-dropDown/lc-form-dropDown'
import { LCFormInputText } from '../../forms/lc-form-inputText/lc-form-inputText'
import { IAdvancedFilterValuesStructure } from './LCAdvancedFilterAddItem'
import { IExtendedColumn } from '../../../../pages/riskhr/riskHrEvaluations/columns/riskHrEvaluations.columns'
import { eSearchType } from '@logicalcommander/types'
import { LCFormDropdownDynamic } from '../../forms/lc-form-dropdown-dynamic/lc-form-dropdown-dynamic'

export interface Props extends HTMLAttributes<any> {
  column: IExtendedColumn
  formik: any // FormikConfig<FormikValues>
  customStyle?: CSSProperties
}

export const LCAdvancedFilterAddItemValues: FC<Props> = ({ column, formik, customStyle }: Props) => {
  const [rawValue, setRawValue] = useState<string>(formik.values.value)

  const handleNonFiltered = (): ReactElement => {
    return <LCFormInputText<any> inputId="value" inputType="text" hideError={true} {...formik.getFieldProps('value')} />
  }

  const onSelectValue = (value: string, filter: IAdvancedFilterValuesStructure) => {
    console.log('formik onSelectValue: ', value)
    setRawValue(value)
    if (filter.valueTransform) {
      const transformedValue = filter.valueTransform(value)
      formik.setFieldValue('value', transformedValue)
    } else {
      formik.setFieldValue('value', value)
    }
  }

  const handleFiltered = (filter: IAdvancedFilterValuesStructure): ReactElement => {
    // console.log('filter', filter)
    switch (filter.type) {
      case 'date':
        const operator = formik.getFieldProps('operator')
        if (operator.value === eSearchType.EXISTS) {
          return <div></div>
        }
        return (
          // <LCFormInputTextCalendar<any>
          //   customStyle={customStyle}
          //   inputId="bDate"
          //   onDateSelected={(value: Date) => formik.setFieldValue('value', datesHelper.formatShort(value))}
          //   defaultValueWhenOpen={moment().toDate()}
          // />
          <LCFormDropdown<any>
            customStyle={customStyle}
            inputId="value"
            hideError={true}
            placeholder="Please select"
            options={filter.values}
            {...formik.getFieldProps('value')}
            onChange={(value: string) => onSelectValue(value, filter)}
            // initValue={filter.values && filter.values[0].value}
          />
        )
      case 'dropdown':
        return (
          <LCFormDropdownDynamic
            customStyle={customStyle}
            inputId="value"
            selectedValue={String(rawValue)}
            placeholder="Please select value"
            setFieldValue={(key: string, value: string) => {
              onSelectValue(value, filter)
            }}
            options={filter.values}
            {...formik.getFieldProps('value')}
          />
        )
      case 'number':
        return (
          <LCFormInputText<any>
            customStyle={customStyle}
            inputId="value"
            inputType="number"
            hideError={true}
            {...formik.getFieldProps('value')}
          />
        )
      case 'string':
      case 'onlyContains':
        return (
          <LCFormInputText<any>
            customStyle={customStyle}
            inputId="value"
            inputType="text"
            hideError={true}
            {...formik.getFieldProps('value')}
          />
        )
    }
  }

  return column?.filter ? handleFiltered(column.filter) : handleNonFiltered()
}
