import React from 'react'
import './lc-form-inputText.scss'
import { CSSProperties, InputHTMLAttributes, ReactElement } from 'react'
import LCPopup from '../../lc-popup/LCPopup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons/faTriangleExclamation'
import { SanitizeHTML } from '../../lc-sanitize-html/SanitizeHTML'

export interface LCFormInputTextProps<ObjectType> extends InputHTMLAttributes<HTMLInputElement> {
  inputId: keyof ObjectType
  inputType: 'text' | 'email' | 'number'
  label?: string
  labelPosition?: 'top' | 'left'
  required?: boolean
  disabled?: boolean
  customStyle?: CSSProperties
  error?: any
  hideError?: boolean
  touched?: any
  placeholder?: string
}

export const LCFormInputText = <ObjectType,>({
  label,
  labelPosition,
  inputId,
  inputType,
  error,
  hideError,
  required,
  disabled,
  customStyle,
  placeholder,
  ...props
}: LCFormInputTextProps<ObjectType>): ReactElement => {
  const classes = ['inputText-Wrapper', disabled ? 'disabled' : '', labelPosition ? labelPosition : 'top']

  // Ensure value is defined to avoid switching from uncontrolled to controlled
  const controlledValue = props.value === undefined ? '' : props.value

  return (
    <div className={classes.join(' ')} style={customStyle}>
      {label && (
        <label className="inputText-label">
          {label} {required && <span className="required">*</span>}
        </label>
      )}

      <div className="inputText-input-wrapper">
        <input
          className="inputText-input"
          id={String(inputId)}
          type={inputType}
          placeholder={placeholder}
          disabled={disabled}
          value={controlledValue} // Explicitly controlled
          {...props} // Now includes a defined value, ensuring controlled input
        />

        {!hideError && error ? (
          <LCPopup
            position={['midleft', 'bottom']}
            toggleOn={'hover'}
            toggler={
              <div className="inputText-input-error-icon">
                <FontAwesomeIcon icon={faTriangleExclamation} size="lg" style={{ color: 'red' }} />
              </div>
            }>
            <div className="inputText-error">
              <SanitizeHTML html={error} />
            </div>
          </LCPopup>
        ) : null}
      </div>
    </div>
  )
}
