import * as React from 'react'
import { SVGProps } from 'react'

const SvgMagnifier = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={9} cy={9} r={8} stroke="currentColor" strokeWidth={2} />
    <path d="M14.85 15.041 19 19" stroke="currentColor" strokeWidth={2} />
  </svg>
)

export default SvgMagnifier
