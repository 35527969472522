import { IAddress, IEnumsPositionGroupSettingsItem, IUser, IUserCompanyDetails } from '@logicalcommander/types'
import { FC, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../../../redux/hooks'
import { selectDepartments, selectPositions, selectPositionsGroups } from '../../../../../redux/reducers/settingsSlice'
import { LCEmployeesDropdown } from '../../../../../ui/awareComponents/lc-employees-dropdown/lc-employees-dropdown'
import { LCFormDropdownDynamic, OptionType } from '../../../../../ui/components/forms/lc-form-dropdown-dynamic/lc-form-dropdown-dynamic'
import { LCFormInputText } from '../../../../../ui/components/forms/lc-form-inputText/lc-form-inputText'
import { getAllCountriesDropdown, getAllStatesDropdown, getDropdownOptionsFromDbEnum } from '../../../../../utils/list-helper'
import { UserActionName } from '../../createUserHelper'
import './companyDetailsForm.scss'

interface Props {
  user: IUser | undefined
  formik: any
  actionName: UserActionName
  disabled?: boolean
}

export const CompanyDetails: FC<Props> = ({ user, disabled = false, formik, actionName }: Props): ReactElement => {
  const { t } = useTranslation('translation', { keyPrefix: 'user.company_details' })
  const [positionGroupName, setPositionGroupName] = useState('')

  const positionsGroups: IEnumsPositionGroupSettingsItem[] = useAppSelector(selectPositionsGroups)
  const allDepartmentOptions: OptionType[] = getDropdownOptionsFromDbEnum(useAppSelector(selectDepartments))
  const allPositionsOptions: OptionType[] = getDropdownOptionsFromDbEnum(useAppSelector(selectPositions))

  console.log('formik.values?.companyDetails?.positionId', formik.values?.companyDetails?.positionId)
  console.log('formik.values?.companyDetails?.departmentId', formik.values?.companyDetails?.departmentId)

  useEffect(() => {
    if (positionsGroups && formik.values.companyDetails?.positionId) {
      const pg = positionsGroups.find((pg) => pg.positions.includes(Number(formik.values.companyDetails?.positionId)))
      pg?.name && setPositionGroupName(pg?.name)
    }
  }, [positionsGroups, formik.values.companyDetails?.positionId])

  return (
    <div className="base-section-wrapper">
      <div className="base-section">
        <LCFormDropdownDynamic
          required={true}
          label={`${t('position')} ${positionGroupName ? `(${positionGroupName})` : ''}`}
          inputId="companyDetails.positionId"
          selectedValue={String(formik.values.companyDetails?.positionId)}
          disabled={disabled}
          setFieldValue={formik.setFieldValue}
          options={allPositionsOptions}
          error={formik.errors.companyDetails?.positionId}
          {...formik.getFieldProps('companyDetails.positionId')}
        />

        <LCFormDropdownDynamic
          required={false}
          label={t('department')}
          inputId="companyDetails.departmentId"
          selectedValue={formik.values?.companyDetails?.departmentId}
          disabled={disabled}
          setFieldValue={formik.setFieldValue}
          options={allDepartmentOptions}
          error={formik.errors.companyDetails?.departmentId}
        />
        <LCEmployeesDropdown
          required={false}
          label={t('direct_manager')}
          inputId="companyDetails.directManagerIdentity"
          setFieldValue={formik.setFieldValue}
          error={formik.errors.companyDetails?.directManagerIdentity}
        />
      </div>

      <div className="base-section">
        <LCFormInputText<IUserCompanyDetails>
          label={t('company_email')}
          inputId="companyDetails.companyEmail"
          inputType="text"
          disabled={disabled}
          error={formik.errors.companyDetails?.companyEmail}
          {...formik.getFieldProps('companyDetails.companyEmail')}
        />
        <LCFormInputText<IUserCompanyDetails>
          label={t('company_mobile')}
          inputId="companyDetails.companyMobile"
          inputType="text"
          disabled={disabled}
          error={formik.errors.companyDetails?.companyMobile}
          {...formik.getFieldProps('companyDetails.companyMobile')}
        />
        <LCFormInputText<IUserCompanyDetails>
          label={t('extension_number')}
          inputId="companyDetails.extensionNumber"
          inputType="number"
          disabled={disabled}
          error={formik.errors.companyDetails?.extensionNumber}
          {...formik.getFieldProps('companyDetails.extensionNumber')}
        />
      </div>
      <div className="base-section">
        <LCFormInputText<IUserCompanyDetails>
          label={t('additional_1')}
          inputId="companyDetails.additional1"
          inputType="text"
          disabled={disabled}
          error={formik.errors.companyDetails?.additional1}
          {...formik.getFieldProps('companyDetails.additional1')}
        />
        <LCFormInputText<IUserCompanyDetails>
          label={t('additional_2')}
          inputId="companyDetails.additional2"
          inputType="text"
          disabled={disabled}
          error={formik.errors.companyDetails?.additional2}
          {...formik.getFieldProps('companyDetails.additional2')}
        />
        <LCFormInputText<IUserCompanyDetails>
          label={t('additional_3')}
          inputId="companyDetails.additional3"
          inputType="text"
          disabled={disabled}
          error={formik.errors.companyDetails?.additional3}
          {...formik.getFieldProps('companyDetails.additional3')}
        />
      </div>

      <div className="base-section">
        <LCFormDropdownDynamic
          required={false}
          label={t('officeAddress.country')}
          inputId="companyDetails.officeAddress.country"
          selectedValue={formik.values?.companyDetails?.officeAddress?.country}
          disabled={disabled}
          setFieldValue={formik.setFieldValue}
          options={getAllCountriesDropdown()}
          error={formik.errors.companyDetails?.officeAddress?.country}
        />
        <LCFormInputText<IAddress>
          label={t('officeAddress.city')}
          required={false}
          inputId="companyDetails.officeAddress.city"
          inputType="text"
          disabled={disabled}
          error={formik.errors.companyDetails?.officeAddress?.city}
          {...formik.getFieldProps('companyDetails.officeAddress.city')}
        />
        <LCFormDropdownDynamic
          required={false}
          label={t('officeAddress.state')}
          inputId="companyDetails.officeAddress.state"
          selectedValue={formik.values?.companyDetails?.officeAddress?.state}
          disabled={disabled}
          setFieldValue={formik.setFieldValue}
          options={getAllStatesDropdown()}
          error={formik.errors.companyDetails?.officeAddress?.state}
        />
        <LCFormInputText<IAddress>
          label={t('officeAddress.post_code')}
          required={false}
          inputId="companyDetails.officeAddress.postcode"
          inputType="text"
          disabled={disabled}
          error={formik.errors.companyDetails?.officeAddress?.postcode}
          {...formik.getFieldProps('companyDetails.officeAddress.postcode')}
        />
      </div>

      <div className="base-section">
        <LCFormInputText<IAddress>
          label={t('officeAddress.address_line_1')}
          required={false}
          inputId="companyDetails.officeAddress.addressLine1"
          inputType="text"
          disabled={disabled}
          error={formik.errors.companyDetails?.officeAddress?.addressLine1}
          {...formik.getFieldProps('companyDetails.officeAddress.addressLine1')}
        />
        <LCFormInputText<IAddress>
          label={t('officeAddress.address_line_2')}
          required={false}
          inputId="companyDetails.officeAddress.addressLine2"
          inputType="text"
          disabled={disabled}
          error={formik.errors.companyDetails?.officeAddress?.addressLine2}
          {...formik.getFieldProps('companyDetails.officeAddress.addressLine2')}
        />
      </div>
      <div className="base-section">
        <LCFormInputText<IAddress>
          label={t('officeAddress.building')}
          required={false}
          inputId="companyDetails.officeAddress.building"
          inputType="text"
          disabled={disabled}
          error={formik.errors.companyDetails?.officeAddress?.building}
          {...formik.getFieldProps('companyDetails.officeAddress.building')}
        />
        <LCFormInputText<IAddress>
          label={t('officeAddress.room')}
          required={false}
          inputId="companyDetails.officeAddress.room"
          inputType="text"
          disabled={disabled}
          error={formik.errors.companyDetails?.officeAddress?.room}
          {...formik.getFieldProps('companyDetails.officeAddress.room')}
        />
      </div>
    </div>
  )
}
