import './app.scss'
import 'react-toastify/dist/ReactToastify.css'

import React, { useEffect, useState } from 'react'
import { useAppSelector } from './app/redux/hooks'
import { eInitializeState, selectGeneral } from './app/redux/reducers/generalSlice'
import { LCLoader } from './app/ui/components/lc-loader/LCLoader'
import { MainLayout } from './app/layout/MainLayout'
import { PublicRoutes } from './app/routes/PublicRoutes'
import { Routes, Route } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import AuthenticatedInitializer from './app/init/afterAuth/AuthenticatedInitializer'
import { initLanguage } from './app/init/beforeAuth/language/initLanguage'
import { ToastContainer } from 'react-toastify'

const App = () => {
  const { showLoader, initializeState, selectedLanguage } = useAppSelector(selectGeneral)
  const [previousLanguage, setPreviousLanguage] = useState<string>('en')

  useEffect(() => {
    if (selectedLanguage && selectedLanguage !== previousLanguage) {
      setPreviousLanguage(selectedLanguage)
      initLanguage(selectedLanguage)
    }
  }, [selectedLanguage])

  return (
    <div className="app">
      <LCLoader show={showLoader || initializeState === eInitializeState.LOADING} />
      {/* <LCSocket /> */}
      <div style={{ backgroundColor: 'red', width: '200px' }}>
        <ToastContainer />
      </div>

      <BrowserRouter>
        <AuthenticatedInitializer>
          <Routes>
            <Route path="/app/*" element={<MainLayout />} />
            {/* Protected routes */}
            <Route path="/*" element={<PublicRoutes />} />
          </Routes>
        </AuthenticatedInitializer>
      </BrowserRouter>
    </div>
  )
}

export default App
