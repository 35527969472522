import './register-company-email-page.scss'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { LCLanguagesSelection } from '../../../ui/components/lc-languages-selection/LCLanguagesSelection'
import { changeLanguage, selectGeneral } from '../../../redux/reducers/generalSlice'
import { useFormik } from 'formik'
import { LCFormInputText } from '../../../ui/components/forms/lc-form-inputText/lc-form-inputText'
import { LCButton } from '../../../ui/components/lc-button/LCButton'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IRegisterLeadEmailRequest, useRegisterLeadEmailMutation } from '../../../services/register.services'
import { RootState } from '../../../redux/store'
import { SanitizeHTML } from '../../../ui/components/lc-sanitize-html/SanitizeHTML'
import { useAppDispatch } from '../../../redux/hooks'
import { LCUnauthenticatedPage } from '../../../ui/components/lc-unauthenticated-page/LCUnauthenticatedPage'
import { ePublicRoutes } from '../../../routes/e-routes'
import { useNavigate } from 'react-router'
import deepEqual from 'deep-equal'
import { LCInlineMessage } from '../../../ui/components/lc-inline-message/LCInlineMessage'

export const RegisterCompanyEmailPage = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'auth.leadRegistration.leadEmailPage' })
  const { t: v } = useTranslation('translation', { keyPrefix: 'validation' })
  const [registerLeadEmailRequest, { data, error, isLoading }] = useRegisterLeadEmailMutation()
  const { selectedLanguage } = useSelector((state: RootState) => selectGeneral(state))
  const [isValidForm, setIsValidForm] = useState(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const doChangeLanguage = (language: string) => {
    formik.setFieldValue('language', language)
    dispatch(changeLanguage(language))
  }

  const validationRules = {
    email: Yup.string()
      .email(v('valid', { field: t('email') }))
      .required(v('required', { field: t('email') })),
  }

  const initialValues = {
    email: '',
    language: selectedLanguage || 'en',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object(validationRules),
    onSubmit: (values: any) => {
      const params: IRegisterLeadEmailRequest = { email: values.email, language: selectedLanguage }
      registerLeadEmailRequest(params)
    },
  })

  useEffect(() => {
    const touched = !deepEqual(formik.values, initialValues)
    setIsValidForm(touched && formik.isValid)
  }, [formik.isValid, formik.touched, formik.values.email])

  return (
    <LCUnauthenticatedPage title={t('title')}>
      <div className="register-company-email-page">
        <SanitizeHTML className="description" html={t('description')} />
        <form className="register-form" onSubmit={formik.handleSubmit}>
          <LCLanguagesSelection label={t('language')} required languageSelected={doChangeLanguage} />

          <LCFormInputText
            label={t('email')}
            inputId="email"
            inputType="text"
            required
            error={formik.errors.email}
            {...formik.getFieldProps('email')}
          />

          <LCButton
            type="submit"
            label={t('submitButton')}
            isLoading={isLoading}
            disabled={!isValidForm}
            customStyle={{ maxWidth: '200px', alignSelf: 'flex-end', marginTop: '10px' }}
          />
        </form>
      </div>
      <div className="register-company-email-page-bottom">
        <div className="link-blue" onClick={() => navigate(ePublicRoutes.LOGIN)}>
          {t('navigateToLogin')}
        </div>
        <LCInlineMessage
          customStyle={{ marginTop: '20px' }}
          clientErrorCode={(error as any)?.data?.clientErrorCode}
          successMessage={data ? { title: t('successFree.title'), body: t('successFree.body') } : undefined}
        />
      </div>
    </LCUnauthenticatedPage>
  )
}
