import './lc-text.scss'
import { CSSProperties, FC } from 'react'
import { useTranslation } from 'react-i18next'

export interface ITextProps {
  label?: string
  labelTX?: string
  disabled?: boolean
  size?: 'small' | 'medium' | 'large' | 'auto'
  customStyle?: CSSProperties
}

export const LCText: FC<ITextProps> = ({ label, labelTX, disabled, size = 'medium', customStyle, ...props }: ITextProps) => {
  const { t } = useTranslation()
  const disabledClass = disabled ? 'lc-text--disabled' : ''

  return (
    <div className={['lc-text', `lc-text--${size}`, disabledClass].join(' ')} style={customStyle} {...props}>
      {labelTX ? t(labelTX) : label}
    </div>
  )
}
