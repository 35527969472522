import './dashboard-components-error.scss'
import React, { CSSProperties, FC, HTMLAttributes } from 'react'


export interface Props extends HTMLAttributes<HTMLDivElement> {
    message?: string
    customStyle?: CSSProperties
}

export const DashboardComponentError: FC<Props> = ({ message = "General error occured, please try again later", customStyle }: Props) => {

    return (
        <div className='bdc-error' style={customStyle}>
            <div className='bdc-error-message'>{message}</div>
        </div>
    )

}


