import './lc-input-text.scss'
import { CSSProperties, FC } from 'react'

export interface Props {
  value: string
  label?: string
  customStyle?: CSSProperties
  onChange?: (value: string) => void
}

export const LCInputText: FC<Props> = ({ label, value, customStyle, onChange, ...props }: Props) => {
  return (
    <div className="lc-input-text-wrapper" style={customStyle} {...props}>
      {label ? <label className="lc-input-text-label">{label}</label> : null}
      <input className="lc-input-text" value={value} onChange={(e) => onChange && onChange(e.currentTarget.value)} />
    </div>
  )
}
