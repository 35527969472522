import { IUser } from '@logicalcommander/types'
import { IEmoResults } from '../../interfaces/emo.interface'
import { IUserInfo } from '../../interfaces/user.interfaces'

export const fullName = (user: IUser | IEmoResults | IUserInfo) => {
  const fullName: string[] = []
  fullName.push(user.firstName)
  user.middleName && fullName.push(user.middleName)
  fullName.push(user.lastName)
  return fullName.join(' ')
}

export const getInitials = (lastName: string, firstName: string): string => {
  return lastName.charAt(0) + firstName.charAt(0)
}
