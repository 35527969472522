import './lc-logo.scss'
import React from 'react'
import { CSSProperties, FC, useEffect, useState } from 'react'
import { LCCachedImage } from '../lc-cached-image/LCCachedImage'
import { useLazyGetCompanyLogoTmpUrlQuery } from '../../../services/settings.service'

export interface ILogoProps {
  name: string
  description: string
  logoWidth?: number
  customStyle?: CSSProperties
  withSeparator?: boolean
  showCompanyLabels?: boolean
}

/**
 * Will show company logo with:
 * name: companyId
 * description: company description
 * path: URL to company logo (if not supplied or fail to fetch will who the default Logical Commander logo)
 */
export const LCLogo: FC<ILogoProps> = ({
  name,
  customStyle,
  description,
  logoWidth = 40,
  showCompanyLabels = false,
  withSeparator = true,
  ...props
}: ILogoProps) => {
  const [getCompanyLogo, { data }] = useLazyGetCompanyLogoTmpUrlQuery()
  const [showLabels, setShowLabels] = useState<boolean>(showCompanyLabels)

  useEffect(() => {
    setShowLabels(showCompanyLabels)
  }, [showCompanyLabels])

  useEffect(() => {
    getCompanyLogo()
  }, [])

  const SeparatorAnsLabels = () => {
    return (
      <div className="lc-logo-labels-overflow">
        {withSeparator && <div className="lc-logo-separator" />}
        <div className="lc-logo-labels">
          <span className="firstText">{name}</span>
          <div className="secondText">{description}</div>
        </div>
      </div>
    )
  }

  return (
    <div className="lc-logo" style={customStyle} {...props}>
      {data?.url ? <LCCachedImage cacheKey={'logo'} url={data.url} width={40} height={40} /> : null}
      {showLabels ? <SeparatorAnsLabels /> : null}
    </div>
  )
}
