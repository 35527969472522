import './emo-risk-dashboard.scss'
import React, { CSSProperties, FC, HTMLAttributes, lazy, useEffect, useState } from 'react'
import { BarColor, CustomLabels } from '../../../../../interfaces/dashboard.interface'
import { ITitleValueNumber } from '@logicalcommander/types'
import { useTranslation } from 'react-i18next'
import { BaseDashboardComponent } from '../../../../ui/components/base-dashboard/BaseDashboardComponent'
import { DashboardComponentTitle } from '../../../../ui/components/base-dashboard/DashboardComponentTitle'

const LCPieChartUniNew = lazy(() => import('../../../../ui/components/charts/lc-pie-chart'))

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

const EmoRiskEmotionPerRisk: FC<Props> = ({ customStyle }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.emoRisk.emotionsPerRisk' })
  const [dataEmotionRisk, setDataEmotionRisk] = useState<ITitleValueNumber[]>([])

  const isLoading = false
  const error = false

  const data = [
    {
      title: 'Energy',
      value: 20,
    },
    {
      title: 'Happiness',
      value: 10,
    },
    {
      title: 'Anger',
      value: 10,
    },
    {
      title: 'Stress',
      value: 10,
    },
    {
      title: 'Excitement',
      value: 10,
    },
    {
      title: 'Mental Load',
      value: 60,
    },
    {
      title: 'Discomfort',
      value: 2,
    },
    {
      title: 'Confidence',
      value: 1,
    },
    {
      title: 'Extreme Emotion',
      value: 5,
    },
    {
      title: 'Emotional Stress',
      value: 9,
    },
    {
      title: 'Dissatisfaction',
      value: 6,
    },
    {
      title: 'Burnout',
      value: 2,
    },
    {
      title: 'Depression',
      value: 8,
    },
  ]

  useEffect(() => {
    if (data) {
      const sorted = [...data].sort(sortByRisk)
      setDataEmotionRisk(sorted)
    }
  }, [])

  const sortByRisk = (a: any, b: any) => {
    return b.value - a.value
  }

  return (
    <BaseDashboardComponent customStyle={customStyle} sectionTitle={<DashboardComponentTitle title={t('title')} />} isLoading={isLoading} isSuccess={!!dataEmotionRisk} isError={!!error}>
      <LCPieChartUniNew
        data={dataEmotionRisk}
        options={{
          color: BarColor.COLOR_STATUS,
          customPercentage: true,
          label: CustomLabels.ALERT_TOTAL,
        }}
      />
    </BaseDashboardComponent>
  )
}

export default EmoRiskEmotionPerRisk
