import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { v4 as uuidv4 } from 'uuid'
import { RootState } from '../redux/store'
import { INotification } from '../ui/awareComponents/lc-notifications/lc-notifications.interface'
import { configManager } from '../../config/configManager'

export const notificationsApi = createApi({
  reducerPath: 'notificationsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${configManager.api.GATEWAY_URL}/app-notifications`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('x-request-id', uuidv4())
      const { token, companyToken } = (getState() as RootState).authReducer
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      if (companyToken) {
        headers.set('x-company-token', companyToken)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    getNotifications: builder.query<INotification[], void>({
      query: (name) => ({
        url: '',
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetNotificationsQuery } = notificationsApi
