/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { OpenCaseModal } from '../../riskHrCases/case/components/CreateCaseModal/OpenCaseModal'
import { useOpenCaseMutation } from '../../../../services/cases.service'
import { IAdvancedFilterValuesStructure } from '../../../../ui/components/advanced-filter/advanced-filter-add-item/LCAdvancedFilterAddItem'
import { LCSvg } from '../../../../ui/components/lc-svg/lc-svg'
import { ILCActionPopupItemData, LCActionPopup } from '../../../../ui/components/lc-action-popup/LCActionPopup'
import { LCModal } from '../../../../ui/components/lc-modal/LCModal'
import { eEvaluationStatus } from '@logicalcommander/types'
import { useResendEvaluationEmailMutation, useUnassignEvaluationMutation } from '../../../../services/evaluations.service'
import { IResendEmailRequest, IUnAssignEvaluationRequest } from '../../../../../interfaces/evaluations.interface'
import { IEvaluationSummary, IExtendedColumn } from './riskHrEvaluations.columns'
import { toast } from 'react-toastify'
import { LCToast } from '../../../../ui/components/lc-toast/Toast'
import moment from 'moment'
import { datesHelper } from '../../../../utils/dates'

export const riskHrEvaluationsAction_columns = (navigate: any, t: any) => [
  {
    Header: t('evaluations.action'),
    disableSortBy: true,
    Cell: (row: any) => {
      const [modalOpenCaseOpen, setModalOpenCaseOpen] = useState(false)
      const [openCase, { data: dataOpenCase, error: errorOpenCase }] = useOpenCaseMutation()
      const [resendEvaluationEmail, { data: dataResend, error: errorResend }] = useResendEvaluationEmailMutation()
      const [unassignEvaluation, { data: dataUnassign, error: errorUnassign }] = useUnassignEvaluationMutation()

      const evaluation: IEvaluationSummary = row.row.original
      const showActions = evaluation.lastStatus === eEvaluationStatus.created || evaluation.lastStatus === eEvaluationStatus.completed

      // Handle open case
      useEffect(() => {
        if (!errorOpenCase && dataOpenCase) {
          navigate(`/app/riskhr/case/${dataOpenCase.id}`)
        }
      }, [dataOpenCase, errorOpenCase])

      // Handle resend response
      useEffect(() => {
        if (!errorResend && dataResend) {
          console.log('dataResend', dataResend)
          if (dataResend.errors && dataResend.errors.length > 0) {
            toast.error(
              <LCToast
                title={'General error'}
                body={`Error resend email to: ${dataResend.errors[0].fullName} (${dataResend.errors[0].identity}), please try again later`}
              />
            )
          } else if (dataResend.success && dataResend.success.length > 0) {
            toast.success(
              <LCToast
                title={'Resend success'}
                body={`Success resend email to: ${dataResend.success[0].fullName} (${dataResend.success[0].identity})`}
              />
            )
          }
        } else if (errorResend) {
          console.log('errorResend', errorResend)
          toast.error(<LCToast title={'General error'} body={`Error resend assign email, please try again later`} />)
        }
      }, [dataResend, errorResend])

      // Handle unassign response
      useEffect(() => {
        if (!errorUnassign && dataUnassign) {
          console.log('dataUnassign', dataUnassign)
          toast.success(<LCToast title={'Unassign success'} body={`Success unassign assessment to: ${dataUnassign}`} />)
        } else if (errorUnassign) {
          console.log('errorUnassign', errorUnassign)
          toast.error(<LCToast title={'General error'} body={`Error unassign assessment, please try again later`} />)
        }
      }, [dataUnassign, errorUnassign])

      const actionPopupData = (evaluation: IEvaluationSummary): ILCActionPopupItemData[] => {
        const evaluationId = evaluation.evaluationId
        const identity = evaluation.identity

        const unassign = () => {
          const unassignRequest: IUnAssignEvaluationRequest = {
            evaluationId,
            identity,
          }
          unassignEvaluation([unassignRequest])
        }

        const resendEmail = () => {
          const resend: IResendEmailRequest = {
            evaluationId,
            identity,
          }
          resendEvaluationEmail([resend])
        }

        return [
          {
            text: t('evaluations.resend_email'),
            iconName: 'report',
            disabled: evaluation.lastStatus !== eEvaluationStatus.created,
            action: resendEmail,
          },
          {
            text: t('evaluations.delete_assignment'),
            iconName: 'delete',
            disabled: evaluation.lastStatus !== eEvaluationStatus.created,
            action: unassign,
          },
          {
            text: t('evaluations.open_case'),
            iconName: 'edit',
            disabled: evaluation.lastStatus !== eEvaluationStatus.completed,
            action: () => {
              setModalOpenCaseOpen(true)
            },
          },
        ]
      }

      return (
        <div>
          <LCModal
            inlineStyle={{ width: '600px' }}
            isOpen={modalOpenCaseOpen}
            title={`${t('risk_hr_case.open_case_modal_title')} for assessment ${evaluation.evaluationId}`}
            onClose={() => setModalOpenCaseOpen(false)}>
            <OpenCaseModal
              title={`Assessment of ${evaluation.fullName} (${evaluation.evaluationId}) completed at ${datesHelper.formatLong(evaluation.status.completed)}`}
              onCloseModal={() => setModalOpenCaseOpen(false)}
              onOpenCase={() => {}}
              // onOpenCase={(title: string) => {
              //   openCase({
              //     title,
              //     evaluation,
              //   } as IOpenCaseRequest)
              //   setModalOpenCaseOpen(false)
              // }}
            />
          </LCModal>
          {showActions ? (
            <LCActionPopup
              data={actionPopupData(evaluation)}
              anchor={
                <div>
                  <LCSvg name="3dots" />
                </div>
              }
            />
          ) : null}
        </div>
      )
    },
    filter: {
      hide: true,
    } as IAdvancedFilterValuesStructure,
  } as IExtendedColumn,
]
