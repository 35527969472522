import './lc-toolbar-button.scss'
import { FC, ReactElement, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner'

export interface Props {
  icon: any
  text?: string
  action?: () => void
  isLoading?: boolean
}

export const LCToolbarButton: FC<Props> = ({ text, icon: propIcon, action, isLoading }: Props): ReactElement => {
  const [icon, setIcon] = useState(propIcon)

  useEffect(() => {
    isLoading === true ? setIcon(faSpinner) : setIcon(propIcon)
  }, [isLoading])

  return (
    <div className="toolbar-button-wrapper" onClick={action}>
      <FontAwesomeIcon className="toolbar-button-icon" icon={icon} size="lg" />
      {text ? <div className="toolbar-button-text">{text}</div> : null}
    </div>
  )
}
