import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { IAssessmentSchemaInfo } from '@logicalcommander/types'

interface AssessmentsState {
  info: IAssessmentSchemaInfo[] | undefined
}

const initialState: AssessmentsState = {
  info: undefined,
}

export const assessmentsSlice = createSlice({
  name: 'assessmentsReducer',
  initialState,
  reducers: {
    setAssessmentsInfo: (state, action: PayloadAction<IAssessmentSchemaInfo[]>) => {
      state.info = action.payload
    },
  },
})

export const { setAssessmentsInfo } = assessmentsSlice.actions
export default assessmentsSlice.reducer
export const selectAssessments = (state: RootState) => state.assessmentsReducer
