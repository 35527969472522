import * as Yup from 'yup'
import { lcRegex } from '../../../utils/regex'
import { Gender, IUser } from '@logicalcommander/types'

export enum UserActionName {
  CREATE = 'create',
  VIEW = 'view',
  EDIT = 'edit',
}

export const userInitialValues: IUser = {
  companyId: '',
  identity: '',
  roles: [],
  firstName: '',
  middleName: '',
  lastName: '',
  mobile: '',
  profileImage: '',
  email: '',
  personalDetails: {
    additionalPhone: '',
    comments: '',
    gender: Gender.MALE,
  },
  personalAddress: {
    city: '',
    addressLine1: '',
    addressLine2: '',
    postcode: '',
  },
  companyDetails: {
    directManagerIdentity: '',
    officeAddress: {
      city: '',
      addressLine1: '',
      addressLine2: '',
      postcode: '',
      building: '',
      room: '',
    },
    companyEmail: '',
    companyMobile: '',
    extensionNumber: '',
    additional1: '',
    additional2: '',
    additional3: '',
  },
}

export const personalDetailsValidationRules = (v: any, t: any) =>
  Yup.object().shape({
    additionalPhone: Yup.string()
      .min(6, v('short', { field: t('personal_details.additional_phone'), short: 6, long: 20 }))
      .max(20, v('long', { field: t('personal_details.additional_phone'), short: 6, long: 20 }))
      .matches(lcRegex.mobileNumber, v('valid', { field: t('personal_details.additional_phone') })),
    comments: Yup.string()
      .min(3, v('short', { field: t('personal_details.comments'), short: 3, long: 1000 }))
      .max(1000, v('long', { field: t('personal_details.comments'), short: 3, long: 1000 })),
  })

export const personalAddressValidationRules = (v: any, t: any) =>
  Yup.object().shape({
    city: Yup.string()
      .min(2, v('short', { field: t('personal_address.city'), short: 2, long: 30 }))
      .max(30, v('long', { field: t('personal_address.city'), short: 2, long: 30 })),
    addressLine1: Yup.string()
      .min(3, v('short', { field: t('personal_address.address_line_1'), short: 3, long: 50 }))
      .max(50, v('long', { field: t('personal_address.address_line_1'), short: 3, long: 50 })),
    addressLine2: Yup.string()
      .min(3, v('short', { field: t('personal_address.address_line_2'), short: 3, long: 50 }))
      .max(50, v('long', { field: t('personal_address.address_line_2'), short: 3, long: 50 })),
    postcode: Yup.string()
      .min(3, v('short', { field: t('personal_address.post_code'), short: 3, long: 20 }))
      .max(20, v('long', { field: t('personal_address.post_code'), short: 3, long: 20 })),
  })

export const companyDetailsValidationRules = (v: any, t: any) =>
  Yup.object().shape({
    departmentId: Yup.string()
      .min(1, v('short', { field: t('company_details.department'), short: 1, long: 4 }))
      .max(4, v('long', { field: t('company_details.department'), short: 1, long: 4 })),
    directManagerIdentity: Yup.string()
      .min(2, v('short', { field: t('company_details.direct_manager'), short: 2, long: 30 }))
      .max(30, v('long', { field: t('company_details.direct_manager'), short: 2, long: 30 })),
    companyEmail: Yup.string()
      .email(v('valid', { field: t('company_details.company_email') }))
      .min(6, v('short', { field: t('company_details.company_email'), short: 6, long: 50 }))
      .max(50, v('long', { field: t('company_details.company_email'), short: 6, long: 50 })),
    companyMobile: Yup.string()
      .matches(lcRegex.phoneNumber, v('valid', { field: t('company_details.company_mobile') }))
      .min(6, v('short', { field: t('company_details.company_mobile'), short: 6, long: 20 }))
      .max(20, v('long', { field: t('company_details.company_mobile'), short: 6, long: 20 })),
    extensionNumber: Yup.string()
      .matches(lcRegex.extensionNumber, v('valid', { field: t('company_details.extension_number') }))
      .min(2, v('short', { field: t('company_details.extension_number'), short: 2, long: 20 }))
      .max(20, v('long', { field: t('company_details.extension_number'), short: 2, long: 20 })),
    // startingDate: Yup.string()
    //   .min(6, v('short', { field: t('company_details.startingDate'), short: 6, long: 20 }))
    //   .max(20, v('long', { field: t('company_details.startingDate'), short: 6, long: 20 })),
    officeAddress: Yup.object().shape({
      city: Yup.string()
        .min(2, v('short', { field: t('company_details.officeAddress.city'), short: 2, long: 30 }))
        .max(30, v('long', { field: t('company_details.officeAddress.city'), short: 2, long: 30 })),
      addressLine1: Yup.string()
        .min(3, v('short', { field: t('company_details.officeAddress.address_line_1'), short: 3, long: 50 }))
        .max(50, v('long', { field: t('company_details.officeAddress.address_line_1'), short: 3, long: 50 })),
      addressLine2: Yup.string()
        .min(3, v('short', { field: t('company_details.officeAddress.address_line_2'), short: 3, long: 50 }))
        .max(50, v('long', { field: t('company_details.officeAddress.address_line_2'), short: 3, long: 50 })),
      postcode: Yup.string()
        .min(3, v('short', { field: t('company_details.officeAddress.post_code'), short: 3, long: 20 }))
        .max(20, v('long', { field: t('company_details.officeAddress.post_code'), short: 3, long: 20 })),
      building: Yup.string()
        .min(1, v('short', { field: t('company_details.officeAddress.building'), short: 1, long: 20 }))
        .max(20, v('long', { field: t('company_details.officeAddress.building'), short: 1, long: 20 })),
      room: Yup.string()
        .min(1, v('short', { field: t('company_details.officeAddress.room'), short: 1, long: 20 }))
        .max(20, v('long', { field: t('company_details.officeAddress.room'), short: 1, long: 20 })),
    }),
    additional1: Yup.string()
      .min(3, v('short', { field: t('company_details.additional_1'), short: 3, long: 50 }))
      .max(50, v('long', { field: t('company_details.additional_1'), short: 3, long: 50 })),
    additional2: Yup.string()
      .min(3, v('short', { field: t('company_details.additional_2'), short: 3, long: 50 }))
      .max(50, v('long', { field: t('company_details.additional_2'), short: 3, long: 50 })),
    additional3: Yup.string()
      .min(3, v('short', { field: t('company_details.additional_3'), short: 3, long: 50 }))
      .max(50, v('long', { field: t('company_details.additional_3'), short: 3, long: 50 })),
    positionId: Yup.string().required(v('required', { field: t('company_details.position') })),
  })

export const validationRules = (v: any, t: any) =>
  Yup.object().shape({
    identity: Yup.string()
      .required(v('required', { field: t('mandatory_fields.identity_number') }))
      .min(5, v('short', { field: t('mandatory_fields.identity_number'), short: 5, long: 50 }))
      .max(50, v('long', { field: t('mandatory_fields.identity_number'), short: 5, long: 50 })),
    firstName: Yup.string()
      .required(v('required', { field: t('mandatory_fields.first_name') }))
      .min(3, v('short', { field: t('mandatory_fields.first_name'), short: 3, long: 30 }))
      .max(30, v('long', { field: t('mandatory_fields.first_name'), short: 3, long: 30 })),
    middleName: Yup.string()
      .min(3, v('short', { field: t('mandatory_fields.middle_name'), short: 3, long: 30 }))
      .max(30, v('long', { field: t('mandatory_fields.middle_name'), short: 3, long: 30 })),
    lastName: Yup.string()
      .required(v('required', { field: t('mandatory_fields.last_name') }))
      .min(3, v('short', { field: t('mandatory_fields.last_name'), short: 3, long: 30 }))
      .max(30, v('long', { field: t('mandatory_fields.last_name'), short: 3, long: 30 })),
    mobile: Yup.string()
      .required(v('required', { field: t('mandatory_fields.mobile_number') }))
      .matches(lcRegex.mobileNumber, v('valid', { field: t('mandatory_fields.mobile_number') }))
      .min(6, v('short', { field: t('mandatory_fields.mobile_number'), short: 6, long: 20 }))
      .max(20, v('long', { field: t('mandatory_fields.mobile_number'), short: 6, long: 20 })),
    email: Yup.string()
      .required(v('required', { field: t('mandatory_fields.email_address') }))
      .email(v('valid', { field: t('mandatory_fields.email_address') }))
      .min(6, v('short', { field: t('mandatory_fields.email_address'), short: 6, long: 50 }))
      .max(50, v('long', { field: t('mandatory_fields.email_address'), short: 6, long: 50 })),

    roles: Yup.array(),
    personalDetails: personalDetailsValidationRules(v, t),
    personalAddress: personalAddressValidationRules(v, t),
    companyDetails: companyDetailsValidationRules(v, t),
  })
