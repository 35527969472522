import './CompanyInformation.scss'

import React, { FC, HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import { CSSProperties } from 'styled-components'
import { BaseDashboardComponent } from '../../../ui/components/base-dashboard/BaseDashboardComponent'
import { useAppSelector } from '../../../redux/hooks'
import { selectExtendedCompany } from '../../../redux/reducers/extendedCompanySlice'
import { DashboardComponentTitle } from '../../../ui/components/base-dashboard/DashboardComponentTitle'
import { ICompanyInfo } from '@logicalcommander/types'
import { useNavigate } from 'react-router'
import { selectAuth } from '../../../redux/reducers/authSlice'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

const CompanyInformation: FC<Props> = ({ customStyle }: Props) => {
  const { companyInfo, expirationDate } = useAppSelector(selectExtendedCompany)
  const { user } = useAppSelector(selectAuth)
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.licenses.companyInfo' })
  const navigate = useNavigate()

  return (
    <BaseDashboardComponent
      customStyle={customStyle}
      sectionTitle={<DashboardComponentTitle title={t('title')} />}
      isLoading={false}
      isSuccess={!!companyInfo}
      isError={!companyInfo}>
      <div className="dashboard-company-info">
        <div className="item">
          <div className="key">{t('companyId')}</div>
          <div className="value">{user.companyId}</div>
        </div>
        {Object.keys(companyInfo).map((key: string) => (
          <div className="item" key={key}>
            <div className="key">{t(key)}</div>
            <div className="value">{companyInfo[key as keyof ICompanyInfo]}</div>
          </div>
        ))}
        <div className="item">
          <div className="key">{t('licenseExpirationDate')}</div>
          <div className="value">{expirationDate}</div>
        </div>
        <div className="update-link" onClick={() => navigate('/app/settings/companyInfo')}>
          {t('updateCompanyInfoLink')}
        </div>
      </div>
    </BaseDashboardComponent>
  )
}

export default CompanyInformation
