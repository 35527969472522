import { EmoEmotionsNames, IEmoResultsSummary } from '../../../../interfaces/emo.interface'
import { LCRiskBalloon } from '../../../ui/components/lc-risk-balloon/LCRiskBalloon'
import { fullName } from '../../../utils/fullname'
import { IExtendedColumn } from '../../riskhr/riskHrEvaluations/columns/riskHrEvaluations.columns'

const buildEmotions = () => {
  return Object.keys(EmoEmotionsNames).map((emotionName) => {
    return {
      Header: emotionName,
      accessor: `emotions.${emotionName}.high`,
      Cell: (row: any) => {
        const emo: IEmoResultsSummary = row.row.original
        return <LCRiskBalloon value={emo.emotions[emotionName as EmoEmotionsNames].score * 10} />
      },
    }
  })
}

export const emo_results_columns = (navigate: any, t: any) =>
  [
    {
      Header: t('evaluations.full_name'),
      accessor: 'firstName',
      Cell: (row: any) => {
        const emo: IEmoResultsSummary = row.row.original
        return (
          <button className="link-button" onClick={() => navigate(`/app/user/${emo.identity}`)}>
            {fullName(emo.userInfo)}
          </button>
        )
      },
    },
    {
      Header: 'Enter',
      accessor: 'totalEnter',
    },
    {
      Header: 'Exit',
      accessor: 'totalExit',
    },
    {
      Header: 'Emotions',
      columns: buildEmotions(),
    },
  ] as IExtendedColumn[]
