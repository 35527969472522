import { LCKeyValue } from '../lc-key-value/LCKeyValue'
import './lc-key-value-panel.scss'

import { CSSProperties, FC, ReactElement } from 'react'

export interface IKeyValueItem {
  text: string
  value: string | ReactElement
}

export interface ILCKeyValuePanelProps {
  keyValues: IKeyValueItem[]
  customStyle?: CSSProperties
}

export const LCKeyPanelValue: FC<ILCKeyValuePanelProps> = ({ keyValues, customStyle, ...props }: ILCKeyValuePanelProps) => {
  return (
    <div className="key-value-panel" style={customStyle} {...props}>
      {keyValues.map((i, index) => (
        <LCKeyValue key={index} keyValue={i} />
      ))}
    </div>
  )
}
