import React, { useEffect, useState } from 'react'
import { LCSvg } from '../../../../ui/components/lc-svg/lc-svg'
import { LCEditPopupImpactRules } from './customComponents/LCEditPopupImpactRules'
import { useTranslation } from 'react-i18next'
import { LCSettingsImpacts } from './customComponents/LCSettingsImpacts'
import { dataTopicImpactRule } from './mockDataSettings'
import './settings-riskhr.scss'
import { toast } from 'react-toastify'
import { LCToast } from '../../../../ui/components/lc-toast/Toast'

export interface ImpactsProps {
  keyImpact: string
  selectImpact: boolean
  color: string
}

export interface TopicsPropsImpactRules {
  name: string
  impacts: ImpactsProps[]
}
export interface PropsFormikCustomImpacts {
  customImpact: string
}

export interface PropsFunctionPopUp {
  actived: boolean
  dataUpdateImpactRules: any
}
export const colorsButtonSettings = [
  '#5E49F5',
  '#CE49F5',
  '#9649F5',
  '#496CF5',
  '#F44BD6',
  '#25F5A4',
  '#25BDF5',
  '#25F5EB',
  '#25F55D',
  '#1D77F5',
  '#DBF531',
  '#37F531',
  '#8AF531',
  '#F5E531',
  '#2AF569',
  '#F5A153',
  '#F5B954',
  '#D99C3D',
  '#A7D93D',
  '#D9AE3D',
]

export const titleText = ({ titleTranslate }: { titleTranslate: string }) => {
  const title = titleTranslate.match(/[^.]+$/)
  return title ? title[0] : null
}

export interface impactSetingsColor {
  keyName: string
  color: string
}

export const SettingsRiskHrImpactRules = () => {
  const error = false

  const { t } = useTranslation('translation', { keyPrefix: 'settings.riskHrImpactRules.impactRules' })
  const [dataTopics, setDataTopics] = useState<TopicsPropsImpactRules[]>([
    { name: '', impacts: [{ keyImpact: '', selectImpact: false, color: '' }] },
  ])
  const [updataDataActivedPopUp, setUpdataDataActivedPopUp] = useState<TopicsPropsImpactRules>({
    name: '',
    impacts: [{ keyImpact: '', selectImpact: true, color: '' }],
  })
  const [activedPopUp, setActivedPopUp] = useState(false)
  const [keyImpacts, setKeyImpacts] = useState<impactSetingsColor[]>([])

  useEffect(() => {
    const getUniqueValues = (array: any[], key: string) => [...new Set(array.map((item) => item[key]))]
    const uniqueKeyImpacts = getUniqueValues(
      dataTopics.flatMap((topic) => topic.impacts),
      'keyImpact'
    )
    const uniqueColors = getUniqueValues(
      dataTopics.flatMap((topic) => topic.impacts),
      'color'
    )
    const combinedValues = uniqueKeyImpacts.map((keyImpact, index) => ({
      keyName: keyImpact as string,
      color: uniqueColors[index] as string,
    }))

    setKeyImpacts(combinedValues)
  }, [dataTopics])

  useEffect(() => {
    if (dataTopicImpactRule) {
      const dataColorSettings = dataTopicImpactRule.map((topic) => ({
        ...topic,
        impacts: topic.impacts.map((impact, index) => ({ ...impact, color: colorsButtonSettings[index] || '#ffffff' })),
      }))
      setDataTopics(dataColorSettings)
    } else if (error) {
      toast.error(<LCToast title={'ERROR TITLE'} body={'ERROR BODY'} />)
    }
  }, [])

  const activedPopUpFunction = ({ actived, dataUpdateImpactRules }: PropsFunctionPopUp) => {
    setDataTopics((state) => state.map((topic) => (topic.name === dataUpdateImpactRules.name ? dataUpdateImpactRules : topic)))
    setUpdataDataActivedPopUp(dataUpdateImpactRules)
    setActivedPopUp(actived)
  }
  const updateColorsFunction = ({ color, keyName }: impactSetingsColor) => {
    setDataTopics((topics) => {
      return topics.map((topic) => ({
        ...topic,
        impacts: topic.impacts.map((impact) => (impact.keyImpact.toLowerCase() === keyName.toLowerCase() ? { ...impact, color } : impact)),
      }))
    })
  }

  const activedUpdateImpacts = ({ dataUpdateImpactRules }: { dataUpdateImpactRules: { customImpact: string } }) => {
    setDataTopics((dataTopic) =>
      dataTopic.map((topic) => ({
        ...topic,
        impacts: [
          ...topic.impacts,
          {
            keyImpact: dataUpdateImpactRules.customImpact,
            selectImpact: false,
            color: colorsButtonSettings[dataTopic[0].impacts.length + 1],
          },
        ],
      }))
    )
  }

  return (
    <div className="container-setting-impact-rule">
      <div className="container-table">
        <LCSettingsImpacts updateTopicsString={activedUpdateImpacts} impacts={keyImpacts} updateColorSettings={updateColorsFunction} />
        <table style={{ width: '100%' }}>
          <thead>
            <tr key={'topic-impact-table-body'}>
              <th key={'topic-table'}>Topics</th>
              <th key={'topic-impact'}>Impact</th>
            </tr>
          </thead>
          <tbody>
            {dataTopics.map(({ name, impacts }, index) => (
              <tr key={`topic-${name}`} className="container-table-informationtopic">
                <td key={`name-${name}`}>{name}</td>
                <td key={`impact-td-${name}`} className="container-impact-table">
                  {impacts.map(({ keyImpact, selectImpact, color }, index) => (
                    <span key={`impact-${name}-${keyImpact}-${color}-${index}`} style={{ color: 'black' }}>
                      {selectImpact ? <>{titleText({ titleTranslate: t(keyImpact) })},</> : null}
                    </span>
                  ))}
                </td>
                <td key={`button-${name}`}>
                  <button
                    className="button-edit-impact"
                    key={`button-${index}-${name}`}
                    type="button"
                    onClick={() => activedPopUpFunction({ actived: true, dataUpdateImpactRules: dataTopics[index] })}>
                    <LCSvg name={'edit'} />
                    Edit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <LCEditPopupImpactRules actived={activedPopUp} updateActived={activedPopUpFunction} dataProps={updataDataActivedPopUp} />
      </div>
    </div>
  )
}
