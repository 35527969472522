import './lc-evaluation-last-status.scss'

import { CSSProperties, FC, HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import { eEvaluationStatus } from '@logicalcommander/types'
import moment from 'moment'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ILCActionPopupItemData, LCActionPopup } from '../lc-action-popup/LCActionPopup'
import { configManager } from '../../../../config/configManager'

export interface ILCEvaluationLastStatusProps {
  statuses: Record<eEvaluationStatus, Date>
  lastStatus: eEvaluationStatus
  customStyle?: CSSProperties
}

export const LCEvaluationLastStatus: FC<ILCEvaluationLastStatusProps & HTMLAttributes<HTMLDivElement>> = ({
  statuses,
  lastStatus,
  customStyle,
  ...props
}: ILCEvaluationLastStatusProps) => {
  const { t } = useTranslation('translation')

  const allStatuses = Object.keys(statuses)
  const data: ILCActionPopupItemData[] = allStatuses?.map((i) => {
    return {
      reactElement: (
        <div>
          <div style={{ fontFamily: 'SF-Pro-Text-Bold' }}>{t(`evaluations.${i}`)}</div>
          <div>{moment(statuses[i as eEvaluationStatus]).format(configManager.LONG_DATE_FORMAT)}</div>
        </div>
      ),
    }
  })

  return (
    <div className="evalution-last-status-wrapper" style={customStyle} {...props}>
      <div className="status-wrapper">
        <div>{t(`evaluations.${lastStatus}`)}</div>
        <LCActionPopup
          data={data}
          anchor={<FontAwesomeIcon icon={faCircleExclamation} size="lg" color="#0568ae" style={{ cursor: 'pointer' }} />}
        />
      </div>
      <div>{moment(statuses[lastStatus]).fromNow()}</div>
    </div>
  )
}
