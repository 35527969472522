import React, { CSSProperties, useEffect, useState, FC, HTMLAttributes } from 'react'
import { sumBy } from 'lodash'
import { BarColor, CustomLabels } from '../../../../../interfaces/dashboard.interface'
import LCPieChartUniNew from '../../../../ui/components/charts/lc-pie-chart'
import { ITitleValueNumber } from '@logicalcommander/types'
import { useTranslation } from 'react-i18next'
import { BaseDashboardComponent } from '../../../../ui/components/base-dashboard/BaseDashboardComponent'
import { DashboardComponentTitle } from '../../../../ui/components/base-dashboard/DashboardComponentTitle'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

const RiskHrDashboardAssignedVsComplete: FC<Props> = ({ customStyle }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.riskHr.riskAssignedVsComplete' })

  const isLoading = false
  const error = false

  const data = [
    {
      title: 'New',
      value: 500,
    },
    {
      title: 'Pending',
      value: 300,
    },
    {
      title: 'In Progress',
      value: 900,
    },
    {
      title: 'Closed',
      value: 640,
    },
  ]

  const [riskPreventiveVsHigh, setRiskPreventiveVsHigh] = useState<ITitleValueNumber[]>([])
  const [sumRisk, setSumRisk] = useState(0)
  const sumSorted = sumBy(data, 'value')

  useEffect(() => {
    if (data) {
      const sorted = [...data].filter(sortFilter).map(dataMap)
      setSumRisk(sumSorted)
      setRiskPreventiveVsHigh(sorted)
    }
  }, [])

  const dataMap = (d: ITitleValueNumber) => {
    const data = {
      ...d,
      percentage: (d.value * 100) / sumSorted,
    }
    return data
  }

  const sortFilter = (a: ITitleValueNumber) => {
    return a.title !== 'In Progress' && a.title !== 'Pending'
  }

  return (
    <BaseDashboardComponent
      customStyle={customStyle}
      sectionTitle={<DashboardComponentTitle title={`${t('titleAssessment')} ${t('titleStatus')}`} subTitle={`${t('total')} ${sumRisk}`} />}
      isLoading={isLoading}
      isSuccess={!!riskPreventiveVsHigh}
      isError={!!error}>
      <LCPieChartUniNew
        data={riskPreventiveVsHigh}
        options={{
          color: BarColor.COLOR_STATUS_VS,
          label: CustomLabels.ALERT_RISK,
          customPercentage: true,
        }}
      />
    </BaseDashboardComponent>
  )
}

export default RiskHrDashboardAssignedVsComplete
