import './lc-svg.scss'
import { CSSProperties, FC, ReactElement, SVGProps } from 'react'
import {
  SvgDanger,
  SvgDashboard,
  SvgDelete,
  SvgDetails,
  SvgEdit,
  SvgEmotionEnergy,
  SvgEmotionMentalLoad,
  SvgEmotionStress,
  SvgMagnifier,
  SvgPlus,
  SvgReport,
  SvgSettings,
  SvgUpload,
  SvgUser,
  SvgUsers,
  SvgSignOut,
  Svg3Dots,
  SvgAnalytics,
  SvgExpandCollapseSideMenu,
  SvgFilterAdd,
  SvgFilterClear,
  SvgFilterList,
  SvgFilterStar,
  SvgFilterSave,
  SvgPDF,
  SvgPrint,
  SvgAddUser,
  SvgArrowBottom,
  SvgBell,
  SvgCalendar,
  SvgXIcon,
  SvgRisk20,
  SvgRisk40,
  SvgRisk100,
  SvgRisk60,
  SvgRisk80,
  SvgPlay,
  SvgArrowUp,
  SvgPause,
  SvgExcel,
  SvgFileUpload,
  SvgPhone,
  SvgVideo,
  SvgMessage,
  SvgRiskHR,
  SvgEmoRisk,
  SvgGeneral,
  SvgCases,
  SvgLoader,
  SvgError,
  SvgEyeLineThrough,
} from './assets'
import SvgAdd from './assets/Add'
import SvgEmotions from './assets/Emotions'
import SvgActionCancel from './assets/ActionCancel'
import SvgActionSave from './assets/ActionSave'
import SvgActionEdit from './assets/ActionEdit'
import SvgActionAdd from './assets/ActionAdd'
import SvgActionDelete from './assets/ActionDelete'
import SvgSuccess from './assets/Success'
import SvgEye from './assets/Eye'
import SvgUserError from './assets/UserError'

export type ISvgNames = (typeof IconsArray)[number]

export const IconsArray: string[] = [
  '3dots',
  'action-cancel',
  'action-add',
  'action-delete',
  'action-save',
  'action-edit',
  'addUser',
  'analytics',
  'arrowBottom',
  'arrowUp',
  'bell',
  'calendar',
  'danger',
  'dashboard',
  'delete',
  'details',
  'edit',
  'error',
  'emotionEnergy',
  'emotionMentalLoad',
  'emotionStress',
  'expandCollapseSideMenu',
  'filterSave',
  'filterAdd',
  'filterClear',
  'filterList',
  'filterStar',
  'magnifier',
  'loader',
  'pause',
  'pdf',
  'play',
  'plus',
  'report',
  'risk20',
  'risk40',
  'risk60',
  'risk80',
  'risk100',
  'settings',
  'signOut',
  'SvgAnalytics',
  'upload',
  'user',
  'users',
  'xIcon',
  'message',
  'video',
  'phone',
  'file-upload',
  'excel',
  'eye',
  'eye-line-through',
]

export interface ISvgProps {
  name: ISvgNames
  disabled?: boolean
  widthAndHeight?: number[]
  customStyle?: CSSProperties
  onClick?: () => void
}

export const LCSvg: FC<ISvgProps> = ({ disabled, widthAndHeight = [20, 20], name, customStyle = {}, onClick, ...props }: ISvgProps) => {
  // const classes = ['lc-svg', disabled ? 'lc-svg--disabled' : ''].join(' ')

  const selectSVG = (svgName: ISvgNames, props?: SVGProps<SVGSVGElement>): ReactElement | undefined => {
    switch (svgName) {
      case '3dots':
        return <Svg3Dots {...props} />
      case 'addUser':
        return <SvgAddUser {...props} />
      case 'add':
        return <SvgAdd {...props} />
      case 'analytics':
        return <SvgAnalytics {...props} />
      case 'arrowBottom':
        return <SvgArrowBottom {...props} />
      case 'arrowUp':
        return <SvgArrowUp {...props} />
      case 'bell':
        return <SvgBell {...props} />
      case 'calendar':
        return <SvgCalendar {...props} />
      case 'danger':
        return <SvgDanger {...props} />
      case 'dashboard':
        return <SvgDashboard {...props} />
      case 'loader':
        return <SvgLoader {...props} />
      case 'riskHR':
        return <SvgRiskHR {...props} />
      case 'emoRisk':
        return <SvgEmoRisk {...props} />
      case 'emotions':
        return <SvgEmotions {...props} />
      case 'general':
        return <SvgGeneral {...props} />
      case 'cases':
        return <SvgCases {...props} />
      case 'delete':
        return <SvgDelete {...props} />
      case 'details':
        return <SvgDetails {...props} />
      case 'edit':
        return <SvgEdit {...props} />
      case 'action-cancel':
        return <SvgActionCancel {...props} />
      case 'action-add':
        return <SvgActionAdd {...props} />
      case 'action-delete':
        return <SvgActionDelete {...props} />
      case 'action-save':
        return <SvgActionSave {...props} />
      case 'action-edit':
        return <SvgActionEdit {...props} />
      case 'filterSave':
        return <SvgFilterSave {...props} />
      case 'filterAdd':
        return <SvgFilterAdd {...props} />
      case 'filterClear':
        return <SvgFilterClear {...props} />
      case 'filterList':
        return <SvgFilterList {...props} />
      case 'filterStar':
        return <SvgFilterStar {...props} />
      case 'emotionEnergy':
        return <SvgEmotionEnergy {...props} />
      case 'emotionMentalLoad':
        return <SvgEmotionMentalLoad {...props} />
      case 'emotionStress':
        return <SvgEmotionStress {...props} />
      case 'expandCollapseSideMenu':
        return <SvgExpandCollapseSideMenu {...props} />
      case 'magnifier':
        return <SvgMagnifier {...props} />
      case 'pause':
        return <SvgPause {...props} />
      case 'pdf':
        return <SvgPDF {...props} />
      case 'print':
        return <SvgPrint {...props} />
      case 'play':
        return <SvgPlay {...props} />
      case 'plus':
        return <SvgPlus {...props} />
      case 'report':
        return <SvgReport {...props} />
      case 'risk20':
        return <SvgRisk20 {...props} />
      case 'risk40':
        return <SvgRisk40 {...props} />
      case 'risk60':
        return <SvgRisk60 {...props} />
      case 'risk80':
        return <SvgRisk80 {...props} />
      case 'risk100':
        return <SvgRisk100 {...props} />
      case 'settings':
        return <SvgSettings {...props} />
      case 'signOut':
        return <SvgSignOut {...props} />
      case 'upload':
        return <SvgUpload {...props} />
      case 'user':
        return <SvgUser {...props} />
      case 'user-error':
        return <SvgUserError {...props} />
      case 'users':
        return <SvgUsers {...props} />
      case 'xIcon':
        return <SvgXIcon {...props} />
      case 'message':
        return <SvgMessage {...props} />
      case 'video':
        return <SvgVideo {...props} />
      case 'phone':
        return <SvgPhone {...props} />
      case 'file-upload':
        return <SvgFileUpload {...props} />
      case 'excel':
        return <SvgExcel {...props} />
      case 'eye':
        return <SvgEye {...props} />
      case 'eye-line-through':
        return <SvgEyeLineThrough {...props} />
      case 'error':
        return <SvgError {...props} />
      case 'success':
        return <SvgSuccess {...props} />
    }
  }

  return (
    <div className={`lc-svg-wrapper ${disabled ? 'disabled' : ''}`} {...props} onClick={onClick} style={customStyle}>
      {selectSVG(name, widthAndHeight ? { width: widthAndHeight[0], height: widthAndHeight[1] } : {})}
    </div>
  )
}
