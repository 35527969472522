import { LCSvg } from '../../../../../ui/components/lc-svg/lc-svg'

export const invoice_columns = (t: any) => [
  {
    Header: t('columns.id'),
    accessor: 'id',
  },
  {
    Header: t('columns.pay_day'),
    accessor: 'pay_day',
  },
  {
    Header: t('columns.customer_name'),
    accessor: 'customer_name',
  },
  {
    Header: t('columns.total'),
    accessor: 'total',
    Cell: (row: any) => {
      const total = Number(row.row.original.total).toLocaleString('en-US')
      const currency = row.row.original.currency
      return <span>{`$${total} ${currency}`}</span>
    },
  },
  {
    Header: t('columns.product_and_quantity'),
    accessor: 'product_and_quantity',
    Cell: (row: any) => {
      const product = row.row.original.product_and_quantity
      return <div>{product.map((item: { product: string; quantity: string }) => `${item.product}; `)}</div>
    },
  },
  {
    Header: t('columns.payment_status'),
    accessor: 'payment_status',
  },
  {
    Header: t('columns.invoice'),
    accessor: 'dowload',
    Cell: () => {
      return (
        <LCSvg
          customStyle={{
            textAlign: 'right',
            transform: 'rotate(180deg)',
          }}
          name="file-upload"
        />
      )
    },
  },
]
