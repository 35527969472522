import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { INotification } from '../../ui/awareComponents/lc-notifications/lc-notifications.interface'

export enum NotificationType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  NONE = 'NONE',
}

export enum eInitializeState {
  NOT_STARTED = 'NOT_STARTED',
  LOADING = 'LOADING',
  FINSIHED = 'FINISHED',
}

export interface IPageHeader {
  icon?: any
  title?: string
  subTitle?: string
  breadcrumbs?: string
}

interface GeneralState {
  showLoader: boolean
  selectedLanguage: string
  notifications: INotification[]
  initializeState: eInitializeState
  pageHeader?: IPageHeader
}

const initialState: GeneralState = {
  showLoader: false,
  selectedLanguage: 'en',
  notifications: [],
  initializeState: eInitializeState.NOT_STARTED,
}

export const generalSlice = createSlice({
  name: 'generalReducer',
  initialState,
  reducers: {
    showLoader: (state, action: PayloadAction<boolean>) => {
      state.showLoader = action.payload
    },
    changeLanguage: (state, action: PayloadAction<string>) => {
      state.selectedLanguage = action.payload
    },
    updateNotifications: (state, action: PayloadAction<INotification[]>) => {
      state.notifications = action.payload
    },
    setPageHeader: (state, action: PayloadAction<IPageHeader>) => {
      state.pageHeader = action.payload
    },
    setInitializeState: (state, action: PayloadAction<eInitializeState>) => {
      state.initializeState = action.payload
    },
  },
})

export const { showLoader, changeLanguage, updateNotifications, setPageHeader, setInitializeState } = generalSlice.actions
export default generalSlice.reducer
export const selectGeneral = (state: RootState) => state.generalReducer
