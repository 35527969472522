import { FC } from 'react'
import { Routes, Route } from 'react-router-dom'
import { ActivityLogPage } from '../pages/Security/ActivityLog/ActivityLogPage'
import { SettingsTabs } from '../pages/Settings/settingsTabs'
import { EmoAnalyticsPage } from '../pages/emoRisk/emoAnalytics/EmoAnalyticsPage'
import { EmoDashboardPage } from '../pages/emoRisk/emoDashboard/EmoDashboardPage'
import { EmoResultsSummaryPage } from '../pages/emoRisk/emoResultsSummary/EmoResultsSummaryPage'
import { GeneralDashboardPage } from '../pages/generalDashboard/GeneralDashboardPage'
import { RiskHrEvaluationDetailedCard } from '../pages/riskhr/components/risk-hr-evaluation-detailed-card/RiskHrEvaluationDetailedCard'
import { RiskHrAnalyticsPage } from '../pages/riskhr/riskHrAnalytics/RiskHrAnalyticsPage'
import { RiskHrCasesPage } from '../pages/riskhr/riskHrCases/RiskHrCasesPage'
import { RiskHrCase } from '../pages/riskhr/riskHrCases/case/RiskHrCase'
import { RiskHrDashboardPage } from '../pages/riskhr/riskHrDashboard/RiskHrDashboardPage'
import { RiskHrEvaluationsPage } from '../pages/riskhr/riskHrEvaluations/RiskHrEvaluationsPage'
import { ComponentsSamplePage } from '../pages/sample-components/ComponentsSample'
import { User } from '../pages/user-detailed-page/user-results-detailed-page'
import { CreateUserTabs } from '../pages/users/createUpdateViewUser/createUserTabs'
import { UsersPage } from '../pages/users/usersList/usersPage'
import { ePrivateRoutes } from './e-routes'
import { Page404 } from '../pages/auth/page-404/Page404'
import { PurchaseTabs } from '../pages/purchase/PurchaseTabs'

export const PrivateRoutes: FC = () => {
  return (
    <Routes>
      <Route path={ePrivateRoutes.SAMPLES} element={<ComponentsSamplePage />} />

      {/* page Users */}
      <Route path={ePrivateRoutes.USERS} element={<UsersPage />} />
      <Route path={ePrivateRoutes.USER_CREATE} element={<CreateUserTabs />} />
      <Route path={ePrivateRoutes.USER_EDIT} element={<CreateUserTabs />} />
      <Route path={ePrivateRoutes.USER_VIEW} element={<CreateUserTabs />} />
      <Route path={ePrivateRoutes.USER} element={<User />} />

      {/* page  General Dashboard*/}
      <Route path={ePrivateRoutes.GENERAL_DASHBOARD} element={<GeneralDashboardPage />} />

      {/*page Risk-Hr */}
      <Route path={ePrivateRoutes.RISKHR_DASHBOARD} element={<RiskHrDashboardPage />} />
      <Route path={ePrivateRoutes.RISKHR_ANALYTICS} element={<RiskHrAnalyticsPage />} />
      <Route path={ePrivateRoutes.RISKHR_ASSESSMENTS} element={<RiskHrEvaluationsPage />} />
      <Route path={ePrivateRoutes.RISKHR_CASES} element={<RiskHrCasesPage />} />
      <Route path={ePrivateRoutes.RISKHR_CASE} element={<RiskHrCase />} />
      <Route path={ePrivateRoutes.RISKHR_REPORT} element={<RiskHrEvaluationDetailedCard />} />

      {/* page settings configuration */}
      <Route path={ePrivateRoutes.SETTINGS} element={<SettingsTabs />} />

      {/*page Emo Risk*/}
      <Route path={ePrivateRoutes.EMORISK_DASHBOARD} element={<EmoDashboardPage />} />
      <Route path={ePrivateRoutes.EMORISK_ANALYTICS} element={<EmoAnalyticsPage />} />
      <Route path={ePrivateRoutes.EMORISK_RESULTS} element={<EmoResultsSummaryPage />} />

      {/*page Security */}
      <Route path={ePrivateRoutes.ACTIVITY_LOG} element={<ActivityLogPage />} />

      {/*page PURCHASE*/}
      <Route path={ePrivateRoutes.PURCHASE} element={<PurchaseTabs />} />

      <Route path="/*" element={<Page404 />} />
    </Routes>
  )
}
