import i18n, { InitOptions } from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { configManager } from '../../../../config/configManager'

export const initI18n = async (language: string): Promise<void> => {
  console.log('init I18n: ' + language)
  i18n
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: 'en',
      debug: false,
      load: 'languageOnly',
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
      backend: {
        loadPath: () => `${configManager.api.GATEWAY_URL}/locale/${language}/risk-hr-admin`,
      },
      react: {
        useSuspense: false,
      },
    } as InitOptions)
}

export default i18n
