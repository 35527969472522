import './risk-hr-dashboard.scss'
import './risk-hr-dashboard-print.scss'
import { CSSProperties, FC, HTMLAttributes } from 'react'
import { LCPage } from '../../../ui/components/lc-page/LCPage'
import { RiskHrDashboardAverageState } from './components/RiskHrDashboardAverageState'
import RiskHrDashboardRisksPerTopicNew from './components/RiskHrDashboardRisksPerTopicNew'
import RiskHrDashboardRisksPerTopic5Important from './components/RiskHrDashboardRisksPerTopic5Important'
import RiskHrDashboardRisksDualWomanAndMen from './components/RiskHrDashboardRisksDualWomanAndMen'
import RiskHrDashboardRiskPieTopic from './components/RiskHrDashboardRiskPieTopic'
import RiskHrDashboardRisksDepartment from './components/RiskHrDashboardRisksDepartment'
import RiskHrDashboardRiskPieDepartment from './components/RiskHrDashboardRiskPieDepartment'
import RiskHrDashboardRiskPieWomanAndMen from './components/RiskHrDashboardRiskPieWomanAndMen'
import RiskHrDashboardEvaluationStatus from './components/RiskHrDashboardEvaluationStatus'
import RiskHrDashboardRiskPosition from './components/RiskHrDashboardRiskPosition'
import RiskHrDashboardRiskPieTopic5Important from './components/RiskHrDashboardRiskPieTopic5Important'
import RiskHrDashboardRiskPiePosition from './components/RiskHrDashboardRiskPiePosition'
import RiskHrDashboardRiskLevel from './components/RiskHrDashboardRiskLevel'
import RiskHrDashboardAlertVsWithoutAlerts from './components/RiskHrDashboardAlertVsWithoutAlerts'
import RiskHrDashboardTopicRisk from './components/RiskHrDashboardTopicRisk'
import RiskDashboardHighLevelVsPreventiveLevelTopic from './components/RiskDashboardHighLevelVsPreventiveLevelTopic'
import RiskHrDashboardHighVsPreventive from './components/RiskHrDashboardHighVsPreventive'
import RiskHrDashboardCaseStatus from './components/RiskHrDashboardCaseStatus'
import RiskHrDashboardAssignedVsComplete from './components/RiskHrDashboardAssignedVsComplete'
import RiskHrDashboardPreEmploymentVsPeriodic from './components/RiskHrDashboardPreEmploymentvsPeriodic'
import RiskDashboardAllAssessments from './components/RiskDashboardAllAssessments'
import { useTranslation } from 'react-i18next'
import { faGauge } from '@fortawesome/free-solid-svg-icons/faGauge'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

export const RiskHrDashboardPage: FC<Props> = ({ customStyle }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'menu' })

  return (
    <LCPage icon={faGauge} title={t('risk_hr')} breadcrumbs="Dashboard">
      <div className="risk-hr-dashboard" style={customStyle}>
        <RiskHrDashboardAverageState />
        <RiskDashboardAllAssessments customStyle={{ width: '400px', maxWidth: '400px' }} />
        <RiskHrDashboardPreEmploymentVsPeriodic customStyle={{ width: '400px', maxWidth: '400px' }} />
        <RiskHrDashboardRiskLevel customStyle={{ width: '400px', maxWidth: '400px' }} />
        <RiskHrDashboardEvaluationStatus customStyle={{ width: '400px', maxWidth: '400px' }} />
        <RiskHrDashboardAlertVsWithoutAlerts customStyle={{ width: '400px', maxWidth: '400px' }} />
        <RiskHrDashboardTopicRisk customStyle={{ width: '400px', maxWidth: '400px' }} />
        <RiskDashboardHighLevelVsPreventiveLevelTopic customStyle={{ width: '400px', maxWidth: '400px' }} />
        <RiskHrDashboardHighVsPreventive customStyle={{ width: '400px', maxWidth: '400px' }} />
        <RiskHrDashboardCaseStatus customStyle={{ width: '400px', maxWidth: '400px' }} />
        <RiskHrDashboardAssignedVsComplete customStyle={{ width: '400px', maxWidth: '400px' }} />
        <RiskHrDashboardRiskPieDepartment />
        <RiskHrDashboardRiskPiePosition />
        <RiskHrDashboardRisksDepartment />
        <RiskHrDashboardRiskPosition />
        <RiskHrDashboardRisksPerTopic5Important />
        <RiskHrDashboardRiskPieTopic5Important />
        <RiskHrDashboardRisksPerTopicNew />
        <RiskHrDashboardRiskPieTopic />
        <RiskHrDashboardRisksDualWomanAndMen />
        <RiskHrDashboardRiskPieWomanAndMen customStyle={{ width: '400px', maxWidth: '400px' }} />
      </div>
    </LCPage>
  )
}
