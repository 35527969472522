import * as React from 'react'
import { SVGProps } from 'react'

const SvgActionCancel = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={10} cy={10} r={9} stroke="currentColor" strokeWidth={1.5} />
    <path
      d="M6.222 6.222l7.556 7.556M13.778 6.222L6.222 13.778"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgActionCancel
