import React, { CSSProperties, useEffect, FC, HTMLAttributes, useState, lazy } from 'react'
import { BarColor, CustomLabels } from '../../../../../interfaces/dashboard.interface'
import { sumBy } from 'lodash'
import { ITitleValueNumber } from '@logicalcommander/types'
import { useTranslation } from 'react-i18next'
import { BaseDashboardComponent } from '../../../../ui/components/base-dashboard/BaseDashboardComponent'
import { DashboardComponentTitle } from '../../../../ui/components/base-dashboard/DashboardComponentTitle'
import { toast } from 'react-toastify'
import { LCToast } from '../../../../ui/components/lc-toast/Toast'

const LCPieChartUniNew = lazy(() => import('../../../../ui/components/charts/lc-pie-chart'))

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}
const RiskHrDashboardHighVsPreventive: FC<Props> = ({ customStyle }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.riskHr.riskHighVsPreventive' })
  const { t: tError } = useTranslation('translation', { keyPrefix: 'dashboard.graphDataErrors.dataLoadError' })

  const isLoading = false
  const error = false

  const data = [
    {
      title: 'LowRisk',
      value: 1200000,
      riskPercentage: '0% - 60% Risk Level',
    },
    {
      title: 'ModerateRisk',
      value: 32000,
      riskPercentage: '61% - 80% Risk Level',
    },
    {
      title: 'HighRisk',
      value: 110400,
      riskPercentage: '81% - 100% Risk Level',
    },
  ]

  const [riskHighVsPreventive, setRiskHighVsPreventive] = useState<ITitleValueNumber[]>([])
  const [sumRiskLevelHigVsPreventive, setSumRiskLevelHigVsPreventive] = useState(0)
  const sumData = sumBy(data, 'value')

  useEffect(() => {
    if (data) {
      const sorted = [...data].filter(sortFilter).map(dataMap)
      setRiskHighVsPreventive(sorted)
      setSumRiskLevelHigVsPreventive(sumData)
    } else if (error) {
      toast.error(
        <LCToast
          title={tError('title', { title: `${t('title_assessments')} ${t('title_status')}` })}
          body={tError('body', { title: `${t('title_assessments')} ${t('title_status')}` })}
        />
      )
    }
  }, [])

  const dataMap = (a: ITitleValueNumber) => {
    const data = {
      ...a,
      percentage: (a.value * 100) / sumData,
    }
    return data
  }

  const sortFilter = (a: ITitleValueNumber) => {
    return a.title !== 'LowRisk'
  }

  return (
    <BaseDashboardComponent
      customStyle={customStyle}
      sectionTitle={
        <DashboardComponentTitle
          title={`${t('titleAssessment')} ${t('titleStatus')}`}
          subTitle={`${t('total')} ${sumRiskLevelHigVsPreventive}`}
        />
      }
      isLoading={isLoading}
      isSuccess={!!riskHighVsPreventive}
      isError={!!error}>
      <LCPieChartUniNew
        data={riskHighVsPreventive}
        dataTotal={sumRiskLevelHigVsPreventive}
        options={{
          color: BarColor.RISK_LEVEL_VS,
          label: CustomLabels.ALERT_RISK_LEVEL,
          customPercentage: true,
        }}
      />
    </BaseDashboardComponent>
  )
}

export default RiskHrDashboardHighVsPreventive
