import { FC, ReactElement } from 'react'
import LCPopup from '../../lc-popup/LCPopup'
import { eTableName } from '@logicalcommander/types'
import { faHeartCirclePlus } from '@fortawesome/free-solid-svg-icons/faHeartCirclePlus'
import { LCToolbarButton } from '../../lc-toolbar-button/LCToolbarButton'

export interface Props {
  tableName: eTableName
}

export const LCTableActionAddFavoriteFilter: FC<Props> = ({ tableName }: Props): ReactElement => {
  return (
    <LCPopup
      position={['midleft', 'bottom']}
      toggler={
        <div>
          <LCToolbarButton icon={faHeartCirclePlus} text="Favorite" />
        </div>
      }>
      <div>sfdsdf</div>
    </LCPopup>
  )
}
