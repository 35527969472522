import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { v4 as uuidv4 } from 'uuid'
import { RootState } from '../redux/store'
import { ICreateUserResponse, IUploadProfileImageRequest } from '../../interfaces/user.interfaces'
import { configManager } from '../../config/configManager'
import { ILCHttpResponse, IUser } from '@logicalcommander/types'

export interface IUpdateUserDTO {
  user: Partial<IUser>
  identity: string
}

export interface IRevokeAdminAccessDTO {
  identity: string
}

export interface IUpdateAdminAccessDTO {
  identity: string
  role: string
}

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${configManager.api.GATEWAY_URL}/user`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('x-request-id', uuidv4())
      const { token, companyToken } = (getState() as RootState).authReducer
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      if (companyToken) {
        headers.set('x-company-token', companyToken)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    createUser: builder.mutation<ICreateUserResponse, IUser>({
      query: (user) => ({
        url: '',
        method: 'POST',
        body: {
          ...user,
        },
      }),
      transformResponse: (rawResult: ILCHttpResponse<ICreateUserResponse>, meta) => {
        return rawResult.data as ICreateUserResponse
      },
    }),
    updateUser: builder.mutation<ICreateUserResponse, IUpdateUserDTO>({
      query: ({ user, identity }) => ({
        url: `/${identity}`,
        method: 'PUT',
        body: {
          ...user,
        },
      }),
      transformResponse: (rawResult: ILCHttpResponse<ICreateUserResponse>, meta) => {
        return rawResult.data as ICreateUserResponse
      },
    }),
    deleteUser: builder.mutation<ICreateUserResponse, string>({
      query: (identity) => ({
        url: `/${identity}`,
        method: 'DELETE',
      }),
      transformResponse: (rawResult: ILCHttpResponse<ICreateUserResponse>, meta) => {
        return rawResult.data as ICreateUserResponse
      },
    }),
    getUser: builder.query<IUser, string>({
      query: (identity) => ({
        url: `/${identity}`,
      }),
      transformResponse: (rawResult: ILCHttpResponse<IUser>, meta) => {
        return rawResult.data as IUser
      },
    }),
    uploadTmpProfileImage: builder.mutation<any, FormData>({
      query: (body) => ({
        url: '/tmp/profile-image',
        method: 'POST',
        body,
      }),
      transformResponse: (rawResult: ILCHttpResponse<any>, meta) => {
        return rawResult.data
      },
    }),
    deleteProfileImage: builder.mutation<any, void>({
      query: () => ({
        url: '/me/profile-image',
        method: 'DELETE',
      }),
      transformResponse: (rawResult: ILCHttpResponse<any>, meta) => {
        return rawResult.data
      },
    }),
    getOthersProfileImage: builder.query<{ url: string }, string>({
      query: (identity) => ({
        url: `/tmp-url/profile-image/${identity}`,
      }),
      keepUnusedDataFor: 60 * 20, // 20 min
      transformResponse: (rawResult: ILCHttpResponse<{ url: string }>, meta) => {
        return rawResult.data as { url: string }
      },
    }),
    uploadOthersProfileImage: builder.mutation<any, IUploadProfileImageRequest>({
      query: ({ body, identity }) => ({
        url: `/tmp/profile-image/${identity}`,
        method: 'PUT',
        body,
      }),
      transformResponse: (rawResult: ILCHttpResponse<any>, meta) => {
        return rawResult.data
      },
    }),
    revokeAdminAccess: builder.mutation<IUser, IRevokeAdminAccessDTO>({
      query: (body) => ({
        url: `/access/revoke`,
        method: 'PUT',
        body,
      }),
      transformResponse: (rawResult: ILCHttpResponse<IUser>, meta) => {
        return rawResult.data as IUser
      },
    }),
    updateAdminAccess: builder.mutation<IUser, IUpdateAdminAccessDTO>({
      query: (body) => ({
        url: `/access/update`,
        method: 'PUT',
        body,
      }),
      transformResponse: (rawResult: ILCHttpResponse<IUser>, meta) => {
        return rawResult.data as IUser
      },
    }),
    searchUser: builder.mutation<IUser[], string>({
      query: (nameOrIdentity) => ({
        url: `/search/${nameOrIdentity}`,
        method: 'GET',
      }),
      transformResponse: (rawResult: ILCHttpResponse<IUser[]>, meta) => {
        return rawResult.data as IUser[]
      },
    }),
  }),
})

export const {
  useCreateUserMutation,
  useDeleteUserMutation,
  useLazyGetUserQuery,
  useUpdateUserMutation,
  useUploadTmpProfileImageMutation,
  useDeleteProfileImageMutation,
  useGetOthersProfileImageQuery,
  useUploadOthersProfileImageMutation,
  useRevokeAdminAccessMutation,
  useUpdateAdminAccessMutation,
  useSearchUserMutation,
} = userApi
