import './lc-table-server-pagination.scss'

import React, { useEffect, useRef } from 'react'
import { useTable, usePagination, useRowSelect, PluginHook, TableOptions, useSortBy } from 'react-table'
import IndeterminateCheckbox from './IndeterminateCheckbox '
import { LCSvg } from '../lc-svg/lc-svg'
import deepEqual from 'deep-equal'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks'
import { usePrevious } from '../../../redux/customHooks'
import { IExportPDFData, getExportFileBlob, getExportFileName } from './LCTableServerPaginationHelper'
import { ISearch, eTableName } from '@logicalcommander/types'
import { selectGeneral, showLoader } from '../../../redux/reducers/generalSlice'
import { LCTableServerPaginationActions } from './LCTableServerPaginationActions'
import { IFilter, selectFilters } from '../../../redux/reducers/filtersSlice'
import { useExportData } from './useExportData'
import { selectSettings } from '../../../redux/reducers/settingsSlice'
import { useLazyGetCompanyLogoTmpUrlQuery } from '../../../services/settings.service'
import { selectAuth } from '../../../redux/reducers/authSlice'

interface Props<T> {
  columns: any
  data: T[]
  fetchData: (params: any) => void
  loading: boolean
  pageCount: number
  showCheckbox?: boolean
  showPagination?: boolean
  onSelectRows?: (rows: T[]) => void
  showTableActions?: boolean
  initPageSize?: number
  searchArray?: ISearch[]
  tableName: eTableName
  pdfOrientation?: 'landscape' | 'portrait'
}

export const LCTableServerPagination = <T,>({
  columns,
  data,
  loading,
  fetchData,
  pageCount: controlledPageCount,
  showCheckbox = false, // Shows only when showPagination === true
  onSelectRows,
  initPageSize,
  searchArray,
  showTableActions = false,
  tableName,
  pdfOrientation,
}: Props<T>) => {
  const [getCompanyLogo, { data: dataLogo }] = useLazyGetCompanyLogoTmpUrlQuery()
  const general = useAppSelector(selectGeneral)
  const { companyId } = useAppSelector(selectAuth).user
  const { name, address, phone, email, website } = useAppSelector(selectSettings).companyInfo
  const dispatch = useAppDispatch()
  const componentRef = useRef(null)

  useEffect(() => {
    getCompanyLogo()
  }, [getCompanyLogo])

  const getAdditionalData = (): IExportPDFData => {
    return {
      company: {
        id: companyId,
        name,
        address,
        phone,
        email,
        website,
        logoUrl: dataLogo?.url || '',
        title: `Table: ${tableName}`, // TODO: take from language file
      },
      orientation: pdfOrientation,
    }
  }

  const tableOptions: TableOptions<any> = {
    columns,
    data,
    initialState: { pageIndex: 0 },
    manualPagination: true,
    manualSortBy: true,
    pageCount: controlledPageCount,
    useRowSelect,
    getExportFileBlob,
    getExportFileName: () => getExportFileName(tableName),
    getAdditionalData,
  }

  const getTablePlugins = (): Array<PluginHook<any>> => {
    const plugins: Array<PluginHook<any>> = []
    plugins.push(useSortBy)
    plugins.push(usePagination)
    plugins.push(useExportData)

    if (showCheckbox) {
      plugins.push(useRowSelect)
      plugins.push((hooks) => {
        hooks.visibleColumns.push((columns) => [
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method to render a checkbox
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} name="aaaa" />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...(row as any).getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ])
      })
    }
    return plugins
  }

  const tableInstance = useTable(tableOptions, ...getTablePlugins())
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    setPageSize,
    gotoPage,
    nextPage,
    previousPage,
    selectedFlatRows,
    exportData,
    state: { pageIndex, pageSize, sortBy, selectedRowIds },
  } = tableInstance

  const tableFilters: IFilter[] = useAppSelector(selectFilters).tableHeader.filter((f) => f.tableName === tableName)

  const prevPageIndex = usePrevious(pageIndex) || 0
  const prevPageSize = usePrevious(pageSize)
  const prevSortBy = usePrevious(sortBy)
  const prevFilters = usePrevious(tableFilters)

  useEffect(() => {
    dispatch(showLoader(loading))
  }, [loading])

  useEffect(() => {
    initPageSize && setPageSize(initPageSize)
  }, [initPageSize])

  useEffect(() => {
    // if (pageSize > 1000) {
    //   console.log("pageSize", pageSize)
    //   return;
    // }
    console.log(
      `fetchData:: --- checking --- pageIndex: ${pageIndex}, pageSize: ${pageSize}, sortBy: ${sortBy}, tableFilters: ${tableFilters}`
    )
    if (
      (pageIndex > 0 && prevPageIndex > 0 && prevPageIndex !== pageIndex) ||
      (pageSize && prevPageSize && prevPageSize !== pageSize) ||
      (sortBy && prevSortBy && prevSortBy !== sortBy) ||
      (tableFilters && prevFilters && !deepEqual(prevFilters, tableFilters))
    ) {
      console.log(
        `fetchData:: --- fetching --- pageIndex: ${pageIndex}, pageSize: ${pageSize}, sortBy: ${sortBy}, tableFilters: ${tableFilters}`
      )
      const filtersSearchArray = tableFilters.map((i) => i.search)
      fetchData({ pageIndex, pageSize, sortBy, search: filtersSearchArray.concat(searchArray || []) })
    }
  }, [pageIndex, pageSize, sortBy, tableFilters])

  useEffect(() => {
    onSelectRows && onSelectRows(selectedFlatRows.map((d) => d.original))
  }, [selectedFlatRows])

  // Render the UI for your table
  console.log('=====> tableName', tableName)

  return (
    <div className="table-wrapper" ref={componentRef}>
      {showTableActions && <LCTableServerPaginationActions tableName={tableName} exportData={exportData} columns={columns} />}
      <table {...getTableProps()} className="table">
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr className={headerGroups.length > 1 ? 'group-header' : ''} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div className="th-wrapper">
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <LCSvg name="arrowBottom" widthAndHeight={[12, 12]} />
                        ) : (
                          <LCSvg name="arrowUp" widthAndHeight={[12, 12]} />
                        )
                      ) : null}
                    </span>
                    {/* <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span> */}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} style={{ backgroundColor: selectedRowIds && selectedRowIds[row.id] ? '#fbb03b' : 'transparent' }}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}

          {page.length === 0 && (
            <tr>
              <td colSpan={10000}>
                <div className="no-data">No data</div>
              </td>
            </tr>
          )}

          <tr className="pagination">
            {general?.showLoader === true ? (
              // Use our custom loading state to show a loading indicator
              <td colSpan={10000}>{/* <LCLoader show={loading} type="small" /> */}</td>
            ) : (
              // <td colSpan={10000}>Loading...</td>
              <td colSpan={10000}>
                <div className="table-footer">
                  <div className="table-footer-text">
                    Showing {page.length} of ~{controlledPageCount * pageSize} items
                  </div>
                  {/* Pagination left right -> */}
                  {controlledPageCount * pageSize > pageSize ? (
                    <div className="table-footer-pagination">
                      <div className="previous-buttons">
                        <button className="link-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                          &#171;
                        </button>
                        <button className="link-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                          &#8249;
                        </button>
                      </div>
                      <span className="pages-count">
                        <span className="current-page">{pageIndex + 1}</span>|<span className="total-pages">{pageOptions.length}</span>
                      </span>
                      <div className="next-buttons">
                        <button className="link-button" onClick={() => nextPage()} disabled={!canNextPage}>
                          &#8250;
                        </button>
                        <button className="link-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                          &#187;
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {/* <- Pagination left right -> */}
                </div>
              </td>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  )
}
