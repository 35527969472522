import './lc-audio-player.scss'
import { CSSProperties, useEffect, useRef, useState } from 'react'
import { useGetPreSignedEvaluationQuestionSoundFileMutation } from '../../../services/evaluations.service'
import { IEvaluationPreSignedQuestionAnsweredUrlRequest } from '@logicalcommander/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlay, faCirclePause } from '@fortawesome/free-solid-svg-icons'

export interface Props {
  evaluationDetails: IEvaluationPreSignedQuestionAnsweredUrlRequest
  disabled?: boolean
  customStyle?: CSSProperties
  onClick?: () => void
}

export const AudioPlayer: React.FC<Props> = ({ evaluationDetails }) => {
  const audioRef = useRef<HTMLAudioElement>(null)
  const [error, setError] = useState<string | null>(null)
  const [url, setURL] = useState<string>()
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [getPreSignedURLs, { data, error: fetchError }] = useGetPreSignedEvaluationQuestionSoundFileMutation()

  useEffect(() => {
    if (!fetchError && data) {
      setURL(data[0].preSignedUrl)
    }
  }, [data, fetchError])

  const fetchPreSignedURL = (): void => {
    getPreSignedURLs(evaluationDetails)
  }

  const onPlay = (): void => {
    if (!url) {
      fetchPreSignedURL()
    } else {
      play()
    }
  }

  const onPause = (): void => {
    pause()
  }

  useEffect(() => {
    if (url) {
      play()
    }
  }, [url])

  const pause = (): void => {
    if (audioRef.current) {
      setIsPlaying(false)
      audioRef.current.pause()
    }
  }

  const play = (): void => {
    if (audioRef.current) {
      audioRef.current
        .play()
        .then(() => setIsPlaying(true))
        .catch((err) => {
          console.error('Error playing the audio file:', err)
          setError('Error playing the audio file.')
          setIsPlaying(false)
        })
    }
  }

  const handleError = () => {
    console.error('Error loading the audio file.')
    setError('Error loading the audio file.')
  }

  return (
    <div>
      {url ? <audio ref={audioRef} src={url} onError={handleError} /> : null}
      {/* <button onClick={fetchPreSignedURL}>Play</button> */}

      <FontAwesomeIcon icon={isPlaying ? faCirclePause : faCirclePlay} size="2xl" color="#0568ae" onClick={!isPlaying ? onPlay : onPause} />

      {error && <p>{error}</p>}
    </div>
  )
}
