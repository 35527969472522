import './base-dashboard-components.scss'
import React, { CSSProperties, FC, HTMLAttributes, ReactNode, Suspense } from 'react'
import { LcStyleNoData } from '../charts/LcStyleNoData'
import { DashboardComponentError } from './DashboardComponentError'
import { DashboardComponentLoader } from './DashboardComponentLoader'
// import { ObserverComponent } from './observer-component'
// import LCPopup from '../lc-popup/LCPopup'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  sectionTitle: string | ReactNode
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
  children?: ReactNode
  customStyle?: CSSProperties
}

export const BaseDashboardComponent: FC<Props> = ({ sectionTitle, children, customStyle, isLoading = true, isSuccess, isError }: Props) => {
  // const handleVisible = () => {
  //   console.log(`Render section`)
  // }

  return (
    // <ObserverComponent onVisible={handleVisible}>
    <Suspense fallback={<LcStyleNoData />}>
      <div className="bdc-wrapper" style={customStyle}>
        <div className="title">{sectionTitle}</div>
        {/* <LCPopup position={'modal'} toggler={<div className="title">{sectionTitle}</div>}>
          <div className="zoom-in">
            <div className="zoom-in-title">{sectionTitle}</div>
            <div className="zoom-in-body">{isSuccess && !isLoading && !isError ? children : null}</div>
          </div>
        </LCPopup> */}

        <div className="body-wrapper">
          <div className="body">
            {isSuccess && !isLoading && !isError ? children : null}
            {isError ? <DashboardComponentError /> : null}
            {isLoading ? <DashboardComponentLoader /> : null}
          </div>
        </div>
      </div>
    </Suspense>
    // </ObserverComponent>
  )
}
