import React, { CSSProperties, FC, HTMLAttributes, lazy, useEffect, useState } from 'react'
import { BarChartLayout, BarColor, CustomLabels } from '../../../../../interfaces/dashboard.interface'
import { useLazyGetAlertsPercentageMenWomenQuery } from '../../../../services/dashboard.service'
import { IDashboardWomanVsMen, ISearch, eSearchType } from '@logicalcommander/types'
import { useTranslation } from 'react-i18next'
import { BaseDashboardComponent } from '../../../../ui/components/base-dashboard/BaseDashboardComponent'
import { DashboardComponentTitle } from '../../../../ui/components/base-dashboard/DashboardComponentTitle'
const LCDualBarChartNew = lazy(() => import('../../../../ui/components/charts/lc-dual-bar-chart'))

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

const RiskHrDashboardRisksDualWomanAndMen: FC<Props> = ({ customStyle }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.riskHr.riskDualWomanAndMen' })
  const [getDashboardAlertsPercentageMenWomen, { data, error, isLoading }] = useLazyGetAlertsPercentageMenWomenQuery()
  const [alertMenWoman, setAlertMenWoman] = useState<IDashboardWomanVsMen[]>([])

  useEffect(() => {
    const search: ISearch[] = [{ keyName: 'aaaa', type: eSearchType.DATE_AFTER, value: '123213' }]
    getDashboardAlertsPercentageMenWomen({ search })
  }, [])

  useEffect(() => {
    if (!error && data) {
      const sorted = [...data]
      setAlertMenWoman(sorted)
    }
  }, [data, error])

  return (
    <BaseDashboardComponent customStyle={customStyle} sectionTitle={<DashboardComponentTitle title={t('title')} subTitle={t('total')} />} isLoading={isLoading} isSuccess={alertMenWoman.length > 0} isError={!!error}>
      <LCDualBarChartNew
        data={alertMenWoman}
        options={{
          layout: BarChartLayout.VERTICAL,
          color: BarColor.COLOR_WOMEN_MEN,
          label: CustomLabels.ALERT_RISK,
        }}
      />
    </BaseDashboardComponent>
  )
}

export default RiskHrDashboardRisksDualWomanAndMen
