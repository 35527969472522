import { sumBy } from 'lodash'
import React, { CSSProperties, FC, HTMLAttributes, lazy, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BarColor, CustomLabels, BarChartLayout } from '../../../../interfaces/dashboard.interface'
import { useLazyGetRisksByCountriesQuery } from '../../../services/dashboard.service'
import { ITitleValueNumber } from '@logicalcommander/types'
import { BaseDashboardComponent } from '../../../ui/components/base-dashboard/BaseDashboardComponent'
import { DashboardComponentTitle } from '../../../ui/components/base-dashboard/DashboardComponentTitle'

const LCBarChartNew = lazy(() => import('../../../ui/components/charts/lc-bar-chart'))

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

const GeneralDashboardCountry: FC<Props> = ({ customStyle }: Props) => {
  const [getTotalRisksByCountries, { data, error, isLoading }] = useLazyGetRisksByCountriesQuery()
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.general.alertLocationByCountry' })

  useEffect(() => {
    getTotalRisksByCountries()
  }, [])

  return (
    <BaseDashboardComponent customStyle={customStyle} sectionTitle={<DashboardComponentTitle title={t('title')} subTitle={`${t('total')} ${sumBy(data, 'value')}`} />} isLoading={isLoading} isSuccess={!!data} isError={!!error}>
      <LCBarChartNew
        data={data as ITitleValueNumber[]}
        options={{
          color: BarColor.COLOR_POSITION,
          label: CustomLabels.TOTAL_EMPLOYEES,
          layout: BarChartLayout.VERTICAL,
        }}
      />
    </BaseDashboardComponent>
  )
}

export default GeneralDashboardCountry
