import './lc-permissions.scss'
import { CSSProperties, FC, ReactElement, useEffect, useState } from 'react'

export interface IPermissionsProps {
  userPermissions: string[]
  allowedPermission: string
  children?: ReactElement
  hidden?: boolean
  collapse?: boolean
  customStyle?: CSSProperties
}

export const LCPermissions: FC<IPermissionsProps> = ({
  userPermissions,
  allowedPermission,
  children,
  hidden = false,
  collapse = false,
  customStyle,
  ...props
}: IPermissionsProps) => {
  const [hiddenClass, setHiddenClass] = useState('lc-permissions--disabled')
  const [collapsedClass, setCollapsedClass] = useState('')

  useEffect(() => {
    const allowed = userPermissions.includes(allowedPermission)
    setHiddenClass(allowed ? '' : `lc-permissions--${hidden ? 'hidden' : 'disabled'}`)
    setCollapsedClass(allowed ? '' : collapse ? 'lc-permissions--collapse' : '')
  }, [userPermissions, allowedPermission, collapse, hidden])

  return (
    <div
      className={['lc-permissions', hiddenClass, collapsedClass].join(' ')}
      data-test-id={'lc-permissions'}
      style={customStyle}
      {...props}>
      {children}
    </div>
  )
}
