import { SVGProps } from 'react'

const SvgFilterStar = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
    <polygon points="172.557 473.445 266.319 402.257 266.319 288.354 430.386 46.313 219.297 46.313 8.477 46.313 172.557 288.354" />
    <ellipse strokeWidth="9" stroke="#ffffff" paintOrder="fill" cx="353.584" cy="270.641" rx="140" ry="140" />
    <g transform="matrix(0.707106, 0.707107, -0.707107, 0.707106, 290.96875, -170.88678)">
      <path
        strokeWidth="0"
        fill="#FFFF00"
        id="svg_4"
        d="m239.49999,236.27011l86.70691,0l26.7931,-76.77538l26.79312,76.77538l86.70689,0l-70.14724,47.44925l26.79449,76.77538l-70.14727,-47.45054l-70.14725,47.45054l26.7945,-76.77538l-70.14726,-47.44925z"
      />
    </g>
  </svg>
)

export default SvgFilterStar
