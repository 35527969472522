import React, { CSSProperties, FC, useState, useRef, useEffect } from 'react'
import { useAppSelector } from '../../../hooks/redux-hooks'
import { selectGeneral } from '../../../redux/reducers/generalSlice'
import { useGetNotificationsQuery } from '../../../services/notifications.service'
import { LCSvg } from '../../components/lc-svg/lc-svg'
import { INotification } from './lc-notifications.interface'
import './lc-notifications.scss'
import { useOnClickOutside } from '../../../redux/customHooks'

export interface Props {
  customStyle?: CSSProperties
}

export const LCNotifications: FC<Props> = ({ customStyle, ...props }: Props) => {
  const [showNotifications, setShowNotifications] = useState(false)
  const stateNotifications = useAppSelector(selectGeneral).notifications
  const { data, error } = useGetNotificationsQuery()
  const [notifications, setNotifications] = useState<INotification[]>([])
  const ref: any = useRef()
  useOnClickOutside(ref, () => setShowNotifications(false))

  // Get the notifications from the API when the app loads
  useEffect(() => {
    if (!error && data) {
      setNotifications(data)
    }
  }, [data, error])

  // Get the notifications from the state, when the socket receive new notification it dispatch it
  useEffect(() => {
    if (stateNotifications && stateNotifications.length > 0) {
      console.log('stateNotifications', stateNotifications)
      setNotifications([...notifications, ...stateNotifications])
    }
  }, [stateNotifications])

  return (
    <div className={'lc-notifications'} style={customStyle} {...props}>
      <div className="bell-wrapper">
        <LCSvg
          name="bell"
          customStyle={{ color: '#ffffff' }}
          widthAndHeight={[16, 16]}
          onClick={() => {
            setShowNotifications(!showNotifications)
          }}
        />
        {notifications.length > 0 ? (
          <div
            className="total"
            onClick={() => {
              setShowNotifications(!showNotifications)
            }}>
            {notifications.length}
          </div>
        ) : null}
      </div>
      {showNotifications ? (
        <ul className="notifications-panel" ref={ref}>
          {notifications.map((notification: INotification, index: number) => {
            return <li key={index}>{notification.message}</li>
          })}
        </ul>
      ) : null}
    </div>
  )
}
