import './risk-hr-evaluations.scss'
import React, { HTMLAttributes, CSSProperties, FC, useState, useEffect, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useAppSelector } from '../../../../hooks/redux-hooks'
import { selectSettings } from '../../../../redux/reducers/settingsSlice'
import { useGetEvaluationsResultsMutation } from '../../../../services/evaluations.service'
import { LCTableServerPagination } from '../../../../ui/components/lc-table/LCTableServerPagination'
import { IEvaluationSummary, riskHrEvaluations_columns } from '../columns/riskHrEvaluations.columns'
import { ISearch, ISort, IPaginationRequest, IPaginationResponse, IPaginationSettings, eTableName } from '@logicalcommander/types'
import { toast } from 'react-toastify'
import { LCToast } from '../../../../ui/components/lc-toast/Toast'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  showTableActions?: boolean
  customStyle?: CSSProperties
  searchArray?: ISearch[]
  onSelectedRows?: (rows: IEvaluationSummary[]) => void
}

export const RiskHrEvaluations: FC<Props> = ({ customStyle, searchArray, showTableActions = false, onSelectedRows }: Props) => {
  const paginationSettings: IPaginationSettings = useAppSelector(selectSettings).commonUX.pagination
  const [getEvaluationsPagination, { data, error, isLoading }] = useGetEvaluationsResultsMutation()

  const [evaluations, setEvaluations] = useState<IEvaluationSummary[]>([])
  const [pageCount, setPageCount] = useState(0) // Number of pages
  const [pageIndex, setPageIndex] = useState(0) // Current page index
  const [pageSize, setPageSize] = useState(paginationSettings.riskHr) // Page size
  const [, setSortBy] = useState<ISort[]>([]) // Sort by
  const navigate = useNavigate()
  const { t } = useTranslation('translation')

  useEffect(() => {
    if (!error && data) {
      setEvaluations(data.data)
    } else if (error) {
      toast.error(<LCToast title={'General error'} body={`Error getting data from server, please try again later`} />)
    }
  }, [data, error])

  useEffect(() => {
    setPageSize(paginationSettings.users)
  }, [paginationSettings])

  const fetchData = useCallback(({ pageIndex, pageSize, sortBy, search }: IPaginationRequest) => {
    getEvaluationsPagination({ pageIndex, pageSize, sortBy, search })
      .then((result: any) => {
        const resData: IPaginationResponse<IEvaluationSummary[]> = result.data
        setPageCount(Math.ceil(resData.totalSize / pageSize))
        setPageIndex(pageIndex)
        setPageSize(pageSize)
        sortBy && setSortBy(sortBy)
      })
      .catch((err) => {
        toast.error(<LCToast title={t('notifications.get_users_error_title')} body={t('notifications.get_users_error_text')} />)
      })
  }, [])

  const columns = useMemo(() => riskHrEvaluations_columns(navigate, t), [pageIndex])

  return (
    <div style={customStyle}>
      <LCTableServerPagination
        tableName={eTableName.RISK_HR_EVALUATIONS_TABLE}
        showCheckbox={true}
        columns={columns}
        data={evaluations}
        fetchData={fetchData}
        loading={isLoading}
        pageCount={pageCount}
        onSelectRows={(rows: IEvaluationSummary[]) => onSelectedRows && onSelectedRows(rows)}
        initPageSize={pageSize}
        searchArray={searchArray}
        showTableActions={showTableActions}
        pdfOrientation={'landscape'}
      />
    </div>
  )
}
