import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { configManager } from '../../config/configManager'
import { v4 as uuidv4 } from 'uuid'
import { RootState } from '../redux/store'
import { ICompany, ILCHttpResponse, IUser } from '@logicalcommander/types'

export interface IRegisterLeadEmailResponse {
  isFreeEmail: boolean
  language: string
  user: { email: string }
}

export interface IRegisterLeadEmailRequest {
  email: string
  language: string
}

export interface IRegisterLeadCompanyRequest {
  token: string
  language: string
  user: {
    identity: string
    password: string
    email: string
    firstName?: string
    middleName?: string
    lastName?: string
    mobile?: string
  }
  company: {
    companyId: string
    name: string
    phone: string
    address: {
      country?: string
      city?: string
      state?: string
      addressLine1?: string
      addressLine2?: string
      postcode?: string
    }
    website?: string
  }
}

export interface IRegisterLeadCompanyResponse {
  token: string
  user: IUser
  company: ICompany
  language: string
  isFreeEmail: boolean
}

export const registerApi = createApi({
  reducerPath: 'registerCompany',
  baseQuery: fetchBaseQuery({
    baseUrl: `${configManager.api.GATEWAY_URL}/lead`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('x-request-id', uuidv4())
      const { token, companyToken } = (getState() as RootState).authReducer
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      if (companyToken) {
        headers.set('x-company-token', companyToken)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    registerLeadEmail: builder.mutation<IRegisterLeadEmailResponse, IRegisterLeadEmailRequest>({
      query: ({ email, language }) => ({
        url: '/email',
        method: 'POST',
        body: { email, language },
      }),
      transformResponse: (rawResult: ILCHttpResponse<IRegisterLeadEmailResponse>, meta) => {
        return rawResult.data as IRegisterLeadEmailResponse
      },
    }),
    registerLeadCompany: builder.mutation<IRegisterLeadCompanyResponse, IRegisterLeadCompanyRequest>({
      query: (data) => ({
        url: '/company',
        method: 'POST',
        body: data,
      }),
      transformResponse: (rawResult: ILCHttpResponse<IRegisterLeadCompanyResponse>, meta) => {
        return rawResult.data as IRegisterLeadCompanyResponse
      },
    }),
  }),
})

export const { useRegisterLeadEmailMutation, useRegisterLeadCompanyMutation } = registerApi
