import { SVGProps } from 'react'

const SvgXIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M4.18164 1L0.99966 4.18198" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.18198 4.18198L1 1" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default SvgXIcon
