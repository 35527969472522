import './settings-enum-item.scss'

import React, { CSSProperties, HTMLAttributes, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux-hooks'
import { selectSettings, setDepartmentsSettings, setPositionsSettings } from '../../../../redux/reducers/settingsSlice'
import * as Yup from 'yup'
import { LCFormInputText } from '../../../../ui/components/forms/lc-form-inputText/lc-form-inputText'
import { LCSvg } from '../../../../ui/components/lc-svg/lc-svg'
import { IEnumsSettings, IEnumsSettingsItem } from '@logicalcommander/types'
import {
  useAddEnumSettingsItemMutation,
  useDeleteEnumSettingsItemMutation,
  useUpdateEnumSettingsItemMutation,
} from '../../../../services/settings.service'
import LCFormInputTextEdit from '../../../../ui/components/forms/lc-form-inputText-edit/lc-form-inputTextEdit'
import { toast } from 'react-toastify'
import { LCToast } from '../../../../ui/components/lc-toast/Toast'

enum UserAction {
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
  ADD = 'ADD',
}

const getSuccessTranslationKey = (action: UserAction): string => {
  switch (action) {
    case UserAction.ADD:
      return 'successAdd'
    case UserAction.UPDATE:
      return 'successUpdate'
    case UserAction.DELETE:
      return 'successDelete'
  }
}

const getErrorTranslationKey = (action: UserAction): string => {
  switch (action) {
    case UserAction.ADD:
      return 'errorAdd'
    case UserAction.UPDATE:
      return 'errorUpdate'
    case UserAction.DELETE:
      return 'errorDelete'
  }
}

export enum eSettingsEnumKind {
  departments = 'departments',
  positions = 'positions',
  positionsGroups = 'positionsGroups',
  employeeStatus = 'employeeStatus',
}

export interface Props extends HTMLAttributes<HTMLDivElement> {
  type: eSettingsEnumKind
  customStyle?: CSSProperties
}

export const SettingsEnumItem = ({ type, customStyle }: Props) => {
  const [addEnumsSettingsItem, { data: dataAdd, error: errorAdd }] = useAddEnumSettingsItemMutation()
  const [deleteEnumsSettingsItem, { data: dataDelete, error: errorDelete }] = useDeleteEnumSettingsItemMutation()
  const [updateEnumsSettingsItem, { data: dataUpdate, error: errorUpdate }] = useUpdateEnumSettingsItemMutation()
  const { t } = useTranslation('translation', { keyPrefix: `settings.enumeratedType.${type}` })
  const { t: v } = useTranslation('translation', { keyPrefix: 'validation' })
  const items = useAppSelector(selectSettings).enums[type]
  const [sortedItems, setSortedItems] = useState<IEnumsSettingsItem[]>([])
  const [itemNameToAdd, setItemNameToAdd] = useState<string>('')
  const [itemToAddError, setItemToAddError] = useState<string>('')
  const dispatch = useAppDispatch()

  const addEnumItemSchema = Yup.string()
    .min(2, v('short', { field: t('name'), short: 2, long: 50 }))
    .max(50, v('long', { field: t('name'), short: 2, long: 50 }))
    .required(v('required', { field: t('name') }))

  const sortItems = (values: IEnumsSettingsItem[]): IEnumsSettingsItem[] => {
    return [...values].sort((a, b) => a.name.localeCompare(b.name))
  }

  useEffect(() => {
    items && setSortedItems(sortItems(items))
  }, [items])

  useEffect(() => {
    if (!errorAdd && dataAdd) {
      showUpdateMessage(true, UserAction.ADD, dataAdd)
    } else if (errorAdd) {
      showUpdateMessage(false, UserAction.ADD)
    }
  }, [dataAdd, errorAdd])

  useEffect(() => {
    if (!errorDelete && dataDelete) {
      showUpdateMessage(true, UserAction.DELETE, dataDelete)
    } else if (errorDelete) {
      showUpdateMessage(false, UserAction.DELETE)
    }
  }, [dataDelete, errorDelete])

  useEffect(() => {
    if (!errorUpdate && dataUpdate) {
      showUpdateMessage(true, UserAction.UPDATE, dataUpdate)
    } else if (errorUpdate) {
      showUpdateMessage(false, UserAction.UPDATE)
    }
  }, [dataUpdate, errorUpdate])

  const showUpdateMessage = (success: boolean, action: UserAction, enums?: IEnumsSettings) => {
    if (success && enums) {
      toast.success(<LCToast title={t(`${getSuccessTranslationKey(action)}.title`)} body={t(`${getSuccessTranslationKey(action)}.body`)} />)
      switch (type) {
        case 'departments':
          dispatch(setDepartmentsSettings(enums.departments))
          break
        case 'positions':
          dispatch(setPositionsSettings(enums.positions))
          break
      }
    } else {
      toast.error(<LCToast title={t(`${getErrorTranslationKey(action)}.title`)} body={t(`${getErrorTranslationKey(action)}.body`)} />)
    }
  }

  const onDeleteItem = (id: number): void => {
    deleteEnumsSettingsItem({ type, id })
  }

  const onSaveItem = (id: number, newValue: string): void => {
    if (isValueExists(newValue)) return
    updateEnumsSettingsItem({ type, id, name: newValue })
  }

  const onAddItem = (): void => {
    addEnumItemSchema
      .validate(itemNameToAdd)
      .then(() => {
        if (isValueExists(itemNameToAdd)) return

        // Continue with add
        addEnumsSettingsItem({ type, name: itemNameToAdd })
      })
      .catch((error: any) => {
        setItemToAddError(error.message)
      })
  }

  const isValueExists = (value: string): boolean => {
    const names = sortedItems.map((i: IEnumsSettingsItem) => i.name.toLowerCase())
    const exists = names.includes(value?.toLowerCase() || '')
    if (exists) {
      setItemToAddError(v('exists', { field: t('name') }))
      return true
    }
    return false
  }

  return (
    <div className="enum-settings" style={customStyle}>
      <h2>{t('title')}</h2>
      <div className="description">{t('description')}</div>

      <div className="add-enum-item">
        <div className="add-text">{t('add')}</div>
        <LCFormInputText inputId={'add'} inputType="text" onChange={(e) => setItemNameToAdd(e.target.value)} value={itemNameToAdd} />
        <LCSvg name="action-add" onClick={onAddItem} />
      </div>
      {itemToAddError ? <div className="error">{itemToAddError}</div> : null}

      <div>
        <h3>{t('customEnums')}</h3>
        <div className="items">
          {sortedItems
            ?.filter((f) => f.id >= 10000)
            .map((i: IEnumsSettingsItem, index: number) => (
              <div className="input-item" key={i.id}>
                <LCFormInputTextEdit
                  customStyle={{ minWidth: '320px' }}
                  inputId={`${i.id}`}
                  inputType="text"
                  value={i.name}
                  onSave={(value: string) => onSaveItem(i.id, value)}
                  onDelete={() => onDeleteItem(i.id)}
                />
              </div>
            ))}
        </div>

        <h3>{t('readOnlyEnums')}</h3>
        <div className="items">
          {sortedItems
            ?.filter((f) => f.id < 10000)
            .map((i: IEnumsSettingsItem, index: number) => (
              <div className="input-item" key={i.id}>
                <LCFormInputText
                  customStyle={{ minWidth: '320px' }}
                  inputId={`${i.id}`}
                  inputType="text"
                  value={i.name}
                  disabled={true}
                  onChange={() => {}}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
