import './company-info-settings.scss'

import React, { CSSProperties, HTMLAttributes, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../../hooks/redux-hooks'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { LCButton } from '../../../../ui/components/lc-button/LCButton'
import { LCFormInputTextPhone } from '../../../../ui/components/forms/lc-form-inputText-phone/lc-form-inputText-phone'
import { LCFormInputText } from '../../../../ui/components/forms/lc-form-inputText/lc-form-inputText'
import { useLazyGetCompanyLogoTmpUrlQuery, useUpdateCompanyInfoSettingsMutation } from '../../../../services/settings.service'
import { lcRegex } from '../../../../utils/regex'
import { ICompanyInfo } from '@logicalcommander/types'
import { isEmpty } from '../../../../utils/object'
import { useAppDispatch } from '../../../../redux/hooks'
import { findObjectDifferences } from '../settingsHelper'
import { LCCachedImage } from '../../../../ui/components/lc-cached-image/LCCachedImage'
import { selectExtendedCompany } from '../../../../redux/reducers/extendedCompanySlice'
import { selectAuth } from '../../../../redux/reducers/authSlice'
import { setCompanyInfoSettings } from '../../../../redux/reducers/settingsSlice'
import { LCompanyFeatures } from '../../../../ui/components/lc-company-features/LCCompanyFeatures'
import { toast } from 'react-toastify'
import { LCToast } from '../../../../ui/components/lc-toast/Toast'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

export const CompanyInfoSettings = ({ customStyle }: Props) => {
  const [updateCompanySettings, { data: dataUpdate, error: errorUpdate }] = useUpdateCompanyInfoSettingsMutation()
  const [getCompanyLogo, { data: dataLogo }] = useLazyGetCompanyLogoTmpUrlQuery()
  const [companyLogo, setCompanyLogo] = useState<string>()
  const { t } = useTranslation('translation', { keyPrefix: 'settings.companyInfo' })
  const { t: v } = useTranslation('translation', { keyPrefix: 'validation' })
  const { companyInfo, expirationDate } = useAppSelector(selectExtendedCompany)
  const { companyId } = useAppSelector(selectAuth).user
  const dispatch = useAppDispatch()

  const initialValues: ICompanyInfo = {
    ...companyInfo,
  }

  useEffect(() => {
    getCompanyLogo()
  }, [])

  useEffect(() => {
    if (dataLogo) {
      setCompanyLogo(dataLogo.url)
    }
  }, [dataLogo])

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, v('short', { field: t('companyName'), short: 3, long: 50 }))
      .max(50, v('long', { field: t('companyName'), short: 3, long: 50 }))
      .required(v('required', { field: t('companyName') })),
    address: Yup.string()
      .min(3, v('short', { field: t('companyAddress'), short: 3, long: 100 }))
      .max(100, v('long', { field: t('companyAddress'), short: 3, long: 100 }))
      .required(v('required', { field: t('companyAddress') })),
    email: Yup.string()
      .min(3, v('short', { field: t('companyEmail'), short: 3, long: 50 }))
      .max(50, v('long', { field: t('companyEmail'), short: 3, long: 50 }))
      .email(v('valid', { field: t('companyEmail') }))
      .required(v('required', { field: t('companyEmail') })),
    phone: Yup.string()
      .min(7, v('short', { field: t('companyPhone'), short: 7, long: 20 }))
      .max(20, v('long', { field: t('companyPhone'), short: 7, long: 20 }))
      .matches(lcRegex.mobileNumber, v('valid', { field: t('companyPhone') }))
      .required(v('required', { field: t('companyPhone') })),
    website: Yup.string()
      .min(6, v('short', { field: t('companyWebsite'), short: 6, long: 100 }))
      .max(100, v('long', { field: t('companyWebsite'), short: 6, long: 100 })),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: (values: any) => {
      console.log('onSubmit', values)
      const companyInfoToUpdate: Partial<ICompanyInfo> = findObjectDifferences<ICompanyInfo>(initialValues, values)
      console.log('company', companyInfoToUpdate)
      if (isEmpty(companyInfoToUpdate)) {
        toast.info(<LCToast title={v('nothingToUpdate.title')} body={v('nothingToUpdate.body')} />)
      } else {
        updateCompanySettings(companyInfoToUpdate)
      }
    },
  })

  useEffect(() => {
    if (!errorUpdate && dataUpdate) {
      toast.success(<LCToast title={t('successUpdatingCompanyInfoSettings.title')} body={t('successUpdatingCompanyInfoSettings.body')} />)
      dispatch(setCompanyInfoSettings({ ...initialValues, ...formik.values }))
    } else if (errorUpdate) {
      toast.error(<LCToast title={t('errorUpdatingCompanyInfoSettings.title')} body={t('errorUpdatingCompanyInfoSettings.body')} />)
    }
  }, [dataUpdate, errorUpdate])

  // console.log(formik.values)

  return (
    <form className="company-info-settings-form" onSubmit={formik.handleSubmit}>
      <div className="settings-content" style={customStyle}>
        <div className="section">
          <h3>{t('companyTitle')}</h3>
          <LCFormInputText inputId={'companyId'} inputType="text" label={t('companyId')} value={companyId} disabled={true} />

          <LCFormInputText
            inputId={'name'}
            inputType="text"
            label={t('companyName')}
            placeholder={t('companyNamePlaceholder')}
            onChange={formik.handleChange}
            value={formik.values.name}
            required={true}
            error={formik.errors.name}
          />

          <LCFormInputTextPhone inputId={'phone'} value={formik.values.phone} label={t('companyPhone')} required={true} />

          <LCFormInputText
            inputId={'address'}
            inputType="text"
            label={t('companyAddress')}
            placeholder={t('companyAddressPlaceholder')}
            onChange={formik.handleChange}
            value={formik.values.address}
            required={true}
            error={formik.errors.address}
          />

          <LCFormInputText
            inputId={'email'}
            inputType="text"
            label={t('companyEmail')}
            placeholder={t('companyEmailPlaceholder')}
            onChange={formik.handleChange}
            value={formik.values.email}
            required={true}
            error={formik.errors.email}
          />

          <LCFormInputText
            inputId={'website'}
            inputType="text"
            label={t('companyWebsite')}
            placeholder={t('companyWebsitePlaceholder')}
            onChange={formik.handleChange}
            value={formik.values.website}
            required={false}
            error={formik.errors.website}
          />
        </div>

        <div className="section">
          <h3>{t('licenseInformationTitle')}</h3>
          <LCFormInputText
            inputId={'expirationDate'}
            inputType="text"
            label={t('companyLicenseEndDate')}
            onChange={formik.handleChange}
            value={expirationDate}
            disabled={true}
          />
          <div className="company-logo">
            <label className="company-logo-label">{t('companyLogo')}</label>
            <div className="company-logo-image">
              {companyLogo ? <LCCachedImage cacheKey={'logo'} url={companyLogo} width={100} height={100} /> : null}
            </div>
          </div>
        </div>

        <div className="section">
          <h3>{t('licenseFeaturesTitle')}</h3>
          <LCompanyFeatures />
        </div>
      </div>

      <div className="update-button-wrapper">
        <LCButton label={t('updateButton')} type="submit" disabled={!formik.dirty || !formik.isValid} />
      </div>
    </form>
  )
}
