import jsPDF from 'jspdf'
import moment from 'moment'
import { IExportPDFData } from '../LCTableServerPaginationHelper'

var pdfConfig = {
  headerTextSize: 20,
  labelTextSize: 12,
  fieldTextSize: 10,
  lineHeight: 6,
  subLineHeight: 4,
}

const colorBlack = '#000000'
const colorGray = '#4d4e53'

export const addPDFHeader = (doc: jsPDF, additionalData: IExportPDFData): number => {
  const { id, name, address, phone, email, website, logoUrl } = additionalData.company

  //starting at 15mm
  let currentHeight = 15

  const docWidth = doc.internal.pageSize.width
  // const docHeight = doc.internal.pageSize.height

  // Company Logo
  if (logoUrl) {
    console.log('========= logoUrl', logoUrl)
    const imageHeader = new Image()
    imageHeader.src = logoUrl
    doc.addImage(imageHeader, 10, 10, 20, 20)
  }

  // Add the date
  const currentDate = moment().format('Do MMMM YYYY, h:mm:ss a')
  doc.setFontSize(pdfConfig.fieldTextSize - 2)
  doc.text(currentDate, 10, 42)

  // Company ID
  doc.setFontSize(pdfConfig.headerTextSize)
  doc.setTextColor(colorBlack)
  doc.text(id, docWidth - 10, currentHeight, { align: 'right' })

  // Company name
  doc.setFontSize(pdfConfig.labelTextSize)
  doc.setTextColor(colorBlack)
  currentHeight += pdfConfig.lineHeight
  doc.text(name, docWidth - 10, currentHeight, { align: 'right' })

  // Company address
  if (address) {
    doc.setFontSize(pdfConfig.fieldTextSize)
    doc.setTextColor(colorGray)
    currentHeight += pdfConfig.subLineHeight
    currentHeight += pdfConfig.subLineHeight
    doc.text(address, docWidth - 10, currentHeight, { align: 'right' })
  }

  // Company phone
  if (phone) {
    currentHeight += pdfConfig.subLineHeight
    doc.text(phone, docWidth - 10, currentHeight, { align: 'right' })
    doc.setFontSize(pdfConfig.fieldTextSize)
  }

  // Company email
  if (email) {
    currentHeight += pdfConfig.subLineHeight
    doc.text(email, docWidth - 10, currentHeight, { align: 'right' })
  }

  // Company website
  if (website) {
    currentHeight += pdfConfig.subLineHeight
    doc.text(website, docWidth - 10, currentHeight, { align: 'right' })
  }

  //line breaker after logo & business info
  currentHeight += pdfConfig.subLineHeight
  doc.line(10, currentHeight, docWidth - 10, currentHeight)

  return currentHeight
}

export const addPDFFooter = (doc: jsPDF): void => {
  var docWidth = doc.internal.pageSize.width
  const currentDate = moment().format('Do MMMM YYYY, h:mm:ss a')

  if (doc.getNumberOfPages() > 1) {
    for (let i = 1; i <= doc.getNumberOfPages(); i++) {
      doc.setFontSize(pdfConfig.fieldTextSize - 2)
      doc.setTextColor(colorGray)
      doc.setPage(i)
      doc.text(`Page ${i} / ${doc.getNumberOfPages()}`, docWidth - 20, doc.internal.pageSize.height - 6)
      doc.text(currentDate, 10, doc.internal.pageSize.height - 6)
    }
  }
}
