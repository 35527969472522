import './emo-risk-dashboard.scss'
import React, { FC, HTMLAttributes, useEffect, useState } from 'react'
import { CSSProperties } from 'styled-components'
import { IDashboardRisksPerDepartment, BarChartLayout, BarColor, CustomLabels } from '../../../../../interfaces/dashboard.interface'
import LCBarChartNew from '../../../../ui/components/charts/lc-bar-chart'
import { useTranslation } from 'react-i18next'
import { BaseDashboardComponent } from '../../../../ui/components/base-dashboard/BaseDashboardComponent'
import { DashboardComponentTitle } from '../../../../ui/components/base-dashboard/DashboardComponentTitle'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

const EmoRiskDepartment: FC<Props> = ({ customStyle }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.emoRisk.riskDepartament' })

  const error = false

  const data = [
    {
      title: 'R&D',
      value: 15,
    },
    {
      title: 'administración',
      value: 41,
    },
    {
      title: 'Contabilidad',
      value: 69,
    },
    {
      title: 'RRHH',
      value: 51,
    },
    {
      title: 'Security',
      value: 94,
    },
    {
      title: 'HR',
      value: 152,
    },
    {
      title: 'Accounting',
      value: 10,
    },
    {
      title: 'Sales',
      value: 41,
    },
    {
      title: 'Systems',
      value: 89,
    },
    {
      title: 'Marketing',
      value: 51,
    },
    {
      title: 'Recuersos humanos',
      value: 53,
    },
    {
      title: 'Finance',
      value: 87,
    },
    {
      title: 'Cybersecurity',
      value: 26,
    },
    {
      title: 'Administration',
      value: 74,
    },
    {
      title: 'Dispatch',
      value: 84,
    },
    {
      title: 'Graphic Design',
      value: 49,
    },
    {
      title: 'Compliance',
      value: 87,
    },
    {
      title: 'Risk',
      value: 97,
    },
    {
      title: 'Public Relations',
      value: 65,
    },
    {
      title: 'Maintenance',
      value: 51,
    },
    {
      title: 'Production',
      value: 29,
    },
    {
      title: 'Designing',
      value: 54,
    },
    {
      title: 'Communications',
      value: 651,
    },
    {
      title: 'IR - Investors Relations',
      value: 23,
    },
    {
      title: 'Treasury',
      value: 74,
    },
    {
      title: 'Payroll',
      value: 62,
    },
    {
      title: 'Legal',
      value: 51,
    },
    {
      title: 'Procurement',
      value: 54,
    },
    {
      title: 'Projects',
      value: 84,
    },
    {
      title: 'Inspection',
      value: 84,
    },
    {
      title: 'Packaging',
      value: 87,
    },
    {
      title: 'Wellbeing',
      value: 95,
    },
    {
      title: 'Data analyse',
      value: 81,
    },
    {
      title: 'Advertising',
      value: 52,
    },
    {
      title: 'Operations',
      value: 54,
    },
    {
      title: 'Distribution',
      value: 84,
    },
  ]

  const [departmentRiskTopic, setDepartmentRiskTopic] = useState<IDashboardRisksPerDepartment[]>([])

  const isLoading = false
  useEffect(() => {
    if (data) {
      const sorted = [...data].sort(sortDepartmentRisk)
      setDepartmentRiskTopic(sorted)
    }
  }, [])

  const sortDepartmentRisk = (a: IDashboardRisksPerDepartment, b: IDashboardRisksPerDepartment) => {
    return b.value - a.value
  }

  return (
    <BaseDashboardComponent customStyle={customStyle} sectionTitle={<DashboardComponentTitle title={t('title')} subTitle={t('total')} />} isLoading={isLoading} isSuccess={!!departmentRiskTopic} isError={!!error}>
      <LCBarChartNew
        data={departmentRiskTopic}
        options={{
          layout: BarChartLayout.HORIZONTAL,
          color: BarColor.COLOR_DEPARTMENT,
          label: CustomLabels.TOTAL_DEPARTMENT,
        }}
      />
    </BaseDashboardComponent>
  )
}

export default EmoRiskDepartment
