import { FC, ReactElement, useRef } from 'react'
import { LCAdvancedFilterAddItem } from '../../advanced-filter/advanced-filter-add-item/LCAdvancedFilterAddItem'
import LCPopup from '../../lc-popup/LCPopup'
import { eTableName } from '@logicalcommander/types'
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter'
import { LCToolbarButton } from '../../lc-toolbar-button/LCToolbarButton'

export interface Props {
  tableName: eTableName
  columns: any
}

export const LCTableActionAddFilter: FC<Props> = ({ columns, tableName }: Props): ReactElement => {
  const ref = useRef<any>(null)

  const closePopUp = () => {
    ref?.current.close()
  }

  return (
    <LCPopup
      position={['midleft', 'bottom']}
      ref={ref}
      toggler={
        <div>
          <LCToolbarButton icon={faFilter} text="Filter" />
        </div>
      }>
      <LCAdvancedFilterAddItem closePopup={closePopUp} customStyle={{ margin: '10px' }} tableName={tableName} columns={columns} />
    </LCPopup>
  )
}
