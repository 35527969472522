import React from 'react'
import './case-history.scss'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { CSSProperties, FC, HTMLAttributes } from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import { useTranslation } from 'react-i18next'
import { datesHelper } from '../../../../../../utils/dates'
import { IRiskHRCaseHistory, RiskHRCaseHistoryAction } from '@logicalcommander/types'
import { ICachedAdmin, cachedStorage, eStorageKey } from '../../../../../../utils/cachedStorage'
import LCAwareOthersImageProfileBlob from '../../../../../../ui/awareComponents/lc-profile-image/LCAwareOthersImageProfileBlob'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  history: IRiskHRCaseHistory[]
  customStyle?: CSSProperties
}

export const CaseHistory: FC<Props> = ({ history, customStyle }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'risk_hr_case.history' })
  const { t: a } = useTranslation('translation', { keyPrefix: 'risk_hr_case.history.actions' })
  const { t: p } = useTranslation('translation', { keyPrefix: 'casePriority' })
  const { t: s } = useTranslation('translation', { keyPrefix: 'caseStatus' })
  const admins = cachedStorage.get<ICachedAdmin[]>(eStorageKey.ADMINS) || []

  const formatAdminWithIdentity = (adminIdentity: string) => {
    const admin = admins.find((admin) => admin.identity === adminIdentity)
    if (admin) {
      return `${admin.name} (${admin.identity})`
    }
    return t('admin_not_found')
  }

  const actionMapper = (action: RiskHRCaseHistoryAction, value: string) => {
    switch (action) {
      case RiskHRCaseHistoryAction.UPDATE_ASSIGNEE:
        return formatAdminWithIdentity(value)
      case RiskHRCaseHistoryAction.UPDATE_PRIORITY:
        return p(value)
      case RiskHRCaseHistoryAction.UPDATE_STATUS:
        return s(value)
    }
  }

  return (
    <div style={customStyle} className="risk-hr-case-history">
      <div className="section-title">{t('title')}</div>
      <div className="history-wrapper">
        <VerticalTimeline layout="1-column" lineColor="#e4e7ef">
          {history.map((h, index) => (
            <VerticalTimelineElement
              key={index}
              className="vertical-timeline-element--work"
              contentStyle={{ background: 'rgb(250, 250, 250)', color: '#555b6a' }}
              contentArrowStyle={{ borderRight: '7px solid #e4e7ef' }}
              date={datesHelper.formatLong(h.date)}
              icon={<LCAwareOthersImageProfileBlob identity={h.adminIdentity} />}
              iconStyle={{ background: '#e4ecff', color: '#e4e7ef' }}>
              <div className="vertical-timeline-element-title">{formatAdminWithIdentity(h.adminIdentity)}</div>
              <div>
                {a(h.action)} <span className='bold'>{actionMapper(h.action, h.toValue)}</span>
              </div>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </div>
    </div>
  )
}
