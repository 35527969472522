import './lc-menu-expand.scss'
import React, { CSSProperties, FC, HTMLAttributes, ReactElement, useState } from 'react'

export interface Props extends HTMLAttributes<any> {
  label: string
  menuItems: ReactElement
  customStyle?: CSSProperties
}

export const LCMenuExpand: FC<Props> = ({ label, menuItems, customStyle, ...props }: Props) => {
  const [isExpanded, setExpanded] = useState(false)

  const onClick = (): void => {
    setExpanded(!isExpanded)
  }

  return (
    <div className='menu-wrapper'>
      <div className='parent-menu-item' style={customStyle} onClick={onClick} {...props}>
        <div className='label'>{label}</div>
        <div className={`sub-tab-arrow${isExpanded ? ' expanded' : ''}`}></div>
      </div>
      <div className={`menu-content${isExpanded ? ' expanded' : ''}`}>
        {menuItems}
      </div>
    </div>
  )
}
