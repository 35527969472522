/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment'
import React from 'react'
import { Column } from 'react-table'
import { IAdvancedFilterValuesStructure } from '../../../../ui/components/advanced-filter/advanced-filter-add-item/LCAdvancedFilterAddItem'
import { LCRiskBalloon } from '../../../../ui/components/lc-risk-balloon/LCRiskBalloon'
import { eAssessmentSizeId, eEvaluationStatus, eImpactType, eSearchType } from '@logicalcommander/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons/faCircleExclamation'
import { LCSeverity } from '../../../../ui/components/lc-severity/LCSeverity'
import { LCImpactPriority } from '../../../../ui/components/lc-impact-priority/LCImpactPriority'
import { convertSeverityToPercentages, getSeverityFromPercentages } from '../../../../ui/components/lc-severity/lc-severity-helper'
import { getMaxImpactPriority } from '../../../../ui/components/lc-impact-priority/lc-impact-priority-helper'
import {
  getAllAssessmentsNamesDropdown,
  getAllAssessmentsSizeDropdown,
  getAllAssessmentsTypesDropdown,
  getAllLanguagesDropdown,
  getAllMaxImpactPriorityDropdown,
  getAllRiskSeverityDropdown,
} from '../../../../utils/list-helper'
import { LCImpactPopup } from '../../../../ui/components/lc-impact-popup/LCImpactPopup'
import LCPopup from '../../../../ui/components/lc-popup/LCPopup'
import { LCEvaluationLastStatus } from '../../../../ui/components/lc-evaluation-last-status/LCEvaluationLastStatus'
import { LCTopicsWithRisk } from '../../../../ui/components/lc-topics-with-risk/LCTopicsWithRisk'
import { riskHrEvaluationsAction_columns } from './riskHrEvaluationsAction.columns'

export type IExtendedColumn = Column & {
  filter?: IAdvancedFilterValuesStructure
  columns?: IExtendedColumn[]
  getCellExportValue?: (row: any, column: IExtendedColumn) => any
}

const languageMap: { [key: string]: string } = {
  en: 'English',
  es: 'Spanish',
  pt: 'Portuguese',
  ur: 'Urdu',
}

export interface IEvaluationSummary {
  identity: string
  firstName: string
  middleName: string
  lastName: string
  fullName: string
  evaluationId: string
  dummyResponse: boolean
  status: Record<eEvaluationStatus, Date>
  lastStatus: eEvaluationStatus
  assessmentInfo: {
    id: number
    typeId: number
    nameId: number
    sizeId: number
    language: string
  }
  summary: {
    maxRisk: number
    topicsWithRisks: number[]
    averageRisk: number
    maxSeverity: number
    maxImpactPriority: number
    impact: eImpactType[]
  }
}

export const riskHrEvaluations_columns = (navigate: any, t: any) => [
  {
    Header: t('evaluations.evaluation_info'),
    columns: [
      {
        Header: t('evaluations.evaluation_id'),
        accessor: 'evaluationId',
        Cell: (row: any) => {
          const evaluation: IEvaluationSummary = row.row.original
          return (
            <button
              className="link-button"
              onClick={() => navigate(`/app/riskhr/report/${evaluation.identity}/${evaluation.evaluationId}`)}>
              {evaluation.evaluationId}
            </button>
          )
        },
      },
      {
        Header: t('evaluations.evaluation_size'),
        accessor: 'assessmentInfo.sizeId',
        Cell: (row: any) => {
          const evaluation: IEvaluationSummary = row.row.original

          const text = evaluation.assessmentInfo.sizeId ? t(`settings.assessments.sizes.${evaluation.assessmentInfo.sizeId}`) : ''
          const toggler =
            evaluation.assessmentInfo.sizeId === eAssessmentSizeId.DEMO ? (
              <div style={{ cursor: 'pointer', color: '#0568ae' }}>
                {<FontAwesomeIcon icon={faCircleExclamation} size="lg" color="#0568ae" />}
              </div>
            ) : undefined

          return toggler ? (
            <div style={{ display: 'flex', gap: '10px' }}>
              {text}
              <LCPopup toggler={toggler}>
                <div style={{ fontFamily: 'SF-Pro-Text-Bold' }}>{t('evaluations.licenseDummy')}</div>
              </LCPopup>
            </div>
          ) : (
            text
          )
        },
        getCellExportValue: (row: any, column: any) => {
          const evaluation: IEvaluationSummary = row.original
          return evaluation.assessmentInfo.sizeId ? t(`settings.assessments.sizes.${evaluation.assessmentInfo.sizeId}`) : ''
        },
        filter: {
          type: 'dropdown',
          values: getAllAssessmentsSizeDropdown(t),
          valueTransform: (value: string) => Number(value),
        } as IAdvancedFilterValuesStructure,
      },
      {
        Header: t('evaluations.evaluation_type'),
        accessor: 'assessmentInfo.typeId',
        Cell: (row: any) => {
          const evaluation: IEvaluationSummary = row.row.original
          return evaluation.assessmentInfo.typeId ? t(`settings.assessments.types.${evaluation.assessmentInfo.typeId}`) : ''
        },
        getCellExportValue: (row: any, column: any) => {
          const evaluation: IEvaluationSummary = row.original
          return evaluation.assessmentInfo.typeId ? t(`settings.assessments.types.${evaluation.assessmentInfo.typeId}`) : ''
        },
        filter: {
          type: 'dropdown',
          values: getAllAssessmentsTypesDropdown(t),
          valueTransform: (value: string) => Number(value),
        } as IAdvancedFilterValuesStructure,
      },
      {
        Header: t('evaluations.evaluation_name'),
        accessor: 'assessmentInfo.nameId',
        Cell: (row: any) => {
          const evaluation: IEvaluationSummary = row.row.original
          return evaluation.assessmentInfo.nameId ? t(`settings.assessments.names.${evaluation.assessmentInfo.nameId}`) : ''
        },
        getCellExportValue: (row: any, column: any) => {
          const evaluation: IEvaluationSummary = row.original
          return evaluation.assessmentInfo.nameId ? t(`settings.assessments.names.${evaluation.assessmentInfo.nameId}`) : ''
        },
        filter: {
          type: 'dropdown',
          values: getAllAssessmentsNamesDropdown(t),
          valueTransform: (value: string) => Number(value),
        } as IAdvancedFilterValuesStructure,
      },
      {
        Header: t('evaluations.evaluation_language'),
        accessor: 'assessmentInfo.language',
        getCellExportValue: (row: any, column: any) => {
          const value = row.values[column.id]
          return languageMap[value] || value
        },
        Cell: (row: any) => {
          const evaluation: IEvaluationSummary = row.row.original
          return t(`languages.${evaluation.assessmentInfo.language}`)
        },
        filter: {
          type: 'dropdown',
          values: getAllLanguagesDropdown(t),
        } as IAdvancedFilterValuesStructure,
      },
    ] as IExtendedColumn[],
  },
  {
    Header: t('evaluations.evaluation_risks'),
    columns: [
      {
        Header: t('evaluations.evaluation_topics_with_risks'),
        accessor: 'summary.topicsWithRisks',
        Cell: (row: any) => {
          const evaluation: IEvaluationSummary = row.row.original
          const topics: number[] = evaluation.summary?.topicsWithRisks
          return topics ? <LCTopicsWithRisk topicsIds={topics} /> : ''
        },
        getCellExportValue: (row: any, column: any) => {
          const evaluation: IEvaluationSummary = row.original
          const topics: number[] = evaluation.summary?.topicsWithRisks
          return topics ? topics : ''
        },
        filter: {
          type: 'number',
        } as IAdvancedFilterValuesStructure,
      },
      {
        Header: t('evaluations.evaluation_max'),
        accessor: 'summary.maxRisk',
        Cell: (row: any) => {
          const evaluation: IEvaluationSummary = row.row.original
          return evaluation.summary?.maxRisk ? <LCRiskBalloon value={evaluation.summary.maxRisk} /> : ''
        },
        getCellExportValue: (row: any, column: any) => {
          const evaluation: IEvaluationSummary = row.original
          return evaluation.summary?.maxRisk ? `${evaluation.summary.maxRisk}%` : ''
        },
        filter: {
          type: 'number',
        } as IAdvancedFilterValuesStructure,
      },
      {
        Header: t('evaluations.evaluation_average'),
        accessor: 'summary.averageRisk',
        Cell: (row: any) => {
          const evaluation: IEvaluationSummary = row.row.original
          return evaluation.summary?.averageRisk ? <LCRiskBalloon value={evaluation.summary.averageRisk} /> : ''
        },
        getCellExportValue: (row: any, column: any) => {
          const evaluation: IEvaluationSummary = row.original
          return evaluation.summary?.averageRisk ? `${evaluation.summary.averageRisk}%` : ''
        },
        filter: {
          type: 'number',
        } as IAdvancedFilterValuesStructure,
      },
      {
        Header: t('evaluations.max_severity'),
        accessor: 'summary.maxSeverity',
        Cell: (row: any) => {
          const evaluation: IEvaluationSummary = row.row.original
          return evaluation.summary?.maxSeverity ? <LCSeverity maxSeverity={evaluation.summary?.maxSeverity} /> : ''
        },
        getCellExportValue: (row: any, column: any) => {
          const evaluation: IEvaluationSummary = row.original
          return getSeverityFromPercentages(evaluation.summary?.maxSeverity, t)
        },
        filter: {
          type: 'dropdown',
          values: getAllRiskSeverityDropdown(t),
          valueTransform: (value: string) => convertSeverityToPercentages(value),
          operatorOverride: [
            { label: eSearchType.LOWER, value: eSearchType.LOWER },
            { label: eSearchType.GREATER, value: eSearchType.GREATER },
          ],
        } as IAdvancedFilterValuesStructure,
      },
      {
        Header: t('evaluations.impact'),
        accessor: 'impact',
        Cell: (row: any) => {
          const evaluation: IEvaluationSummary = row.row.original
          return <LCImpactPopup impact={evaluation?.summary?.impact} />
        },
        getCellExportValue: (row: any, column: any) => {
          const evaluation: IEvaluationSummary = row.original
          const allImpacts: string[] = evaluation?.summary?.impact?.map((i: any) => t(`settings.riskHrImpactRules.impacts.${i}.name`)) || []
          return allImpacts.join('\r\n')
        },
        disableSortBy: true,
        filter: {
          hide: true,
        } as IAdvancedFilterValuesStructure,
      },
      {
        Header: t('evaluations.max_impact_priority'),
        accessor: 'summary.maxImpactPriority',
        Cell: (row: any) => {
          const evaluation: IEvaluationSummary = row.row.original
          return evaluation.summary?.maxImpactPriority ? <LCImpactPriority maxImpactPriority={evaluation.summary?.maxImpactPriority} /> : ''
        },
        getCellExportValue: (row: any, column: any) => {
          const evaluation: IEvaluationSummary = row.original
          return evaluation.summary?.maxImpactPriority ? getMaxImpactPriority(evaluation.summary?.maxImpactPriority, t) : ''
        },
        filter: {
          type: 'dropdown',
          values: getAllMaxImpactPriorityDropdown(t),
          valueTransform: (value: string) => Number(value),
          operatorOverride: [
            { label: eSearchType.LOWER, value: eSearchType.LOWER },
            { label: eSearchType.GREATER, value: eSearchType.GREATER },
          ],
        } as IAdvancedFilterValuesStructure,
      },
    ] as IExtendedColumn[],
  },
  // {
  //   Header: t('evaluations.case_info'),
  //   columns: [
  //     {
  //       Header: t('evaluations.case_id'),
  //       accessor: 'case.id',
  //       Cell: (row: any) => {
  //         const evaluation: IEvaluation = row.row.original
  //         return (
  //           <button className="link-button" onClick={() => navigate(`/app/riskhr/case/${evaluation.case?.id}`)}>
  //             {evaluation.case?.id}
  //           </button>
  //         )
  //       },
  //     },
  //     {
  //       Header: t('evaluations.priority'),
  //       accessor: 'case.priority',
  //       Cell: (row: any) => {
  //         const evaluation: IEvaluation = row.row.original
  //         return <span>{evaluation.case?.priority ? t(`casePriority.${evaluation.case?.priority}`) : ''}</span>
  //       },
  //       filter: {
  //         type: 'dropdown',
  //         values: Object.keys(eCasePriority).map((i) => {
  //           return { label: t(`casePriority.${i}`), value: i }
  //         }),
  //       } as IAdvancedFilterValuesStructure,
  //     },
  //     {
  //       Header: t('evaluations.status'),
  //       accessor: 'case.status',
  //       Cell: (row: any) => {
  //         const evaluation: IEvaluation = row.row.original
  //         return <span>{evaluation.case?.status ? t(`caseStatus.${evaluation.case?.status}`) : ''}</span>
  //       },
  //       filter: {
  //         type: 'dropdown',
  //         values: Object.keys(eCaseStatus).map((i) => {
  //           return { label: t(`caseStatus.${i}`), value: i }
  //         }),
  //       } as IAdvancedFilterValuesStructure,
  //     },
  //     {
  //       Header: t('evaluations.assigned_to'),
  //       accessor: 'case.assignTo',
  //       Cell: (row: any) => {
  //         const evaluation: IEvaluation = row.row.original
  //         return <LCAdminName identity={evaluation.case?.assignTo} />
  //       },
  //       filter: {
  //         type: 'dropdown',
  //         values: cachedStorage.get<ICachedAdmin[]>(eStorageKey.ADMINS)?.map((i) => {
  //           return { label: i.name, value: i.identity }
  //         }),
  //       } as IAdvancedFilterValuesStructure,
  //     },
  //   ] as IExtendedColumn[],
  // },
  {
    Header: t('evaluations.user'),
    columns: [
      {
        Header: t('users.columns.fullName'),
        accessor: 'firstName,middleName,lastName',
        Cell: (row: any) => {
          const evaluation: IEvaluationSummary = row.row.original
          return (
            <button className="link-button" onClick={() => navigate(`/app/user/${evaluation.identity}`)}>
              {evaluation.fullName}
            </button>
          )
        },
        getCellExportValue: (row: any, column: any) => {
          const evaluation: IEvaluationSummary = row.original
          return evaluation.fullName
        },
      },
      {
        Header: t('evaluations.identity'),
        accessor: 'identity',
      },
    ] as IExtendedColumn[],
  },
  {
    Header: t('evaluations.evaluation_status'),
    columns: [
      {
        Header: t('lastStatus'),
        accessor: 'status.lastStatus',
        Cell: (row: any) => {
          const evaluation: IEvaluationSummary = row.row.original
          return <LCEvaluationLastStatus statuses={evaluation.status} lastStatus={evaluation.lastStatus} />
        },
        getCellExportValue: (row: any, column: any) => {
          const evaluation: IEvaluationSummary = row.original
          const statusText = t(`evaluations.${evaluation.lastStatus}`)
          const statusDate = moment(evaluation.status[evaluation.lastStatus]).fromNow()
          return `${statusText} (${statusDate})`
        },
        filter: {
          hide: true,
          // type: 'dropdown',
          // values: getAllEvaluationStatusesDropdown(t),
        } as IAdvancedFilterValuesStructure,
      },
      // {
      //   Header: t('created'),
      //   accessor: 'status.created',
      //   Cell: (row: any) => {
      //     const evaluation: IEvaluationSummary = row.row.original
      //     return <div>{evaluation.status.created && moment(evaluation.status.created).fromNow()}</div>
      //   },
      //   getCellExportValue: (row: any, column: any) => {
      //     const value = row.values[column.id]
      //     return moment(value).fromNow()
      //   },
      //   filter: {
      //     type: 'date',
      //     values: datesHelper.getDatesRangeFromNow(),
      //   } as IAdvancedFilterValuesStructure,
      // },
      // {
      //   Header: t('evaluations.accepted'),
      //   accessor: 'status.accepted',
      //   getCellExportValue: (row: any, column: any) => {
      //     const value = row.values[column.id]
      //     return moment(value).fromNow()
      //   },
      //   Cell: (row: any) => {
      //     const evaluation: IEvaluationSummary = row.row.original
      //     return <div>{evaluation.status.accepted && moment(evaluation.status.accepted).fromNow()}</div>
      //   },
      //   filter: {
      //     type: 'date',
      //     values: datesHelper.getDatesRangeFromNow(),
      //   } as IAdvancedFilterValuesStructure,
      // },
      // {
      //   Header: t('evaluations.started'),
      //   accessor: 'status.started',
      //   getCellExportValue: (row: any, column: any) => {
      //     const value = row.values[column.id]
      //     return moment(value).fromNow()
      //   },
      //   Cell: (row: any) => {
      //     const evaluation: IEvaluationSummary = row.row.original
      //     return <div>{evaluation.status.started && moment(evaluation.status.started).fromNow()}</div>
      //   },
      //   filter: {
      //     type: 'date',
      //     values: datesHelper.getDatesRangeFromNow(),
      //   } as IAdvancedFilterValuesStructure,
      // },
      // {
      //   Header: t('evaluations.completed'),
      //   accessor: 'status.completed',
      //   getCellExportValue: (row: any, column: any) => {
      //     const value = row.values[column.id]
      //     return moment(value).fromNow()
      //   },
      //   Cell: (row: any) => {
      //     const evaluation: IEvaluationSummary = row.row.original
      //     return <div>{evaluation.status.completed && moment(evaluation.status.completed).fromNow()}</div>
      //   },
      //   filter: {
      //     type: 'date',
      //     values: datesHelper.getDatesRangeFromNow(),
      //   } as IAdvancedFilterValuesStructure,
      // },
    ] as IExtendedColumn[],
  },
  ...riskHrEvaluationsAction_columns(navigate, t),
]
