import React from 'react'
import { FC, HTMLAttributes, ReactElement } from 'react'
import { CSSProperties } from 'styled-components'
import { cachedStorage, ICachedAdmin, eStorageKey } from '../../../utils/cachedStorage'

interface Props extends HTMLAttributes<HTMLDivElement> {
  identity: string | undefined
  customStyle?: CSSProperties
}

export const LCAdminName: FC<Props> = ({ identity, customStyle }: Props): ReactElement | null => {
  const admins = cachedStorage.get<ICachedAdmin[]>(eStorageKey.ADMINS) || []
  return <div style={customStyle}>{admins.find((i) => i.identity === identity)?.name || ''}</div>
}
