import React, { FC, HTMLAttributes, lazy, useEffect, useState } from 'react'
import { CSSProperties } from 'styled-components'
import { sumBy } from 'lodash'
import { BarColor, CustomLabels } from '../../../../../interfaces/dashboard.interface'
import { ITitleValueNumber } from '@logicalcommander/types'
import { useTranslation } from 'react-i18next'
import { BaseDashboardComponent } from '../../../../ui/components/base-dashboard/BaseDashboardComponent'
import { DashboardComponentTitle } from '../../../../ui/components/base-dashboard/DashboardComponentTitle'
import { toast } from 'react-toastify'
import { LCToast } from '../../../../ui/components/lc-toast/Toast'

const LCPieChartUniNew = lazy(() => import('../../../../ui/components/charts/lc-pie-chart'))

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

const RiskHrDashboardPreEmploymentVsPeriodic: FC<Props> = ({ customStyle }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.riskHr.preEmploymentVsPeriodicAssessments' })
  const isLoading = false
  const error = false

  const data = [
    {
      title: 'Pre-employment',
      value: 527,
    },
    {
      title: 'PeriodicAssessments',
      value: 500,
    },
  ]
  const [employeeData, setEmployeeData] = useState<ITitleValueNumber[]>([])
  const [sumEmployeeData, setSumEmployeeData] = useState(0)

  useEffect(() => {
    if (data) {
      const sorted = [...data]
      const sumSorted = sumBy(sorted, 'value')
      setEmployeeData(sorted)
      setSumEmployeeData(sumSorted)
    } else if (true) {
      toast.error(<LCToast title={t('get_pre_employmen_vs_periodic_error_title')} body={t('get_pre_employmen_vs_periodic_error_text')} />)
    }
  }, [])

  return (
    <BaseDashboardComponent
      customStyle={customStyle}
      sectionTitle={<DashboardComponentTitle title={t('title')} subTitle={`${t('total')} ${sumEmployeeData}`} />}
      isLoading={isLoading}
      isSuccess={!!employeeData}
      isError={!!error}>
      <LCPieChartUniNew
        data={employeeData}
        dataTotal={sumEmployeeData}
        options={{
          color: BarColor.COLOR_STATUS,
          label: CustomLabels.ALERT_EVALUATION_PIE,
          customPercentage: false,
        }}
      />
    </BaseDashboardComponent>
  )
}

export default RiskHrDashboardPreEmploymentVsPeriodic
