import * as React from 'react'
import { SVGProps } from 'react'

const SvgPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10 1H2.8A1.8 1.8 0 0 0 1 2.8v14.4A1.8 1.8 0 0 0 2.8 19h10.8a1.8 1.8 0 0 0 1.8-1.8V6.4L10 1Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10 1v5.4h5.4M8.2 15.4V10M5.5 12.7h5.4" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default SvgPlus
