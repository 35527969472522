import './acceptance.scss'
import { CSSProperties, FC, HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import { SanitizeHTML } from '../../../ui/components/lc-sanitize-html/SanitizeHTML'
import { LCButton } from '../../../ui/components/lc-button/LCButton'

export interface ILCAcceptanceProps {
  version: string
  callback: (accepted: boolean) => void
  customStyle?: CSSProperties
}

export const LCAcceptance: FC<ILCAcceptanceProps & HTMLAttributes<HTMLDivElement>> = ({
  version,
  callback,
  customStyle,
  ...props
}: ILCAcceptanceProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'auth.acceptance' })

  const yesClicked = () => {
    callback(true)
  }
  const noClicked = () => {
    callback(false)
  }

  return (
    <div className="acceptance-wrapper" style={customStyle} {...props}>
      <SanitizeHTML html={t(version)} />
      <div className="acceptance-buttons">
        <LCButton label={t('noButton')} onClick={noClicked} />
        <LCButton label={t('yesButton')} onClick={yesClicked} />
      </div>
    </div>
  )
}
