import * as React from 'react'
import { SVGProps } from 'react'

const SvgActionDelete = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.5 5H4.79l1.2 9.6a2 2 0 0 0 2 1.9h4.02a2 2 0 0 0 2-1.9L15.21 5M17.5 5h-15M6.25 5V3.8a1.8 1.8 0 0 1 1.8-1.8h3.9a1.8 1.8 0 0 1 1.8 1.8V5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgActionDelete
