import './lc-form-dropdown-dynamic.scss'
import React, { CSSProperties, ReactElement, useEffect, useState } from 'react'
import Select, { MenuPlacement } from 'react-select'

export interface OptionType {
  value: number | string
  label: string
  isDisabled?: boolean
}

export interface GroupType {
  label: string
  options: OptionType[]
}

interface Props {
  inputId: string
  label: string
  options: OptionType[] | GroupType[]
  selectedValue?: string | number
  required?: boolean
  customStyle?: CSSProperties
  error?: any
  disabled?: boolean
  isLoading?: boolean
  menuPlacement?: MenuPlacement
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

export const LCFormDropdownDynamic = ({
  label,
  inputId,
  options,
  required,
  selectedValue,
  customStyle,
  error,
  disabled = false,
  isLoading = false,
  setFieldValue,
  menuPlacement,
}: Props): ReactElement => {
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null)

  useEffect(() => {
    // Find the option in the options array that matches the current Formik value
    // console.log('---------> options: ', options)
    // console.log('---------> selectedValue: ', selectedValue)
    let currentOption: OptionType | undefined
    if (options && (options[0] as OptionType).value) {
      currentOption = (options as OptionType[]).find((option) => String(option.value) === String(selectedValue))
    } else if (options && (options[0] as GroupType).options) {
      const group = (options as GroupType[]).filter((g) => {
        return g.options.find((i) => i.value === String(selectedValue))
      })
      currentOption = group[0]?.options?.find((i) => i.value === String(selectedValue))
    }
    setSelectedOption(currentOption || null)
  }, [options, selectedValue, inputId])

  const handleChange = (selectedOption: OptionType | null) => {
    setFieldValue(inputId, selectedOption ? selectedOption.value : '')
    setSelectedOption(selectedOption)
  }

  return (
    <div className="lc-dropdown-dynamic-Wrapper" style={customStyle}>
      <label className="lc-dropdown-dynamic-label">
        {label} {required && <span className="required">*</span>}
      </label>
      <Select
        value={selectedOption}
        defaultValue={selectedOption}
        className="basic-multi-select"
        classNamePrefix="select"
        id={inputId}
        isDisabled={disabled}
        isClearable={true}
        isSearchable={true}
        options={options}
        isLoading={isLoading}
        onChange={handleChange}
        menuPlacement={menuPlacement}
      />
      {error ? <div className="lc-dropdown-dynamic-error">{error}</div> : null}
    </div>
  )
}
