export const prodEnv = {
  api: {
    GATEWAY_URL: 'https://gateway.e-commander.com/v1',
    SOCKET_URL: 'https://gateway.e-commander.com',
  },
  SHORT_DATE_FORMAT: 'DD/MM/YYYY',
  SHORT_TIME_FORMAT: 'hh:mm:ss',
  SHORT_DATE_CALENDAR_FORMAT: 'YYYY-MM-DD',
  LONG_DATE_FORMAT: 'DD/MM/YYYY hh:mm:ss',
}
