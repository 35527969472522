import './lc-yes-no-dialog.scss'
import LCPopup from '../lc-popup/LCPopup'
import { FC, HTMLAttributes, ReactElement, useRef } from 'react'
import { LCButton } from '../lc-button/LCButton'
import React from 'react'
import { CSSProperties } from 'styled-components'
import { LCSvg } from '../lc-svg/lc-svg'
import { SanitizeHTML } from '../lc-sanitize-html/SanitizeHTML'

export interface IYesNoDialogData {
  title: string
  text: string
  yesButtonText: string
  noButtonText: string
  yesButtonCallback: () => void
  noButtonCallback?: () => void
}

export interface Props extends HTMLAttributes<HTMLDivElement> {
  toggler: ReactElement | string
  dialogData: IYesNoDialogData
  buttonSize?: 'xsmall' | 'small' | 'medium' | 'large'
  isLink?: boolean
  customStyle?: CSSProperties
}

export const LCYesNoDialog: FC<Props> = ({ toggler, dialogData, buttonSize = 'medium', isLink = false, customStyle, ...props }: Props) => {
  const ref = useRef<any>(null)

  let _toggler: ReactElement
  if (typeof toggler === 'string') {
    _toggler = isLink ? <div className="link-button">{toggler}</div> : <LCButton size={buttonSize} label={toggler} />
  } else if (React.isValidElement(toggler)) {
    _toggler = toggler
  } else {
    console.log('toggler is undefined or not a recognized type')
    return null
  }

  const yesClicked = () => {
    dialogData.yesButtonCallback()
    ref?.current.close()
  }

  const noClicked = () => {
    dialogData.noButtonCallback && dialogData.noButtonCallback()
    ref?.current.close()
  }

  return (
    <LCPopup ref={ref} position={'modal'} toggler={_toggler} {...props}>
      <div className="yes-no-dialog-wrapper" style={customStyle}>
        <div className="title">
          <div className="title-text">{dialogData.title}</div>
          <div className="title-x">
            <LCSvg name="xIcon" customStyle={{ color: '#ffffff' }} widthAndHeight={[14, 14]} onClick={() => ref?.current.close()} />
          </div>
        </div>
        <div className="body">
          <div className="description">
            <SanitizeHTML html={dialogData.text} />
          </div>
        </div>
        <div className="buttons">
          <LCButton label={dialogData.yesButtonText} onClick={yesClicked} />
          <LCButton label={dialogData.noButtonText} onClick={noClicked} />
        </div>
      </div>
    </LCPopup>
  )
}
