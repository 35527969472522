import React, { CSSProperties, HTMLAttributes } from 'react'
import { LCTabs } from '../../../../ui/components/lc-tabs/lc-tabs'
import { SettingsEnumItem, eSettingsEnumKind } from './SettingsEnumItem'
import { useTranslation } from 'react-i18next'
import { PositionsGroupsMapping } from './PositionsGroupsMapping'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

export const SettingsEnumeratedTypesTabs = ({ customStyle }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'settings.enumeratedType' })

  return (
    <div className="tabs">
      <LCTabs
        tabs={[
          {
            id: 'departments',
            title: t('tabs.departments'),
            component: <SettingsEnumItem type={eSettingsEnumKind.departments} />,
          },
          {
            id: 'positions',
            title: t('tabs.positions'),
            component: <SettingsEnumItem type={eSettingsEnumKind.positions} />,
          },
          {
            id: 'positionsGroups',
            title: t('tabs.positionsGroups'),
            component: <SettingsEnumItem type={eSettingsEnumKind.positionsGroups} />,
          },
          {
            id: 'employeeStatus',
            title: t('tabs.employeeStatus'),
            component: <SettingsEnumItem type={eSettingsEnumKind.employeeStatus} />,
          },
          {
            id: 'positionsGroupsMapping',
            title: t('tabs.positionsGroupsMapping'),
            component: <PositionsGroupsMapping />,
          },
        ]}
      />
    </div>
  )
}
