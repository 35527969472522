import React, { CSSProperties, FC, HTMLAttributes, useEffect, useState } from 'react'
import { sumBy } from 'lodash'
import { BarColor, CustomLabels } from '../../../../../interfaces/dashboard.interface'
import LCPieChartUniNew from '../../../../ui/components/charts/lc-pie-chart'
import { ITitleValueNumber } from '@logicalcommander/types'
import { useTranslation } from 'react-i18next'
import { BaseDashboardComponent } from '../../../../ui/components/base-dashboard/BaseDashboardComponent'
import { DashboardComponentTitle } from '../../../../ui/components/base-dashboard/DashboardComponentTitle'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

const EmoRiskDashboardCase: FC<Props> = ({ customStyle }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.emoRisk.riskDasboardCase' })
  const [dataCaseEmoRisk, setDataCaseEmoRisk] = useState<ITitleValueNumber[]>([])
  const [sumDataCaseEmotionRisk, setSumDataCaseEmotionRisk] = useState<number>(0)

  const isLoading = false
  const error = false

  const data = [
    {
      title: 'New',
      value: 200,
    },
    {
      title: 'Pending',
      value: 125,
    },
    {
      title: 'Closed',
      value: 600,
    },
    {
      title: 'In Progress',
      value: 50,
    },
  ]

  useEffect(() => {
    if (data) {
      const sorted = [...data]
      const sumSorted = sumBy(sorted, 'value')
      setDataCaseEmoRisk(sorted)
      setSumDataCaseEmotionRisk(sumSorted)
    }
  }, [])

  return (
    <BaseDashboardComponent customStyle={customStyle} sectionTitle={<DashboardComponentTitle title={t('title')} subTitle={t('total')} />} isLoading={isLoading} isSuccess={!!dataCaseEmoRisk} isError={!!error}>
      <LCPieChartUniNew
        data={dataCaseEmoRisk}
        dataTotal={sumDataCaseEmotionRisk}
        options={{
          color: BarColor.COLOR_STATUS,
          label: CustomLabels.STATUS_EVALUATION_PIE,
          customPercentage: false,
        }}
      />
    </BaseDashboardComponent>
  )
}

export default EmoRiskDashboardCase
