import React, { FC, useEffect, useState } from 'react'
import { CSSProperties } from 'styled-components'
import { OpenCaseModal } from '../riskHrCases/case/components/CreateCaseModal/OpenCaseModal'
import { useDeleteUsersMutation } from '../../../services/users.service'
import { LCButton } from '../../../ui/components/lc-button/LCButton'
import { ILCActionPopupItemData, LCActionPopup } from '../../../ui/components/lc-action-popup/LCActionPopup'
import { LCModal } from '../../../ui/components/lc-modal/LCModal'
import { IEvaluationSummary } from './columns/riskHrEvaluations.columns'
import { toast } from 'react-toastify'
import { LCToast } from '../../../ui/components/lc-toast/Toast'

interface Props {
  selectedRows: IEvaluationSummary[]
  onOpenCaseSuccess?: (identities: string[]) => void
  customStyle?: CSSProperties
}

export const MultipleEvaluationsActions: FC<Props> = ({ customStyle, selectedRows, onOpenCaseSuccess }: Props) => {
  const [openCaseModalOpen, setOpenCaseModalOpen] = useState(false)
  const [deleteUsers, { data, error }] = useDeleteUsersMutation()

  const actionPopupData = (): ILCActionPopupItemData[] => {
    return [
      {
        text: 'Open Cases',
        iconName: 'addUser',
        action: () => {
          setOpenCaseModalOpen(true)
        },
      },
    ]
  }

  useEffect(() => {
    if (!error && data) {
      toast.success(<LCToast title={'SUCCESS'} body={`${selectedRows.length} users were deleted`} />)
      onOpenCaseSuccess && onOpenCaseSuccess(data)
    } else if (error) {
      toast.error(<LCToast title={'ERROR'} body={`Error deleting ${selectedRows.length} users`} />)
    }
  }, [data, error])

  const doOpenCase = (): void => {
    const identities = selectedRows.map((user) => user.identity)
    console.log('identities', identities)
    deleteUsers(identities)
  }

  return (
    <div className="multiple-actions" style={customStyle}>
      <LCModal isOpen={openCaseModalOpen} title={`Open Cases`} onClose={() => setOpenCaseModalOpen(false)}>
        <OpenCaseModal
          title={`No need title when opening multiple`}
          onCloseModal={() => setOpenCaseModalOpen(false)}
          onOpenCase={() => {
            doOpenCase()
            setOpenCaseModalOpen(false)
          }}
        />
      </LCModal>
      <LCActionPopup data={actionPopupData()} anchor={<LCButton label="Multiple Actions" />} />
    </div>
  )
}
