import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { v4 as uuidv4 } from 'uuid'
import { RootState } from '../redux/store'
import {
  IEmoResults,
  IEmoResultsSummary,
  IEmoResultsSummaryByMonths,
  IGetEmoAttendanceForUserRequest,
  IGetEmoAttendanceForUserResponse,
  IGetEmoResultsRequest,
} from '../../interfaces/emo.interface'
import { IPaginationResponse, ISearch } from '@logicalcommander/types'
import { configManager } from '../../config/configManager'

export const emoApi = createApi({
  reducerPath: 'emoApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${configManager.api.GATEWAY_URL}/emo`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('x-request-id', uuidv4())
      const { token, companyToken } = (getState() as RootState).authReducer
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      if (companyToken) {
        headers.set('x-company-token', companyToken)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    getEmoResults: builder.mutation<IPaginationResponse<IEmoResults[]>, IGetEmoResultsRequest>({
      query: ({ pageIndex, pageSize, sortBy, search }) => {
        return {
          url: 'results',
          method: 'POST',
          body: {
            pageIndex,
            pageSize,
            sortBy,
            search,
          },
        }
      },
    }),
    getEmoAttendanceForUser: builder.mutation<IGetEmoAttendanceForUserResponse, IGetEmoAttendanceForUserRequest>({
      query: ({ search, identity }) => {
        return {
          url: `/attendance/${identity}`,
          method: 'POST',
          body: {
            search,
          },
        }
      },
    }),
    getEmoResultsSummaryByMonths: builder.mutation<IPaginationResponse<IEmoResultsSummaryByMonths[]>, ISearch[]>({
      query: (search) => {
        return {
          url: 'emo-results-summary-months',
          method: 'POST',
          body: search,
        }
      },
    }),
    getEmoResultsSummaryPagination: builder.mutation<IPaginationResponse<IEmoResultsSummary[]>, IGetEmoResultsRequest>({
      query: ({ pageIndex, pageSize, sortBy, search }) => {
        return {
          url: 'emo-results-summary-pagination',
          method: 'POST',
          body: {
            pageIndex,
            pageSize,
            sortBy,
            search,
          },
        }
      },
    }),
  }),
})

export const {
  useGetEmoResultsMutation,
  useGetEmoResultsSummaryByMonthsMutation,
  useGetEmoResultsSummaryPaginationMutation,
  useGetEmoAttendanceForUserMutation,
} = emoApi
