import React, { FC, useEffect, useState } from 'react'
import { ImpactsProps, PropsFunctionPopUp, TopicsPropsImpactRules, titleText } from '../SettingsRiskHrImpactRules';
import { useTranslation } from 'react-i18next';
import '../settings-riskhr.scss'

interface Props {
    actived: boolean
    dataProps: TopicsPropsImpactRules
    updateActived: ({ actived, dataUpdateImpactRules }: PropsFunctionPopUp) => void
}

export const LCEditPopupImpactRules: FC<Props> = ({ actived, dataProps, updateActived }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'settings.riskHrImpactRules.impactPopUp' })
    const { t: tImpacts } = useTranslation('translation', { keyPrefix: 'settings.riskHrImpactRules.impactRules' })

    const { name, impacts } = dataProps
    const [selectedImpactUpdate, setSelectedImpactUpdate] = useState<ImpactsProps[] | []>([])

    useEffect(() => {
        setSelectedImpactUpdate(impacts)
    }, [impacts])

    const deselectedUpdateFunction = ({ keyImpactSelect }: { keyImpactSelect: string }) => {
        setSelectedImpactUpdate((state) => state.map(impact => impact.keyImpact === keyImpactSelect ? { ...impact, selectImpact: false } : impact))
    }

    const selectedUpdateFunction = ({ keyImpactSelect }: { keyImpactSelect: string }) => {
        setSelectedImpactUpdate((state) => state.map(impact => impact.keyImpact === keyImpactSelect ? { ...impact, selectImpact: true } : impact))
    }


    return (
        <>

            {actived ?
                <div className='container-pop-up' >
                    <div className='content-pop-up'>
                        <div className='container-title-select'>
                            <h3>{t('title')}</h3>
                            <p>{t('titleTopics', { topic: name })}</p>
                            <div className='container-select'>
                                <h5>{t('selectImpactTitle')} :</h5>
                                {
                                    selectedImpactUpdate.findIndex(actived => actived.selectImpact) === -1
                                        ? <p>{t('notSelectImpactBody')} </p>
                                        : selectedImpactUpdate.map(({ keyImpact, selectImpact }) => (<>{selectImpact ? <button type='button' className='button-select' onClick={() => deselectedUpdateFunction({ keyImpactSelect: keyImpact })}>{titleText({ titleTranslate: tImpacts(keyImpact) })}</button> : null}</>))
                                }
                            </div>
                            <div className='container-to-select'>
                                <h5>{t('impactToSelectTitle')} :</h5>
                                {
                                    selectedImpactUpdate.findIndex(actived => !actived.selectImpact) === -1
                                        ? <p>{t('notSelectImpactBody')} </p>
                                        : selectedImpactUpdate.map(({ keyImpact, selectImpact }) => (<>{!selectImpact ? <button type='button' className='button-select' onClick={() => selectedUpdateFunction({ keyImpactSelect: keyImpact })}>{titleText({ titleTranslate: tImpacts(keyImpact) })}</button> : null}</>))
                                }
                            </div>
                        </div>

                        <div className='container-buttom-update'>
                            <button type='button' className='button-update' onClick={() => updateActived({ actived: false, dataUpdateImpactRules: { name: name, impacts: selectedImpactUpdate } })}>{t('buttonUpdate')}</button>
                            <button type='button' className='button-cancel' onClick={() => updateActived({ actived: false, dataUpdateImpactRules: dataProps })}>{t('buttonCancel')}</button>
                        </div>
                    </div>
                </div>

                : null
            }
        </>
    )
}
