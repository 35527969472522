import * as XLSX from 'xlsx'

export const generateExcel = (columns: any, data: any, fileType: any, fileName: any) => {
  return new Promise<void>((resolve) => {
    data.unshift(columns.map((i: any) => i.Header)) // Insert the headers
    const worksheet = XLSX.utils.aoa_to_sheet(data)

    var wscols = [{ wch: 16 }, { wch: 30 }, { wch: 20 }, { wch: 50 }, { wch: 20 }]
    worksheet['!cols'] = wscols

    const workbook = XLSX.utils.book_new()
    const sheetName = fileName.split('_')[0]
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName)
    XLSX.writeFile(workbook, `${fileName}.${fileType}`, { compression: true })
    resolve()
  })
}
