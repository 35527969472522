import './ux-settings.scss'

import React, { CSSProperties, HTMLAttributes, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux-hooks'
import { selectSettings, setUXSettings } from '../../../../redux/reducers/settingsSlice'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { LCButton } from '../../../../ui/components/lc-button/LCButton'
import { LCFormInputText } from '../../../../ui/components/forms/lc-form-inputText/lc-form-inputText'
import { isEmpty } from '../../../../utils/object'
import { findObjectDifferences } from '../settingsHelper'
import { IUXSettings } from '@logicalcommander/types'
import { useUpdateUXSettingsMutation } from '../../../../services/settings.service'
import { toast } from 'react-toastify'
import { LCToast } from '../../../../ui/components/lc-toast/Toast'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

export const UXSettings = ({ customStyle }: Props) => {
  const [updateUXSettings, { data: dataUpdate, error: errorUpdate }] = useUpdateUXSettingsMutation()
  const { t } = useTranslation('translation', { keyPrefix: 'settings.uxSettings' })
  const { t: v } = useTranslation('translation', { keyPrefix: 'validation' })
  const uxSettings = useAppSelector(selectSettings).commonUX
  const dispatch = useAppDispatch()

  const initialValues: IUXSettings = uxSettings

  const validationSchema = Yup.object().shape({
    popUpMessageTime: Yup.number()
      .min(4, v('short', { field: t('others.popUpMessageTime'), short: 4, long: 60 }))
      .max(60, v('long', { field: t('others.popUpMessageTime'), short: 4, long: 60 })),

    evaluationMaxDays: Yup.number()
      .min(1, v('short', { field: t('others.evaluationMaxDays'), short: 1, long: 30 }))
      .max(30, v('long', { field: t('others.evaluationMaxDays'), short: 1, long: 30 })),

    dimensions: Yup.object().shape({
      leftMenuWidth: Yup.object().shape({
        opened: Yup.number()
          .min(160, v('short', { field: t('dimensions.leftMenuWidth.opened'), short: 160, long: 300 }))
          .max(300, v('long', { field: t('dimensions.leftMenuWidth.opened'), short: 160, long: 300 })),
        closed: Yup.number()
          .min(60, v('short', { field: t('dimensions.leftMenuWidth.closed'), short: 60, long: 140 }))
          .max(140, v('long', { field: t('dimensions.leftMenuWidth.closed'), short: 60, long: 140 })),
      }),
    }),

    pagination: Yup.object().shape({
      attendance: Yup.number()
        .min(10, v('short', { field: t('pagination.attendance'), short: 10, long: 100 }))
        .max(100, v('long', { field: t('pagination.attendance'), short: 10, long: 100 })),
      cases: Yup.number()
        .min(10, v('short', { field: t('pagination.cases'), short: 10, long: 100 }))
        .max(100, v('long', { field: t('pagination.cases'), short: 10, long: 100 })),
      emoRisk: Yup.number()
        .min(10, v('short', { field: t('pagination.emoRisk'), short: 10, long: 100 }))
        .max(100, v('long', { field: t('pagination.emoRisk'), short: 10, long: 100 })),
      evaluationQuestions: Yup.number()
        .min(10, v('short', { field: t('pagination.evaluationQuestions'), short: 10, long: 100 }))
        .max(100, v('long', { field: t('pagination.evaluationQuestions'), short: 10, long: 100 })),
      riskHr: Yup.number()
        .min(10, v('short', { field: t('pagination.riskHr'), short: 10, long: 100 }))
        .max(100, v('long', { field: t('pagination.riskHr'), short: 10, long: 100 })),
      users: Yup.number()
        .min(10, v('short', { field: t('pagination.users'), short: 10, long: 100 }))
        .max(100, v('long', { field: t('pagination.users'), short: 10, long: 100 })),
    }),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: (values: any) => {
      console.log('onSubmit', values)
      const common: Partial<IUXSettings> = findObjectDifferences<IUXSettings>(initialValues, values)
      console.log('common', common)
      if (isEmpty(common)) {
        toast.info(<LCToast title={v('nothingToUpdate.title')} body={v('nothingToUpdate.body')} />)
      } else {
        updateUXSettings(common)
      }
    },
  })

  useEffect(() => {
    if (!errorUpdate && dataUpdate) {
      toast.success(<LCToast title={t('successUpdatingUxSettings.title')} body={t('successUpdatingUxSettings.body')} />)
      dispatch(setUXSettings({ ...initialValues, ...formik.values }))
    } else if (errorUpdate) {
      toast.error(<LCToast title={t('errorUpdatingUxSettings.title')} body={t('errorUpdatingUxSettings')} />)
    }
  }, [dataUpdate, errorUpdate])

  // console.log('formik.values', formik.values)
  // console.log('formik.errors', formik.errors)
  // console.log('formik.touched', formik.touched)

  return (
    <form className="ux-settings-form" onSubmit={formik.handleSubmit}>
      <div className="settings-content" style={customStyle}>
        <div className="section">
          <h3>{t('pagination.title')}</h3>
          <LCFormInputText
            inputId={'pagination.attendance'}
            inputType="number"
            label={t('pagination.attendance')}
            onChange={formik.handleChange}
            value={formik.values.pagination.attendance}
            error={(formik.errors.pagination as any)?.attendance}
          />

          <LCFormInputText
            inputId={'pagination.cases'}
            inputType="number"
            label={t('pagination.cases')}
            onChange={formik.handleChange}
            value={formik.values.pagination.cases}
            error={(formik.errors.pagination as any)?.cases}
          />

          <LCFormInputText
            inputId={'pagination.emoRisk'}
            inputType="number"
            label={t('pagination.emoRisk')}
            onChange={formik.handleChange}
            value={formik.values.pagination.emoRisk}
            error={(formik.errors.pagination as any)?.emoRisk}
          />

          <LCFormInputText
            inputId={'pagination.evaluationQuestions'}
            inputType="number"
            label={t('pagination.evaluationQuestions')}
            onChange={formik.handleChange}
            value={formik.values.pagination.evaluationQuestions}
            error={(formik.errors.pagination as any)?.evaluationQuestions}
          />

          <LCFormInputText
            inputId={'pagination.riskHr'}
            inputType="number"
            label={t('pagination.riskHr')}
            onChange={formik.handleChange}
            value={formik.values.pagination.riskHr}
            error={(formik.errors.pagination as any)?.riskHr}
          />

          <LCFormInputText
            inputId={'pagination.users'}
            inputType="number"
            label={t('pagination.users')}
            onChange={formik.handleChange}
            value={formik.values.pagination.users}
            error={(formik.errors.pagination as any)?.users}
          />
        </div>

        <div className="section">
          <h3>{t('dimensions.leftMenuWidth.title')}</h3>
          <LCFormInputText
            inputId={'dimensions.leftMenuWidth.opened'}
            inputType="number"
            label={t('dimensions.leftMenuWidth.opened')}
            onChange={formik.handleChange}
            value={formik.values.dimensions.leftMenuWidth.opened}
            error={((formik.errors.dimensions as any)?.leftMenuWidth as any)?.opened}
          />

          <LCFormInputText
            inputId={'dimensions.leftMenuWidth.closed'}
            inputType="number"
            label={t('dimensions.leftMenuWidth.closed')}
            onChange={formik.handleChange}
            value={formik.values.dimensions.leftMenuWidth.closed}
            error={((formik.errors.dimensions as any)?.leftMenuWidth as any)?.closed}
          />
        </div>

        <div className="section">
          <h3>{t('others.title')}</h3>
          <LCFormInputText
            inputId={'popUpMessageTime'}
            inputType="number"
            label={t('others.popUpMessageTime')}
            onChange={formik.handleChange}
            value={formik.values.popUpMessageTime}
            error={formik.errors.popUpMessageTime}
          />

          <LCFormInputText
            inputId={'evaluationMaxDays'}
            inputType="number"
            label={t('others.evaluationMaxDays')}
            onChange={formik.handleChange}
            value={formik.values.evaluationMaxDays}
            error={formik.errors.evaluationMaxDays}
          />
        </div>
      </div>
      <div className="update-button-wrapper">
        <LCButton label={t('updateButton')} type="submit" disabled={!formik.dirty || !formik.isValid} />
      </div>
    </form>
  )
}
