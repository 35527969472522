import './lc-charts.scss'
import { HTMLAttributes, useState, useCallback, SetStateAction } from 'react'
import { CSSProperties } from 'styled-components'
import { BarChartLayout, BarColor, CustomLabels, IPieOptions } from '../../../../interfaces/dashboard.interface'
import { ITitleValueNumber } from '@logicalcommander/types'
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'
import { CustomTooltipPie, RenderActiveShapePie, RenderCustomLabel } from './customComponents/custom-lc-pie-charts'
import { barColors } from './bar-colors'
import LCBarChartNew from './lc-bar-chart'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
  data: ITitleValueNumber[]
  dataTotal?: number
  options: IPieOptions
}

const LcPieChart = ({ options, data, customStyle }: Props) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const onPieEnter = useCallback(
    (_: any, index: SetStateAction<number>) => {
      setActiveIndex(index)
    },
    [setActiveIndex]
  )
  const { color, label } = options

  return (
    <>
      {data.length < 16 ? (
        <div className="chart-widget" style={customStyle}>
          <div className="container-chart">
            <ResponsiveContainer width={'100%'} height="100%">
              <PieChart margin={{ top: 20, right: 0, left: 0, bottom: 20 }}>
                <Pie
                  activeIndex={activeIndex}
                  activeShape={<RenderActiveShapePie index={activeIndex} color={color} />}
                  onMouseEnter={onPieEnter}
                  data={data}
                  cx={'50%'}
                  cy="50%"
                  minAngle={3}
                  innerRadius={'45%'}
                  outerRadius={'60%'}
                  dataKey="value"
                  fontSize={10.5}
                  labelLine={false}
                  label={<RenderCustomLabel color="none" customPieOfPie={false} data={data} labelCustom={label} />}>
                  {data.map((d: any, i: number) => (
                    <Cell key={`cell-${i}`} fill={`${barColors[`${color}`](i)}`} opacity={0.85} stroke={'rgba(244, 247, 254, 0.39)'} />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltipPie labelCustom={label} />} />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      ) : (
        <LCBarChartNew
          data={data}
          // totalData={undefined}
          overFlowData={true}
          options={{
            layout: BarChartLayout.VERTICAL,
            color: BarColor.ALERT,
            label: CustomLabels.TOTAL_DEPARTMENT,
          }}
        />
      )}
    </>
  )
}

export default LcPieChart
