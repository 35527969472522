import React, { CSSProperties, lazy, useEffect, useState, FC, HTMLAttributes } from 'react'
import { sumBy } from 'lodash'
import { BarColor, CustomLabels } from '../../../../../interfaces/dashboard.interface'
import { ITitleValueNumber } from '@logicalcommander/types'
import { useTranslation } from 'react-i18next'
import { BaseDashboardComponent } from '../../../../ui/components/base-dashboard/BaseDashboardComponent'
import { DashboardComponentTitle } from '../../../../ui/components/base-dashboard/DashboardComponentTitle'

const LCPieChartUniNew = lazy(() => import('../../../../ui/components/charts/lc-pie-chart'))

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}
const RiskDashboardHighLevelVsPreventiveLevelTopic: FC<Props> = ({ customStyle }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.riskHr.riskHighLevelVsPreventiveLevelTopic' })

  const isLoading = false
  const error = false

  const data = [
    {
      title: 'Low',
      value: 403950,
      riskPercentage: '0% - 60% Risk Level',
    },
    {
      title: 'Preventive',
      value: 9650,
      riskPercentage: '61% - 80% Risk Level',
    },
    {
      title: 'High',
      value: 33241,
      riskPercentage: '81% - 100% Risk Level',
    },
  ]

  const [riskPreventiveVsHigh, setRiskPreventiveVsHigh] = useState<ITitleValueNumber[]>([])
  const [sumRisk, setSumRisk] = useState(0)
  const sumSorted = sumBy(data, 'value')

  useEffect(() => {
    if (data) {
      const sorted = [...data].filter(sortFilter).map(dataMap)
      setSumRisk(sumSorted)
      setRiskPreventiveVsHigh(sorted)
    }
  }, [])

  const dataMap = (d: ITitleValueNumber) => {
    const data = {
      ...d,
      percentage: (d.value * 100) / sumSorted,
    }
    return data
  }

  const sortFilter = (a: ITitleValueNumber) => {
    return a.title !== 'Low'
  }

  return (
    <BaseDashboardComponent
      customStyle={customStyle}
      sectionTitle={<DashboardComponentTitle title={`${t('title_assessment')} ${t('title_status')}`} subTitle={`${t('total')} ${sumRisk}`} />}
      isLoading={isLoading}
      isSuccess={!!riskPreventiveVsHigh}
      isError={!!error}>
      <LCPieChartUniNew
        data={riskPreventiveVsHigh}
        dataTotal={sumRisk}
        options={{
          color: BarColor.RISK_LEVEL_VS,
          label: CustomLabels.TOPIC_RISK_LEVEL,
          customPercentage: true,
        }}
      />
    </BaseDashboardComponent>
  )
}
export default RiskDashboardHighLevelVsPreventiveLevelTopic
