import './lc-aware-profile-image-upload.scss'
import React, { CSSProperties, FC, ReactElement, useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { LCSvg } from '../../components/lc-svg/lc-svg'
import { LCButton } from '../../components/lc-button/LCButton'
import { IUploadProfileImageRequest } from '../../../../interfaces/user.interfaces'
import {
  useGetOthersProfileImageQuery,
  useUploadOthersProfileImageMutation,
  useUploadTmpProfileImageMutation,
} from '../../../services/user.service'
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons/faTrashCan'

interface Props {
  identity: string
  featchImage?: boolean
  customStyle?: CSSProperties
  disabled?: boolean
  srcURL?: string
  imageUploaded?: (url: string) => string
}

/**
 * When not sending identity, the server will create the image in tmp folder
 * @param param0
 * @returns
 */
export const LCAwareProfileImageUpload: FC<Props> = ({
  customStyle,
  identity,
  featchImage,
  srcURL,
  imageUploaded,
  disabled,
}: Props): ReactElement => {
  const [imageSource, setImageSource] = useState<string | undefined>(srcURL)
  const [uploadOthersImage, { data: uploadData, isLoading: uploadIsLoading, error: uploadError }] = useUploadOthersProfileImageMutation()
  const [uploadTmpImage, { data: tmpData, isLoading: tmpIsLoading, error: tmpError }] = useUploadTmpProfileImageMutation()
  const { data, error, isLoading } = useGetOthersProfileImageQuery(identity ?? '', {
    skip: !identity, // Skip the query if identity is not provided
  })

  const [isError, setIsError] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hasImageLoaded, setHasImageLoaded] = useState(false)

  useEffect(() => {
    console.log('data.url', data?.url)
    if (!error && data && data.url) {
      setImageSource(data.url)
    }
  }, [data, error, isLoading])

  useEffect(() => {
    if (!uploadError && uploadData && uploadData.url) {
      setImageSource(uploadData.url)
    }
  }, [uploadData, uploadIsLoading, uploadError])

  useEffect(() => {
    if (!tmpError && tmpData && tmpData.url) {
      setImageSource(tmpData.url)
      imageUploaded && imageUploaded(tmpData.url)
      console.log('=== Put in profile image before saving', tmpData)
    }
  }, [tmpData, tmpIsLoading, tmpError])

  const onDrop = useCallback(
    (acceptedFiles: any[]) => {
      // console.log(acceptedFiles)
      const file = acceptedFiles[0]
      const uploadData = new FormData()
      uploadData.append('file', file)

      const params: IUploadProfileImageRequest = {
        body: uploadData,
        identity: identity,
      }
      setImageSource(undefined)
      if (identity) {
        uploadOthersImage(params)
      } else {
        uploadTmpImage(uploadData)
      }
    },
    [identity]
  )

  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'image/*': [] },
    maxFiles: 1,
    onDrop,
    noClick: disabled,
    noKeyboard: disabled,
    noDrag: disabled,
  })

  // const imageClass = `lc-aware-image ${hasImageLoaded ? 'fade-in' : ''}`
  const imageClass = `lc-aware-image`

  return (
    <div style={{ position: 'relative' }}>
      <div style={customStyle} className="lc-aware-image-profile-upload-wrapper" {...getRootProps()}>
        {uploadIsLoading || isLoading ? (
          <div className="icon-wrapper">
            <LCSvg name="loader" widthAndHeight={[80, 80]} customStyle={{ color: '#0568ae' }} />
          </div>
        ) : imageSource && !isError ? (
          <div className={`lc-aware-image-wrapper ${disabled ? 'disabled' : ''}`}>
            <img
              className={imageClass}
              src={imageSource}
              height={180}
              alt="Profile"
              onError={() => setIsError(true)}
              onLoad={() => setHasImageLoaded(true)}
            />
          </div>
        ) : (
          <div className="icon-wrapper">
            <LCSvg name="upload" widthAndHeight={[80, 80]} />
            <div className="icon-text">Drag & drop</div>
            <input {...getInputProps()} />
          </div>
        )}
      </div>
      {!disabled && imageSource && !isError ? (
        <div className="lc-aware-buttons">
          <div className="upload-button">
            <LCButton newIcon={faUpload} label="Upload" onClick={() => {}} />
            <input {...getInputProps()} style={{ height: '40px', position: 'absolute', top: 0, opacity: 0 }} />
          </div>
          <LCButton newIcon={faTrashCan} label="Delete" onClick={() => setImageSource(undefined)} style={{ zIndex: 1 }} />
        </div>
      ) : null}
    </div>
  )
}
