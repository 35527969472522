import './lc-form-inputText-calendar.scss'
import { CSSProperties, ReactElement, useState } from 'react'
import Calendar from 'react-calendar'
import { datesHelper } from '../../../../utils/dates'
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons/faCalendarDays'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props<ObjectType> {
  inputId: keyof ObjectType
  label?: string
  value?: Date
  defaultValueWhenOpen?: Date
  required?: boolean
  customStyle?: CSSProperties
  error?: any
  disabled?: boolean
  hideError?: boolean
  onDateSelected?: (date: Date) => void
}

export const LCFormInputTextCalendar = <ObjectType,>({
  label,
  inputId,
  required,
  customStyle,
  error,
  value,
  defaultValueWhenOpen,
  hideError,
  onDateSelected,
  disabled = false,
}: Props<ObjectType>): ReactElement => {
  const [showCalendar, setShowCalendar] = useState<boolean>(false)
  const [dateStringValue, setDateStringValue] = useState<string>(value ? datesHelper.formatShort(value) : '')
  const [dateValue, setDateValue] = useState<Date | undefined>(value)

  const onCalendarSelect = (date: Date): void => {
    setShowCalendar(false)
    setDateValue(date)
    setDateStringValue(datesHelper.formatShort(date))
    onDateSelected && onDateSelected(date)
  }

  const calendar = (): ReactElement => {
    return (
      <div className="lc-calendar-modal">
        <Calendar onChange={(date) => onCalendarSelect(date as Date)} showWeekNumbers value={dateValue || defaultValueWhenOpen} />
      </div>
    )
  }

  return (
    <div className="lc-calendar-Wrapper" style={customStyle}>
      {label && (
        <label className="lc-calendar-label">
          {label} {required ? '*' : ''}
        </label>
      )}
      <div className="lc-calendar-input-wrapper">
        <input
          type="text"
          className={`lc-calendar-input ${disabled ? 'disabled' : ''}`}
          id={inputId as string}
          value={dateStringValue}
          disabled={disabled}
          onChange={(e) => {
            // onTextChanged(e.target.value)
          }}
        />
        {!disabled ? (
          <div className="lc-calendar-input-icon" onClick={() => setShowCalendar(!showCalendar)}>
            <FontAwesomeIcon icon={faCalendarDays} size="lg" />
          </div>
        ) : null}
      </div>
      <div className="lc-calendar-modal-wrapper">{showCalendar ? calendar() : null}</div>
      {hideError && <div className="lc-calendar-error">{error}</div>}
    </div>
  )
}
