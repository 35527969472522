export enum namesPurchase {
  RISK_HR = 'risk-hr',
  EMO_RISK = 'emoRisk',
  INTERNAL_COMPLAINTS = 'internal_complaints',
  SOS = 'sos',
  DOCUMENTS = 'documents',
  PACKAGES_SILVER = 'packages_silver',
  PACKAGES_GOLD = 'packages_gold',
  PACKAGES_PLATINIUM = 'packages_platinium',
}
