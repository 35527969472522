import './page-404.scss'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const Page404: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'auth.page404' })

  return (
    <div className="page-not-found-wrapper">
      <div className="page-not-found">
        <div className="page-not-found-logo">
          <img className="login-logo" src="./../../../../images/ecommander.png" alt="logo" />
        </div>
        <h1>{t('code')}</h1>
        <h2>{t('title')}</h2>
        <p>{t('message')}</p>
        <a href="/app/general-dashboard">{t('linkText')}</a>
      </div>
    </div>
  )
}
