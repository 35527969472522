import { CSSProperties } from 'styled-components'
import './lc-unauthenticted-page.scss'

import React, { ReactNode } from 'react'

interface ILCUnauthenticatedPage {
  title?: string
  className?: string
  children?: ReactNode | ReactNode[]
  customStyle?: CSSProperties
}

export const LCUnauthenticatedPage = ({ title, className, customStyle, children }: ILCUnauthenticatedPage) => {
  return (
    <div className="unauthenticated-page" style={customStyle}>
      <div className="logo-wrapper">
        <img className="logo" src="./../../../../images/ecommander.png" alt="logo" />
      </div>
      <h1>{title}</h1>
      <div className={`unauthenticated-page-content${className ? ' ' + className : ''}`}>{children}</div>
    </div>
  )
}
