import React, { CSSProperties, FC, useEffect, useState } from 'react'
import { DeleteUserModal } from '../deleteUserModal/DeleteUserModal'
import { useTranslation } from 'react-i18next'
import { useDeleteUsersMutation } from '../../../services/users.service'
import { AssignEvaluationModal } from '../../../ui/awareComponents/lc-assign/LCAssignModal'
import { LCButton } from '../../../ui/components/lc-button/LCButton'
import { ILCActionPopupItemData, LCActionPopup } from '../../../ui/components/lc-action-popup/LCActionPopup'
import { LCModal } from '../../../ui/components/lc-modal/LCModal'
import { IAssessmentSchemaInfo } from '@logicalcommander/types'
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons/faTrashCan'
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons/faLayerGroup'
import { IUserSummary } from './users.columns'
import { LCToast } from '../../../ui/components/lc-toast/Toast'
import { toast } from 'react-toastify'

interface Props {
  selectedRows: IUserSummary[]
  onDeleteSuccess?: (identities: string[]) => void
  onAssignSuccess?: (assessmentInfo: IAssessmentSchemaInfo) => void
  customStyle?: CSSProperties
}

export const MultipleUsersActions: FC<Props> = ({ customStyle, selectedRows, onDeleteSuccess, onAssignSuccess }: Props) => {
  const [deleteUsersModalOpen, setDeleteUsersModalOpen] = useState(false)
  const [assignModalOpen, setAssignModalOpen] = useState(false)
  const [deleteUsers, { data, error }] = useDeleteUsersMutation()
  const { t } = useTranslation('translation', { keyPrefix: 'users' })

  const actionPopupData = (): ILCActionPopupItemData[] => {
    return [
      {
        text: t('multiple_actions.assign_evaluation'),
        newIcon: faLayerGroup,
        action: () => {
          setAssignModalOpen(true)
        },
      },
      {
        text: t('multiple_actions.delete_users'),
        newIcon: faTrashCan,
        action: () => {
          setDeleteUsersModalOpen(true)
        },
      },
    ]
  }

  useEffect(() => {
    if (!error && data) {
      toast.success(
        <LCToast
          title={t('notifications.delete_users_success_title')}
          body={t('notifications.delete_users_success_text', { TOTAL_USERS: selectedRows.length })}
        />
      )
      onDeleteSuccess && onDeleteSuccess(data)
    } else if (error) {
      toast.error(
        <LCToast
          title={t('notifications.delete_users_error_title')}
          body={t('notifications.delete_users_error_text', { TOTAL_USERS: selectedRows.length })}
        />
      )
    }
  }, [data, error])

  const doDeleteUsers = (): void => {
    const identities = selectedRows.map((user) => user.identity)
    // console.log('identities', identities)
    deleteUsers(identities)
  }

  return (
    <div className="multiple-actions" style={customStyle}>
      <LCModal
        isOpen={deleteUsersModalOpen}
        title={t('delete_users_modal_title', { TOTAL_USERS: selectedRows.length })}
        onClose={() => setDeleteUsersModalOpen(false)}>
        <DeleteUserModal
          fullName={t('delete_users_modal_text', { TOTAL_USERS: selectedRows.length })}
          onCloseModal={() => setDeleteUsersModalOpen(false)}
          onDeleteUser={() => {
            doDeleteUsers()
            setDeleteUsersModalOpen(false)
          }}
        />
      </LCModal>
      <LCModal isOpen={assignModalOpen} title={t('assign_evaluation_users_title')} onClose={() => setAssignModalOpen(false)}>
        <AssignEvaluationModal
          identities={selectedRows.map((i) => i.identity)}
          onCloseModal={() => {
            setAssignModalOpen(false)
          }}
          onAssign={(assessmentInfo: IAssessmentSchemaInfo) => {
            setAssignModalOpen(false)
            onAssignSuccess && onAssignSuccess(assessmentInfo)
          }}
        />
      </LCModal>
      <LCActionPopup
        data={actionPopupData()}
        anchor={
          <div>
            <LCButton label={t('multiple_actions.label')} newIcon={faBars} />
          </div>
        }
      />
    </div>
  )
}
