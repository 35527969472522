import { ITitleValueString, ISearch } from '@logicalcommander/types'

export interface IDashboardRisksPerDepartment {
  title: string
  value: number
  //  percentage?: number
}
export interface IDashboardProcessTimeEvaluation {
  title: string
  value: number
  processedTime: string
}

export interface IDashboardRiskEmotion {
  title: string
  value1: number
  value2: number
}

export interface IDashboardDataAttendanceBar {
  title: string
  value1?: number
  value2?: number
  value3?: number
  value4?: number
  value5?: number
  Energy?: number
  Happiness?: number
  Anger?: number
  Stress?: number
  Excitement?: number
  Sadness?: number
  MentalLoad?: number
  Discomfort?: number
  ExtremeEmotion?: number
  EmotionalStress?: number
  Dissatisfaction?: number
  Burnout?: number
  Depression?: number
}

export interface IDashboardCharts {
  key: string
  value: any
  chart1: ITitleValueString[]
  chart2: ITitleValueString[]
  chart3: ITitleValueString[]
}

export interface IDashboardRisksPerTopicRequest {
  search?: ISearch[]
}

export interface IDashboardRisksPerDepartmentRequest {
  search?: ISearch[]
}

export interface IDashboardAlertsPercentageMenWomenRequest {
  search?: ISearch[]
}

export interface IDashboardRisksByCitiesRequest {
  country: string
}

export interface IDashboardComponentError {
  title: string
  description: string
}

export interface IDashboardRiskEmotionLevel {
  title: string
  value1: number
  value2: number
}

export enum BarChartLayout {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export enum BarColor {
  ALERT = 'alert',
  COLOR_DEPARTMENT = 'colorDepartment',
  COLOR_POSITION = 'colorPosition',
  COLOR_EMPLOYEES = `colorEmployees`,
  MULTI_DEPARTMENT = 'multiDepartment',
  COLOR_STATUS = 'colorStatus',
  COLOR_WOMEN_MEN = 'colorWomanMen',
  COLOR_LICENSE = 'colorLicense',
  COLOR_RISK_LEVEL = 'riskLevelColor',
  COLOR_RISK_VS_NOT_RISK = 'colorRiskVsNotRisk',
  RISK_LEVEL_VS = 'riskLevelVs',
  COLOR_STATUS_VS = 'colorStatusVs',
  COLOR_STATUS_ASSESMENT_ALL = 'colorStatus',
  EMO_RISK = 'emoRisk',
  EMO_RISK_LEVEL = 'emoRiskLevel',
}
export enum CustomLabels {
  STATUS_EVALUATION_PIE = 'statusEvaluationPie',
  ALERT_EVALUATION_PIE = 'alertEvaluationPie',
  ALERT_RISK_LEVEL = 'alertRiskLevels',
  TOPIC_RISK_LEVEL = 'topicAlertRisk',
  TOTAL_DEPARTMENT = 'totalDepartment',
  TOTAL_POSITION = 'totalPosition',
  ALERT_RISK = 'alertRisk',
  TOTAL_EMPLOYEES = 'totalEmployees',
  ALERT_TOTAL = 'alertTotal',
  EMO_RISK_ATTENDANCE_BAR = 'emoRiskAttendanceBar',
  EMO_RISK_ATTENDANCE_BAR_WOMAN_MEN = 'emoRiskAttendanceWomanMen',
  EMO_RISK_EMOTION_AGE = 'emoRiskEmotionAge',
  TOTAL_POSITION_EMPLOYEES = 'totalPosionEmployes',
  TOTAL_DEPARTAMENTS_EMPLOYEES = 'totalDepartamentsEmployes',
  RISK_HR_All_ASSESSMENTS = 'allAssesmentRiskHr',
}
export enum EmotionRisk {
  ENERGY_RISK = 'energy',
  HAPPINESS_RISK = 'happiness',
  GONNA_RISK = 'gonna',
  STRESS_RISK = 'stress',
  EXCITEMENT_RISK = 'excitement',
  SADNESS_RISK = 'sadness',
  MENTAL_LOAD_RISK = 'mentalLoad',
  DISCOMFORT_RISK = 'discomfort',
  CONFIDENCE_RISK = 'confidence',
  EXTREME_EMOTION_RISK = 'extremeEmotion',
  EMOTIONAL_STRESS_RISK = 'emotionalStress',
  DISSATISFACTION_RISK = 'dissatisfaction',
  BURNOUT_RISK = 'burnout',
  depression_RISK = 'depression',
}

export interface IBarOptions {
  layout: BarChartLayout
  color: BarColor
  label?: CustomLabels
}
export interface IBarEmoOptions {
  color: BarColor
  emotionRisk: string
  label: CustomLabels
}
export interface ILicense {
  color: string
}
export interface IPieOptions {
  color: BarColor
  customPercentage: boolean
  label: CustomLabels
}
