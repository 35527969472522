const cacheKeyPrefix = '[cached-images] '

export const cachedImagesStorage = {
  getImage: (key: string): string | null => {
    return localStorage.getItem(`${cacheKeyPrefix}${key}`)
  },
  clearImages: (): void => {
    cachedImagesStorage.getAllKeysFromLocalStorage().forEach((item) => {
      if (item.indexOf(cacheKeyPrefix) === 0) {
        console.log(`------ clear ${item}`)
        localStorage.setItem(item, '')
      }
    })
  },
  addImage: (key: string, blob: string): void => {
    localStorage.setItem(`${cacheKeyPrefix}${key}`, blob)
  },
  getAllKeysFromLocalStorage: (): string[] => {
    let keys: string[] = []
    for (let i = 0; i < localStorage.length; i++) {
      const s = localStorage.key(i)
      if (s !== null) {
        keys.push(s)
      }
    }
    return keys
  },
}
