import { SVGProps } from 'react'

const SvgFilterAdd = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
    <polygon points="172.557 473.445 266.319 402.257 266.319 288.354 430.386 46.313 219.297 46.313 8.477 46.313 172.557 288.354" />
    <ellipse strokeWidth="9" stroke="#ffffff" paintOrder="fill" cx="353.584" cy="270.641" rx="140" ry="140" />
    <rect x="330.586" y="185.787" width="40" height="160" paintOrder="fill" fill="#ffffff" rx="11.083" ry="11.083" />
    <rect
      x="405.226"
      y="187.921"
      width="40"
      height="160"
      paintOrder="fill"
      fill="#ffffff"
      rx="11.083"
      ry="11.083"
      transform="matrix(0, 1, -1, 0, 619.68396, -158.937515)"
    />
  </svg>
)

export default SvgFilterAdd
