import React, { FC, HTMLAttributes, lazy, useEffect, useState } from 'react'
import { CSSProperties } from 'styled-components'
import { IDashboardRisksPerDepartment, BarColor, CustomLabels } from '../../../../../interfaces/dashboard.interface'
import { useLazyGetRisksPerTopicDepartmentQuery } from '../../../../services/dashboard.service'
import { ISearch, eSearchType } from '@logicalcommander/types'
import { useTranslation } from 'react-i18next'
import { BaseDashboardComponent } from '../../../../ui/components/base-dashboard/BaseDashboardComponent'
import { DashboardComponentTitle } from '../../../../ui/components/base-dashboard/DashboardComponentTitle'

const LCPieChartUniNew = lazy(() => import('../../../../ui/components/charts/lc-pie-chart'))

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

const RiskHrDashboardRiskPieDepartment: FC<Props> = ({ customStyle }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.riskHr.riskPieDepartament' })

  const [getDashboardDepartmentTopic, { data, error, isLoading }] = useLazyGetRisksPerTopicDepartmentQuery()
  const [riskDepartment, setRiskDepartment] = useState<IDashboardRisksPerDepartment[]>([])

  useEffect(() => {
    const search: ISearch[] = [{ keyName: 'aaaa', type: eSearchType.DATE_AFTER, value: '123213' }]
    getDashboardDepartmentTopic({ search })
  }, [])

  useEffect(() => {
    if (data && !error) {
      const sorted = [...data].sort(sortDepartmentRisk)
      setRiskDepartment(sorted)
    }
  }, [data, error])

  const sortDepartmentRisk = (a: IDashboardRisksPerDepartment, b: IDashboardRisksPerDepartment) => {
    return b.value - a.value
  }

  return (
    <BaseDashboardComponent customStyle={customStyle} sectionTitle={<DashboardComponentTitle title={t('title')} />} isLoading={isLoading} isSuccess={riskDepartment.length > 0} isError={!!error}>
      <LCPieChartUniNew
        data={riskDepartment}
        dataTotal={0}
        options={{
          color: BarColor.MULTI_DEPARTMENT,
          label: CustomLabels.ALERT_EVALUATION_PIE,
          customPercentage: false,
        }}
      />
    </BaseDashboardComponent>
  )
}

export default RiskHrDashboardRiskPieDepartment
