import './settings-riskhr-view.scss'
import React, { CSSProperties, HTMLAttributes, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../../../redux/hooks'
import { IDropdownOption, LCDropdown } from '../../../../../ui/components/lc-dropdown/LCDropdown'
import { IAssessmentAugmantinTopic, eAssessmentSizeId, eAssessmentTypeId } from '@logicalcommander/types'
import { useLazyGetAssessmentAugmantinQuery } from '../../../../../services/assessments.service'
import { selectSettings } from '../../../../../redux/reducers/settingsSlice'
import {
  getAssessmentsIdByTypeIdAndNameId,
  getAssessmentsNamesDropdownItems,
  getAssessmentsTypesDropdownItems,
} from './settings-riskhr-helper'
import { LCSvg } from '../../../../../ui/components/lc-svg/lc-svg'
import { LCRadioButton, eLCRadioButtonLayout } from '../../../../../ui/components/lc-radio-button/LCRadioButton'
import { LCShow } from '../../../../../ui/components/lc-show/LCShow'
import { LCLanguagesSelection } from '../../../../../ui/components/lc-languages-selection/LCLanguagesSelection'
import { LCToast } from '../../../../../ui/components/lc-toast/Toast'
import { toast } from 'react-toastify'

interface IPreviousAction {
  action: 'size' | 'type' | 'name'
  value: number
}

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

export const SettingsRiskHrView = ({ customStyle }: Props) => {
  const [getAssessmentData, { data, error }] = useLazyGetAssessmentAugmantinQuery()
  const [loading, setLoading] = useState(false)
  const { assessmentsSizes } = useAppSelector(selectSettings)
  // console.log('[SettingsReiskHrView] assessmentsSizes', assessmentsSizes)

  const { t: s } = useTranslation('translation', { keyPrefix: 'settings.assessments' })
  const { t } = useTranslation('translation', { keyPrefix: 'settings.riskHr' })

  const [selectedSizeId, setSelectedSizeId] = useState<number>(eAssessmentSizeId.DEMO)
  const [selectedTypeId, setSelectedTypeId] = useState<number>(eAssessmentTypeId.PERIODIC)
  const [selectedNameId, setSelectedNameId] = useState<number>(1000)
  const [selectedAssessmentId, setSelectedAssessmentId] = useState<number>()
  const [selectedLanguage, setSelectedLanguage] = useState<string>('en')
  const [previousAction, setPreviousAction] = useState<IPreviousAction>()

  // Prepare the dropdowns data
  const [assessmentsTypesDropdown, setAssessmentsTypesDropdown] = useState<IDropdownOption[]>()
  const [assessmentNamesDropdown, setAssessmentNamesDropdown] = useState<IDropdownOption[]>()

  const sizesRadioOptions = [
    { label: s('sizes.3'), value: '3' },
    { label: s('sizes.1'), value: '1' },
    { label: s('sizes.2'), value: '2' },
  ]

  const [selectedTopics, setSelectedTopics] = useState<IAssessmentAugmantinTopic[]>([])
  const [hideTypes, setHideTypes] = useState<boolean>()

  // console.log('[SettingsReiskHrView] selectedSizeId', selectedSizeId)
  // console.log('[SettingsReiskHrView] selectedTypeId', selectedTypeId)
  // console.log('[SettingsReiskHrView] selectedNameId', selectedNameId)
  // console.log('[SettingsReiskHrView] selectedAssessmentId', selectedAssessmentId)

  // Update the typeId dropdown when selectedSizeId changed.
  useEffect(() => {
    if (selectedSizeId) {
      const _types: IDropdownOption[] = getAssessmentsTypesDropdownItems(assessmentsSizes, selectedSizeId, s)
      if (_types.length === 0) {
        setHideTypes(true)
        return
      }
      setHideTypes(false)
      _types && setAssessmentsTypesDropdown(_types)
      setSelectedTypeId(+_types[0].value)
    }
  }, [selectedSizeId])

  // Update the selectedNameId when selectedTypeId changed.
  useEffect(() => {
    if (selectedTypeId) {
      const _names = getAssessmentsNamesDropdownItems(assessmentsSizes, selectedSizeId, selectedTypeId, s)
      _names && setAssessmentNamesDropdown(_names)
      setSelectedNameId(+_names[0].value)
    }
  }, [selectedTypeId, selectedSizeId])

  // Update the selectedAssessmentId when selectedNameId is changed.
  useEffect(() => {
    if (selectedNameId) {
      const _selectedAssessmentId = getAssessmentsIdByTypeIdAndNameId(assessmentsSizes, selectedSizeId, selectedTypeId, selectedNameId)
      _selectedAssessmentId && setSelectedAssessmentId(_selectedAssessmentId)
    }
  }, [selectedNameId, selectedSizeId, selectedTypeId])

  // Call the server to get the assessments topics and questions according to selectedAssessmentId.
  useEffect(() => {
    if (selectedAssessmentId && selectedLanguage) {
      setLoading(true) // Manually set loading to true before fetching
      getAssessmentData({ assessmentId: +selectedAssessmentId, language: selectedLanguage })
        .unwrap()
        .then(() => setLoading(false)) // Manually set loading to false after fetching
        .catch(() => setLoading(false)) // Ensure loading is set to false even if there's an error
    }
  }, [selectedAssessmentId, selectedLanguage, getAssessmentData])

  // Update the selected topics
  useEffect(() => {
    if (!error && data) {
      setSelectedTopics(data.topics)
    } else if (error) {
      toast.error(<LCToast title={t('errorGettingAssessment.title')} body={t('errorGettingAssessment.body')} />)

      switch (previousAction?.action) {
        case 'size':
          setSelectedSizeId(previousAction.value)
          break
        case 'type':
          setSelectedTypeId(previousAction.value)
          break
        case 'name':
          setSelectedNameId(previousAction.value)
          break
      }
    }
  }, [data, error])

  const onSelectedAssesstmentSizeChanged = (selectedValue: string) => {
    setPreviousAction({ action: 'size', value: selectedSizeId })
    setSelectedSizeId(+selectedValue)
  }

  const onSelectedAssesstmentTypeChanged = (event: any) => {
    setPreviousAction({ action: 'type', value: selectedTypeId })
    event?.value && setSelectedTypeId(event.value)
  }

  const onSelectedAssesstmentNameChanged = (event: any) => {
    setPreviousAction({ action: 'name', value: selectedNameId })
    event?.value && setSelectedNameId(event.value)
  }

  const onSelectedAssesstmentLanguageChanged = (event: any) => {
    event?.value && setSelectedLanguage(event.value)
  }

  // console.log('[SettingsReiskHrView] assessmentNamesDropdown', assessmentNamesDropdown, selectedNameId)
  const selectedNameValue = assessmentNamesDropdown?.find((i) => i.value === selectedNameId + '')?.label || s(`names.${selectedNameId}`)

  return (
    <div className="settings-riskhr-container" style={customStyle}>
      <h3>{t('title')}</h3>
      <div className="select-role">
        {t('selectAssessmentSize')}
        <LCRadioButton
          selected={selectedSizeId + ''}
          layout={eLCRadioButtonLayout.HORIZONTAL}
          options={sizesRadioOptions}
          name="assessmentSize"
          onChange={onSelectedAssesstmentSizeChanged}
        />
      </div>

      <LCShow show={hideTypes}>
        <div className="description">{t('noAssessmentsForThisLicenseSize')}</div>
      </LCShow>

      <LCShow show={!hideTypes}>
        <div className="select-role">
          {t('selectAssessmentType')}
          {assessmentsTypesDropdown ? (
            <LCDropdown
              options={assessmentsTypesDropdown}
              defaultOption={selectedTypeId + ''}
              onChange={onSelectedAssesstmentTypeChanged}
            />
          ) : null}
          {assessmentNamesDropdown ? (
            <LCDropdown options={assessmentNamesDropdown} defaultOption={selectedNameId + ''} onChange={onSelectedAssesstmentNameChanged} />
          ) : null}
          <LCLanguagesSelection languageSelected={onSelectedAssesstmentLanguageChanged} defaultLanguage={selectedLanguage} />
        </div>

        <div className="description">{t('description')}</div>

        <div className="assessment-details">
          <div className="view-assessment">
            <h3 className="selected-assessment">{s(`types.${selectedTypeId}`) + ' ➙ ' + selectedNameValue}</h3>
          </div>
          {loading ? (
            <div className="loader">
              <LCSvg name="loader" widthAndHeight={[100, 100]} customStyle={{ color: '#0568ae' }} />
            </div>
          ) : (
            selectedTopics.map((topic: IAssessmentAugmantinTopic) => {
              return (
                <div key={topic.id}>
                  <div className="topic-text">{topic.text}</div>
                  <ul className="questions-wrapper">
                    {topic.questions.map((question) => (
                      <li key={question.id}>{question.text}</li>
                    ))}
                  </ul>
                </div>
              )
            })
          )}
        </div>
      </LCShow>
    </div>
  )
}
