import React, { FC, HTMLAttributes, ReactElement } from 'react'
import { CSSProperties } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { BaseDashboardComponent } from '../../../ui/components/base-dashboard/BaseDashboardComponent'
import { useAppSelector } from '../../../redux/hooks'
import { selectExtendedCompany } from '../../../redux/reducers/extendedCompanySlice'
import { ResponsiveContainer, PieChart, Pie, Cell, Label, Legend } from 'recharts'
import { IRiskHRLicenses } from '@logicalcommander/types'
import { DashboardComponentTitle } from '../../../ui/components/base-dashboard/DashboardComponentTitle'
import { LicenseCustomLabel, LicenseCustomizedLegend } from './helpers/licenses.helper'

interface IPieDetails {
  name: string
  total: number
  used: number
  data: IPieData[]
}

interface IPieData {
  name: string
  value: number
}

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

const RiskHrLicenses: FC<Props> = ({ customStyle }: Props) => {
  const { licenses, licensesUsed } = useAppSelector(selectExtendedCompany)
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.licenses.riskHR' })

  const pieDetailsArray: IPieDetails[] = Object.keys(licenses.riskHRLicenses).map((name: string) => {
    const license: number = licenses.riskHRLicenses[name as keyof IRiskHRLicenses] || 0
    const used = licensesUsed.riskHRLicenses[name as keyof IRiskHRLicenses] || 0
    return {
      name,
      total: license,
      used,
      data: [
        { name: t('remain'), value: license - used },
        { name: t('used'), value: used },
      ],
    }
  })

  console.log(pieDetailsArray)

  const COLORS = ['#02A8FF', '#FBB03B', '#80D4FF', '#0088FE', '#00C49F', '#FFBB28', '#FF8042']

  const LicenesBarChart = ({ details, index }: { details: IPieDetails; index: number }): ReactElement => {
    return (
      <ResponsiveContainer width="33%" key={index}>
        <PieChart margin={{ top: 0 }}>
          <Pie data={details.data} dataKey="value" cx={'50%'} cy={'50%'} innerRadius={60} outerRadius={80}>
            {details.data.map((pieData: IPieData, index: number) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
            <Label
              content={<LicenseCustomLabel labelText={t(`types.${details.name}`)} value={`${details.used} / ${details.total}`} />}
              position="center"
            />
          </Pie>
          <Legend layout="horizontal" verticalAlign="top" align="center" content={LicenseCustomizedLegend} />
        </PieChart>
      </ResponsiveContainer>
    )
  }

  return (
    <BaseDashboardComponent
      customStyle={customStyle}
      sectionTitle={<DashboardComponentTitle title={t('title')} />}
      isLoading={false}
      isSuccess={!!pieDetailsArray}
      isError={!pieDetailsArray}>
      {pieDetailsArray?.map((details, index) => <LicenesBarChart key={index} details={details} index={index} />)}
    </BaseDashboardComponent>
  )
}

export default RiskHrLicenses
