import { sum } from 'lodash'
import { FC, HTMLAttributes, useState } from 'react'
import { Bar, ComposedChart, Label, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis, Text } from 'recharts'
import { CSSProperties } from 'styled-components'
import { barColors } from './bar-colors'
import { customLabel } from './custom-labels'
import './lc-charts.scss'
import React from 'react'
import { IDashboardDataAttendanceBar, IBarEmoOptions } from '../../../../interfaces/dashboard.interface'
import { calculateFontSize } from './customComponents/helpers-lc-pie-charts'

const filterKeyTitle = (a: any) => a !== 'title'
interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
  data: IDashboardDataAttendanceBar[]
  options: IBarEmoOptions
}
const dataMapPercentage = (a: string) => `percent${a}`

const dataFormatter = (tick: number) => {
  if (tick >= 99) return '100%'
  else if (tick === 75) return '75%'
  else if (tick === 50) return '50%'
  else if (tick === 25) return '25%'
  else if (tick === 0) return '0%'
  else return ''
}
const CustomLabelBar = (props: any) => {
  const { data, index, keyLabel, x, y, width, height } = props
  const percent = data[index][`${keyLabel}`]?.toFixed(1)
  const fontSize = calculateFontSize({ inputPercentage: percent, maxFontSize: 11, maxPercentage: 10, minFontSize: 5, minPercentage: 1 })

  return (
    <g>
      <Text x={x + width / 2} y={y + height / 2} fontSize={fontSize} fill={'#FFFFFF'} textAnchor="middle">
        {`${percent}%`}
      </Text>
    </g>
  )
}
const CustomTooltip = (props: any) => {
  const { active, label, payload, keys, separator, barLegendStatus } = props
  const dataValue = payload.length > 0 ? Object.values(payload[0]?.payload).filter((a) => typeof a !== 'string') : [0]
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="custom-label">{label}</p>
        {keys.map((key: any, index: number) => {
          const percentageName = payload[0].payload[`percent${key.name}`].toFixed(2)
          return (
            <p className="custom-text-tooltip" hidden={barLegendStatus[`${key.value}`] === true} style={{ color: `${key.color}` }} key={`key-text-tooltip${index}`}>
              {`${key.value}${separator}${dataValue[index]} (${percentageName}%)`}
            </p>
          )
        })}
      </div>
    )
  }
  return <></>
}

export const LCBarAttendanceNew: FC<Props> = ({ customStyle, data, options }: Props) => {
  const { color, label } = options

  const mapKey = (a: any, i: number) => {
    const data = {
      inactive: false,
      name: a,
      value: customLabel[`${label}`](i),
      color: barColors[`${color}`](i),
    }
    return data
  }
  const nameKeys = Object.keys(data[0]).filter(filterKeyTitle).map(mapKey)
  const [dataLegend, setDataLegend] = useState(nameKeys)

  const [barLegendStatus, setBarLegendStatus] = useState(
    nameKeys.reduce((a: any, { value, name }: any) => {
      a[value] = false
      a[name] = false
      return a
    }, {})
  )

  const selectBar = ({ value }: any) => {
    const dataActive = dataLegend.map((d) => {
      const active = {
        ...d,
        inactive: d.value === value ? !d.inactive : d.inactive,
      }
      return active
    })
    setBarLegendStatus({
      ...barLegendStatus,
      [value]: !barLegendStatus[value],
      hover: null,
    })
    setDataLegend(dataActive)
  }

  const dataPercentege = data.map((d: any) => {
    const keysObjectPencent = Object.keys(d).filter(filterKeyTitle).map(dataMapPercentage)
    const keysObject = Object.keys(d).filter(filterKeyTitle)
    const values = sum(Object.values(d).filter((a) => typeof a !== 'string'))
    const dataTrasnformPercentage: any = keysObjectPencent.reduce((a: any, value: any, i) => {
      a[value] = (d[`${keysObject[i]}`] * 100) / values
      return a
    }, {})
    const data = { ...d, ...dataTrasnformPercentage }
    return data
  })

  return (
    <div className="chart-widget" style={customStyle}>
      <div className="container-chart">
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={dataPercentege}
            margin={{
              top: 20,
              right: 20,
              bottom: 10,
              left: 20,
            }}>
            <XAxis dataKey="title" allowDataOverflow={true} interval={0} dy={5} angle={340} fontSize="80%" />
            <YAxis tickFormatter={dataFormatter} domain={[0, 100]} tickCount={5.5} padding={{ top: 10 }}>
              <Label value="Alert Month" offset={0} position="insideLeft" angle={270} dx={-10} dy={30} />
            </YAxis>
            -
            <Tooltip content={<CustomTooltip keys={nameKeys} barLegendStatus={barLegendStatus} />} />
            <Legend onClick={selectBar} verticalAlign="bottom" height={36} align="center" payload={dataLegend} />
            {nameKeys.map(({ name, value }, index) => {
              return (
                <Bar
                  key={`barAttendance${index}`}
                  hide={barLegendStatus[`${value}`] === true}
                  dataKey={`percent${name}`}
                  maxBarSize={30}
                  label={<CustomLabelBar data={dataPercentege} keyLabel={`percent${name}`} />}
                  fill={barColors[`${color}`](index)}
                  stackId="EmotionRisk"
                />
              )
            })}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
