import './invite-people-dialog.scss'
import React, { FC, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useAppSelector } from '../../../../../../redux/hooks'
import { selectAllRoles, selectSettings } from '../../../../../../redux/reducers/settingsSlice'
import { LCFormInputText } from '../../../../../../ui/components/forms/lc-form-inputText/lc-form-inputText'
import { LCFormDropdownDynamic } from '../../../../../../ui/components/forms/lc-form-dropdown-dynamic/lc-form-dropdown-dynamic'
import { LCButton } from '../../../../../../ui/components/lc-button/LCButton'
import { LCDialog } from '../../../../../../ui/components/lc-dialog/LCDialog'
import { SanitizeHTML } from '../../../../../../ui/components/lc-sanitize-html/SanitizeHTML'
import deepEqual from 'deep-equal'
import { getAllRolesDropdown } from '../../../../../../utils/list-helper'

interface Props {
  togglerText: string
}

export const InvitePeopleDialog: FC<Props> = ({ togglerText }: Props): ReactElement => {
  const { t } = useTranslation('translation', { keyPrefix: 'settings.admins.invite-modal' })
  const { t: v } = useTranslation('translation', { keyPrefix: 'validation' })

  const { companyInfo } = useAppSelector(selectSettings)
  const [isValidForm, setIsValidForm] = useState(false)
  const roles = useAppSelector(selectAllRoles)

  const initialValues = {
    email: '',
    role: '',
  }

  const validationRules = {
    email: Yup.string()
      .required(v('required', { field: t('email') }))
      .email(v('valid', { field: t('email') }))
      .min(6, v('short', { field: t('email'), short: 6, long: 50 }))
      .max(50, v('long', { field: t('email'), short: 6, long: 50 })),
    role: Yup.string().required(v('required', { field: t('role') })),
  }

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object(validationRules),
    onSubmit: (values: any) => {
      alert('Need to implement invite')
      //login({ companyId: values.companyId, email: values.email, password: values.password })
    },
  })

  useEffect(() => {
    const touched = !deepEqual(formik.values, initialValues)
    setIsValidForm(touched && formik.isValid)
  }, [formik.isValid, formik.touched, formik.values])

  console.log('formik.values', formik.values)

  return (
    <LCDialog className="invite-people-dialog" toggler={togglerText} title={t('title', { companyName: companyInfo.name })}>
      <div className="description">
        <SanitizeHTML html={t('description', { hours: 48 })} />
      </div>

      <form className="body" onSubmit={formik.handleSubmit}>
        <LCFormInputText<any>
          label={t('email')}
          required={true}
          inputId="email"
          inputType="email"
          disabled={false}
          error={formik.errors.email}
          {...formik.getFieldProps('email')}
        />

        <LCFormDropdownDynamic
          required={false}
          label={t('role')}
          inputId="role"
          selectedValue={formik.values?.role}
          disabled={false}
          setFieldValue={formik.setFieldValue}
          options={getAllRolesDropdown(roles)}
          error={formik.errors.role}
        />

        <div className="buttons">
          <LCButton label={t('invite-button')} type="submit" disabled={!isValidForm} />
        </div>
      </form>
    </LCDialog>
  )
}
