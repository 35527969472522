import { FC, HTMLAttributes, useEffect, useState } from 'react'
import { CSSProperties } from 'styled-components'
import { sumBy } from 'lodash'
import { BarChartLayout, BarColor, CustomLabels } from '../../../../../interfaces/dashboard.interface'
import { ITitleValueNumber } from '@logicalcommander/types'
import LCBarChartNew from '../../../../ui/components/charts/lc-bar-chart'
import { useTranslation } from 'react-i18next'
import { BaseDashboardComponent } from '../../../../ui/components/base-dashboard/BaseDashboardComponent'
import { DashboardComponentTitle } from '../../../../ui/components/base-dashboard/DashboardComponentTitle'
import React from 'react'
import { toast } from 'react-toastify'
import { LCToast } from '../../../../ui/components/lc-toast/Toast'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

const RiskDashboardAllAssessments: FC<Props> = ({ customStyle }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.riskHr.allAssessments' })
  const isLoading = false
  const error = false

  const data = [
    {
      title: 'Periodic',
      value: 2001,
    },
    {
      title: 'Pre-employement',
      value: 8200,
    },
    {
      title: 'Short ',
      value: 1000,
    },
  ]

  const [assesmentAllRisk, setAssesmentAll] = useState<ITitleValueNumber[]>([])
  const [dataAllAssesmentSum, setDataAllAssesmentSum] = useState<number>(0)
  useEffect(() => {
    if (data) {
      const sorted = [...data].sort(sortPositionRisk)
      const sortedSum = sumBy(sorted, 'value')
      setDataAllAssesmentSum(sortedSum)
      setAssesmentAll(sorted)
    } else if (true) {
      toast.error(<LCToast title={t('get_all_assessment_error_title')} body={t('get_all_assessment_error_text')} />)
    }
  }, [])

  const sortPositionRisk = (a: ITitleValueNumber, b: ITitleValueNumber) => {
    return b.value - a.value
  }

  return (
    <BaseDashboardComponent
      customStyle={customStyle}
      sectionTitle={<DashboardComponentTitle title={t('title')} subTitle={`${t('total')} ${dataAllAssesmentSum}`} />}
      isLoading={isLoading}
      isSuccess={assesmentAllRisk.length > 0}
      isError={!!error}>
      <LCBarChartNew
        data={assesmentAllRisk}
        legendCustom={false}
        options={{
          layout: BarChartLayout.VERTICAL,
          color: BarColor.COLOR_STATUS_ASSESMENT_ALL,
          label: CustomLabels.RISK_HR_All_ASSESSMENTS,
        }}
      />
    </BaseDashboardComponent>
  )
}

export default RiskDashboardAllAssessments
