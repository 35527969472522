import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { v4 as uuidv4 } from 'uuid'
import { RootState } from '../redux/store'
import { configManager } from '../../config/configManager'
import { IRolesAndPermissions } from '@logicalcommander/types'

export const rolesApi = createApi({
  reducerPath: 'rolesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${configManager.api.GATEWAY_URL}/role`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('x-request-id', uuidv4())
      const { token, companyToken } = (getState() as RootState).authReducer
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      if (companyToken) {
        headers.set('x-company-token', companyToken)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    getRolesAndPermissions: builder.query<IRolesAndPermissions[], void>({
      query: () => {
        return {
          url: 'all',
        }
      },
    }),
    getPermissionsByRole: builder.query<any, { roleName: string }>({
      query: ({ roleName }) => {
        return {
          url: `permissions/${roleName}`,
        }
      },
    }),
  }),
})

export const { useLazyGetRolesAndPermissionsQuery, useLazyGetPermissionsByRoleQuery } = rolesApi
