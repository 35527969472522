import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { v4 as uuidv4 } from 'uuid'
import { RootState } from '../redux/store'
import {
  IDashboardRisksPerTopicRequest,
  IDashboardRisksPerDepartmentRequest,
  IDashboardAlertsPercentageMenWomenRequest,
  IDashboardRisksPerDepartment,
  IDashboardRisksByCitiesRequest,
} from '../../interfaces/dashboard.interface'
import {
  ITitleValueNumber,
  IDashboardWomanVsMen,
  IDashboardLicenses,
  ITitleValueString,
  IRiskHRRiskLevel,
  ICompanyInfo,
} from '@logicalcommander/types'
import { configManager } from '../../config/configManager'

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${configManager.api.GATEWAY_URL}/dashboard`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('x-request-id', uuidv4())
      const { token, companyToken } = (getState() as RootState).authReducer
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      if (companyToken) {
        headers.set('x-company-token', companyToken)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    getRisksPerTopic: builder.query<ITitleValueNumber[], IDashboardRisksPerTopicRequest>({
      query: () => ({
        url: '/risks-per-topic',
        method: 'GET',
      }),
    }),
    // getDashboardData: builder.query<IDashboardCharts, IDashboardRisksPerTopicRequest>({
    //   query: () => ({
    //     url: '/dashboard-data',
    //     method: 'GET',
    //   }),
    // }),
    getRisksPerTopicDepartment: builder.query<IDashboardRisksPerDepartment[], IDashboardRisksPerDepartmentRequest>({
      query: () => ({
        url: '/risks-per-department',
        method: 'GET',
      }),
    }),
    getAlertsPercentageMenWomen: builder.query<IDashboardWomanVsMen[], IDashboardAlertsPercentageMenWomenRequest>({
      query: () => ({
        url: '/alerts-percentage-men-women',
        method: 'GET',
      }),
    }),
    getLicenses: builder.query<IDashboardLicenses, void>({
      query: () => ({
        url: '/licenses',
      }),
    }),
    getTotalMenVsWomen: builder.query<IDashboardWomanVsMen[], void>({
      query: () => ({
        url: '/total/men-women',
      }),
    }),
    getRisksByCountries: builder.query<ITitleValueNumber[], void>({
      query: () => ({
        url: '/risks-by-countries',
      }),
    }),
    getRisksByCities: builder.query<ITitleValueNumber[], IDashboardRisksByCitiesRequest>({
      query: ({ country }) => ({
        url: `/risks-by-cities/${country}`,
      }),
    }),
    getDetailedCompanyInformation: builder.query<ICompanyInfo, void>({
      query: () => ({
        url: '/detailed-company-information',
      }),
    }),
    getEvaluationStatus: builder.query<ITitleValueNumber[], void>({
      query: () => ({
        url: '/evaluation-status',
      }),
    }),
    getRiskHrAverageState: builder.query<ITitleValueString[], void>({
      query: () => ({
        url: '/riskhr-average-state',
      }),
    }),
    getRiskHrRiskLevel: builder.query<IRiskHRRiskLevel, void>({
      query: () => ({
        url: '/riskhr-risk-level',
      }),
    }),
    getRiskHrTopicRisk: builder.query<IRiskHRRiskLevel, void>({
      query: () => ({
        url: '/riskhr-topic-risk',
      }),
    }),
  }),
})

export const {
  useLazyGetRisksPerTopicQuery,
  useLazyGetAlertsPercentageMenWomenQuery,
  useLazyGetRisksPerTopicDepartmentQuery,
  useLazyGetLicensesQuery,
  useLazyGetTotalMenVsWomenQuery,
  useLazyGetRisksByCountriesQuery,
  useLazyGetRisksByCitiesQuery,
  useLazyGetDetailedCompanyInformationQuery,
  useLazyGetEvaluationStatusQuery,
  useLazyGetRiskHrAverageStateQuery,
  useLazyGetRiskHrRiskLevelQuery,
  useLazyGetRiskHrTopicRiskQuery,
} = dashboardApi
