import './emo-results-summary.scss'
import React, { CSSProperties, FC, HTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { emo_results_columns } from './emoResultsSummary.columns'
import { useTranslation } from 'react-i18next'
import deepEqual from 'deep-equal'
import { IEmoResultsSummary } from '../../../../interfaces/emo.interface'
import { useAppSelector } from '../../../hooks/redux-hooks'
import { selectSettings } from '../../../redux/reducers/settingsSlice'
import { useGetEmoResultsSummaryPaginationMutation } from '../../../services/emo.service'
import { LCTableServerPagination } from '../../../ui/components/lc-table/LCTableServerPagination'
import { ISearch, ISort, IPaginationRequest, IPaginationResponse, IPaginationSettings, eTableName } from '@logicalcommander/types'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  showTableActions?: boolean
  customStyle?: CSSProperties
  filters?: ISearch[]
  onSelectedRows?: (rows: IEmoResultsSummary[]) => void
}

export const EmoResultsSummary: FC<Props> = ({ customStyle, filters: filtersInit, showTableActions = false, onSelectedRows }: Props) => {
  const paginationSettings: IPaginationSettings = useAppSelector(selectSettings).commonUX.pagination
  const [getEmoResultsSummaryPagination, { data, error, isLoading }] = useGetEmoResultsSummaryPaginationMutation()
  const [emoResultsSummary, setEmoResultsSummary] = useState<IEmoResultsSummary[]>([])
  const [pageCount, setPageCount] = useState(0) // Number of pages
  const [filters, setFilters] = useState<ISearch[]>(filtersInit || [])
  // eslint-disable-next-line
  const [selectedRows, setSelectedRows] = useState<IEmoResultsSummary[]>([])
  const [pageSize, setPageSize] = useState(paginationSettings.emoRisk) // Page size
  const [sortBy, setSortBy] = useState<ISort[]>([]) // Sort by
  const [pageIndex, setPageIndex] = useState(0) // Current page index
  const navigate = useNavigate()
  const { t } = useTranslation('translation')

  useEffect(() => {
    if (!error && data) {
      setEmoResultsSummary(data.data)
    }
  }, [data, error])

  useEffect(() => {
    console.log('EmoResultsSummary constructor')
  }, [])

  useEffect(() => {
    onFiltersChanged(filtersInit || [])
  }, [filtersInit])

  const fetchData = useCallback(({ pageSize, pageIndex, sortBy, search }: IPaginationRequest) => {
    getEmoResultsSummaryPagination({ pageIndex, pageSize, sortBy, search })
      .then((result: any) => {
        const resData: IPaginationResponse<IEmoResultsSummary[]> = result.data
        setPageCount(Math.ceil(resData.totalSize / pageSize))
        setPageIndex(pageIndex)
        setPageSize(pageSize)
        sortBy && setSortBy(sortBy)
      })
      .catch((err) => {
        console.log('ERROR: ', err)
      })
  }, [])

  const onFiltersChanged = (_filters: ISearch[]) => {
    console.log('onFiltersChanged', _filters)
    if (!deepEqual(filters, _filters) && _filters.length > 0) {
      setFilters(_filters)
      fetchData({ pageIndex, pageSize, sortBy, search: _filters })
    }
  }

  const columns = useMemo(() => emo_results_columns(navigate, t), [pageIndex])

  return (
    <div style={customStyle}>
      <LCTableServerPagination
        tableName={eTableName.EMO_RESULTS_SUMMARY_TABLE}
        showCheckbox={true}
        columns={columns}
        data={emoResultsSummary}
        fetchData={fetchData}
        loading={isLoading}
        pageCount={pageCount}
        onSelectRows={(rows: IEmoResultsSummary[]) => setSelectedRows(rows)}
        initPageSize={pageSize}
        showTableActions={showTableActions}
      />
    </div>
  )
}
