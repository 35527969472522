import './header.scss'

import React, { FC } from 'react'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { selectAuth } from '../../redux/reducers/authSlice'
import { LCNotifications } from '../../ui/awareComponents/lc-notifications/LCNotifications'
import { LCAwareSignOutMenu } from '../../ui/awareComponents/lc-sign-out-menu/LCAwareSignoutMenu'
import { changeLanguage } from '../../redux/reducers/generalSlice'
import { LCLanguagesSelection } from '../../ui/components/lc-languages-selection/LCLanguagesSelection'

export const Header: FC = () => {
  const auth = useAppSelector(selectAuth)
  const dispatch = useAppDispatch()

  const doChangeLanguage = (language: string) => {
    dispatch(changeLanguage(language))
  }

  return (
    <header className="header">
      <div className="left-block"></div>
      <div className="right-block">
        <LCLanguagesSelection languageSelected={doChangeLanguage} />
        <div className="notifications">
          <LCNotifications />
        </div>
        {auth.token && <div className="sign-out-button">{<LCAwareSignOutMenu />}</div>}
      </div>
    </header>
  )
}
