import { eCountries } from '@logicalcommander/types'

export const padNumberLeadingZero = (num: number, size: number) => {
  var s = '0000000' + num
  return s.substr(s.length - size)
}

export const roundNumberDigitsAddPercentage = (num: number): string => {
  return `${(Math.round(num) * 100) / 100} %`
}

export const objectExists = (arrayOfObjects: any[], key: string, value: string): boolean => {
  return arrayOfObjects.some((obj) => obj.key === key && obj.value === value)
}

export const removeObjectByKeyValuePair = <T>(arr: T[], keyToDelete: keyof T, valueToDelete: T[keyof T]): T[] => {
  return [...arr.filter((obj) => obj[keyToDelete] !== valueToDelete)]
}

export const capitalizeFirstLetter = (str: string): string => {
  if (!str) return str
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const getCountryNameByCode = (code: string): string | undefined => {
  const countryEntries = Object.entries(eCountries)

  for (const [countryName, countryCode] of countryEntries) {
    if (countryCode === code) {
      return countryName
    }
  }

  return undefined // Return undefined if the code is not found
}
