import './login.scss'
import React, { FC, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { LCButton } from '../../../ui/components/lc-button/LCButton'
import { useAppDispatch } from '../../../redux/hooks'
import { setAuthenticatedUser } from '../../../redux/reducers/authSlice'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { changeLanguage, showLoader } from '../../../redux/reducers/generalSlice'
import { useLoginMutation } from '../../../services/auth.service'
import { LCFormInputText } from '../../../ui/components/forms/lc-form-inputText/lc-form-inputText'
import { useFormik } from 'formik'
import { cachedStorage, eStorageKey } from '../../../utils/cachedStorage'
import { useTranslation } from 'react-i18next'
import packageInfo from '../../../../../package.json'
import { ePublicRoutes } from '../../../routes/e-routes'
import { LCFormInputTextPassword } from '../../../ui/components/forms/lc-form-inputText-password/lc-form-inputText-pawword'
import { LCLanguagesSelection } from '../../../ui/components/lc-languages-selection/LCLanguagesSelection'
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons/faRightToBracket'
import deepEqual from 'deep-equal'
import { toast } from 'react-toastify'
import { LCToast } from '../../../ui/components/lc-toast/Toast'
import LCPopup from '../../../ui/components/lc-popup/LCPopup'
import { LCAcceptance } from './Acceptance'
import { useAcceptTermsAndConditionsMutation, useIsVersionAcceptedMutation } from '../../../services/acceptance.service'

const initialValues = {
  email: '',
  password: '',
  companyId: '',
}

interface ILogin {
  email: string
  password: string
  companyId: string
}

export const Login: FC = () => {
  const [login, { data, error, isLoading }] = useLoginMutation()
  const [updateServerTermsAccepted] = useAcceptTermsAndConditionsMutation()
  const [isTermsAccepted] = useIsVersionAcceptedMutation()
  const dispatch = useAppDispatch()
  let location = useLocation()
  let navigate = useNavigate()
  const { t } = useTranslation('translation', { keyPrefix: 'auth.login' })
  const { t: v } = useTranslation('translation', { keyPrefix: 'validation' })
  const [isValidForm, setIsValidForm] = useState(false)
  const [showAcceptance, setShowAcceptance] = useState(false)
  const [acceptanceVersion, setAcceptanceVersion] = useState('1.0.0')
  const ref = useRef<any>(null)

  const validationRules = {
    companyId: Yup.string().required(v('required', { field: t('company') })),
    password: Yup.string().required(v('required', { field: t('password') })),
    email: Yup.string()
      .email(v('valid', { field: t('email') }))
      .required(v('required', { field: t('email') })),
  }

  useEffect(() => {
    if (showAcceptance) {
      ref?.current.open()
    } else {
      ref?.current.close()
    }
  }, [showAcceptance])

  useEffect(() => {
    if (!error && data) {
      setAcceptanceVersion(data.common.acceptanceVersion)
      isTermsAccepted({ version: data.common.acceptanceVersion, token: data.token, companyToken: data.companyToken })
        .then((response: any) => {
          if (response.data === true) {
            updateState()
            continueSuccess()
          } else {
            setShowAcceptance(true)
          }
        })
        .catch((e) => {
          setShowAcceptance(false)
          toast.error(<LCToast title={t('LoginError.title')} body={t('LoginError.body')} />)
        })
    } else if (error) {
      setShowAcceptance(false)
      toast.error(<LCToast title={t('LoginError.title')} body={t('LoginError.body')} />)
    }
  }, [data, error, dispatch, navigate, location.state])

  useEffect(() => {
    dispatch(showLoader(isLoading))
  }, [isLoading, dispatch])

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object(validationRules),
    onSubmit: (values: any) => {
      login({ companyId: values.companyId, email: values.email, password: values.password })
    },
  })

  useEffect(() => {
    const touched = !deepEqual(formik.values, initialValues)
    setIsValidForm(touched && formik.isValid)
  }, [formik.isValid, formik.touched, formik.values])

  const doChangeLanguage = (language: string) => {
    dispatch(changeLanguage(language))
  }

  const updateState = () => {
    if (data) {
      dispatch(setAuthenticatedUser(data))
      cachedStorage.set(eStorageKey.STATE, { authReducer: data })
    }
  }

  const continueSuccess = () => {
    if (data) {
      let { from } = location.state || { from: '/app/general-dashboard' }
      toast.success(
        <LCToast title={t('loginSuccess.title')} body={t('loginSuccess.body', { user: `${data.user.firstName} ${data.user.lastName}` })} />
      )
      navigate(from)
    }
  }

  const acceptanceClicked = (accepted: boolean) => {
    setShowAcceptance(false)
    // Update the user accept the terms and conditions.
    if (accepted) {
      updateState()
      updateServerTermsAccepted()
      continueSuccess()
    }
  }

  return (
    <div className="login-screen">
      <LCPopup ref={ref} position={'modal'}>
        <LCAcceptance version={acceptanceVersion} callback={acceptanceClicked} />
      </LCPopup>
      <div className="left-side">
        <img className="login-logo" src="./../../../../images/ecommander.png" alt="logo" />
        <img className="login-curve" src="./../../../../images/login-curve.svg" alt="curve" />
        <img className="login-sablul" src="./../../../../images/login-sablul.svg" alt="sablul" />
        <div className="login-version">{t('version', { version: packageInfo.version })}</div>
      </div>
      <div className="right-side">
        <div className="login-wrapper">
          <div className="login-title">{t('signIn')}</div>
          <form className="login-form" onSubmit={formik.handleSubmit}>
            <LCLanguagesSelection languageSelected={doChangeLanguage} />
            <LCFormInputText<ILogin>
              label={t('company')}
              inputId="companyId"
              inputType="text"
              error={formik.errors.companyId}
              {...formik.getFieldProps('companyId')}
            />
            <LCFormInputText<ILogin>
              label={t('email')}
              inputId="email"
              inputType="email"
              error={formik.errors.email}
              {...formik.getFieldProps('email')}
            />
            <LCFormInputTextPassword<ILogin>
              label={t('password')}
              inputId="password"
              error={formik.errors.password}
              {...formik.getFieldProps('password')}
            />

            <LCButton
              disabled={!isValidForm}
              newIcon={faRightToBracket}
              type="submit"
              isLoading={isLoading}
              customStyle={{ marginTop: 20, alignSelf: 'flex-end' }}
              label={t('signIn')}
            />
          </form>
          <div className="links">
            <div className="link-blue" onClick={() => navigate(ePublicRoutes.RESET_PASSWORD)}>
              {t('forgot')}
            </div>
            <div className="link-blue" onClick={() => navigate(ePublicRoutes.TRIAL_REGISTRATION_EMAIL)}>
              {t('registerForATrial')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
