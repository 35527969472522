import './lc-inline-message.scss'
import React, { ReactElement } from 'react'
import { CSSProperties, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SanitizeHTML } from '../lc-sanitize-html/SanitizeHTML'

export interface ISuccessMessage {
  title: string
  body: string
}

export interface ILCInlineMessage {
  clientErrorCode?: string
  successMessage?: ISuccessMessage
  customStyle?: CSSProperties
}

export const LCInlineMessage: FC<ILCInlineMessage> = ({ clientErrorCode, successMessage, customStyle, ...props }: ILCInlineMessage) => {
  return (
    <div className="lc-inline-message" style={customStyle} {...props}>
      {clientErrorCode ? <ErrorMessage clientErrorCode={clientErrorCode} /> : null}
      {successMessage ? <SucceessMessage title={successMessage.title} body={successMessage.body} /> : null}
    </div>
  )
}

const ErrorMessage = ({ clientErrorCode }: { clientErrorCode: string }): ReactElement => {
  const { t } = useTranslation('translation', { keyPrefix: 'client-errors' })
  return (
    <div className="lc-inline-message-error">
      <div className="lc-inline-message-title">{t(`${clientErrorCode}.title`)}</div>
      <SanitizeHTML className="lc-inline-message-body" html={t(`${clientErrorCode}.body`)} />
    </div>
  )
}

const SucceessMessage = ({ title, body }: ISuccessMessage): ReactElement => {
  return (
    <div className="lc-inline-message-success">
      <div className="lc-inline-message-title">{title}</div>
      <SanitizeHTML className="lc-inline-message-body" html={body} />
    </div>
  )
}
