import * as React from 'react'
import { SVGProps } from 'react'

const SvgBell = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.64 16.513h4.023C5.73 18.323 7.254 20 9.5 20c2.236 0 3.769-1.658 3.847-3.487h4.013c1.015 0 1.64-.544 1.64-1.363 0-1.02-.947-1.886-1.836-2.716-.693-.657-.859-1.972-.986-3.315-.136-3.593-1.22-5.994-3.759-6.89C12.04.963 10.955 0 9.5 0S6.961.962 6.59 2.23c-2.548.895-3.622 3.296-3.768 6.889-.127 1.343-.293 2.658-.986 3.316C.947 13.264 0 14.13 0 15.15c0 .82.625 1.363 1.64 1.363Zm.742-1.773v-.114c.254-.276.82-.781 1.299-1.315.674-.753.986-2.144 1.084-3.954.117-3.821 1.328-5.107 2.89-5.517.244-.067.37-.181.39-.438.049-.963.606-1.61 1.455-1.61.86 0 1.406.647 1.455 1.61.02.257.156.371.39.438 1.572.41 2.773 1.696 2.89 5.517.108 1.81.42 3.201 1.084 3.954.478.534 1.025 1.039 1.28 1.315v.114H2.381ZM9.5 18.466c-1.103 0-1.894-.772-1.962-1.953h3.934c-.058 1.172-.859 1.953-1.972 1.953Z"
      fill="currentColor"
    />
  </svg>
)

export default SvgBell
