import './delete-user-modal.scss'
import { CSSProperties, FC } from 'react'
import { LCButton } from '../../../ui/components/lc-button/LCButton'
import { useTranslation } from 'react-i18next'

export interface Props {
  fullName: string
  onCloseModal: () => void
  onDeleteUser: () => void
  customStyle?: CSSProperties
}

export const DeleteUserModal: FC<Props> = ({ customStyle, fullName, onCloseModal, onDeleteUser }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'users' })

  return (
    <div style={customStyle} className="delete-user-modal">
      {t('delete_user_modal_text', { FULL_NAME: fullName })}
      <div className="buttons">
        <LCButton customStyle={{ marginRight: '10px' }} label={t('delete_user_modal_button_delete')} onClick={onDeleteUser} />
        <LCButton label={t('delete_user_modal_button_cancel')} onClick={onCloseModal} />
      </div>
    </div>
  )
}
