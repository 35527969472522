import React, { HTMLAttributes, CSSProperties, FC } from 'react'
import { BarColor, CustomLabels } from '../../../../../interfaces/dashboard.interface'
import { LCBarChartLevel } from '../../../../ui/components/charts/lc-bar-chart-level'
import { useTranslation } from 'react-i18next'
import { BaseDashboardComponent } from '../../../../ui/components/base-dashboard/BaseDashboardComponent'
import { DashboardComponentTitle } from '../../../../ui/components/base-dashboard/DashboardComponentTitle'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

const EmoRiskEmotionPosition: FC<Props> = ({ customStyle }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.emoRisk.emotionPosition' })

  const isLoading = false
  const error = false

  const data = {
    Depression: [
      { id: 'Depression', title: 'Marketing', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: 'Customer Success Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: 'Account Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: 'Production', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: 'Risk', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: 'HR', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: 'Finance', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: 'VP Product Management', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: 'Frontend Engineer', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: 'Product Management', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: 'HR Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: 'VP Sales', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: 'VP R&D', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: 'VP HR', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: 'CTO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: 'CSO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: 'CMO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: 'CSIO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: 'CIO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: 'CHRO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: 'CFO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: 'CEO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: 'Engineering Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: 'Operations', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: 'Security Engineer', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: 'Sales Person', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: 'Sales', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: 'Driver', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Depression', title: 'Backend Engineer', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
    ],
    Happiness: [
      { id: 'Happiness', title: 'Marketing', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: 'Customer Success Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: 'Account Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: 'Production', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: 'Risk', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: 'HR', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: 'Finance', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: 'VP Product Management', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: 'Frontend Engineer', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: 'Product Management', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: 'HR Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: 'VP Sales', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: 'VP R&D', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: 'VP HR', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: 'CTO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: 'CSO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: 'CMO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: 'CSIO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: 'CIO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: 'CHRO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: 'CFO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: 'CEO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: 'Engineering Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: 'Operations', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: 'Security Engineer', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: 'Sales Person', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: 'Sales', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: 'Driver', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Happiness', title: 'Backend Engineer', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
    ],
    Anger: [
      { id: 'Anger', title: 'Marketing', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: 'Customer Success Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: 'Account Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: 'Production', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: 'Risk', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: 'HR', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: 'Finance', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: 'VP Product Management', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: 'Frontend Engineer', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: 'Product Management', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: 'HR Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: 'VP Sales', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: 'VP R&D', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: 'VP HR', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: 'CTO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: 'CSO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: 'CMO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: 'CSIO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: 'CIO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: 'CHRO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: 'CFO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: 'CEO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: 'Engineering Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: 'Operations', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: 'Security Engineer', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: 'Sales Person', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: 'Sales', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: 'Driver', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Anger', title: 'Backend Engineer', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
    ],
    Stress: [
      { id: 'Stress', title: 'Marketing', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Stress', title: 'Customer Success Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Stress', title: 'Account Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Stress', title: 'Production', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Stress', title: 'Risk', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Stress', title: 'HR', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Stress', title: 'Finance', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Stress', title: 'VP Product Management', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Stress', title: 'Frontend Engineer', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Stress', title: 'Product Management', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Stress', title: 'HR Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Stress', title: 'VP Sales', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Stress', title: 'VP R&D', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Stress', title: 'VP HR', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Stress', title: 'CTO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Stress', title: 'CSO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Stress', title: 'CMO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Stress', title: 'CSIO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Stress', title: 'CIO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Stress', title: 'CHRO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Stress', title: 'CFO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Stress', title: 'CEO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Stress', title: 'Engineering Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Stress', title: 'Operations', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Stress', title: 'Security Engineer', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Stress', title: 'Sales Person', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Stress', title: 'Sales', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Stress', title: 'Driver', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Stress', title: 'Backend Engineer', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
    ],
    Excitement: [
      { id: 'Excitement', title: 'Marketing', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: 'Customer Success Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: 'Account Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: 'Production', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: 'Risk', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: 'HR', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: 'Finance', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: 'VP Product Management', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: 'Frontend Engineer', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: 'Product Management', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: 'HR Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: 'VP Sales', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: 'VP R&D', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: 'VP HR', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: 'CTO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: 'CSO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: 'CMO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: 'CSIO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: 'CIO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: 'CHRO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: 'CFO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: 'CEO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: 'Engineering Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: 'Operations', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: 'Security Engineer', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: 'Sales Person', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: 'Sales', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: 'Driver', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Excitement', title: 'Backend Engineer', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
    ],
    Sadness: [
      { id: 'Sadness', title: 'Marketing', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: 'Customer Success Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: 'Account Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: 'Production', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: 'Risk', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: 'HR', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: 'Finance', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: 'VP Product Management', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: 'Frontend Engineer', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: 'Product Management', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: 'HR Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: 'VP Sales', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: 'VP R&D', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: 'VP HR', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: 'CTO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: 'CSO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: 'CMO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: 'CSIO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: 'CIO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: 'CHRO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: 'CFO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: 'CEO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: 'Engineering Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: 'Operations', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: 'Security Engineer', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: 'Sales Person', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: 'Sales', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: 'Driver', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Sadness', title: 'Backend Engineer', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
    ],
    MentalLoad: [
      { id: 'MentalLoad', title: 'Marketing', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: 'Customer Success Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: 'Account Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: 'Production', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: 'Risk', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: 'HR', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: 'Finance', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: 'VP Product Management', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: 'Frontend Engineer', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: 'Product Management', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: 'HR Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: 'VP Sales', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: 'VP R&D', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: 'VP HR', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: 'CTO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: 'CSO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: 'CMO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: 'CSIO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: 'CIO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: 'CHRO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: 'CFO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: 'CEO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: 'Engineering Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: 'Operations', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: 'Security Engineer', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: 'Sales Person', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: 'Sales', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: 'Driver', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'MentalLoad', title: 'Backend Engineer', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
    ],
    Discomfort: [
      { id: 'Discomfort', title: 'Marketing', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: 'Customer Success Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: 'Account Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: 'Production', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: 'Risk', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: 'HR', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: 'Finance', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: 'VP Product Management', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: 'Frontend Engineer', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: 'Product Management', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: 'HR Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: 'VP Sales', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: 'VP R&D', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: 'VP HR', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: 'CTO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: 'CSO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: 'CMO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: 'CSIO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: 'CIO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: 'CHRO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: 'CFO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: 'CEO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: 'Engineering Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: 'Operations', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: 'Security Engineer', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: 'Sales Person', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: 'Sales', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: 'Driver', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Discomfort', title: 'Backend Engineer', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
    ],
    Dissatisfaction: [
      { id: 'Dissatisfaction', title: 'Marketing', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: 'Customer Success Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: 'Account Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: 'Production', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: 'Risk', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: 'HR', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: 'Finance', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: 'VP Product Management', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: 'Frontend Engineer', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: 'Product Management', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: 'HR Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: 'VP Sales', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: 'VP R&D', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: 'VP HR', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: 'CTO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: 'CSO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: 'CMO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: 'CSIO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: 'CIO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: 'CHRO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: 'CFO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: 'CEO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: 'Engineering Manager', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: 'Operations', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: 'Security Engineer', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: 'Sales Person', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: 'Sales', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: 'Driver', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
      { id: 'Dissatisfaction', title: 'Backend Engineer', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50) },
    ],
    Burnout: [
      {
        id: 'Burnout',
        title: 'Marketing',
        Normal: Math.floor(Math.random() * 50),
        Low: Math.floor(Math.random() * 50),
        Medium: Math.floor(Math.random() * 50),
        High: Math.floor(Math.random() * 50),
        Critical: Math.floor(Math.random() * 50),
      },
      {
        id: 'Burnout',
        title: 'Customer Success Manager',
        Normal: Math.floor(Math.random() * 50),
        Low: Math.floor(Math.random() * 50),
        Medium: Math.floor(Math.random() * 50),
        High: Math.floor(Math.random() * 50),
        Critical: Math.floor(Math.random() * 50),
      },
      {
        id: 'Burnout',
        title: 'Account Manager',
        Normal: Math.floor(Math.random() * 50),
        Low: Math.floor(Math.random() * 50),
        Medium: Math.floor(Math.random() * 50),
        High: Math.floor(Math.random() * 50),
        Critical: Math.floor(Math.random() * 50),
      },
      {
        id: 'Burnout',
        title: 'Production',
        Normal: Math.floor(Math.random() * 50),
        Low: Math.floor(Math.random() * 50),
        Medium: Math.floor(Math.random() * 50),
        High: Math.floor(Math.random() * 50),
        Critical: Math.floor(Math.random() * 50),
      },
      { id: 'Burnout', title: 'Risk', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), Critical: Math.floor(Math.random() * 50) },
      { id: 'Burnout', title: 'HR', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), Critical: Math.floor(Math.random() * 50) },
      { id: 'Burnout', title: 'Finance', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), Critical: Math.floor(Math.random() * 50) },
      {
        id: 'Burnout',
        title: 'VP Product Management',
        Normal: Math.floor(Math.random() * 50),
        Low: Math.floor(Math.random() * 50),
        Medium: Math.floor(Math.random() * 50),
        High: Math.floor(Math.random() * 50),
        Critical: Math.floor(Math.random() * 50),
      },
      {
        id: 'Burnout',
        title: 'Frontend Engineer',
        Normal: Math.floor(Math.random() * 50),
        Low: Math.floor(Math.random() * 50),
        Medium: Math.floor(Math.random() * 50),
        High: Math.floor(Math.random() * 50),
        Critical: Math.floor(Math.random() * 50),
      },
      {
        id: 'Burnout',
        title: 'Product Management',
        Normal: Math.floor(Math.random() * 50),
        Low: Math.floor(Math.random() * 50),
        Medium: Math.floor(Math.random() * 50),
        High: Math.floor(Math.random() * 50),
        Critical: Math.floor(Math.random() * 50),
      },
      {
        id: 'Burnout',
        title: 'HR Manager',
        Normal: Math.floor(Math.random() * 50),
        Low: Math.floor(Math.random() * 50),
        Medium: Math.floor(Math.random() * 50),
        High: Math.floor(Math.random() * 50),
        Critical: Math.floor(Math.random() * 50),
      },
      { id: 'Burnout', title: 'VP Sales', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), Critical: Math.floor(Math.random() * 50) },
      { id: 'Burnout', title: 'VP R&D', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), Critical: Math.floor(Math.random() * 50) },
      { id: 'Burnout', title: 'VP HR', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), Critical: Math.floor(Math.random() * 50) },
      { id: 'Burnout', title: 'CTO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), Critical: Math.floor(Math.random() * 50) },
      { id: 'Burnout', title: 'CSO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), Critical: Math.floor(Math.random() * 50) },
      { id: 'Burnout', title: 'CMO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), Critical: Math.floor(Math.random() * 50) },
      { id: 'Burnout', title: 'CSIO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), Critical: Math.floor(Math.random() * 50) },
      { id: 'Burnout', title: 'CIO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), Critical: Math.floor(Math.random() * 50) },
      { id: 'Burnout', title: 'CHRO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), Critical: Math.floor(Math.random() * 50) },
      { id: 'Burnout', title: 'CFO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), Critical: Math.floor(Math.random() * 50) },
      { id: 'Burnout', title: 'CEO', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), Critical: Math.floor(Math.random() * 50) },
      {
        id: 'Burnout',
        title: 'Engineering Manager',
        Normal: Math.floor(Math.random() * 50),
        Low: Math.floor(Math.random() * 50),
        Medium: Math.floor(Math.random() * 50),
        High: Math.floor(Math.random() * 50),
        Critical: Math.floor(Math.random() * 50),
      },
      {
        id: 'Burnout',
        title: 'Operations',
        Normal: Math.floor(Math.random() * 50),
        Low: Math.floor(Math.random() * 50),
        Medium: Math.floor(Math.random() * 50),
        High: Math.floor(Math.random() * 50),
        Critical: Math.floor(Math.random() * 50),
      },
      {
        id: 'Burnout',
        title: 'Security Engineer',
        Normal: Math.floor(Math.random() * 50),
        Low: Math.floor(Math.random() * 50),
        Medium: Math.floor(Math.random() * 50),
        High: Math.floor(Math.random() * 50),
        Critical: Math.floor(Math.random() * 50),
      },
      {
        id: 'Burnout',
        title: 'Sales Person',
        Normal: Math.floor(Math.random() * 50),
        Low: Math.floor(Math.random() * 50),
        Medium: Math.floor(Math.random() * 50),
        High: Math.floor(Math.random() * 50),
        Critical: Math.floor(Math.random() * 50),
      },
      { id: 'Burnout', title: 'Sales', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), Critical: Math.floor(Math.random() * 50) },
      { id: 'Burnout', title: 'Driver', Normal: Math.floor(Math.random() * 50), Low: Math.floor(Math.random() * 50), Medium: Math.floor(Math.random() * 50), High: Math.floor(Math.random() * 50), Critical: Math.floor(Math.random() * 50) },
      {
        id: 'Burnout',
        title: 'Backend Engineer',
        Normal: Math.floor(Math.random() * 50),
        Low: Math.floor(Math.random() * 50),
        Medium: Math.floor(Math.random() * 50),
        High: Math.floor(Math.random() * 50),
        Critical: Math.floor(Math.random() * 50),
      },
    ],
  }

  return (
    <BaseDashboardComponent customStyle={customStyle} sectionTitle={<DashboardComponentTitle title={t('title')} />} isLoading={isLoading} isSuccess={!!data} isError={!!error}>
      <LCBarChartLevel
        data={data}
        options={{
          color: BarColor.EMO_RISK_LEVEL,
          emotionRisk: 'default',
          label: CustomLabels.EMO_RISK_EMOTION_AGE,
        }}
      />
    </BaseDashboardComponent>
  )
}

export default EmoRiskEmotionPosition
