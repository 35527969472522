import { SVGProps } from 'react'

const SvgPause = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="10" cy="10" r="10" fill="#46C4D7" />
    <rect x="5" y="5" width="30" height="30" stroke="#F4F7FE" fill="#F4F7FE" strokeWidth="5" />
  </svg>
)

export default SvgPause
