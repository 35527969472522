import { generateExcel } from './helpers/generateExcel'
import { generateTablePDF } from './helpers/generateTablePDF'

export enum eExportFileType {
  xlsx = 'xlsx',
  pdf = 'pdf',
}

export interface IExportPDFData {
  company: {
    id: string
    name: string
    address?: string
    phone?: string
    email?: string
    website?: string
    logoUrl?: string
    title?: string
  }
  orientation?: 'landscape' | 'portrait'
}

export interface IExportFileBlob {
  columns?: any
  data?: any
  fileType: eExportFileType
  fileName: string
  additionalData: IExportPDFData
}

export const getExportFileBlob = ({ columns, data, fileType, fileName, additionalData }: IExportFileBlob) => {
  switch (fileType) {
    case 'xlsx':
      generateExcel(columns, data, fileType, fileName)
      break
    case 'pdf':
      generateTablePDF(columns, data, fileType, fileName, additionalData)
      break
    default:
      alert('not supported')
  }
}

export const getExportFileName = (tableName: string) => {
  const date = new Date()
  const dateString = `${date.getFullYear()}_${date.getMonth() + 1}_${date.getDate()}`
  return `${tableName}_${dateString}`
}
