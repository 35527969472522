export enum eSettingsRoutes {
  COMPANY_INFO = '/company-info',
  ADMIN_MANAGMENT = '/management-admins',
  PERMISSIONS = '/management-permissions',
  PERMISSIONS_BY_ROLE = '/management-permissions-by-role',
  ORGANIZATIONAL_STRUCTURE = '/organizational-structure',
  EMAIL = '/emails',
  UX_SETTINGS = '/ux',
  RISK_HR_ASSESSMENTS = '/modules-risk-hr-assessments',
  RISK_HR_SEVERITY_RULE = '/modules-risk-hr-severity',
  RISK_HR_IMPACT_RULES = '/modules-risk-hr-impact',
  EMO_RISK = '/modules-emo-risk',
  PURCHASE_INVOICE = '/purchase-invoices',
  PURCHASE_TO_APPROVE = '/purchase-to-approve',
  PURCHASE_MY_REQUESTS = 'purchase-my-requests',
}
