import React from 'react'
import moment from 'moment'
import { IRolesAndPermissions, IUser } from '@logicalcommander/types'
import { IAdvancedFilterValuesStructure } from '../../../ui/components/advanced-filter/advanced-filter-add-item/LCAdvancedFilterAddItem'
import LCPopup from '../../../ui/components/lc-popup/LCPopup'
import { LCSvg } from '../../../ui/components/lc-svg/lc-svg'
import { getAllRolesDropdown } from '../../../utils/list-helper'

export interface IActivityLogSummary {
  identity: string
  createdAt: Date
  firstName: string
  middleName: string
  lastName: string
  fullName: string
  ipAddress: string
  outcome: string
  details: {
    action: string
    duration?: number
    errorMessage?: string
  }
  roles: string[]
}

export const activity_columns = (navigate: any, t: any, rolesAndPermissions: IRolesAndPermissions[] | undefined) => [
  {
    Header: t('date'),
    accessor: 'createdAt',
    Cell: (row: any) => {
      const al: IActivityLogSummary = row.row.original
      return moment(al.createdAt).format('DD/MM/YYYY HH:mm:ss')
    },
  },
  {
    Header: t('ip'),
    accessor: 'ipAddress',
  },
  {
    Header: t('identity'),
    accessor: 'identity',
  },
  {
    Header: t('role'),
    accessor: 'roles',
    Cell: (row: any) => {
      const al: IActivityLogSummary = row.row.original
      return <div> {al.roles.join(', ')} </div>
    },
    getCellExportValue: (row: any, column: any) => {
      const al: IActivityLogSummary = row.original
      return al.roles.join(', ')
    },
    filter: {
      type: 'dropdown',
      values: rolesAndPermissions && getAllRolesDropdown(rolesAndPermissions),
    } as IAdvancedFilterValuesStructure,
  },
  {
    Header: t('fullName'),
    accessor: 'firstName', // For the sorting
    Cell: (row: any) => {
      const al: IActivityLogSummary = row.row.original
      return (
        <button style={{ textAlign: 'left' }} className="link-button" onClick={() => navigate(`/app/user/${al.identity}`)}>
          {al.fullName}
        </button>
      )
    },
    getCellExportValue: (row: any, column: any) => {
      const user: IUser = row.original
      return user.fullName
    },
    filter: {
      type: 'onlyContains',
    } as IAdvancedFilterValuesStructure,
  },
  // {
  //   Header: t('role'),
  //   accessor: 'roles',
  // },
  {
    Header: t('action'),
    accessor: 'details.action',
    Cell: (row: any) => {
      const al: IActivityLogSummary = row.row.original
      const { action } = al.details
      return <div style={{ width: '200px' }}>{action}</div>
    },
  },
  {
    Header: t('duration'),
    accessor: 'details.duration',
  },
  {
    Header: t('outcome'),
    accessor: 'outcome',
    Cell: (row: any) => {
      const al: IActivityLogSummary = row.row.original
      const { errorMessage } = al.details
      return (
        <div>
          {row.row.original.outcome === 'success' ? (
            <LCSvg name="success" customStyle={{ color: 'green', cursor: 'default' }} />
          ) : (
            <LCPopup
              position={['midleft', 'bottom']}
              toggler={
                <div style={{ textDecoration: 'underline', color: '#0568AE', cursor: 'pointer' }}>
                  <LCSvg name="error" customStyle={{ color: 'red' }} />
                </div>
              }>
              {errorMessage}
            </LCPopup>
          )}
        </div>
      )
    },
  },
]
