import { CSSProperties, FC, HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import { eImpactType } from '@logicalcommander/types'
import { ILCActionPopupItemData, LCActionPopup } from '../lc-action-popup/LCActionPopup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons/faCircleExclamation'

export interface ILCImpactPopupProps {
  impact: eImpactType[]
  customStyle?: CSSProperties
}

export const LCImpactPopup: FC<ILCImpactPopupProps & HTMLAttributes<HTMLDivElement>> = ({
  impact,
  customStyle,
  ...props
}: ILCImpactPopupProps) => {
  const { t } = useTranslation('translation')

  const actionPopupTopicsData: ILCActionPopupItemData[] = impact?.map((i: any) => {
    return {
      reactElement: (
        <div>
          <div style={{ fontFamily: 'SF-Pro-Text-Bold' }}>{t(`settings.riskHrImpactRules.impacts.${i}.name`)}</div>
          <div>{t(`settings.riskHrImpactRules.impacts.${i}.description`)}</div>
        </div>
      ),
    }
  })

  return (
    <div style={customStyle} {...props}>
      {actionPopupTopicsData && actionPopupTopicsData.length > 0 ? (
        <LCActionPopup
          data={actionPopupTopicsData}
          position={['left', 'midbottom']}
          customStyle={{ width: '320px' }}
          anchor={
            <div style={{ cursor: 'pointer', color: '#0568ae' }}>
              {<FontAwesomeIcon icon={faCircleExclamation} size="lg" color="#0568ae" />}
            </div>
          }
        />
      ) : null}
    </div>
  )
}
