import './lc-cached-image.scss'
import React from 'react'
import { CSSProperties, FC, useEffect, useState } from 'react'
import { cachedImagesStorage } from '../../../utils/cachedImagesStorage'
import { useAppSelector } from '../../../redux/hooks'
import { selectAuth } from '../../../redux/reducers/authSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser'

export interface ICachedImageProps {
  cacheKey: string
  url: string
  alt?: string
  height?: number
  width?: number
  customStyle?: CSSProperties
}

export const LCCachedImageAuthenticated: FC<ICachedImageProps> = ({
  url,
  cacheKey,
  alt,
  customStyle,
  height,
  width,
  ...props
}: ICachedImageProps) => {
  const [currentImageURL, setCurrentImageURL] = useState<string | undefined>()
  const [isError, setIsError] = useState(false)
  const [pendingRequest, setPendingRequest] = useState<string>()
  const auth = useAppSelector(selectAuth)

  const fetchImage = (imageUrl: string) => {
    setPendingRequest(imageUrl)
    const headers = new Headers()
    headers.set('Authorization', `Bearer ${auth.token}`)
    headers.set('x-company-token', auth.companyToken)
    fetch(imageUrl, { headers })
      .then((response) => response.blob())
      .then((blob) => {
        const objectURL = URL.createObjectURL(blob)
        cachedImagesStorage.addImage(cacheKey, objectURL)
        setCurrentImageURL(objectURL)
      })
      .catch((error) => {
        console.error('Error fetching image:', error)
      })
      .finally(() => {
        setPendingRequest('')
      })
  }

  useEffect(() => {
    if (url) {
      const response = cachedImagesStorage.getImage(cacheKey)
      if (response) {
        setCurrentImageURL(response)
      } else {
        console.log('pendingRequest', pendingRequest)
        if (pendingRequest !== url && url) {
          fetchImage(url)
        } else {
        }
      }
    }
  }, [url, pendingRequest])

  return (
    <div style={customStyle} {...props}>
      {isError || !currentImageURL ? (
        <FontAwesomeIcon icon={faUser} size="lg" />
      ) : (
        <img
          className="lc-cached-image"
          src={currentImageURL}
          height={height || 40}
          width={width || 40}
          alt={alt}
          onError={() => setIsError(true)}
        />
      )}
    </div>
  )
}
