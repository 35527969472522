import './risk-hr-evaluation-topics.scss'
import { CSSProperties, FC, Fragment, HTMLAttributes, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IEvaluationSpecific } from '../../../../../interfaces/evaluations.interface'
import { LCRiskBalloon } from '../../../../ui/components/lc-risk-balloon/LCRiskBalloon'
import { IRiskHREvaluationResultsTopic } from '@logicalcommander/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { LCImpactPriority } from '../../../../ui/components/lc-impact-priority/LCImpactPriority'
import { LCSeverity } from '../../../../ui/components/lc-severity/LCSeverity'
import { LCImpactPopup } from '../../../../ui/components/lc-impact-popup/LCImpactPopup'
import { LCButton } from '../../../../ui/components/lc-button/LCButton'
import { AudioPlayer } from '../../../../ui/components/lc-audio-player/AudioPlayer'
import { LCRiskHrRiskLegendPopup } from '../../../../ui/components/lc-risk-hr-risks-legend-popup/LCRiskHrRiskLegendPopup'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  evaluation: IEvaluationSpecific | undefined
  customStyle?: CSSProperties
}

export const RiskHrEvaluationTopics: FC<Props> = ({ evaluation, customStyle }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'risk_hr_topics' })
  const [expandedTopics, setExpandedTopics] = useState<boolean[]>(Array(evaluation?.results?.topics.length).fill(false))
  const [allExpanded, setAllExpanded] = useState<boolean>(false)

  if (!evaluation || (evaluation && !evaluation.results) || !evaluation.results?.summary) {
    return null
  }

  const toggleExpand = (index: number) => {
    const newExpandedTopics = [...expandedTopics]
    newExpandedTopics[index] = !newExpandedTopics[index]
    setExpandedTopics(newExpandedTopics)
  }

  const toggleExpandAll = () => {
    const newState = !allExpanded
    setExpandedTopics(Array(evaluation.results?.topics.length).fill(newState))
    setAllExpanded(newState)
  }

  return (
    <div style={customStyle}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <h3>{t('title')}</h3>
        <LCButton onClick={toggleExpandAll} label={t('expandCollapseAll')} />
        <LCRiskHrRiskLegendPopup />
      </div>
      <div className="risk-hr-topics">
        <div className="risk-hr-topics-header">
          <div className="risk-hr-topics-header-item header-text">{t('topics')}</div>
          <div className="risk-hr-topics-header-item">{t('power')}</div>
          <div className="risk-hr-topics-header-item">{t('maxRisk')}</div>
          <div className="risk-hr-topics-header-item">{t('maxSeverity')}</div>
          <div className="risk-hr-topics-header-item">{t('maxImpact')}</div>
          <div className="risk-hr-topics-header-item">{t('impact')}</div>
          <div className="risk-hr-topics-header-item">{'Play'}</div>
        </div>
        {evaluation.results?.topics.map((topic: IRiskHREvaluationResultsTopic, index: number) => (
          <Fragment key={index}>
            <div className="risk-hr-topic-row">
              <div className="risk-hr-topic-item topic-question-text">
                {topic.augmantinText}
                <span onClick={() => toggleExpand(index)} style={{ cursor: 'pointer', marginLeft: '10px' }}>
                  {expandedTopics[index] ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                </span>
              </div>
              <div className="risk-hr-topic-item"></div>
              <div className="risk-hr-topic-item">
                <LCRiskBalloon value={Math.max(...topic.questions.map((q) => q.averageRisk || 0))} />
              </div>
              <div className="risk-hr-topic-item">
                <LCSeverity maxSeverity={Math.max(...topic.questions.map((q) => q.severity || 0))} />
              </div>
              <div className="risk-hr-topic-item">
                {topic.maxImpactPriority ? <LCImpactPriority maxImpactPriority={topic.maxImpactPriority} /> : null}
              </div>
              <div className="risk-hr-topic-item">{topic.impact ? <LCImpactPopup impact={topic.impact} /> : null}</div>
              <div className="risk-hr-topic-item"></div>
            </div>
            {expandedTopics[index] &&
              topic.questions.map((question) => (
                <div key={question.id} className="risk-hr-topic-row expanded-content">
                  <div className="risk-hr-topic-item expanded-content-text">{question.augmantinText}</div>
                  <div className="risk-hr-topic-item">{t(`powerMap.${question.power}`)}</div>
                  <div className="risk-hr-topic-item">{question.averageRisk ? <LCRiskBalloon value={question.averageRisk} /> : null}</div>
                  <div className="risk-hr-topic-item">{question.severity ? <LCSeverity maxSeverity={question.severity} /> : null}</div>
                  <div className="risk-hr-topic-item"></div>
                  <div className="risk-hr-topic-item"></div>
                  <div className="risk-hr-topic-item">
                    <AudioPlayer
                      evaluationDetails={{
                        identity: evaluation.identity,
                        evaluationId: evaluation.evaluationInfo.evaluationId,
                        questionsIds: [question.id],
                      }}
                    />
                  </div>
                </div>
              ))}
          </Fragment>
        ))}
      </div>
    </div>
  )
}
