import './case-details.scss'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import React, { CSSProperties, FC, HTMLAttributes, useEffect, useState } from 'react'
import draftToHtml from 'draftjs-to-html'
import { convertToRaw, EditorState } from 'draft-js'
import 'react-vertical-timeline-component/style.min.css'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CaseHistory } from '../CaseHistory/CaseHistory'
import {
  IUpdateSpecificCasePriorityRequest,
  IUpdateSpecificCaseStatusRequest,
  IUpdateSpecificCaseAssigneeRequest,
  IAddSpecificCaseCommentRequest,
} from '../../../../../../../interfaces/cases.interface'
import {
  useUpdateSpecificCaseStatusMutation,
  useUpdateSpecificCasePriorityMutation,
  useUpdateSpecificCaseAssigneeMutation,
  useAddSpecificCaseCommentMutation,
} from '../../../../../../services/cases.service'
import { LCDropdown, IDropdownOption } from '../../../../../../ui/components/lc-dropdown/LCDropdown'
import { fullName } from '../../../../../../utils/fullname'
import { LCCommentEditor } from '../../../../../../ui/components/lc-comment-editor/LCCommentEditor'
import { LCCommentViewEditor } from '../../../../../../ui/components/lc-comment-view-editor/LCCommentViewEditor'
import { IRiskHRCase, eCasePriority, eCaseStatus } from '@logicalcommander/types'
import { ICachedAdmin, cachedStorage, eStorageKey } from '../../../../../../utils/cachedStorage'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  rhCaseData: IRiskHRCase
  customStyle?: CSSProperties
}

export const CaseDetails: FC<Props> = ({ rhCaseData, customStyle }: Props) => {
  const [updateRiskHrCaseStatus, { data: dataUpdateStatus, error: errorUpdateStatus }] = useUpdateSpecificCaseStatusMutation()
  const [updateRiskHrCasePriority, { data: dataUpdatePriority, error: errorUpdatePriority }] = useUpdateSpecificCasePriorityMutation()
  const [updateRiskHrCaseAssignee, { data: dataUpdateAssignee, error: errorUpdateAssignee }] = useUpdateSpecificCaseAssigneeMutation()
  const [addRiskHrCaseComment, { data: dataAddComment, error: errorAddComment }] = useAddSpecificCaseCommentMutation()
  const [rhCase, setRhCase] = useState<IRiskHRCase>(rhCaseData)
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty())
  const navigate = useNavigate()
  const { t } = useTranslation('translation', { keyPrefix: 'risk_hr_case.details' })
  const { t: p } = useTranslation('translation', { keyPrefix: 'casePriority' })
  const { t: s } = useTranslation('translation', { keyPrefix: 'caseStatus' })
  const admins = cachedStorage.get<ICachedAdmin[]>(eStorageKey.ADMINS) || []

  // Listen to status update
  useEffect(() => {
    if (!errorUpdateStatus && dataUpdateStatus) {
      setRhCase(dataUpdateStatus)
    }
  }, [dataUpdateStatus, errorUpdateStatus])

  // Listen to priority update
  useEffect(() => {
    if (!errorUpdatePriority && dataUpdatePriority) {
      setRhCase(dataUpdatePriority)
    }
  }, [dataUpdatePriority, errorUpdatePriority])

  // Listen to assignee update
  useEffect(() => {
    if (!errorUpdateAssignee && dataUpdateAssignee) {
      setRhCase(dataUpdateAssignee)
    }
  }, [dataUpdateAssignee, errorUpdateAssignee])

  // Listen to add comment
  useEffect(() => {
    if (!errorAddComment && dataAddComment) {
      setRhCase(dataAddComment)
    }
  }, [dataAddComment, errorAddComment])

  // Update priority
  const doChangePriority = (priority: eCasePriority) => {
    const updated: any = { ...rhCase, priority }
    updateRiskHrCasePriority({ caseId: rhCase?.id, priority } as IUpdateSpecificCasePriorityRequest)
    setRhCase(updated)
  }

  // Update status
  const doChangeStatus = (status: eCaseStatus) => {
    const updated: any = { ...rhCase, status }
    updateRiskHrCaseStatus({ caseId: rhCase?.id, status } as IUpdateSpecificCaseStatusRequest)
    setRhCase(updated)
  }

  // Update assignee
  const doChangeAssignee = (assignTo: string) => {
    if (assignTo === rhCase?.assignTo) {
      return
    }
    const updated: any = { ...rhCase, assignTo }
    updateRiskHrCaseAssignee({ caseId: rhCase?.id, assignTo } as IUpdateSpecificCaseAssigneeRequest)
    setRhCase(updated)
  }

  // Add comment
  const onAddComment = (): void => {
    const comment: string = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    const updated: any = { ...rhCase, message: [...(rhCase?.messages || []), comment] }
    addRiskHrCaseComment({ caseId: rhCase?.id, comment } as IAddSpecificCaseCommentRequest)
    setRhCase(updated)
    setEditorState(EditorState.createEmpty())
  }

  const onEditorStateChange = (editorState: EditorState): void => {
    setEditorState(editorState)
  }

  const formatAdminWithIdentity = (adminIdentity: string) => {
    const admin = admins.find((admin) => admin.identity === adminIdentity)
    if (admin) {
      return `${admin.name} (${admin.identity})`
    }
    return `Not found (${adminIdentity})`
  }

  return (
    <div style={customStyle} className="risk-hr-case">
      <div className="title-wrapper">
        <div className="case-id">[{rhCase.id}]</div>
        <div className="title">{rhCase.title}</div>
      </div>
      <div className="dropdowns">
        {t('priority')}
        <LCDropdown
          options={Object.values(eCasePriority).map((i) => {
            return { value: i, label: p(i) }
          })}
          defaultOption={rhCase.priority}
          onChange={(args: IDropdownOption) => doChangePriority(args.value as eCasePriority)}
        />
        {t('status')}
        <LCDropdown
          options={Object.values(eCaseStatus).map((i) => {
            return { value: i, label: s(i) }
          })}
          defaultOption={rhCase.status}
          onChange={(args: IDropdownOption) => doChangeStatus(args.value as eCaseStatus)}
        />
        {t('assignedTo')}
        <LCDropdown
          options={admins.map((admin) => {
            return { label: formatAdminWithIdentity(admin.identity), value: admin.identity }
          })}
          defaultOption={rhCase.assignTo}
          onChange={(args: IDropdownOption) => doChangeAssignee(args.value as string)}
        />
      </div>
      <div className="separator"></div>
      <div className="content-wrapper">
        <div className="left-side">
          <div className="details">
            <h4>{t('details')}</h4>
            <div className="details-item">
              <span className="key">{t('full_name')}</span>
              <button className="link-button" onClick={() => navigate(`/app/user/${rhCase.identity}`)}>
                {rhCase.userInfo ? fullName(rhCase.userInfo) : ''}
              </button>
            </div>
            <div className="details-item">
              <span className="key">{t('identity')}</span>
              <span>{rhCase.identity}</span>
            </div>
            <div className="details-item">
              <span className="key">{t('evaluation_id')}</span>
              <button className="link-button" onClick={() => navigate(`/app/riskhr/report/${rhCase.identity}/${rhCase.evaluationId}`)}>
                {rhCase.evaluationId}
              </button>
            </div>
            <div className="details-item">
              <span className="key">{t('evaluation_type')}</span>
              <span>{rhCase.assessmentInfo.typeId}</span>
            </div>
            <div className="details-item">
              <span className="key">{t('evaluation_name')}</span>
              <span>{rhCase.assessmentInfo.nameId}</span>
            </div>
            <div className="details-item">
              <span className="key">{t('evaluation_language')}</span>
              {/* <span>{rhCase.assessmentInfo.language}</span> */}
              <span>Israel</span>
            </div>
            <div className="details-item">
              <span className="key">{t('evaluation_code')}</span>
              <span>{rhCase.assessmentInfo.id}</span>
            </div>
          </div>

          <LCCommentEditor editorState={editorState} onEditorStateChange={onEditorStateChange} onAddComment={onAddComment} />

          <div className="comments">
            <h4>{t('messages')}</h4>
            {rhCase.messages?.map((msg, index) => (
              <LCCommentViewEditor key={index} comment={msg.message} identity={msg.identity} date={msg.date} />
            ))}
          </div>
        </div>
        <div className="separator-horizontal"></div>
        <div className="right-side">
          <CaseHistory history={rhCase.history || []} />
        </div>
      </div>
    </div>
  )
}
