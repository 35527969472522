import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons/faCircleExclamation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { ActiveEditRulesInterface, TitleTranslateInterface } from './severityAndImpact.interface'
import { useAppDispatch } from '../../../../../../hooks/redux-hooks'
import { useLazyGetAssessmentsTopicsQuery } from '../../../../../../services/assessments.service'
import { IUpdateSeveritySettings, useUpdateSeveritySettingsMutation } from '../../../../../../services/settings.service'
import { LCToast } from '../../../../../../ui/components/lc-toast/Toast'
import { setSeveritySettings } from '../../../../../../redux/reducers/settingsSlice'
import { LCButton } from '../../../../../../ui/components/lc-button/LCButton'
import { SettingsSeverityTable } from '../settingsSeverityTable/SettingsSeverityTable'

export const titleText = ({ titleTranslate }: TitleTranslateInterface) => {
  const title = titleTranslate.match(/[^.]+$/)
  return title ? title[0] : ''
}
export const SettingsRiskSeverityRules = () => {
  const dispatch = useAppDispatch()
  const [getEvaluationTopics, { data: dataTopics, error: errorTopics }] = useLazyGetAssessmentsTopicsQuery()
  const { t } = useTranslation('translation', { keyPrefix: 'settings.severityRules' })
  const { t: tError } = useTranslation('translation', { keyPrefix: 'settings.severityRules.errorDataSeverity' })
  const [activedEditView, setActivedEditView] = useState<boolean>(false)
  const [updateSeveritySettings] = useUpdateSeveritySettingsMutation()

  useEffect(() => {
    getEvaluationTopics('en')
  }, [])

  useEffect(() => {
    if (errorTopics && !dataTopics) {
      toast.error(
        <LCToast
          title={tError('title', { title: titleText({ titleTranslate: t('titleSeverity') }) })}
          body={tError('body', { title: titleText({ titleTranslate: t('titleSeverity') }) })}
        />
      )
    }
  }, [dataTopics])

  const activedEditRulesSeverity = ({ actived }: ActiveEditRulesInterface) => {
    setActivedEditView(actived)
  }

  const handleChange = async (newChanges: IUpdateSeveritySettings) => {
    if (newChanges.severityPositions) {
      const severityPositions = newChanges.severityPositions.map((position) => ({
        id: position.id,
        topicsTRF: position.topicsTRF,
      }))
      const payload = { severityPositions }

      console.log('Payload to send:', JSON.stringify(payload))

      try {
        const response = await updateSeveritySettings(payload).unwrap()
        console.log('Update successful:', response)
        dispatch(setSeveritySettings(response))
      } catch (error) {
        console.error('Update failed:', error)
      }
    }
  }

  const resetSeverityFields = () => {}

  return (
    <div className="container-settings-risk-severity-rules">
      <div className="container-table-position">
        <div className="container-button-edit">
          <div className="legend-container-informative-settings">
            <div
              className="legend-container-informative-widget"
              style={{ display: 'flex', flexDirection: 'row', columnGap: 6, alignItems: 'center' }}>
              <FontAwesomeIcon icon={faCircleExclamation} size="lg" color="#555B6A" />

              <span>{t('infoTextHeaderTable')}</span>
            </div>
          </div>

          {activedEditView ? (
            <LCButton
              className="button-closed-edit-rules"
              label={t('close')}
              onClick={() => activedEditRulesSeverity({ actived: false })}
            />
          ) : (
            <LCButton className="button-edit-rules" label={t('editRules')} onClick={() => activedEditRulesSeverity({ actived: true })} />
          )}

          {activedEditView && <LCButton className="button-edit-rules" label={t('reset')} onClick={resetSeverityFields} />}
        </div>

        <SettingsSeverityTable topics={dataTopics} editing={activedEditView} onSave={handleChange} />
      </div>
    </div>
  )
}
