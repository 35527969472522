import { FC } from 'react'
import Dropdown, { Group, Option } from 'react-dropdown'
import 'react-dropdown/style.css'
import { CSSProperties } from 'styled-components'
import './lc-dropdown.scss'

export type IDropdownOption = Option
export type IDropdownGroup = Group

export interface Props {
  options: (IDropdownOption | IDropdownGroup | string)[]
  defaultOption?: IDropdownOption | string
  placeholder?: string
  disabled?: boolean
  label?: string
  customStyle?: CSSProperties
  customDropdwonContainerStyle?: CSSProperties
  onChange?: (args: IDropdownOption) => void
  classNameFromParent?: string
}

export const LCDropdown: FC<Props> = ({
  options = [],
  defaultOption = '',
  placeholder = '',
  disabled,
  label,
  classNameFromParent = '',
  customStyle,
  onChange,
  ...props
}: Props) => {
  const classes = ['lc-dropdown', disabled ? 'disabled' : '', classNameFromParent].join(' ').trim()

  return (
    <div className="lc-dropdown-Wrapper" style={customStyle}>
      {label ? <label className="inputText-label">{label}</label> : null}
      <Dropdown
        className={classes}
        disabled={disabled}
        options={options}
        onChange={onChange}
        value={defaultOption}
        placeholder={placeholder}
        {...props}
      />
    </div>
  )
}
