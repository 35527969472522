import './lc-impact-priority.scss'
import { CSSProperties, FC, HTMLAttributes } from 'react'
import ClassNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { getMaxImpactPriority } from './lc-impact-priority-helper'

export interface ILCImpactPriorityProps {
  maxImpactPriority: number
  customStyle?: CSSProperties
}

export const LCImpactPriority: FC<ILCImpactPriorityProps & HTMLAttributes<HTMLDivElement>> = ({
  maxImpactPriority,
  customStyle,
  ...props
}: ILCImpactPriorityProps) => {
  const { t } = useTranslation('translation')

  const wrapper = ClassNames({
    balloonWrapper: true,
    veryLow: maxImpactPriority === 1,
    low: maxImpactPriority === 2,
    medium: maxImpactPriority === 3,
    high: maxImpactPriority === 4,
    critical: maxImpactPriority === 5,
  })

  return (
    <div className={wrapper} style={customStyle} {...props}>
      {getMaxImpactPriority(maxImpactPriority, t)}
    </div>
  )
}
