import React, { FC, ReactElement, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useRevokeAdminAccessMutation } from '../../../../../../services/user.service'
import { IYesNoDialogData, LCYesNoDialog } from '../../../../../../ui/components/lc-yes-no-dialog/LCYesNoDialog'
import { IUser } from '@logicalcommander/types'
import { toast } from 'react-toastify'
import { LCToast } from '../../../../../../ui/components/lc-toast/Toast'

interface Props {
  admin: IUser
  onCloseDialog?: () => void
}

export const RevokeAccessDialog: FC<Props> = ({ admin, onCloseDialog }: Props): ReactElement => {
  const { t } = useTranslation('translation', { keyPrefix: 'settings.admins.revoke-modal' })
  const [revokeAdminAccess] = useRevokeAdminAccessMutation()
  const dialogRef = useRef<any>(null)

  const revokeDialodData = (fullname: string): IYesNoDialogData => {
    return {
      title: t('dialogTitle', { fullname }),
      text: t('dialogText'),
      yesButtonText: t('dialogYesButton'),
      noButtonText: t('dialogNoButton'),

      yesButtonCallback: function (): void {
        revokeAdminAccess({ identity: admin.identity })
          .then((response: any) => {
            toast.success(
              <LCToast
                title={t('successRevokeAdminAccess.title')}
                body={t('successRevokeAdminAccess.body', { fullname: admin.fullName, companyName: admin.companyId })}
              />
            )
            onCloseDialog && onCloseDialog()
            dialogRef.current?.close()
          })
          .catch(() => {
            toast.error(
              <LCToast
                title={t('errorRevokeAdminAccess.title')}
                body={t('errorRevokeAdminAccess.body', { fullname: admin.fullName, companyName: admin.companyId })}
              />
            )
          })
      },
    }
  }

  return <LCYesNoDialog isLink={true} toggler={t('button')} dialogData={revokeDialodData(admin.fullName || '')} />
}
