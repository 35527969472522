import { parse2Json, parse2string } from './stringify'

export interface ICachedAdmin {
  name: string
  identity: string
}

export enum eStorageKey {
  STATE = 'state',
  ADMINS = 'cached-admins',
  GROUPED_PERMISSIONS = 'grouped-permissions',
  LEFT_MENU_EXPANDED = 'leftmenu-expanded',
}

export const cachedStorage = {
  get: <T>(key: eStorageKey): T | undefined => {
    const result = localStorage.getItem(key)
    if (result) {
      const resultParsed = parse2Json(result) as T
      return resultParsed
    }
    return undefined
  },
  set: (key: eStorageKey, value: any): void => {
    const resultParsed = parse2string(value)
    localStorage.setItem(key, resultParsed)
  },
  exists: (key: eStorageKey): boolean => {
    return !!cachedStorage.get(key)
  },
  clear: (key: eStorageKey): void => localStorage.setItem(key, ''),
}
