import React, { FC, HTMLAttributes, lazy, useEffect, useState } from 'react'
import { CSSProperties } from 'styled-components'
import { sumBy } from 'lodash'
import { BarColor, CustomLabels } from '../../../../../interfaces/dashboard.interface'
import { useLazyGetRisksPerTopicQuery } from '../../../../services/dashboard.service'
import { ITitleValueNumber, ISearch, eSearchType } from '@logicalcommander/types'
import { useTranslation } from 'react-i18next'
import { BaseDashboardComponent } from '../../../../ui/components/base-dashboard/BaseDashboardComponent'
import { DashboardComponentTitle } from '../../../../ui/components/base-dashboard/DashboardComponentTitle'

const LCPieChartUniNew = lazy(() => import('../../../../ui/components/charts/lc-pie-chart'))

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

const RiskHrDashboardRiskPieTopic5Important: FC<Props> = ({ customStyle }: Props) => {
  const { t } = useTranslation('translate', { keyPrefix: 'dashboard.riskHr.riskPieTopic5Importan' })

  const [getDashboardRisksPerTopic, { data, isLoading, error }] = useLazyGetRisksPerTopicQuery()
  const [riskPerTopic, setRiskPerTopic] = useState<ITitleValueNumber[]>([])
  const [sumTotal, setSumTotal] = useState(0)

  useEffect(() => {
    const search: ISearch[] = [{ keyName: 'aaaa', type: eSearchType.DATE_AFTER, value: '123213' }]
    getDashboardRisksPerTopic({ search })
  }, [])

  useEffect(() => {
    if (!error && data) {
      const dataPercentage = [...data].sort(sortRisk)
      const sumAlert = sumBy(data, 'value')
      const sorted = dataPercentage
        .map((d) => {
          const data = {
            ...d,
            percentage: (d.value * 100) / sumAlert,
          }
          return data
        })
        .slice(0, 5)
      setSumTotal(sumAlert)
      setRiskPerTopic(sorted)
    }
  }, [data, error])

  const sortRisk = (a: ITitleValueNumber, b: ITitleValueNumber) => {
    return b.value - a.value
  }

  return (
    <BaseDashboardComponent customStyle={customStyle} sectionTitle={<DashboardComponentTitle title={t('title')} subTitle={t('total')} />} isLoading={isLoading} isSuccess={riskPerTopic.length > 0} isError={!!error}>
      <LCPieChartUniNew
        data={riskPerTopic}
        dataTotal={sumTotal}
        options={{
          color: BarColor.COLOR_STATUS,
          label: CustomLabels.STATUS_EVALUATION_PIE,
          customPercentage: true,
        }}
      />
    </BaseDashboardComponent>
  )
}

export default RiskHrDashboardRiskPieTopic5Important
