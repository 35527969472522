import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

interface EvaluationsState {
  aaa: any
}

const initialState: EvaluationsState = {
  aaa: undefined,
}

export const evaluationsSlice = createSlice({
  name: 'evaluationsReducer',
  initialState,
  reducers: {
    placeHolderForNewFunctions: (state, action: PayloadAction<any[]>) => {
      state.aaa = action.payload
    },
  },
})

export const { placeHolderForNewFunctions } = evaluationsSlice.actions
export default evaluationsSlice.reducer
export const selectEvaluations = (state: RootState) => state.evaluationsReducer
