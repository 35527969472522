import './dashboard-components-title.scss'
import React, { CSSProperties, FC, HTMLAttributes } from 'react'


export interface Props extends HTMLAttributes<HTMLDivElement> {
    title: string
    subTitle?: string
    customStyle?: CSSProperties
}

export const DashboardComponentTitle: FC<Props> = ({ title, subTitle, customStyle }: Props) => {

    return (
        <div className='bdc-title-wrapper' style={customStyle}>
            <div className='bdc-title' >{title}</div>
            <div className='bdc-sub-title' >{subTitle}</div>
        </div>
    )

}


