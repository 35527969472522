import React, { FC, HTMLAttributes, lazy, useEffect, useState } from 'react'
import { CSSProperties } from 'styled-components'
import { BarColor, CustomLabels } from '../../../../../interfaces/dashboard.interface'
import { useLazyGetRisksPerTopicQuery } from '../../../../services/dashboard.service'
import { ITitleValueNumber, ISearch, eSearchType } from '@logicalcommander/types'
import { useTranslation } from 'react-i18next'
import { BaseDashboardComponent } from '../../../../ui/components/base-dashboard/BaseDashboardComponent'
import { DashboardComponentTitle } from '../../../../ui/components/base-dashboard/DashboardComponentTitle'

const LCPieChartUniNew = lazy(() => import('../../../../ui/components/charts/lc-pie-chart'))

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

const RiskHrDashboardRiskPieTopic: FC<Props> = ({ customStyle }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.riskHr.riskPieTopic' })
  const [getDashboardRisksPerTopic, { data, isLoading, error }] = useLazyGetRisksPerTopicQuery()
  const [alertRisk, setAlertRisk] = useState<ITitleValueNumber[]>([])

  useEffect(() => {
    const search: ISearch[] = [{ keyName: 'aaaa', type: eSearchType.DATE_AFTER, value: '123212' }]
    getDashboardRisksPerTopic({ search })
  }, [])

  useEffect(() => {
    if (!error && data) {
      const sorted = [...data].sort(sortByRisk)
      setAlertRisk(sorted)
    }
  }, [data, error])

  const sortByRisk = (a: ITitleValueNumber, b: ITitleValueNumber) => {
    return b.value - a.value
  }

  return (
    <BaseDashboardComponent customStyle={customStyle} sectionTitle={<DashboardComponentTitle title={t('title')} subTitle={t('total')} />} isLoading={isLoading} isSuccess={alertRisk.length > 0} isError={!!error}>
      <LCPieChartUniNew
        data={alertRisk}
        options={{
          color: BarColor.COLOR_STATUS,
          label: CustomLabels.STATUS_EVALUATION_PIE,
          customPercentage: false,
        }}
      />
    </BaseDashboardComponent>
  )
}

export default RiskHrDashboardRiskPieTopic
