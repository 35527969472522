import { SVGProps } from 'react'

const SvgFilterList = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
    <polygon
      points="175.96 472.863 269.722 401.675 269.722 287.772 433.789 45.731 222.7 45.731 11.88 45.731 175.96 287.772"
      stroke="currentColor"
      strokeWidth={13}
    />
    <ellipse stroke="currentColor" strokeWidth={9} fill="#ffffff" cx="356.987" cy="270.059" rx="140" ry="140" />
    <rect x="278.142" y="217.277" width="148.428" height="23.283" rx="11.642" ry="11.642" />
    <rect x="277.014" y="247.963" width="148.428" height="23.283" rx="11.642" ry="11.642" />
    <rect x="277.85" y="278.141" width="148.428" height="23.283" rx="11.642" ry="11.642" />
    <rect x="277.686" y="308.319" width="148.428" height="23.283" rx="11.642" ry="11.642" />
  </svg>
)

export default SvgFilterList
