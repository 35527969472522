import React from 'react'
import { LCPage } from '../../../ui/components/lc-page/LCPage'
import './risk-hr-analytics.scss'
import { CSSProperties, FC, HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import { faChartLine } from '@fortawesome/free-solid-svg-icons/faChartLine'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

export const RiskHrAnalyticsPage: FC<Props> = ({ customStyle }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'menu' })

  return (
    <LCPage icon={faChartLine} title={t('risk_hr')} breadcrumbs="Analytics">
      <div className="risk-hr-analytics" style={customStyle}>
        Coming soon
      </div>
    </LCPage>
  )
}
