import { sumBy } from 'lodash'
import React, { FC, HTMLAttributes } from 'react'
import { CSSProperties } from 'styled-components'
import { BarColor, CustomLabels } from '../../../../../interfaces/dashboard.interface'
import LCPieChartUniNew from '../../../../ui/components/charts/lc-pie-chart'
import { ITitleValueNumber } from '@logicalcommander/types'
import { useTranslation } from 'react-i18next'
import { BaseDashboardComponent } from '../../../../ui/components/base-dashboard/BaseDashboardComponent'
import { DashboardComponentTitle } from '../../../../ui/components/base-dashboard/DashboardComponentTitle'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

const RiskHrDashboardCaseStatus: FC<Props> = ({ customStyle }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.riskHr.riskCaseStatus' })

  const error = false
  const isLoading = false

  const data = [
    {
      title: 'New',
      value: 500,
    },
    {
      title: 'Pending',
      value: 300,
    },
    {
      title: 'InProgress',
      value: 900,
    },
    {
      title: 'Closed',
      value: 640,
    },
  ]

  const sumCaseStatus = sumBy([...data], 'value')

  return (
    <BaseDashboardComponent
      customStyle={customStyle}
      sectionTitle={<DashboardComponentTitle title={`${t('titleAssessment')} ${t('titleStatus')}`} subTitle={`${t('total')} ${sumCaseStatus}`} />}
      isLoading={isLoading}
      isSuccess={!!data}
      isError={!!error}>
      <LCPieChartUniNew
        data={data as ITitleValueNumber[]}
        dataTotal={sumCaseStatus}
        options={{
          color: BarColor.COLOR_STATUS,
          label: CustomLabels.ALERT_RISK,
          customPercentage: false,
        }}
      />
    </BaseDashboardComponent>
  )
}

export default RiskHrDashboardCaseStatus
