import React, { HTMLAttributes, useEffect } from 'react'
import { CSSProperties } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useLazyGetRiskHrAverageStateQuery } from '../../../../services/dashboard.service'
import { LCTitleValue } from '../../../../ui/components/charts/lc-title-value'
import { ITitleValueString } from '@logicalcommander/types'
import { BaseDashboardComponent } from '../../../../ui/components/base-dashboard/BaseDashboardComponent'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  customStyle?: CSSProperties
}

export const RiskHrDashboardAverageState = ({ customStyle }: Props) => {
  const [getRiskHRAverageState, { data, error, isLoading }] = useLazyGetRiskHrAverageStateQuery()
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.riskhr_average_state' })

  useEffect(() => {
    getRiskHRAverageState()
  }, [])

  return (
    <BaseDashboardComponent customStyle={customStyle} sectionTitle={t('title')} isLoading={isLoading} isSuccess={!!data} isError={!!error}>
      <LCTitleValue data={data as ITitleValueString[]} />
    </BaseDashboardComponent>
  )
}
