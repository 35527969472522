import './lc-advanced-filter-item.scss'
import { CSSProperties, FC, HTMLAttributes } from 'react'
import { LCSvg } from '../../lc-svg/lc-svg'
import { useTranslation } from 'react-i18next'
import { datesHelper } from '../../../../utils/dates'
import { ISearch, eSearchType } from '@logicalcommander/types'

export interface Props extends HTMLAttributes<any> {
  index: number
  filter: ISearch
  onDeleteFilter: (filter: ISearch) => void
  customStyle?: CSSProperties
}

export interface IAdvancedFilterKey {
  label: string
  value: string
}

export const LCAdvancedFilterItem: FC<Props> = ({ customStyle, onDeleteFilter, index, filter, ...props }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'filters' })

  const renderValue = (): string => {
    switch (filter.type) {
      case eSearchType.DATE_AFTER:
      case eSearchType.DATE_BEFORE:
        return datesHelper.formatShort(new Date(filter.value))
      default:
        return filter.value // t(`values.${filter.value}`)
    }
  }

  return (
    <div style={customStyle} className="lc-advanced-filter-item" {...props}>
      <div className="lc-advanced-filter-item-text">
        <span className="key">{t(`keys.${filter.keyName}`)}</span>
        <span className="operator">{filter.type}</span>
        <span className="value">{renderValue()}</span>
      </div>
      <LCSvg
        name="xIcon"
        customStyle={{ marginTop: '2px', marginLeft: '20px', color: '#ffffff' }}
        onClick={() => onDeleteFilter(filter)}
        widthAndHeight={[10, 10]}
      />
    </div>
  )
}
