import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { v4 as uuidv4 } from 'uuid'
import { RootState } from '../redux/store'
import { ITestEmailData } from '@logicalcommander/types'
import { configManager } from '../../config/configManager'

export const mailerApi = createApi({
  reducerPath: 'mailerApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${configManager.api.GATEWAY_URL}/mailer`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('x-request-id', uuidv4())
      const { token, companyToken } = (getState() as RootState).authReducer
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      if (companyToken) {
        headers.set('x-company-token', companyToken)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    sendEmail: builder.mutation<any, { data: ITestEmailData; template: any }>({
      query: ({ data, template }) => ({
        url: `/send-email/${template}`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
})

export const { useSendEmailMutation } = mailerApi
